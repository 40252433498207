import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Link } from "@swan-io/lake/src/components/Link";
import { t } from "../utils/i18n";

type Props = {
  to: string;
  size?: "small" | "large";
  external?: boolean;
  disabled?: boolean;
};

export const LinkInputButton = ({
  to,
  external = false,
  size = "large",
  disabled = false,
}: Props) => {
  const button = (
    <LakeButton
      disabled={disabled}
      ariaLabel={t("linkButton.navigate")}
      mode="secondary"
      size={size}
      icon={external ? "open-filled" : "arrow-right-filled"}
    />
  );

  return disabled ? (
    button
  ) : (
    <LakeTooltip content={t("linkButton.navigate")} togglableOnFocus={true} placement="left">
      <Link to={to} target="blank">
        {button}
      </Link>
    </LakeTooltip>
  );
};
