import { P, match } from "ts-pattern";
import {
  CapitalDepositDocumentType,
  Category,
  ConsentPurpose,
  DocumentReasonCode,
  RejectedReasonCode,
} from "../graphql/partner";
import { DiffPeriodUnit } from "./date";
import { isTranslationKey, t } from "./i18n";

const UBO_FIELD_REGEX = /individualUltimateBeneficialOwners\[(\d+)\]\.(.+)/;

export const getOnboardingMissingFieldLabel = (field: string) =>
  match(`onboarding.general.fieldMissing.${field}`)
    .with(P.when(isTranslationKey), key => t(key))
    .otherwise(() => {
      const [, index, subField] = field.match(UBO_FIELD_REGEX) ?? [];

      if (index == null || subField == null) {
        return field;
      }

      return t("onboarding.general.individualUboFieldMissing", {
        number: String(index),
        subField: match(`onboarding.general.individualUboFieldMissing.${subField}`)
          .with(P.when(isTranslationKey), key => t(key))
          .otherwise(() => field),
      });
    });

export const getWiseIctLabel = (key: string) =>
  match(`transaction.internationalCreditTransfer.${key}`)
    .with(P.when(isTranslationKey), key => t(key))
    .otherwise(() => key);

export const getTransactionRejectedReasonLabel = (reason: RejectedReasonCode) => {
  try {
    return match(`transaction.reason.${reason}`)
      .with(P.when(isTranslationKey), key => t(key))
      .exhaustive();
  } catch {
    return reason;
  }
};

export const tTransactionCategory = (category: Category) => {
  try {
    return match(`transaction.category.${category}`)
      .with(P.when(isTranslationKey), key => t(key))
      .exhaustive();
  } catch {
    return category;
  }
};

export const getCapitalDepositDocumentType = (type: CapitalDepositDocumentType) => {
  try {
    return match(`capitalDeposit.documents.type.${type}`)
      .with(P.when(isTranslationKey), key => t(key))
      .exhaustive();
  } catch {
    return type;
  }
};

export const getCapitalDepositDocumentRejectionReason = (reason: DocumentReasonCode) => {
  try {
    return match(`capitalDepositDocument.rejectionReason.${reason}`)
      .with(P.when(isTranslationKey), key => t(key))
      .exhaustive();
  } catch {
    return reason;
  }
};

export const getConsentPurpose = (purpose: ConsentPurpose) => {
  try {
    return match(`consent.purpose.${purpose}`)
      .with(P.when(isTranslationKey), key => t(key))
      .exhaustive();
  } catch {
    return (
      purpose.charAt(0) +
      purpose
        .slice(1)
        .replace(/([A-Z])/g, " $1")
        .toLowerCase()
    );
  }
};

export const getDiffAgoLabel = (diff: { value: number; unit: DiffPeriodUnit }) =>
  match(diff)
    .with({ unit: "minute" }, ({ value }) => t("duration.minutesAgo", { count: value }))
    .with({ unit: "hour" }, ({ value }) => t("duration.hoursAgo", { count: value }))
    .with({ unit: "day" }, ({ value }) => t("duration.daysAgo", { count: value }))
    .with({ unit: "week" }, ({ value }) => t("duration.weeksAgo", { count: value }))
    .with({ unit: "month" }, ({ value }) => t("duration.monthsAgo", { count: value }))
    .with({ unit: "year" }, ({ value }) => t("duration.yearsAgo", { count: value }))
    .exhaustive();
