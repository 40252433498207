import { Array, AsyncData, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Portal } from "@swan-io/lake/src/components/Portal";
import { WithCurrentColor } from "@swan-io/lake/src/components/WithCurrentColor";
import { lowerCase } from "@swan-io/lake/src/utils/string";
import { SkipToContent } from "@swan-io/shared-business/src/components/SkipToContent";
import { useCallback, useMemo } from "react";
import { P, match } from "ts-pattern";
import { EnvType, ProjectAreaDocument } from "../graphql/admin";
import { PermissionsProvider } from "../hooks/usePermissions";
import { ProjectInfoProvider } from "../hooks/useProjectInfo";
import { AdminPage } from "../pages/AdminPage";
import { NotFoundPage } from "../pages/NotFoundPage";
import { TrackingProvider } from "../utils/matomo";
import { Router, finiteRouteNames } from "../utils/routes";
import { ActivateProjectLayer } from "./ActivateProjectLayer";
import { BackToDashboard } from "./BackToDashboard";
import { ErrorView } from "./ErrorView";
import { Header } from "./Header";
import { LiveAccessGate } from "./LiveAccessGate";
import { ProjectNavigation } from "./ProjectNavigation";
import { ProjectPlaceholderView } from "./ProjectPlaceholderView";
import { ProjectRoot } from "./ProjectRoot";
import { ProjectWithEnvArea } from "./ProjectWithEnvArea";

const rootNode = document.querySelector("#full-page-layer-root") as Element;

type Props = {
  projectId: string;
  isProjectActivationLayerOpen: boolean;
};

export const ProjectArea = ({ projectId, isProjectActivationLayerOpen }: Props) => {
  const route = Router.useRoute(["ProjectRoot", "ProjectAdminArea", "ProjectWithEnvArea"]);

  const [data, { refresh }] = useQuery(ProjectAreaDocument, { projectId });

  const userAndProjectInfo = useMemo(
    () =>
      data.mapOkToResult(data => {
        return match(data)
          .with(
            {
              user: P.select("user", { memberships: P.array() }),
              project: P.select("project", P.nonNullable),
            },
            ({ user, project }) => {
              const projectMembership = Array.findMap(user.memberships, membership =>
                Option.fromNullable(membership.project).flatMap(project =>
                  project.id === projectId ? Option.Some(membership) : Option.None(),
                ),
              );

              const isProjectLive = match(project.status)
                .with(
                  "PendingLiveReview",
                  "LimitedLiveAccess",
                  "BetaLiveAccess",
                  "FullLiveAccess",
                  () => true,
                )
                .otherwise(() => false);

              const hasProjectFullLiveAccess = match(project.status)
                .with("Enabled", "FullLiveAccess", () => true)
                .otherwise(() => false);

              const projectDisplayInfo = Option.fromNullable(
                isProjectLive
                  ? (project.liveProjectSettings ?? project.sandboxProjectSettings)
                  : project.sandboxProjectSettings,
              );

              return Option.allFromDict({ projectMembership, projectDisplayInfo })
                .toResult(undefined)
                .map(({ projectMembership, projectDisplayInfo }) => ({
                  user,
                  project,
                  isProjectLive,
                  hasProjectFullLiveAccess,
                  projectMembership,
                  projectDisplayInfo,
                }));
            },
          )
          .otherwise(() => Result.Error(undefined));
      }),
    [data, projectId],
  );

  const onProjectEnvChange = useCallback((envType: EnvType) => {
    const route = Router.getRoute(finiteRouteNames);

    if (route != null && "projectEnv" in route.params) {
      // @ts-expect-error
      Router.push(route.name, {
        ...route.params,
        projectEnv: lowerCase(envType),
      });
    }
  }, []);

  return (
    <ProjectInfoProvider projectId={projectId} projectEnv="sandbox">
      {match(userAndProjectInfo)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <ProjectPlaceholderView />)
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(
          AsyncData.P.Done(Result.P.Ok(P.select())),
          ({
            user,
            project,
            projectDisplayInfo,
            isProjectLive,
            hasProjectFullLiveAccess,
            projectMembership,
          }) => (
            <PermissionsProvider value={projectMembership.roleV2?.permissions ?? undefined}>
              {match({ route, isProjectLive })
                .with(
                  { route: { name: "ProjectRoot" } },
                  ({
                    route: {
                      params: { projectId, alreadyInvited },
                    },
                  }) => (
                    <ProjectRoot
                      projectId={projectId}
                      projectName={projectDisplayInfo.name}
                      isProjectLive={isProjectLive}
                      alreadyInvited={alreadyInvited}
                    />
                  ),
                )
                .with(
                  { route: { name: "ProjectAdminArea" } },
                  ({
                    route: {
                      params: { projectId },
                    },
                  }) => (
                    <ProjectInfoProvider projectId={projectId} projectEnv="sandbox">
                      <WithCurrentColor variant="partner">
                        <>
                          <SkipToContent />
                          <Header hasProjectFullLiveAccess={hasProjectFullLiveAccess} user={user} />
                          <BackToDashboard />

                          <TrackingProvider category="Admin">
                            <AdminPage currentUserMembershipId={projectMembership.id} />
                          </TrackingProvider>
                        </>
                      </WithCurrentColor>
                    </ProjectInfoProvider>
                  ),
                )
                .with(
                  { route: { name: "ProjectWithEnvArea" } },
                  ({
                    route: {
                      params: { projectId, projectEnv },
                    },
                  }) => {
                    const shouldBlockLiveSection = projectEnv === "live" && !isProjectLive;

                    return (
                      <ProjectInfoProvider projectId={projectId} projectEnv={projectEnv}>
                        <WithCurrentColor variant={projectEnv}>
                          <>
                            <SkipToContent />

                            <Header
                              hasProjectFullLiveAccess={hasProjectFullLiveAccess}
                              user={user}
                            />

                            <ProjectNavigation
                              disabled={shouldBlockLiveSection}
                              onProjectEnvChange={onProjectEnvChange}
                            />

                            {shouldBlockLiveSection ? (
                              <LiveAccessGate />
                            ) : (
                              <ProjectWithEnvArea
                                projectId={projectId}
                                projectEnv={projectEnv}
                                project={project}
                                projectMembership={projectMembership}
                                user={user}
                                isProjectLive={isProjectLive}
                              />
                            )}
                          </>
                        </WithCurrentColor>
                      </ProjectInfoProvider>
                    );
                  },
                )
                .otherwise(() => (
                  <NotFoundPage />
                ))}

              <Portal container={rootNode}>
                <ActivateProjectLayer
                  visible={isProjectActivationLayerOpen}
                  currentUser={user}
                  projectName={projectDisplayInfo.name}
                  projectStatus={project.status ?? "Initiated"}
                  onProjectUpdate={() => {
                    refresh();
                  }}
                  contractSigned={project.contractSigned}
                />
              </Portal>
            </PermissionsProvider>
          ),
        )
        .exhaustive()}
    </ProjectInfoProvider>
  );
};
