import { ClientContext } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { ErrorBoundary } from "@swan-io/lake/src/components/ErrorBoundary";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { usePersistedState } from "@swan-io/lake/src/hooks/usePersistedState";
import { lowerCase } from "@swan-io/lake/src/utils/string";
import { CONTENT_ID } from "@swan-io/shared-business/src/components/SkipToContent";
import { useEffect } from "react";
import { match } from "ts-pattern";
import {
  LoggedUserInfoFragment,
  ProjectBasicInfoFragment,
  ProjectMembershipFragment,
} from "../graphql/admin";
import { ProjectEnv } from "../hooks/useProjectInfo";
import { NotFoundPage } from "../pages/NotFoundPage";
import {
  livePartnerByProjectIdClient,
  livePartnerByProjectIdClient__projectMember,
  sandboxPartnerByProjectIdClient,
  sandboxPartnerByProjectIdClient__projectMember,
} from "../utils/gql";
import { logFrontendError, setSentryUser } from "../utils/logger";
import { TrackingProvider, useSessionTracking } from "../utils/matomo";
import { Router, projectSectionsRoutes } from "../utils/routes";
import { updateTgglContext, useTgglFlag } from "../utils/tggl";
import { DataArea } from "./DataArea";
import { DevelopersArea } from "./DevelopersArea";
import { ErrorView } from "./ErrorView";
import { LiveInsightsArea } from "./LiveInsightsArea";
import { Redirect } from "./Redirect";
import { SandboxInsightsArea } from "./SandboxInsightsArea";
import { SettingsArea } from "./SettingsArea";

type Props = {
  projectId: string;
  projectEnv: ProjectEnv;
  user: LoggedUserInfoFragment;
  project: ProjectBasicInfoFragment;
  projectMembership: ProjectMembershipFragment;
  isProjectLive: boolean;
};

export const ProjectWithEnvArea = ({
  projectId,
  projectEnv,
  user,
  project,
  projectMembership,
}: Props) => {
  const [, setProjectState] = usePersistedState<{ projectId: string; projectEnv?: ProjectEnv }>(
    "swan_session_projectState",
    { projectId, projectEnv },
  );

  const shouldUseProjectMemberToken = useTgglFlag("dashboardProjectMemberToken").getOr(false);

  const client = match({ projectEnv, shouldUseProjectMemberToken })
    .with({ projectEnv: "live", shouldUseProjectMemberToken: true }, () =>
      livePartnerByProjectIdClient__projectMember(projectId),
    )
    .with({ projectEnv: "live" }, () => livePartnerByProjectIdClient(projectId))
    .with({ projectEnv: "sandbox", shouldUseProjectMemberToken: true }, () =>
      sandboxPartnerByProjectIdClient__projectMember(projectId),
    )
    .with({ projectEnv: "sandbox" }, () => sandboxPartnerByProjectIdClient(projectId))
    .exhaustive();

  const role = projectMembership.roleV2?.name ?? "Unknown";

  useSessionTracking({
    loaded: true,
    role,
    projectEnv,
    projectId,
  });

  const userId = projectMembership.userId ?? undefined;
  const email = projectMembership.email ?? undefined;

  useEffect(() => {
    updateTgglContext({
      environmentType: lowerCase(projectEnv),
      email,
      projectId,
      userId,
    });
  }, [projectId, projectEnv, userId, email]);

  useEffect(() => {
    setProjectState({ projectId, projectEnv });
  }, [projectEnv, projectId, setProjectState]);

  useEffect(() => {
    setSentryUser({
      id: user.id,
      firstName: user.firstName ?? undefined,
      lastName: user.preferredLastName ?? undefined,
      phoneNumber: user.mobilePhoneNumber ?? undefined,
    });
  }, [user]);

  const route = Router.useRoute(projectSectionsRoutes);

  return (
    <Box id={CONTENT_ID} tabIndex={0} direction="row" style={commonStyles.fill}>
      <ErrorBoundary
        key={route?.name}
        onError={error => logFrontendError(error)}
        fallback={() => <ErrorView />}
      >
        {match(route)
          .with({ name: "ProjectWithEnvRoot" }, ({ params: { projectId, projectEnv } }) => (
            <Redirect to={Router.UserList({ projectId, projectEnv })} />
          ))
          .with({ name: "DataArea" }, ({ name, params }) => (
            <TrackingProvider category="Data">
              <ClientContext.Provider value={client}>
                <DataArea key={`${name}.${params.projectEnv}`} membershipEmail={email} />
              </ClientContext.Provider>
            </TrackingProvider>
          ))
          .with({ name: "InsightsArea" }, ({ params }) => (
            <TrackingProvider category="Insights">
              {params.projectEnv === "live" ? <LiveInsightsArea /> : <SandboxInsightsArea />}
            </TrackingProvider>
          ))
          .with({ name: "SettingsArea" }, () => (
            <TrackingProvider category="Settings">
              <SettingsArea user={user} />
            </TrackingProvider>
          ))
          .with({ name: "DevelopersArea" }, ({ params }) => {
            const projectStatus =
              params.projectEnv === "sandbox" ? (project.status ?? undefined) : undefined;

            return (
              <TrackingProvider category="Developers">
                <DevelopersArea projectStatus={projectStatus} />
              </TrackingProvider>
            );
          })
          .with({ name: "SandboxActivation" }, () => (
            <TrackingProvider category="Activation">
              <Redirect to="/?activate=true" />
            </TrackingProvider>
          ))
          .otherwise(() => (
            <NotFoundPage />
          ))}
      </ErrorBoundary>
    </Box>
  );
};
