import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useFocusReset } from "@swan-io/chicane";
import { useQuery } from "@swan-io/graphql-client";
import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { TileGridPlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { useMemo, useRef } from "react";
import { P, match } from "ts-pattern";
import { GetOnboardingDetailsPageDocument } from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { NotFoundPage } from "../pages/NotFoundPage";
import { t } from "../utils/i18n";
import { Router, onboardingDetailRoutes } from "../utils/routes";
import { ErrorView } from "./ErrorView";
import { OnboardingDetailGeneral } from "./OnboardingDetailGeneral";
import { Redirect } from "./Redirect";
import { SupportingDocumentsCollectionArea } from "./SupportingDocumentsCollectionArea";
import { UltimateBeneficialOwnerList } from "./UltimateBeneficialOwnerList";

type Props = {
  onboardingId: string;
};

export const OnboardingDetails = ({ onboardingId }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const route = Router.useRoute(onboardingDetailRoutes);
  const containerRef = useRef(null);

  useFocusReset({ route, containerRef });

  const [data] = useQuery(GetOnboardingDetailsPageDocument, { onboardingId });

  useCrumb(
    useMemo(
      () =>
        data
          .toOption()
          .flatMap(result => result.toOption())
          .flatMap(data => Option.fromNullable(data.onboarding))
          .map(onboarding => ({
            label: onboarding.email ?? t("common.unknown"),
            link: Router.OnboardingDetailRoot({ projectId, projectEnv, onboardingId }),
          }))
          .toUndefined(),
      [projectId, projectEnv, data, onboardingId],
    ),
  );

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <TileGridPlaceholder />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok({ onboarding: P.nullish })), () => (
      <Redirect to={Router.OnboardingRoot({ projectId, projectEnv })} />
    ))
    .with(AsyncData.P.Done(Result.P.Ok({ onboarding: P.select(P.nonNullable) })), onboarding => (
      <>
        <TabView
          tabs={[
            {
              label: t("onboarding.general"),
              url: Router.OnboardingDetailRoot({ projectId, projectEnv, onboardingId }),
            },
            ...(onboarding.info.__typename === "OnboardingCompanyAccountHolderInfo" &&
            onboarding.info.type === "Company"
              ? [
                  {
                    label: t("onboarding.ultimateBeneficialOwners"),
                    url: Router.OnboardingDetailUltimateBeneficialOwners({
                      projectId,
                      projectEnv,
                      onboardingId,
                    }),
                  },
                ]
              : []),
            {
              label: t("onboarding.supportingDocuments"),
              url: Router.OnboardingDetailSupportingDocuments({
                projectId,
                projectEnv,
                onboardingId,
                supportingDocumentCollectionId: onboarding.supportingDocumentCollection.id,
              }),
            },
          ]}
          otherLabel={t("common.tabs.other")}
        />

        <Space height={24} />

        {match(route)
          .with({ name: "OnboardingDetailRoot" }, () => (
            <LakeScrollView>
              <OnboardingDetailGeneral onboarding={onboarding} />
            </LakeScrollView>
          ))
          .with({ name: "OnboardingDetailUltimateBeneficialOwners" }, () =>
            match(onboarding.info)
              .with({ __typename: "OnboardingCompanyAccountHolderInfo" }, info =>
                info.type === "Company" ? (
                  <UltimateBeneficialOwnerList
                    ultimateBeneficialOwners={info.individualUltimateBeneficialOwners ?? []}
                    extraInfo={{ projectEnv, projectId, reexecuteQuery: () => {} }}
                    emptyListTitle={t("ultimateBeneficialOwner.list.emptyTitle")}
                  />
                ) : (
                  <NotFoundPage />
                ),
              )
              .otherwise(() => <NotFoundPage />),
          )
          .with(
            { name: "OnboardingDetailSupportingDocuments" },
            { name: "OnboardingDetailSupportingDocumentsNew" },
            ({ params: { supportingDocumentCollectionId } }) => (
              <SupportingDocumentsCollectionArea
                onboardingId={onboardingId}
                supportingDocumentCollectionId={supportingDocumentCollectionId}
              />
            ),
          )
          .otherwise(() => (
            <NotFoundPage />
          ))}
      </>
    ))
    .exhaustive();
};
