import { Box } from "@swan-io/lake/src/components/Box";
import { InformationTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Space } from "@swan-io/lake/src/components/Space";
import { t, TranslationKey } from "../utils/i18n";
import { DocumentationName, LakeDocumentationLink } from "./DocumentationLink";

type Props = {
  link: DocumentationName;
  description: TranslationKey;
};

export const LearnMore = ({ link, description }: Props) => (
  <Box direction="row" justifyContent="end" alignItems="center">
    <LakeDocumentationLink to={link}>{t("common.learnMore")}</LakeDocumentationLink>
    <Space width={16} />
    <InformationTooltip text={t(description)} />
  </Box>
);
