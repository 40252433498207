import { useFocusReset } from "@swan-io/chicane";
import { Box } from "@swan-io/lake/src/components/Box";
import { Breadcrumbs, BreadcrumbsRoot } from "@swan-io/lake/src/components/Breadcrumbs";
import { InformationTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Space } from "@swan-io/lake/src/components/Space";
import { useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { AccountMembershipListPage } from "../pages/AccountMembershipListPage";
import { NotFoundPage } from "../pages/NotFoundPage";
import { t } from "../utils/i18n";
import { Router, accountMembershipsRoutes } from "../utils/routes";
import { AccountMembershipDetail } from "./AccountMembershipDetail";
import { LakeDocumentationLink } from "./DocumentationLink";

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
});

export const AccountMembershipsArea = () => {
  const { projectEnv, projectId } = useProjectInfo();
  const route = Router.useRoute(accountMembershipsRoutes);
  const containerRef = useRef(null);

  const rootLevelCrumbs = useMemo(
    () => [
      {
        label: t("accountMemberships.all"),
        link: Router.AccountMembershipsRoot({ projectId, projectEnv }),
      },
    ],
    [projectEnv, projectId],
  );

  useFocusReset({ route, containerRef });

  return (
    <BreadcrumbsRoot rootLevelCrumbs={rootLevelCrumbs}>
      <Box direction="row" justifyContent="spaceBetween" alignItems="center">
        <Breadcrumbs />

        <Box direction="row" justifyContent="end" alignItems="center">
          <LakeDocumentationLink to="accountMembership">
            {t("common.learnMore")}
          </LakeDocumentationLink>

          <Space width={16} />
          <InformationTooltip text={t("accountMembership.subtitle")} />
        </Box>
      </Box>

      <Space height={16} />

      <View ref={containerRef} style={styles.container}>
        {match(route)
          .with({ name: "AccountMembershipsRoot" }, ({ params }) => (
            <AccountMembershipListPage params={params} />
          ))
          .with({ name: "AccountMembershipsDetailArea" }, ({ params: { accountMembershipId } }) => (
            <AccountMembershipDetail accountMembershipId={accountMembershipId} />
          ))
          .otherwise(() => (
            <NotFoundPage />
          ))}
      </View>
    </BreadcrumbsRoot>
  );
};
