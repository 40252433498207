import { Array, AsyncData, Option, Result } from "@swan-io/boxed";
import { ClientError, useDeferredQuery, useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { Space } from "@swan-io/lake/src/components/Space";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors, spacings } from "@swan-io/lake/src/constants/design";
import { useAsyncDataSuspense } from "@swan-io/lake/src/hooks/useAsyncDataSuspense";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useCallback, useEffect } from "react";
import { StyleSheet } from "react-native";
import { P, match } from "ts-pattern";
import {
  ProjectMembershipByIdDocument,
  ProjectMembershipByIdQuery,
  RemoveMembershipDocument,
} from "../../graphql/admin";
import { useProjectInfo } from "../../hooks/useProjectInfo";
import { t } from "../../utils/i18n";
import { ErrorView } from "../ErrorView";

const styles = StyleSheet.create({
  buttonsContainer: {
    paddingTop: spacings[24],
  },
});

type Props = {
  membershipId: Option<string>;
  onDelete: () => void;
  onCancel: () => void;
};

const DeleteMemberConfirmationModalContents = ({
  data,
  membershipId,
  onCancel,
  onDelete,
}: {
  membershipId: string;
  data: AsyncData<Result<ProjectMembershipByIdQuery, ClientError>>;
  onCancel: () => void;
  onDelete: () => void;
}) => {
  useAsyncDataSuspense(data);

  const [removeMember, memberRemoval] = useMutation(RemoveMembershipDocument);

  const onPressDelete = useCallback(() => {
    removeMember({ membershipId })
      .mapOkToResult(({ removeMembership }) =>
        removeMembership != null ? Result.Ok(removeMembership) : Result.Error(undefined),
      )
      .tapOk(() => {
        showToast({
          variant: "success",
          title: t("toast.success.membersDeleted"),
        });
        onDelete();
      })
      .tapError(error => {
        showToast({ variant: "error", error, title: translateError(error) });
      });
  }, [removeMember, membershipId, onDelete]);

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <LoadingView />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => {
      const projectName = Array.findMap(
        [
          data.projectMembershipById.project?.liveProjectSettings?.name,
          data.projectMembershipById.project?.sandboxProjectSettings?.name,
        ],
        Option.fromNullable,
      );

      return match(projectName)
        .with(Option.P.Some(P.select()), projectName => (
          <>
            <LakeText color={colors.gray[600]}>
              {t("membership.delete.confirm.description", {
                project: projectName,
              })}
            </LakeText>

            <Box
              direction="row"
              justifyContent="spaceBetween"
              alignItems="center"
              style={styles.buttonsContainer}
            >
              <LakeButton mode="secondary" style={commonStyles.fill} onPress={onCancel}>
                {t("common.cancel")}
              </LakeButton>

              <Space width={24} />

              <LakeButton
                color="negative"
                mode="primary"
                onPress={onPressDelete}
                style={commonStyles.fill}
                loading={memberRemoval.isLoading()}
              >
                {t("common.delete")}
              </LakeButton>
            </Box>
          </>
        ))
        .otherwise(() => <ErrorView />);
    })
    .exhaustive();
};

export const DeleteMemberConfirmationModal = ({ membershipId, onCancel, onDelete }: Props) => {
  const { projectId } = useProjectInfo();

  const [data, { query, reset }] = useDeferredQuery(ProjectMembershipByIdDocument);

  useEffect(() => {
    if (membershipId.isSome()) {
      query({
        input: {
          membershipId: membershipId.get(),
          projectId,
        },
      });
    } else {
      reset();
    }
  }, [membershipId, projectId, query, reset]);

  return (
    <LakeModal
      icon="delete-regular"
      onPressClose={onCancel}
      color="negative"
      visible={membershipId.isSome()}
      title={t("membership.delete.confirm.title", {
        email: data
          .toOption()
          .flatMap(result => result.toOption())
          .flatMap(data => Option.fromNullable(data.projectMembershipById.email))
          .getOr(""),
      })}
    >
      {match(membershipId)
        .with(Option.P.Some(P.select()), membershipId => (
          <DeleteMemberConfirmationModalContents
            membershipId={membershipId}
            data={data}
            onCancel={onCancel}
            onDelete={onDelete}
            key={membershipId}
          />
        ))
        .otherwise(() => null)}
    </LakeModal>
  );
};
