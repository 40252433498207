import { ClientContext } from "@swan-io/graphql-client";
import { P, match } from "ts-pattern";
import { ProjectArea } from "./components/ProjectArea";
import { RedirectToRelevantProject } from "./components/RedirectToRelevantProject";
import { ProjectPicker } from "./components/SsoProjectPicker";
import { CreateProjectPage } from "./pages/CreateProjectPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import {
  adminByProjectIdClient,
  adminByProjectIdClient__projectMember,
  adminClient,
} from "./utils/gql";
import { Router } from "./utils/routes";
import { useTgglFlag } from "./utils/tggl";

export const AuthenticatedArea = () => {
  const route = Router.useRoute([
    "BaseSsoProjectPicker",
    "BaseRoot",
    "BaseProjects",
    "BaseStart",
    "ProjectArea",
  ]);

  const shouldUseProjectMemberToken = useTgglFlag("dashboardProjectMemberToken").getOr(false);

  return match(route)
    .with({ name: "BaseSsoProjectPicker" }, () => (
      <ClientContext.Provider value={adminClient}>
        <ProjectPicker />
      </ClientContext.Provider>
    ))
    .with({ name: "BaseRoot" }, { name: "BaseProjects" }, () => (
      <ClientContext.Provider value={adminClient}>
        <RedirectToRelevantProject />
      </ClientContext.Provider>
    ))
    .with({ name: "BaseStart" }, () => (
      <ClientContext.Provider value={adminClient}>
        <CreateProjectPage />
      </ClientContext.Provider>
    ))
    .with({ name: "ProjectArea" }, ({ params: { projectId, activate } }) => (
      <ClientContext.Provider
        value={
          shouldUseProjectMemberToken
            ? adminByProjectIdClient__projectMember(projectId)
            : adminByProjectIdClient(projectId)
        }
      >
        <ProjectArea
          key={projectId}
          projectId={projectId}
          isProjectActivationLayerOpen={activate != null}
        />
      </ClientContext.Provider>
    ))
    .with(P.nullish, () => <NotFoundPage />)
    .exhaustive();
};
