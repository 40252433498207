import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Link } from "@swan-io/lake/src/components/Link";
import { ReadOnlyFieldList } from "@swan-io/lake/src/components/ReadOnlyFieldList";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { colors } from "@swan-io/lake/src/constants/design";
import { useDisclosure } from "@swan-io/lake/src/hooks/useDisclosure";
import { isNotNullish, isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { countries } from "@swan-io/shared-business/src/constants/countries";
import dayjs from "dayjs";
import { useMemo } from "react";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { CancelContractModal, CancelPhysicalModal, SuspendModal } from "../components/CardModals";
import { LinkButton } from "../components/LinkButton";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { Card } from "../pages/CardDetailsPage";
import { getCardHolderName, printMaskedPanFormat } from "../utils/card";
import { formatCurrency, t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { BooleanTag } from "./BooleanTag";
import { TrackPressable } from "./TrackPressable";

const styles = StyleSheet.create({
  unknownValue: {
    fontStyle: "italic",
  },
  cancelContract: {
    width: 180,
  },
});

type Props = {
  card: Card;
  reexecuteQuery: () => void;
};

export const CardDetailsGeneral = ({ card, reexecuteQuery }: Props) => {
  const cardHolderName = getCardHolderName(card).toNull();
  const accountId = card.accountMembership.account?.id;
  const { projectEnv, projectId } = useProjectInfo();
  const canCancelCardContracts = usePermissions(projectEnv).dataCard.write;

  const [cancelPhysicalCardVisible, { open: openPhysicalCard, close: closePhysicalCard }] =
    useDisclosure(false);
  const [suspendCardVisible, { open: openSuspendCard, close: closeSuspendCard }] =
    useDisclosure(false);
  const [cancelContractVisible, { open: openCancelContract, close: closeCancelContract }] =
    useDisclosure(false);

  const unknownValue = useMemo(
    () => <LakeText style={styles.unknownValue}>{t("common.unknown")}</LakeText>,
    [],
  );
  const accountMembership = card.accountMembership;
  const physicalCard = card.physicalCard ?? null;
  const physicalCardStatus = physicalCard?.statusInfo.status;
  const accountName = `${accountMembership.account?.name ?? ""} ${
    accountMembership.account?.number ?? ""
  }`;
  const accountHolderId = accountMembership.account?.holder.id;
  const accountHolderName = accountMembership.account?.holder.info.name;

  return (
    <>
      <TileGrid>
        <Tile title={t("cards.general.cardContract")}>
          <ReadOnlyFieldList>
            <LakeLabel
              label={t("cards.general.cardContract.cardHolder")}
              type="view"
              render={() => (
                <LakeText color={colors.gray[900]}>{cardHolderName ?? unknownValue}</LakeText>
              )}
              actions={
                isNotNullish(accountHolderId) ? (
                  <Link
                    to={Router.AccountMembershipsDetailRoot({
                      projectId,
                      projectEnv,
                      accountMembershipId: accountMembership.id,
                    })}
                  >
                    <Icon size={20} name="arrow-right-filled" />
                  </Link>
                ) : null
              }
            />

            <LakeLabel
              label={t("cards.general.cardContract.account")}
              type="view"
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {isNotNullish(accountName) ? accountName : unknownValue}
                </LakeText>
              )}
              actions={
                isNotNullish(accountName) &&
                isNotNullish(accountId) && (
                  <Link to={Router.AccountDetailRoot({ projectId, projectEnv, accountId })}>
                    <Icon size={20} name="arrow-right-filled" />
                  </Link>
                )
              }
            />

            <LakeLabel
              label={t("cards.general.cardContract.accountHolder")}
              type="view"
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {isNotNullish(accountHolderName) ? accountHolderName : unknownValue}
                </LakeText>
              )}
              actions={
                isNotNullish(accountHolderId) &&
                isNotNullish(accountHolderName) && (
                  <Link
                    to={Router.HoldersDetailRoot({
                      projectId,
                      projectEnv,
                      accountHolderId,
                    })}
                  >
                    <Icon size={20} name="arrow-right-filled" />
                  </Link>
                )
              }
            />

            <LakeLabel
              label={t("cards.general.cardContract.type")}
              type="view"
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {match(card.type)
                    .with("SingleUseVirtual", () => (
                      <Tag color="darkPink" icon="phone-regular">
                        {t("card.type.singleUseVirtual")}
                      </Tag>
                    ))
                    .with("Virtual", () => (
                      <Tag color="mediumSladeBlue" icon="phone-regular">
                        {t("card.type.virtual")}
                      </Tag>
                    ))
                    .with("VirtualAndPhysical", () => (
                      <Tag color="shakespear" icon="payment-regular">
                        {t("card.type.virtualAndPhysical")}
                      </Tag>
                    ))
                    .exhaustive()}
                </LakeText>
              )}
            />

            <LakeLabel
              label={t("cards.general.cardContract.name")}
              type="view"
              render={() => (
                <LakeText color={colors.gray[900]}>{card.name ?? unknownValue}</LakeText>
              )}
            />

            <LakeLabel
              label={t("cards.general.cardContract.status")}
              type="view"
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {match(card.statusInfo.status)
                    .with("ConsentPending", () => (
                      <Tag color="gray">{t("cards.list.status.consentPending")}</Tag>
                    ))
                    .with("Processing", () => (
                      <Tag color="shakespear">{t("cards.list.status.processing")}</Tag>
                    ))
                    .with("Enabled", () => (
                      <Tag color="positive">{t("cards.list.status.enabled")}</Tag>
                    ))
                    .with("Canceling", () => (
                      <Tag color="warning">{t("cards.list.status.canceling")}</Tag>
                    ))
                    .with("Canceled", () => (
                      <Tag color="warning">{t("cards.list.status.canceled")}</Tag>
                    ))
                    .exhaustive()}
                </LakeText>
              )}
            />

            {match(card.statusInfo)
              .with(
                { __typename: "CardCanceledStatusInfo" },
                { __typename: "CardCancelingStatusInfo" },
                ({ reason }) => (
                  <LakeLabel
                    label={t("cards.general.cardContract.reasonMessage")}
                    type="view"
                    render={() => <LakeText color={colors.gray[900]}> {reason}</LakeText>}
                  />
                ),
              )
              .otherwise(() => null)}

            <LakeLabel
              label={t("cards.general.cardContract.contractExpiryDate")}
              type="view"
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {isNotNullish(card.cardContractExpiryDate) ? (
                    card.cardContractExpiryDate
                  ) : (
                    <Tag color="gray">{t("cards.list.unlimited")}</Tag>
                  )}
                </LakeText>
              )}
            />

            <LakeLabel
              label={t("cards.general.cardContract.id")}
              type="view"
              render={() => <LakeText color={colors.gray[900]}>{card.id}</LakeText>}
              actions={
                <LakeCopyButton
                  valueToCopy={card.id}
                  copyText={t("copyButton.copyTooltip")}
                  copiedText={t("copyButton.copiedTooltip")}
                />
              }
            />

            <LakeLabel
              label={t("cards.general.cardContract.createdDate")}
              type="view"
              render={() => (
                <LakeText color={colors.gray[900]}>{dayjs(card.createdAt).format("LLL")}</LakeText>
              )}
            />

            <LakeLabel
              label={t("cards.general.cardContract.updatedDate")}
              type="view"
              render={() => (
                <LakeText color={colors.gray[900]}>{dayjs(card.updatedAt).format("LLL")}</LakeText>
              )}
            />
          </ReadOnlyFieldList>
        </Tile>

        <Tile title={t("cards.general.virtual")}>
          <ReadOnlyFieldList>
            <LakeLabel
              label={t("cards.general.virtual.cardNumber")}
              type="view"
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {card.cardMaskedNumber !== ""
                    ? printMaskedPanFormat(card.cardMaskedNumber)
                    : unknownValue}
                </LakeText>
              )}
            />

            <LakeLabel
              label={t("cards.general.virtual.expiryDate")}
              type="view"
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {isNotNullish(card.expiryDate) ? (
                    card.expiryDate
                  ) : (
                    <Tag color="gray">{t("cards.list.unlimited")}</Tag>
                  )}
                </LakeText>
              )}
            />
          </ReadOnlyFieldList>
        </Tile>

        {isNotNullish(physicalCard) &&
          match(physicalCard.statusInfo)
            .with(
              { __typename: "PhysicalCardToActivateStatusInfo" },
              { __typename: "PhysicalCardRenewedStatusInfo" },
              ({ address, estimatedDeliveryDate, trackingNumber, shippingProvider }) => {
                const providerUrl: string = match(shippingProvider)
                  .with(
                    "La Poste",
                    () =>
                      `https://www.laposte.fr/outils/suivre-vos-envois?code=${
                        trackingNumber ?? ""
                      }`,
                  )
                  .with(
                    "DHL",
                    () =>
                      `https://www.dhl.com/fr-fr/home/suivi.html?tracking-id=${
                        trackingNumber ?? ""
                      }`,
                  )
                  .otherwise(() => "");

                return (
                  <>
                    <Space height={24} />

                    <Tile title={t("cards.general.shipping")}>
                      <ReadOnlyFieldList>
                        <LakeLabel
                          label={t("cards.general.shipping.estimatedDeliveryDate")}
                          type="view"
                          render={() => (
                            <LakeText color={colors.gray[900]}>
                              {isNotNullish(estimatedDeliveryDate)
                                ? dayjs(estimatedDeliveryDate).format("LL")
                                : unknownValue}
                            </LakeText>
                          )}
                        />

                        <LakeLabel
                          label={t("cards.general.shipping.provider")}
                          type="view"
                          render={() => (
                            <LakeText color={colors.gray[900]}>
                              {isNotNullish(shippingProvider) ? shippingProvider : unknownValue}
                            </LakeText>
                          )}
                        />

                        <LakeLabel
                          label={t("cards.general.shipping.trackingNumber")}
                          type="view"
                          render={() => (
                            <LakeText color={colors.gray[900]}>
                              {trackingNumber !== "" ? trackingNumber : unknownValue}
                            </LakeText>
                          )}
                          actions={
                            trackingNumber !== "" &&
                            providerUrl !== "" && <LinkButton external={true} to={providerUrl} />
                          }
                        />

                        <LakeLabel
                          label={t("cards.general.shipping.address")}
                          type="view"
                          render={() => (
                            <LakeText color={colors.gray[900]}>
                              {isNotNullishOrEmpty(address.addressLine1)
                                ? address.addressLine1
                                : unknownValue}
                            </LakeText>
                          )}
                        />

                        <LakeLabel
                          label={t("cards.general.shipping.addressLine2")}
                          type="view"
                          render={() => (
                            <LakeText color={colors.gray[900]}>
                              {isNotNullishOrEmpty(address.addressLine2)
                                ? address.addressLine2
                                : unknownValue}
                            </LakeText>
                          )}
                        />

                        <LakeLabel
                          label={t("cards.general.shipping.postalCode")}
                          type="view"
                          render={() => (
                            <LakeText color={colors.gray[900]}>
                              {isNotNullishOrEmpty(address.postalCode)
                                ? address.postalCode
                                : unknownValue}
                            </LakeText>
                          )}
                        />

                        <LakeLabel
                          label={t("cards.general.shipping.city")}
                          type="view"
                          render={() => (
                            <LakeText color={colors.gray[900]}>
                              {isNotNullishOrEmpty(address.city)
                                ? `${address.city.charAt(0).toUpperCase()}${address.city.slice(1)}`
                                : unknownValue}
                            </LakeText>
                          )}
                        />

                        <LakeLabel
                          label={t("cards.general.shipping.country")}
                          type="view"
                          render={() => {
                            const country = countries.find(c => c.cca3 === address.country);
                            return (
                              <LakeText color={colors.gray[900]}>
                                {isNotNullish(country)
                                  ? `${country.flag} ${country.name}`
                                  : unknownValue}
                              </LakeText>
                            );
                          }}
                        />
                      </ReadOnlyFieldList>
                    </Tile>
                  </>
                );
              },
            )
            .otherwise(() => null)}

        <Tile title={t("cards.general.limits")}>
          <ReadOnlyFieldList>
            <LakeLabel
              label={t("cards.general.limits.spendingLimit.partner")}
              type="view"
              render={() => {
                const limit = card.spendingLimits?.find(limit => limit.type === "Partner") ?? null;
                return (
                  <LakeText color={colors.gray[900]}>
                    {isNotNullish(limit)
                      ? match(limit.period)
                          .with("Always", () =>
                            formatCurrency(parseFloat(limit.amount.value), limit.amount.currency),
                          )
                          .with("Daily", () =>
                            t("cards.general.limits.spendingLimit.daily", {
                              limit: formatCurrency(
                                parseFloat(limit.amount.value),
                                limit.amount.currency,
                              ),
                            }),
                          )
                          .with("Weekly", () =>
                            t("cards.general.limits.spendingLimit.weekly", {
                              limit: formatCurrency(
                                parseFloat(limit.amount.value),
                                limit.amount.currency,
                              ),
                            }),
                          )
                          .with("Monthly", () =>
                            t("cards.general.limits.spendingLimit.monthly", {
                              limit: formatCurrency(
                                parseFloat(limit.amount.value),
                                limit.amount.currency,
                              ),
                            }),
                          )
                          .exhaustive()
                      : "-"}
                  </LakeText>
                );
              }}
            />

            <LakeLabel
              label={t("cards.general.limits.spendingLimit.accountHolder")}
              type="view"
              render={() => {
                const limit =
                  card.spendingLimits?.find(limit => limit.type === "AccountHolder") ?? null;
                return (
                  <LakeText color={colors.gray[900]}>
                    {isNotNullish(limit)
                      ? match(limit.period)
                          .with("Always", () =>
                            formatCurrency(parseFloat(limit.amount.value), limit.amount.currency),
                          )
                          .with("Daily", () =>
                            t("cards.general.limits.spendingLimit.daily", {
                              limit: formatCurrency(
                                parseFloat(limit.amount.value),
                                limit.amount.currency,
                              ),
                            }),
                          )
                          .with("Weekly", () =>
                            t("cards.general.limits.spendingLimit.weekly", {
                              limit: formatCurrency(
                                parseFloat(limit.amount.value),
                                limit.amount.currency,
                              ),
                            }),
                          )
                          .with("Monthly", () =>
                            t("cards.general.limits.spendingLimit.monthly", {
                              limit: formatCurrency(
                                parseFloat(limit.amount.value),
                                limit.amount.currency,
                              ),
                            }),
                          )
                          .exhaustive()
                      : "-"}
                  </LakeText>
                );
              }}
            />

            <LakeLabel
              label={t("cards.general.spending")}
              type="view"
              render={() => {
                const spending = card.spending ?? null;
                const amount = spending?.amount ?? null;

                return (
                  <LakeText color={colors.gray[900]}>
                    {isNotNullish(spending) && isNotNullish(amount)
                      ? match(spending.period)
                          .with("Always", () =>
                            formatCurrency(parseFloat(amount.value), amount.currency),
                          )
                          .with("Daily", () =>
                            t("cards.general.limits.spendingLimit.daily", {
                              limit: formatCurrency(parseFloat(amount.value), amount.currency),
                            }),
                          )
                          .with("Weekly", () =>
                            t("cards.general.limits.spendingLimit.weekly", {
                              limit: formatCurrency(parseFloat(amount.value), amount.currency),
                            }),
                          )
                          .with("Monthly", () =>
                            t("cards.general.limits.spendingLimit.monthly", {
                              limit: formatCurrency(parseFloat(amount.value), amount.currency),
                            }),
                          )
                          .exhaustive()
                      : "-"}
                  </LakeText>
                );
              }}
            />

            <LakeLabel
              label={t("cards.general.eCommerce")}
              type="view"
              render={() => <BooleanTag value={card.eCommerce} />}
            />

            <LakeLabel
              label={t("cards.general.international")}
              type="view"
              render={() => <BooleanTag value={card.international} />}
            />

            <LakeLabel
              label={t("cards.general.nonEuro")}
              type="view"
              render={() => <BooleanTag value={card.nonMainCurrencyTransactions} />}
            />

            <LakeLabel
              label={t("cards.general.withdrawal")}
              type="view"
              render={() => <BooleanTag value={card.withdrawal} />}
            />
          </ReadOnlyFieldList>
        </Tile>

        {isNotNullish(physicalCard) && (
          <Tile
            title={t("cards.general.physicalCard")}
            headerEnd={
              <Box direction="row">
                {(physicalCardStatus === "Activated" || physicalCardStatus === "Renewed") && (
                  <>
                    <TrackPressable action="Suspend physical card">
                      <LakeButton
                        size="small"
                        color="warning"
                        icon="lock-closed-regular"
                        mode="secondary"
                        onPress={openSuspendCard}
                      >
                        {t("cards.general.physicalCard.suspend")}
                      </LakeButton>
                    </TrackPressable>

                    <Space width={12} />
                  </>
                )}

                {physicalCardStatus !== "Canceled" && (
                  <TrackPressable action="Cancel physical card">
                    <LakeButton
                      size="small"
                      color="negative"
                      mode="secondary"
                      icon="subtract-circle-regular"
                      onPress={openPhysicalCard}
                    >
                      {t("cards.general.physicalCard.cancel")}
                    </LakeButton>
                  </TrackPressable>
                )}
              </Box>
            }
          >
            {physicalCardStatus === "Suspended" && (
              <LakeAlert variant="warning" title={t("cards.general.physicalCard.suspended")} />
            )}

            <ReadOnlyFieldList>
              <LakeLabel
                label={t("cards.general.physicalCard.identifier")}
                type="view"
                render={() => (
                  <LakeText color={colors.gray[900]}>
                    {physicalCard.identifier ?? unknownValue}
                  </LakeText>
                )}
              />

              <LakeLabel
                label={t("cards.general.physical.cardNumber")}
                type="view"
                render={() => (
                  <LakeText color={colors.gray[900]}>
                    {physicalCard.cardMaskedNumber !== ""
                      ? printMaskedPanFormat(physicalCard.cardMaskedNumber)
                      : unknownValue}
                  </LakeText>
                )}
              />

              <LakeLabel
                label={t("cards.general.physicalCard.expiryDate")}
                type="view"
                render={() => (
                  <LakeText color={colors.gray[900]}>
                    {isNotNullish(physicalCard.expiryDate)
                      ? physicalCard.expiryDate
                      : match(physicalCard.statusInfo.status)
                          .with("Canceled", () => unknownValue)
                          .otherwise(() => <Tag color="gray">{t("cards.list.unlimited")}</Tag>)}
                  </LakeText>
                )}
              />

              <LakeLabel
                label={t("cards.general.physicalCards.spendingLimit")}
                type="view"
                render={() => (
                  <LakeText color={colors.gray[900]}>
                    {formatCurrency(
                      parseFloat(physicalCard.offlineSpendingLimit.value),
                      physicalCard.offlineSpendingLimit.currency,
                    )}
                  </LakeText>
                )}
              />

              <LakeLabel
                label={t("cards.general.physicalCards.status")}
                type="view"
                render={() => (
                  <LakeText color={colors.gray[900]}>
                    {match(physicalCard.statusInfo.status)
                      .with("ConsentPending", () => (
                        <Tag color="gray">{t("cards.list.status.consentPending")}</Tag>
                      ))
                      .with("Processing", () => (
                        <Tag color="shakespear">{t("cards.list.status.processing")}</Tag>
                      ))
                      .with("Activated", () => (
                        <Tag color="positive">{t("cards.list.status.activated")}</Tag>
                      ))
                      .with("ToActivate", () => (
                        <Tag color="shakespear">{t("cards.list.status.toActivate")}</Tag>
                      ))
                      .with("Canceling", () => (
                        <Tag color="warning">{t("cards.list.status.canceling")}</Tag>
                      ))
                      .with("Canceled", () => (
                        <Tag color="gray">{t("cards.list.status.canceled")}</Tag>
                      ))
                      .with("Suspended", () => (
                        <Tag color="warning">{t("cards.list.status.suspended")}</Tag>
                      ))
                      .with("Renewed", () => (
                        <Tag color="shakespear">{t("cards.list.status.renewed")}</Tag>
                      ))
                      .with("ToRenew", () => (
                        <Tag color="warning">{t("cards.list.status.toRenew")}</Tag>
                      ))
                      .exhaustive()}
                  </LakeText>
                )}
              />

              {match(physicalCard.statusInfo)
                .with(
                  { __typename: "PhysicalCardCanceledStatusInfo" },
                  { __typename: "PhysicalCardCancelingStatusInfo" },
                  { __typename: "PhysicalCardSuspendedStatusInfo" },
                  ({ reason }) => (
                    <LakeLabel
                      label={t("cards.general.cardContract.reasonMessage")}
                      type="view"
                      render={() => <LakeText color={colors.gray[900]}> {reason}</LakeText>}
                    />
                  ),
                )
                .otherwise(() => null)}

              {match(physicalCard.statusInfo)
                .with(
                  { __typename: "PhysicalCardRenewedStatusInfo" },
                  { __typename: "PhysicalCardToActivateStatusInfo" },
                  ({ isPINReady }) => (
                    <LakeLabel
                      label={t("cards.general.physicalCards.pin")}
                      type="view"
                      render={() => <BooleanTag value={isPINReady} />}
                    />
                  ),
                )
                .otherwise(() => null)}
            </ReadOnlyFieldList>
          </Tile>
        )}
      </TileGrid>

      {card.statusInfo.status !== "Canceled" && card.statusInfo.status !== "Canceling" && (
        <TrackPressable action="Cancel physical card contract">
          <LakeTooltip
            placement="right"
            content={t("common.action.denied")}
            disabled={canCancelCardContracts}
          >
            <LakeButton
              size="small"
              style={styles.cancelContract}
              color="negative"
              mode="secondary"
              icon="subtract-circle-regular"
              onPress={openCancelContract}
              disabled={!canCancelCardContracts}
            >
              {t("cards.general.physicalCard.cancelContract")}
            </LakeButton>
          </LakeTooltip>
        </TrackPressable>
      )}

      <CancelContractModal
        visible={cancelContractVisible}
        cardHolderName={cardHolderName ?? ""}
        cardId={card.id}
        canCancelCardContracts={canCancelCardContracts}
        onClose={shouldReload => {
          closeCancelContract();

          if (shouldReload) {
            reexecuteQuery();
          }
        }}
      />

      <SuspendModal
        visible={suspendCardVisible}
        cardHolderName={cardHolderName ?? ""}
        cardId={card.id}
        onClose={shouldReload => {
          closeSuspendCard();

          if (shouldReload) {
            reexecuteQuery();
          }
        }}
      />

      <CancelPhysicalModal
        visible={cancelPhysicalCardVisible}
        cardHolderName={cardHolderName ?? ""}
        cardId={card.id}
        onClose={shouldReload => {
          closePhysicalCard();

          if (shouldReload) {
            reexecuteQuery();
          }
        }}
      />

      <Space height={24} />
    </>
  );
};
