import { AsyncData, Result } from "@swan-io/boxed";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { TileGridPlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { isNullish } from "@swan-io/lake/src/utils/nullish";
import { capitalize } from "@swan-io/lake/src/utils/string";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { P, match } from "ts-pattern";
import { ErrorView } from "../components/ErrorView";
import { Redirect } from "../components/Redirect";
import { ServerConsentForm } from "../components/ServerConsentForm";
import {
  GetServerConsentProjectSettingsDocument,
  UpdateServerConsentProjectSettingsDocument,
  UpdateServerConsentProjectSettingsInput,
} from "../graphql/admin";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { NotFoundPage } from "./NotFoundPage";

export const ServerConsentSandboxPage = () => {
  const { projectId, projectEnv } = useProjectInfo();

  const route = Router.useRoute([
    "DevelopersServerConsentDraft",
    "DevelopersServerConsentPending",
    "DevelopersServerConsentCurrent",
  ]);

  const [data, { reload }] = useQuery(GetServerConsentProjectSettingsDocument, {
    projectId,
    env: capitalize(projectEnv),
  });

  const [updateServerConsentProjectSettings, update] = useMutation(
    UpdateServerConsentProjectSettingsDocument,
  );

  const onSubmit = (values: UpdateServerConsentProjectSettingsInput) => {
    updateServerConsentProjectSettings({ input: values })
      .mapOk(data => data.updateServerConsentProjectSettings)
      .tapOk(data => {
        match(data.__typename)
          .with(
            "UpdateServerConsentProjectSettingsSuccessPayload",
            "UpdateServerConsentProjectSettingsSuccessConsentPayload",
            () => {
              showToast({ variant: "success", title: t("toast.success.serverConsentSaved") });
              reload();
            },
          )
          .with(
            "ForbiddenRejection",
            "PendingConsentProjectSettingsAlreadyExistsRejection",
            "ProjectNotFound",
            "ServerConsentNotAllowedRejection",
            __typename => {
              showToast({ variant: "error", title: translateError(__typename) });
            },
          )
          .with("CredentialNotUsableRejection", () => {
            showToast({
              variant: "error",
              title: t("toast.error.serverConsentCredentialAlreadyUsed"),
            });
          })
          .exhaustive();
      })
      .tapError(error => {
        showToast({ variant: "error", error, title: translateError(error) });
      });
  };

  if (isNullish(route)) {
    return <NotFoundPage />;
  }

  if (route.name !== "DevelopersServerConsentDraft") {
    return <Redirect to={Router.DevelopersServerConsentDraft({ projectId, projectEnv })} />;
  }

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <TileGridPlaceholder />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => {
      const settings = data?.serverConsentProjectSettings;
      const initialAllowedIpAddresses = settings?.allowedIpAddresses;

      const initialPublicKey =
        settings?.pendingConsent?.consentOperations
          .flat()
          .map(item => item?.props ?? [])
          .flat()
          .find(item => item.name === "PublicKey")?.value ??
        settings?.serverConsentProjectCredential?.publicKey;

      return (
        <ServerConsentForm
          onSubmit={onSubmit}
          initialAllowedIpAddresses={initialAllowedIpAddresses}
          initialPublicKey={initialPublicKey}
          loading={update.isLoading()}
          variant="Sandbox"
        />
      );
    })
    .exhaustive();
};
