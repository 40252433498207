import { Array, AsyncData, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import {
  LinkConfig,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import { deriveUnion, identity } from "@swan-io/lake/src/utils/function";
import { isNotNullish, nullishOrEmptyToUndefined } from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import { useMemo } from "react";
import { P, isMatching, match } from "ts-pattern";
import { CapitalDepositFiltersForm } from "../components/CapitalDepositFiltersForm";
import { CapitalDepositList } from "../components/CapitalDepositList";
import { Connection } from "../components/Connection";
import { ErrorView } from "../components/ErrorView";
import {
  CapitalDepositCaseOrderByInput,
  CapitalDepositCaseStatus,
  CapitalDepositsListFragment,
  GetCapitalDepositsListDocument,
  OrderByDirection,
} from "../graphql/partner";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { useFiltersTracking } from "../utils/matomo";
import { RouteParams, Router } from "../utils/routes";

type Props = {
  params: RouteParams["CapitalDepositList"];
};

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  onChangeSort?: (sortBy: CapitalDepositCaseOrderByInput) => void;
  sortBy?: CapitalDepositCaseOrderByInput;
  reexecuteQuery: () => void;
};

const PER_PAGE = 20;

const statusList = deriveUnion<CapitalDepositCaseStatus>({
  Canceled: true,
  Completed: true,
  Initiated: true,
  WaitingForNotaryTransfer: true,
  WaitingForRegisterExtract: true,
  WaitingForRequirements: true,
  WaitingForShareDepositCertificate: true,
});

const getRowLink = ({
  item: {
    node: { id },
  },
  extraInfo: { projectEnv, projectId },
}: LinkConfig<GetEdge<CapitalDepositsListFragment>, ExtraInfo>) => (
  <Link to={Router.CapitalDepositDetailRoot({ projectId, projectEnv, capitalDepositId: id })} />
);

const isStringList = isMatching(P.array(P.string));

export const CapitalDepositListPage = ({ params }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const serializedStatus = JSON.stringify(params.status ?? null);

  const filters = useMemo(() => {
    const status = JSON.parse(serializedStatus) as unknown;

    return {
      status: isStringList(status)
        ? Array.filterMap(status, item => (statusList.is(item) ? Option.Some(item) : Option.None()))
        : undefined,
    } as const;
  }, [serializedStatus]);

  const sortBy: CapitalDepositCaseOrderByInput = useMemo(() => {
    return {
      field: match(params.sortBy)
        .returnType<CapitalDepositCaseOrderByInput["field"]>()
        .with("createdAt", "updatedAt", identity)
        .otherwise(() => "createdAt"),
      direction: match(params.direction)
        .returnType<OrderByDirection>()
        .with("Asc", "Desc", identity)
        .otherwise(() => "Desc"),
    };
  }, [params.sortBy, params.direction]);

  const search = nullishOrEmptyToUndefined(params.search);
  const hasSearchOrFilters = isNotNullish(search) || Object.values(filters).some(isNotNullish);

  const [data, { isLoading, reload, setVariables }] = useQuery(GetCapitalDepositsListDocument, {
    first: PER_PAGE,
    filters: { ...filters, search },
    orderBy: sortBy,
  });

  const extraInfo: ExtraInfo = useMemo(() => {
    return {
      projectEnv,
      projectId,
      onChangeSort: ({ field, direction }) => {
        Router.push("CapitalDepositList", {
          ...params,
          sortBy: field ?? undefined,
          direction: direction ?? undefined,
        });
      },
      sortBy,
      reexecuteQuery: reload,
    };
  }, [projectEnv, projectId, sortBy, reload, params]);

  const capitalDeposit = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ capitalDepositCases }) => capitalDepositCases);

  const totalCount = capitalDeposit.map(({ totalCount }) => totalCount);

  useFiltersTracking({
    filters,
    totalCount: totalCount.getOr(0),
    loaded: data.isDone(),
  });

  return (
    <>
      <CapitalDepositFiltersForm
        filters={filters}
        search={search}
        totalCount={totalCount}
        onRefresh={reload}
        onChangeFilters={filters => {
          Router.replace("CapitalDepositList", { ...params, ...filters });
        }}
        onChangeSearch={search => {
          Router.replace("CapitalDepositList", { ...params, search });
        }}
      />

      <Space height={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={PER_PAGE}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.capitalDepositCases}>
            {capitalDepositCases => (
              <CapitalDepositList
                capitalDeposit={capitalDepositCases.edges}
                onEndReached={() => {
                  if (capitalDepositCases.pageInfo.hasNextPage === true) {
                    setVariables({ after: capitalDepositCases.pageInfo.endCursor ?? undefined });
                  }
                }}
                perPage={PER_PAGE}
                extraInfo={extraInfo}
                isLoading={isLoading}
                getRowLink={getRowLink}
                hasSearchOrFilters={hasSearchOrFilters}
              />
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};
