import { Array, AsyncData, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { usePersistedState } from "@swan-io/lake/src/hooks/usePersistedState";
import { identity } from "@swan-io/lake/src/utils/function";
import { P, match } from "ts-pattern";
import { GetUserMembershipsDocument } from "../graphql/admin";
import { Router } from "../utils/routes";
import { ErrorView } from "./ErrorView";
import { ProjectPlaceholderView } from "./ProjectPlaceholderView";
import { Redirect } from "./Redirect";

export const RedirectToRelevantProject = () => {
  const [data] = useQuery(GetUserMembershipsDocument, {});

  const [projectState] = usePersistedState<unknown>("swan_session_projectState", {});

  const safeProjectState = match(projectState)
    .with({ projectId: P.string, projectEnv: P.union("live", "sandbox") }, value => value)
    .otherwise(() => null);

  const firstRelevantProject = data.mapOk(({ user }) => {
    return Option.fromNullable(user)
      .flatMap(user => Option.fromNullable(user.memberships))
      .flatMap(memberships => {
        // Try to get the last visited
        const lastVisitedMembership = Array.findMap(memberships, membership =>
          Option.fromNullable(membership.project).flatMap(project =>
            project.id === safeProjectState?.projectId
              ? Option.Some(safeProjectState)
              : Option.None(),
          ),
        );

        // In case we don't have a last visited one, or that the membership
        // doesn't exist anymore, take the first project in the list
        const firstMembership = Array.findMap(memberships, membership =>
          Option.fromNullable(membership.project).map(project => ({
            projectId: project.id,
            projectEnv:
              membership.roleV2?.permissions?.live_dataUser_read === true
                ? ("live" as const)
                : ("sandbox" as const),
          })),
        );

        return Array.findMap([lastVisitedMembership, firstMembership], identity);
      });
  });

  return (
    match(firstRelevantProject)
      .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
        <ProjectPlaceholderView shimmer={false} />
      ))
      .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
      // Couldn't find a membership, redirect to the new project page
      .with(AsyncData.P.Done(Result.P.Ok(Option.P.None)), () => (
        <Redirect to={Router.BaseStart()} />
      ))
      // Redirect to the first relevant project
      .with(
        AsyncData.P.Done(Result.P.Ok(Option.P.Some(P.select()))),
        ({ projectId, projectEnv }) => (
          <Redirect to={Router.ProjectWithEnvRoot({ projectId, projectEnv })} />
        ),
      )
      .exhaustive()
  );
};
