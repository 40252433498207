import { AsyncData, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { ScrollView } from "@swan-io/lake/src/components/ScrollView";
import { SwanLogo } from "@swan-io/lake/src/components/SwanLogo";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { colors, negativeSpacings, spacings } from "@swan-io/lake/src/constants/design";
import { Pressable, StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { GetUserMembershipsDocument, GetUserMembershipsQuery } from "../graphql/admin";
import { t } from "../utils/i18n";
import { ErrorView } from "./ErrorView";
import { TrackPressable } from "./TrackPressable";

const getProjects = (
  currentUser: GetUserMembershipsQuery | undefined,
): {
  name: string;
  id: string;
  logoUri: string;
}[] => {
  const memberships = currentUser?.user?.memberships ?? [];

  return memberships.reduce<
    {
      name: string;
      id: string;
      logoUri: string;
    }[]
  >((acc, { project }) => {
    if (project == null) {
      return acc;
    }

    const settings = match(project.status)
      .with(
        "PendingLiveReview",
        "LimitedLiveAccess",
        "BetaLiveAccess",
        "FullLiveAccess",
        () => project.liveProjectSettings ?? project.sandboxProjectSettings,
      )
      .otherwise(() => project.sandboxProjectSettings);

    if (settings == null) {
      return acc;
    }

    acc.push({
      name: settings.name,
      id: project.id,
      logoUri: settings.logoUri ?? "",
    });

    return acc;
  }, []);
};

const styles = StyleSheet.create({
  root: {
    flexGrow: 1,
    height: 1,
  },
  container: {
    flexGrow: 1,
    height: 1,
    padding: spacings[16],
    maxWidth: 600,
    width: "100%",
    marginHorizontal: "auto",
  },
  contents: {
    marginVertical: "auto",
    alignItems: "center",
    flexDirection: "row",
  },
  item: {
    paddingVertical: spacings[16],
    flexDirection: "row",
    paddingHorizontal: spacings[32],
    marginHorizontal: negativeSpacings[32],
  },
  hovered: {
    backgroundColor: colors.gray[50],
  },
  text: {
    flexGrow: 1,
  },
  logo: {
    height: 30,
    width: 135,
    alignSelf: "center",
    marginVertical: spacings[32],
  },
});

const REDIRECT_URL = encodeURIComponent("https://swan3071.zendesk.com");

export const ProjectPicker = () => {
  const [data] = useQuery(GetUserMembershipsDocument, {});

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <LoadingView />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => {
      const projects = getProjects(data);

      if (projects.length === 1) {
        const project = projects[0];
        if (project != null) {
          window.location.replace(`/zendesk/sso/${project.id}?redirect_url=${REDIRECT_URL}`);
          return null;
        }
      }

      if (projects.length === 0) {
        return <ErrorView />;
      }

      return (
        <View style={styles.root}>
          <SwanLogo style={styles.logo} />

          <ScrollView style={styles.container} contentContainerStyle={styles.contents}>
            <Tile title={t("projectPicker.selectYourProject")} flexGrow={1}>
              {projects.map(project => (
                <TrackPressable action="Select SSO project" key={project.id}>
                  <Pressable
                    onPress={() => {
                      window.location.replace(
                        `/zendesk/sso/${project.id}?redirect_url=${REDIRECT_URL}`,
                      );
                    }}
                    style={({ hovered }) => [styles.item, hovered && styles.hovered]}
                  >
                    <LakeText numberOfLines={1} color={colors.gray[800]} style={styles.text}>
                      {project.name}
                    </LakeText>

                    <Fill minWidth={32} />
                    <Icon size={16} name="chevron-right-filled" color={colors.gray[800]} />
                  </Pressable>
                </TrackPressable>
              ))}
            </Tile>
          </ScrollView>
        </View>
      );
    })
    .exhaustive();
};
