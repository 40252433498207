import { Tag } from "@swan-io/lake/src/components/Tag";
import { P, match } from "ts-pattern";
import { PaymentProduct } from "../graphql/partner";
import { t } from "../utils/i18n";

const formatPaymentProduct = (paymentProduct: string) => {
  const isSepa = paymentProduct.startsWith("SEPA");
  const prefix = isSepa ? "SEPA " : "";
  const unprefixed = isSepa ? paymentProduct.slice(4) : paymentProduct;

  return (
    prefix +
    unprefixed.charAt(0).toUpperCase() +
    unprefixed
      .slice(1)
      .replace(/([A-Z])/g, " $1")
      .toLowerCase()
  );
};

type Props = {
  paymentProduct: PaymentProduct;
};

export const PaymentProductTag = ({ paymentProduct }: Props) =>
  match(paymentProduct)
    .with("Card", () => <Tag color="darkPink">{t("transaction.paymentProduct.Card")}</Tag>)
    .with("InternalCreditTransfer", () => (
      <Tag color="warning">{t("transaction.paymentProduct.InternalCreditTransfer")}</Tag>
    ))
    .with("InternalDirectDebit", () => (
      <Tag color="warning">{t("transaction.paymentProduct.InternalDirectDebit")}</Tag>
    ))
    .with("SEPACreditTransfer", () => (
      <Tag color="shakespear">{t("transaction.paymentProduct.SEPACreditTransfer")}</Tag>
    ))
    .with("SEPADirectDebit", () => (
      <Tag color="live">{t("transaction.paymentProduct.SEPADirectDebit")}</Tag>
    ))
    .with("Fees", () => <Tag color="gray">{t("transaction.paymentProduct.Fees")}</Tag>)
    .with("Check", () => <Tag color="sunglow">{t("transaction.paymentProduct.Check")}</Tag>)
    .with(P.string, value => <Tag color="gray">{formatPaymentProduct(value)}</Tag>)
    .otherwise(() => null);
