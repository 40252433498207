import { Link } from "@swan-io/chicane";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { InformationTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { ReadOnlyFieldList } from "@swan-io/lake/src/components/ReadOnlyFieldList";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { colors } from "@swan-io/lake/src/constants/design";
import { isNotNullish, isNotNullishOrEmpty, isNullish } from "@swan-io/lake/src/utils/nullish";
import { Flag } from "@swan-io/shared-business/src/components/Flag";
import {
  CountryCCA3,
  getCountryByCCA3,
  isCountryCCA3,
} from "@swan-io/shared-business/src/constants/countries";
import dayjs from "dayjs";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { GetAccountHolderQuery } from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { formatCurrency, locale, t } from "../utils/i18n";
import { Router } from "../utils/routes";

type Props = {
  accountHolder: NonNullable<GetAccountHolderQuery["accountHolder"]>;
};

const styles = StyleSheet.create({
  unknownValue: {
    fontStyle: "italic",
  },
});

const UNKNOWN_VALUE = <LakeText style={styles.unknownValue}>{t("common.unknown")}</LakeText>;

export const AccountHolderDetailGeneral = ({ accountHolder }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const statusInfo = accountHolder.statusInfo;
  const supportingDocumentCollection = accountHolder.supportingDocumentCollections.edges[0];
  //the order of the accounts is done by descending date, we want the first element (the oldest)
  const legalRepresentativeCompany =
    accountHolder.accounts.edges[0]?.node.legalRepresentativeMembership;
  const accountHolderAddress = accountHolder.residencyAddress;

  return (
    <TileGrid>
      <Tile title={t("accountHolder.accountHolder")}>
        <ReadOnlyFieldList>
          <LakeLabel
            label={t("accountHolder.accountHolder.type")}
            type="view"
            color="current"
            render={() =>
              match(accountHolder.info.type)
                .with("Individual", () => (
                  <Tag color="darkPink" icon="person-regular">
                    {t("accountHolder.individual")}
                  </Tag>
                ))
                .with("Company", () => (
                  <Tag color="shakespear" icon="building-regular">
                    {t("accountHolder.company")}
                  </Tag>
                ))
                .exhaustive()
            }
          />

          {isNotNullish(statusInfo) &&
          statusInfo.__typename !== "AccountHolderEnabledStatusInfo" ? (
            <LakeLabel
              label={t("accountHolder.status")}
              type="view"
              color="current"
              render={() =>
                match(statusInfo.status)
                  .with("Enabled", value => <Tag color="positive">{value}</Tag>)
                  .with("Suspended", value => <Tag color="warning">{value}</Tag>)
                  .with("Canceled", value => <Tag color="negative">{value}</Tag>)
                  .exhaustive()
              }
            />
          ) : null}

          {isNotNullish(statusInfo) &&
          statusInfo.__typename === "AccountHolderCanceledStatusInfo" ? (
            <LakeLabel
              label={t("accountHolder.status.reason")}
              type="view"
              color="current"
              render={() => <LakeText color={colors.gray[900]}>{statusInfo.reason}</LakeText>}
            />
          ) : null}

          <LakeLabel
            label={t("accountHolder.verificationStatus")}
            type="view"
            color="current"
            render={() =>
              match(accountHolder.verificationStatus)
                .with("NotStarted", () => (
                  <Box direction="row" alignItems="center">
                    <Tag color="gray">{t("accountHolder.verificationStatus.notStarted")}</Tag>
                    <Space width={16} />

                    <InformationTooltip
                      text={t("accountHolder.verificationStatus.notStarted.info")}
                    />
                  </Box>
                ))
                .with("Pending", () => (
                  <Box direction="row" alignItems="center">
                    <Tag color="shakespear">{t("accountHolder.verificationStatus.pending")}</Tag>
                    <Space width={16} />
                    <InformationTooltip text={t("accountHolder.verificationStatus.pending.info")} />
                  </Box>
                ))
                .with("Verified", () => (
                  <Tag color="positive">{t("accountHolder.verificationStatus.verified")}</Tag>
                ))
                .with("Refused", () => (
                  <Tag color="negative">{t("accountHolder.verificationStatus.refused")}</Tag>
                ))
                .with("WaitingForInformation", () => (
                  <Box direction="row" alignItems="center">
                    <Tag color="warning">
                      {t("accountHolder.verificationStatus.waitingForInformation")}
                    </Tag>

                    <Space width={16} />

                    <InformationTooltip
                      text={t("accountHolder.verificationStatus.waitingForInformation.info")}
                    />
                  </Box>
                ))
                .exhaustive()
            }
          />

          {accountHolder.verificationStatus !== "Verified" &&
            isNotNullish(supportingDocumentCollection) && (
              <LakeLabel
                label={t("accountHolder.onboardingDocumentsCollection")}
                type="view"
                color="current"
                actions={
                  isNotNullish(accountHolder.onboarding) && (
                    <Link
                      to={Router.OnboardingDetailSupportingDocuments({
                        projectId,
                        projectEnv,
                        onboardingId: accountHolder.onboarding.id,
                        supportingDocumentCollectionId:
                          accountHolder.onboarding.supportingDocumentCollection.id,
                      })}
                    >
                      <Icon size={20} name="arrow-right-filled" color={colors.swan[900]} />
                    </Link>
                  )
                }
                render={() =>
                  match(supportingDocumentCollection.node.statusInfo.status)
                    .with("Canceled", () => (
                      <Tag color="gray">
                        {t("accountHolder.onboardingDocumentsCollection.canceled")}
                      </Tag>
                    ))
                    .with("PendingReview", () => (
                      <Tag color="shakespear">
                        {t("accountHolder.onboardingDocumentsCollection.pendingReview")}
                      </Tag>
                    ))
                    .with("Approved", () => (
                      <Tag color="positive">
                        {t("accountHolder.onboardingDocumentsCollection.approved")}
                      </Tag>
                    ))
                    .with("Rejected", () => (
                      <Tag color="negative">
                        {t("accountHolder.onboardingDocumentsCollection.rejected")}
                      </Tag>
                    ))
                    .with("WaitingForDocument", () => (
                      <Tag color="warning">
                        {t("accountHolder.verificationStatus.waitingForDocument")}
                      </Tag>
                    ))
                    .exhaustive()
                }
              />
            )}

          <LakeLabel
            label={t("accountHolder.id")}
            type="view"
            color="current"
            render={() => <LakeText color={colors.gray[900]}>{accountHolder.id}</LakeText>}
            actions={
              <LakeCopyButton
                valueToCopy={accountHolder.id}
                copyText={t("copyButton.copyTooltip")}
                copiedText={t("copyButton.copiedTooltip")}
              />
            }
          />

          <LakeLabel
            label={t("accountHolder.onboarding")}
            type="view"
            color="current"
            actions={
              isNotNullish(accountHolder.onboarding) && (
                <Link
                  to={Router.OnboardingDetailRoot({
                    projectId,
                    projectEnv,
                    onboardingId: accountHolder.onboarding.id,
                  })}
                >
                  <Icon size={20} name="arrow-right-filled" color={colors.swan[900]} />
                </Link>
              )
            }
            render={() => (
              <LakeText color={colors.gray[900]}>
                {isNotNullish(accountHolder.onboarding)
                  ? accountHolder.onboarding.id
                  : UNKNOWN_VALUE}
              </LakeText>
            )}
          />

          <LakeLabel
            label={t("accountHolder.createdAt")}
            type="view"
            render={() => (
              <LakeText color={colors.gray[900]}>
                {dayjs(accountHolder.createdDate).format(
                  `${locale.dateFormat} ${locale.timeFormat}`,
                )}
              </LakeText>
            )}
          />

          <LakeLabel
            label={t("accountHolder.updatedAt")}
            type="view"
            render={() => (
              <LakeText color={colors.gray[900]}>
                {dayjs(accountHolder.updatedDate).format(
                  `${locale.dateFormat} ${locale.timeFormat}`,
                )}
              </LakeText>
            )}
          />
        </ReadOnlyFieldList>
      </Tile>

      {match(accountHolder)
        .with(
          {
            info: { __typename: "AccountHolderCompanyInfo" },
          },
          ({ accounts }) => {
            const user = accounts.edges[0]?.node.legalRepresentativeMembership?.user;

            if (isNullish(user)) {
              return null;
            }
            const { nationalityCCA3 } = user;

            const isExpert = user.identificationLevels?.expert === true;
            const isQES = user.identificationLevels?.QES === true;
            const isPVID = user.identificationLevels?.PVID === true;

            return (
              <Tile title={t("accountHolder.legalRepresentative")}>
                <ReadOnlyFieldList>
                  <LakeLabel
                    label={t("accountHolder.legalRepresentative.phoneNumber")}
                    type="view"
                    color="current"
                    render={() =>
                      isNotNullish(user.mobilePhoneNumber) ? (
                        <LakeText color={colors.gray[900]}>{user.mobilePhoneNumber}</LakeText>
                      ) : (
                        UNKNOWN_VALUE
                      )
                    }
                  />

                  <LakeLabel
                    label={t("accountHolder.legalRepresentative.name")}
                    type="view"
                    color="current"
                    render={() => <LakeText color={colors.gray[900]}>{user.fullName}</LakeText>}
                  />

                  {isNotNullish(user.birthDate) ? (
                    <LakeLabel
                      label={t("accountHolder.legalRepresentative.birthdate")}
                      type="view"
                      color="current"
                      render={() => <LakeText color={colors.gray[900]}>{user.birthDate}</LakeText>}
                    />
                  ) : null}

                  {isCountryCCA3(nationalityCCA3) ? (
                    <LakeLabel
                      label={t("accountHolder.legalRepresentative.nationality")}
                      type="view"
                      color="current"
                      render={() => {
                        const country = getCountryByCCA3(nationalityCCA3);

                        return (
                          <Box direction="row" alignItems="center">
                            <Flag code={country.cca2} width={14} />
                            <Space width={8} />
                            <LakeText color={colors.gray[900]}>{country.name}</LakeText>
                          </Box>
                        );
                      }}
                    />
                  ) : null}

                  <LakeLabel
                    label={t("accountHolder.legalRepresentative.identificationLevels")}
                    type="view"
                    color="current"
                    render={() => (
                      <Box direction="row" alignItems="center">
                        <Tag
                          label={t("accountHolder.legalRepresentative.identificationLevels.expert")}
                          color={isExpert ? "positive" : "gray"}
                        >
                          {isExpert ? t("common.true") : t("common.false")}
                        </Tag>

                        <Space width={12} />

                        <Tag
                          label={t("accountHolder.legalRepresentative.identificationLevels.qes")}
                          color={isQES ? "positive" : "gray"}
                        >
                          {isQES ? t("common.true") : t("common.false")}
                        </Tag>

                        <Space width={12} />

                        <Tag
                          label={t("accountHolder.legalRepresentative.identificationLevels.pvid")}
                          color={isPVID ? "positive" : "gray"}
                        >
                          {isPVID ? t("common.true") : t("common.false")}
                        </Tag>
                      </Box>
                    )}
                    actions={
                      <LakeButton
                        ariaLabel={t("common.moreInfo")}
                        mode="tertiary"
                        icon="arrow-right-regular"
                        href={Router.UserDetailIdentifications({
                          projectId,
                          projectEnv,
                          userId: user.id,
                        })}
                        onPress={event => {
                          event.preventDefault();
                          Router.push("UserDetailIdentifications", {
                            projectId,
                            projectEnv,
                            userId: user.id,
                          });
                        }}
                      />
                    }
                  />

                  {legalRepresentativeCompany && (
                    <LakeLabel
                      label={t("accountHolder.legalRepresentative.membershipEmail")}
                      type="view"
                      color="current"
                      actions={
                        isNotNullish(accountHolder.onboarding) && (
                          <Link
                            to={Router.AccountMembershipsDetailRoot({
                              projectId,
                              projectEnv,
                              accountMembershipId: legalRepresentativeCompany.id,
                            })}
                          >
                            <Icon size={20} name="arrow-right-filled" color={colors.swan[900]} />
                          </Link>
                        )
                      }
                      render={() => (
                        <LakeText color={colors.gray[900]}>
                          {legalRepresentativeCompany.email}
                        </LakeText>
                      )}
                    />
                  )}
                </ReadOnlyFieldList>
              </Tile>
            );
          },
        )
        .otherwise(() => null)}

      {match(accountHolder.info)
        .with({ __typename: "AccountHolderCompanyInfo" }, info => {
          const companyType = info.companyType;
          return (
            <Tile title={t("accountHolder.companyDetails")}>
              <ReadOnlyFieldList>
                <LakeLabel
                  label={t("accountHolder.company.name")}
                  type="view"
                  color="current"
                  render={() => <LakeText color={colors.gray[900]}>{info.name}</LakeText>}
                />

                {isNotNullish(companyType) ? (
                  <LakeLabel
                    label={t("accountHolder.company.legalForm")}
                    type="view"
                    color="current"
                    render={() => (
                      <LakeText color={colors.gray[900]}>
                        {match(companyType)
                          .with("SelfEmployed", () => t("companyType.selfEmployed"))
                          .with("Company", () => t("companyType.company"))
                          .with("Association", () => t("companyType.association"))
                          .with("HomeOwnerAssociation", () => t("companyType.homeOwnerAssociation"))
                          .with("Other", () => t("companyType.other"))
                          .exhaustive()}
                      </LakeText>
                    )}
                  />
                ) : null}

                <LakeLabel
                  label={t("accountHolder.company.registrationNumber")}
                  type="view"
                  color="current"
                  render={() =>
                    isNotNullish(info.registrationNumber) ? (
                      <LakeText color={colors.gray[900]}>{info.registrationNumber}</LakeText>
                    ) : (
                      UNKNOWN_VALUE
                    )
                  }
                />

                <LakeLabel
                  label={t("accountHolder.company.vatNumber")}
                  type="view"
                  color="current"
                  render={() =>
                    isNotNullish(info.vatNumber) ? (
                      <LakeText color={colors.gray[900]}>{info.vatNumber}</LakeText>
                    ) : (
                      UNKNOWN_VALUE
                    )
                  }
                />

                <LakeLabel
                  label={t("accountHolder.company.businessActivity")}
                  type="view"
                  color="current"
                  render={() => (
                    <LakeText color={colors.gray[900]}>
                      {match(info.businessActivity)
                        .with("AdministrativeServices", () =>
                          t("businessActivity.administrativeServices"),
                        )
                        .with("Agriculture", () => t("businessActivity.agriculture"))
                        .with("Arts", () => t("businessActivity.arts"))
                        .with("BusinessAndRetail", () => t("businessActivity.businessAndRetail"))
                        .with("Construction", () => t("businessActivity.construction"))
                        .with("Education", () => t("businessActivity.education"))
                        .with("ElectricalDistributionAndWaterSupply", () =>
                          t("businessActivity.electricalDistributionAndWaterSupply"),
                        )
                        .with("FinancialAndInsuranceOperations", () =>
                          t("businessActivity.financialAndInsuranceOperations"),
                        )
                        .with("Health", () => t("businessActivity.health"))
                        .with("Housekeeping", () => t("businessActivity.housekeeping"))
                        .with("InformationAndCommunication", () =>
                          t("businessActivity.informationAndCommunication"),
                        )
                        .with("LodgingAndFoodServices", () =>
                          t("businessActivity.lodgingAndFoodServices"),
                        )
                        .with("ManufacturingAndMining", () =>
                          t("businessActivity.manufacturingAndMining"),
                        )
                        .with("PublicAdministration", () =>
                          t("businessActivity.publicAdministration"),
                        )
                        .with("RealEstate", () => t("businessActivity.realEstate"))
                        .with("Other", () => t("businessActivity.other"))
                        .with("ScientificActivities", () =>
                          t("businessActivity.scientificActivities"),
                        )
                        .with("Transportation", () => t("businessActivity.transportation"))
                        .exhaustive()}
                    </LakeText>
                  )}
                />

                <LakeLabel
                  label={t("accountHolder.company.businessActivityDescription")}
                  type="view"
                  color="current"
                  render={() => (
                    <LakeText color={colors.gray[900]}>{info.businessActivityDescription}</LakeText>
                  )}
                />

                <LakeLabel
                  label={t("accountHolder.company.monthlyPaymentVolume")}
                  type="view"
                  color="current"
                  render={() => (
                    <LakeText color={colors.gray[900]}>
                      {match(info.monthlyPaymentVolume)
                        .with("LessThan10000", () => t("monthlyPaymentVolume.lessThan10000"))
                        .with("Between10000And50000", () =>
                          t("monthlyPaymentVolume.between10000And50000"),
                        )
                        .with("Between50000And100000", () =>
                          t("monthlyPaymentVolume.between50000And100000"),
                        )
                        .with("MoreThan100000", () => t("monthlyPaymentVolume.moreThan100000"))
                        .exhaustive()}
                    </LakeText>
                  )}
                />

                {isNotNullish(info.taxIdentificationNumber) && (
                  <LakeLabel
                    label={t("accountHolder.company.taxIdentificationNumber")}
                    type="view"
                    color="current"
                    render={() => (
                      <LakeText color={colors.gray[900]}>
                        {isNotNullishOrEmpty(info.taxIdentificationNumber)
                          ? info.taxIdentificationNumber
                          : UNKNOWN_VALUE}
                      </LakeText>
                    )}
                  />
                )}
              </ReadOnlyFieldList>
            </Tile>
          );
        })
        .with({ __typename: "AccountHolderIndividualInfo" }, info => {
          const nationalityCCA3 = info.user.nationalityCCA3 as CountryCCA3;
          const isExpert = info.user.identificationLevels?.expert === true;
          const isQES = info.user.identificationLevels?.QES === true;
          const isPVID = info.user.identificationLevels?.PVID === true;

          return (
            <Tile title={t("accountHolder.individual")}>
              <ReadOnlyFieldList>
                <LakeLabel
                  label={t("accountHolder.individual.mobilePhoneNumber")}
                  type="view"
                  color="current"
                  render={() => (
                    <LakeText color={colors.gray[900]}>{info.user.mobilePhoneNumber}</LakeText>
                  )}
                />

                <LakeLabel
                  label={t("accountHolder.individual.name")}
                  type="view"
                  color="current"
                  render={() => <LakeText color={colors.gray[900]}>{info.name}</LakeText>}
                />

                {isNotNullish(info.user.birthDate) ? (
                  <LakeLabel
                    label={t("accountHolder.individual.birthdate")}
                    type="view"
                    color="current"
                    render={() => (
                      <LakeText color={colors.gray[900]}>{info.user.birthDate}</LakeText>
                    )}
                  />
                ) : null}

                {isNotNullish(nationalityCCA3) ? (
                  <LakeLabel
                    label={t("accountHolder.individual.nationality")}
                    type="view"
                    color="current"
                    render={() => {
                      const country = getCountryByCCA3(nationalityCCA3);

                      return (
                        <Box direction="row" alignItems="center">
                          <Flag code={country.cca2} width={14} />
                          <Space width={8} />
                          <LakeText color={colors.gray[900]}>{country.name}</LakeText>
                        </Box>
                      );
                    }}
                  />
                ) : null}

                <LakeLabel
                  label={t("accountHolder.legalRepresentative.identificationLevels")}
                  type="view"
                  color="current"
                  render={() => (
                    <Box direction="row" alignItems="center">
                      <Tag
                        label={t("accountHolder.legalRepresentative.identificationLevels.expert")}
                        color={isExpert ? "positive" : "gray"}
                      >
                        {isExpert ? t("common.true") : t("common.false")}
                      </Tag>

                      <Space width={12} />

                      <Tag
                        label={t("accountHolder.legalRepresentative.identificationLevels.qes")}
                        color={isQES ? "positive" : "gray"}
                      >
                        {isQES ? t("common.true") : t("common.false")}
                      </Tag>

                      <Space width={12} />

                      <Tag
                        label={t("accountHolder.legalRepresentative.identificationLevels.pvid")}
                        color={isPVID ? "positive" : "gray"}
                      >
                        {isPVID ? t("common.true") : t("common.false")}
                      </Tag>
                    </Box>
                  )}
                  actions={
                    <LakeButton
                      ariaLabel={t("common.moreInfo")}
                      mode="tertiary"
                      icon="arrow-right-regular"
                      href={Router.UserDetailIdentifications({
                        projectId,
                        projectEnv,
                        userId: info.user.id,
                      })}
                      onPress={event => {
                        event.preventDefault();
                        Router.push("UserDetailIdentifications", {
                          projectId,
                          projectEnv,
                          userId: info.user.id,
                        });
                      }}
                    />
                  }
                />

                <LakeLabel
                  label={t("accountHolder.individual.employmentStatus")}
                  type="view"
                  color="current"
                  render={() => (
                    <LakeText color={colors.gray[900]}>
                      {match(info.employmentStatus)
                        .with("Craftsman", () => t("employmentStatus.craftsman"))
                        .with("Employee", () => t("employmentStatus.employee"))
                        .with("Entrepreneur", () => t("employmentStatus.entrepreneur"))
                        .with("Farmer", () => t("employmentStatus.farmer"))
                        .with("Manager", () => t("employmentStatus.manager"))
                        .with("Practitioner", () => t("employmentStatus.practitioner"))
                        .with("Retiree", () => t("employmentStatus.retiree"))
                        .with("ShopOwner", () => t("employmentStatus.shopOwner"))
                        .with("Student", () => t("employmentStatus.student"))
                        .with("Unemployed", () => t("employmentStatus.unemployed"))
                        .exhaustive()}
                    </LakeText>
                  )}
                />

                <LakeLabel
                  label={t("accountHolder.individual.monthlyIncome")}
                  type="view"
                  color="current"
                  render={() => (
                    <LakeText color={colors.gray[900]}>
                      {match(info.monthlyIncome)
                        .with("LessThan500", () => t("monthlyIncome.lessThan500"))
                        .with("Between500And1500", () => t("monthlyIncome.between500And1500"))
                        .with("Between1500And3000", () => t("monthlyIncome.between1500And3000"))
                        .with("Between3000And4500", () => t("monthlyIncome.between3000And4500"))
                        .with("MoreThan4500", () => t("monthlyIncome.moreThan4500"))
                        .exhaustive()}
                    </LakeText>
                  )}
                />
              </ReadOnlyFieldList>
            </Tile>
          );
        })
        .otherwise(() => null)}

      <Tile title={t("accountHolder.address.title")}>
        <ReadOnlyFieldList>
          <LakeLabel
            label={t("accountHolder.address")}
            type="view"
            color="current"
            render={() => (
              <View>
                {isNotNullishOrEmpty(accountHolderAddress.postalCode) ? (
                  <LakeText color={colors.gray[900]}>
                    {accountHolder.residencyAddress.addressLine1 ?? UNKNOWN_VALUE}
                  </LakeText>
                ) : (
                  UNKNOWN_VALUE
                )}

                {isNotNullishOrEmpty(accountHolderAddress.addressLine2) ? (
                  <LakeText color={colors.gray[900]}>
                    {accountHolder.residencyAddress.addressLine2}
                  </LakeText>
                ) : null}
              </View>
            )}
          />

          <LakeLabel
            label={t("accountHolder.address.postalCode")}
            type="view"
            color="current"
            render={() => (
              <LakeText color={colors.gray[900]}>
                {isNotNullishOrEmpty(accountHolderAddress.postalCode)
                  ? accountHolder.residencyAddress.postalCode
                  : UNKNOWN_VALUE}
              </LakeText>
            )}
          />

          <LakeLabel
            label={t("accountHolder.address.city")}
            type="view"
            color="current"
            render={() => (
              <LakeText color={colors.gray[900]}>
                {isNotNullishOrEmpty(accountHolderAddress.city)
                  ? accountHolderAddress.city
                  : UNKNOWN_VALUE}
              </LakeText>
            )}
          />

          <LakeLabel
            label={t("accountHolder.address.country")}
            type="view"
            color="current"
            render={() => {
              const cca3 = accountHolderAddress.country;

              if (isCountryCCA3(cca3)) {
                const country = getCountryByCCA3(cca3);

                return (
                  <Box direction="row" alignItems="center">
                    <Flag code={country.cca2} width={14} />
                    <Space width={8} />
                    <LakeText color={colors.gray[900]}>{country.name}</LakeText>
                  </Box>
                );
              } else {
                return UNKNOWN_VALUE;
              }
            }}
          />
        </ReadOnlyFieldList>
      </Tile>

      {match(accountHolder.fundingLimitSettings)
        .with({ __typename: P.nonNullable }, fundingLimitSettings => (
          <Tile title={t("fundingLimitSettings.fundingLimit")}>
            <ReadOnlyFieldList>
              <LakeLabel
                label={t("fundingLimitSettings.status")}
                type="view"
                render={() =>
                  match(fundingLimitSettings.statusInfo.status)
                    .with("Suspended", () => (
                      <Tag color="warning">{t("fundingLimitSettings.status.suspended")}</Tag>
                    ))
                    .with("Enabled", () => (
                      <Tag color="positive">{t("fundingLimitSettings.status.enabled")}</Tag>
                    ))
                    .with("Disabled", () => (
                      <Tag color="negative">{t("fundingLimitSettings.status.disabled")}</Tag>
                    ))
                    .exhaustive()
                }
              />

              <LakeLabel
                label={t("fundingLimitSettings.limit")}
                type="view"
                render={() => {
                  const amount = fundingLimitSettings.fundingLimit?.amount;
                  const days = fundingLimitSettings.fundingLimit?.rollingDays;
                  const value = Number(amount?.value);

                  return isNotNullish(amount) && isNotNullish(days) ? (
                    <LakeText color={colors.gray[900]}>
                      {`${formatCurrency(value, amount.currency)} / ${t(
                        "fundingLimitSettings.rollingDays.value",
                        { count: days },
                      )}`}
                    </LakeText>
                  ) : (
                    UNKNOWN_VALUE
                  );
                }}
              />

              <LakeLabel
                label={t("fundingLimitSettings.funding")}
                type="view"
                render={() => {
                  const amount = fundingLimitSettings.fundingLimit?.funding;
                  const days = fundingLimitSettings.fundingLimit?.rollingDays;
                  const value = Number(amount?.value);

                  return isNotNullish(amount) && isNotNullish(days) ? (
                    <LakeText color={colors.gray[900]}>
                      {`${formatCurrency(value, amount.currency)} / ${t(
                        "fundingLimitSettings.rollingDays.value",
                        { count: days },
                      )}`}
                    </LakeText>
                  ) : (
                    UNKNOWN_VALUE
                  );
                }}
              />

              <LakeLabel
                label={t("fundingLimitSettings.instantLimit")}
                type="view"
                render={() => {
                  const amount = fundingLimitSettings.instantFundingLimit?.amount;
                  const value = Number(amount?.value);

                  return isNotNullish(amount) ? (
                    <LakeText color={colors.gray[900]}>
                      {formatCurrency(value, amount.currency)}
                    </LakeText>
                  ) : (
                    UNKNOWN_VALUE
                  );
                }}
              />

              <LakeLabel
                label={t("fundingLimitSettings.instantFunding")}
                type="view"
                render={() => {
                  const amount = fundingLimitSettings.instantFundingLimit?.funding;
                  const value = Number(amount?.value);

                  return isNotNullish(amount) ? (
                    <LakeText color={colors.gray[900]}>
                      {formatCurrency(value, amount.currency)}
                    </LakeText>
                  ) : (
                    UNKNOWN_VALUE
                  );
                }}
              />
            </ReadOnlyFieldList>
          </Tile>
        ))
        .otherwise(() => null)}
    </TileGrid>
  );
};
