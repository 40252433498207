import { ClientContext } from "@swan-io/graphql-client";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { ScrollView } from "@swan-io/lake/src/components/ScrollView";
import { Space } from "@swan-io/lake/src/components/Space";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { backgroundColor, colors, spacings } from "@swan-io/lake/src/constants/design";
import { lowerCase } from "@swan-io/lake/src/utils/string";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { SandboxUsersArea } from "../components/SandboxUsersArea";
import { ProjectStatus } from "../graphql/admin";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { AccessDeniedPage } from "../pages/AccessDeniedPage";
import { ApiPage } from "../pages/ApiPage";
import { ConsentNotificationPage } from "../pages/ConsentNotificationPage";
import { NotFoundPage } from "../pages/NotFoundPage";
import { PaymentControlPage } from "../pages/PaymentControlPage";
import { ServerConsentLivePage } from "../pages/ServerConsentLivePage";
import { ServerConsentSandboxPage } from "../pages/ServerConsentSandboxPage";
import {
  adminByProjectIdClient,
  adminByProjectIdClient__projectMember,
  liveExposedInternalByProjectIdClient,
  liveExposedInternalByProjectIdClient__projectMember,
  livePartnerByProjectIdClient,
  livePartnerByProjectIdClient__projectMember,
  sandboxExposedInternalByProjectIdClient,
  sandboxExposedInternalByProjectIdClient__projectMember,
  sandboxPartnerAdminByProjectIdClient,
  sandboxPartnerAdminByProjectIdClient__projectMember,
  sandboxPartnerAdminClient,
  sandboxPartnerByProjectIdClient,
  sandboxPartnerByProjectIdClient__projectMember,
} from "../utils/gql";
import { t } from "../utils/i18n";
import { Router, developerRoots } from "../utils/routes";
import { useTgglFlag } from "../utils/tggl";
import { SandboxOnlyView } from "./SandboxOnlyView";
import { Menu, Sidebar } from "./Sidebar";
import { SimulatorArea } from "./SimulatorArea";
import { WebhooksArea } from "./WebhooksArea";

const styles = StyleSheet.create({
  base: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: "stretch",
  },
  contentContainer: {
    marginHorizontal: "auto",
    borderRightWidth: 1,
    borderColor: colors.gray[50],
    backgroundColor: backgroundColor.default,
  },
  container: {
    flexGrow: 1,
    flexShrink: 1,
    maxWidth: 2560,
    marginHorizontal: "auto",
    flexDirection: "row",
  },
  content: {
    flexGrow: 1,
    paddingHorizontal: spacings[24],
    paddingTop: spacings[16],
  },
});

type Props = {
  projectStatus?: ProjectStatus;
};

export const DevelopersArea = ({ projectStatus }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const route = Router.useRoute(developerRoots);
  const {
    oAuthSettings: { read: canViewApi },
    eventSimulator: { read: canViewEventSimulator, write: canUseEventSimulators },
    sandboxUser: { read: canViewSandboxUser },
    webhook: { read: canViewWebhook },
    paymentControl: { read: canViewPaymentControl },
    consentNotification: { read: canViewConsentNotification },
    serverConsent: { read: canViewServerConsent },
  } = usePermissions(projectEnv);

  const shouldUseProjectMemberToken = useTgglFlag("dashboardProjectMemberToken").getOr(false);

  const exposedInternalClient = match({ projectEnv, shouldUseProjectMemberToken })
    .with({ projectEnv: "live", shouldUseProjectMemberToken: true }, () =>
      liveExposedInternalByProjectIdClient__projectMember(projectId),
    )
    .with({ projectEnv: "live" }, () => liveExposedInternalByProjectIdClient(projectId))
    .with({ projectEnv: "sandbox", shouldUseProjectMemberToken: true }, () =>
      sandboxExposedInternalByProjectIdClient__projectMember(projectId),
    )
    .with({ projectEnv: "sandbox" }, () => sandboxExposedInternalByProjectIdClient(projectId))
    .exhaustive();

  const menu: Menu = [
    {
      matchRoutes: ["DevelopersApi"],
      to: Router.DevelopersApi({ projectId, projectEnv }),
      icon: "code-regular",
      iconActive: "code-filled",
      name: t("menu.developersApi"),
    },
    {
      matchRoutes: ["DevelopersSimulatorArea"],
      to: Router.DevelopersSimulatorRoot({ projectId, projectEnv }),
      icon: "window-dev-tools-regular",
      iconActive: "window-dev-tools-filled",
      name: t("menu.developersSimulator"),
    },
    {
      matchRoutes: ["DevelopersUsersArea"],
      to: Router.DevelopersUsersRoot({ projectId, projectEnv }),
      icon: "people-regular",
      iconActive: "people-filled",
      name: t("menu.sandboxUsers"),
    },
    {
      matchRoutes: ["DevelopersWebhooksArea"],
      to: Router.DevelopersWebhooksRoot({ projectId, projectEnv }),
      icon: "send-regular",
      iconActive: "send-filled",
      name: t("menu.webhooks"),
    },
    {
      matchRoutes: ["DevelopersPaymentControl"],
      to: Router.DevelopersPaymentControl({ projectId, projectEnv }),
      icon: "shield-checkmark-regular",
      iconActive: "shield-checkmark-filled",
      name: t("menu.paymentControl"),
    },
    {
      matchRoutes: ["DevelopersConsentNotification"],
      to: Router.DevelopersConsentNotification({ projectId, projectEnv }),
      icon: "phone-regular",
      iconActive: "phone-filled",
      name: t("menu.consentNotification"),
    },
    {
      matchRoutes: ["DevelopersServerConsentArea"],
      to: Router.DevelopersServerConsentDraft({ projectId, projectEnv }),
      icon: "cloud-sync-regular",
      iconActive: "cloud-sync-filled",
      name: t("menu.serverConsent"),
    },
  ];

  if (
    [
      canViewApi,
      canViewEventSimulator,
      canViewSandboxUser,
      canViewWebhook,
      canViewPaymentControl,
      canViewConsentNotification,
      canViewServerConsent,
    ].every(item => item === false)
  ) {
    return <AccessDeniedPage />;
  }

  return (
    <ScrollView horizontal={true} style={commonStyles.fill} contentContainerStyle={styles.base}>
      <View style={styles.container}>
        <Sidebar menu={menu} />

        <ScrollView
          role="main"
          style={styles.contentContainer}
          contentContainerStyle={styles.content}
        >
          {match(route)
            .with({ name: "DevelopersApi" }, () =>
              canViewApi ? (
                <LakeScrollView>
                  <ApiPage projectStatus={projectStatus} />
                </LakeScrollView>
              ) : (
                <AccessDeniedPage />
              ),
            )
            .with({ name: "DevelopersSimulatorArea", params: { projectEnv: "live" } }, () => (
              <SandboxOnlyView
                icon="window-dev-tools-regular"
                onSelect={envType =>
                  Router.push("DevelopersSimulatorRoot", {
                    projectId,
                    projectEnv: lowerCase(envType),
                  })
                }
              />
            ))
            .with({ name: "DevelopersSimulatorArea", params: { projectEnv: "sandbox" } }, () =>
              canViewEventSimulator && canUseEventSimulators ? (
                <ClientContext.Provider
                  value={
                    shouldUseProjectMemberToken
                      ? sandboxPartnerAdminByProjectIdClient__projectMember(projectId)
                      : sandboxPartnerAdminByProjectIdClient(projectId)
                  }
                >
                  <SimulatorArea />
                </ClientContext.Provider>
              ) : (
                <AccessDeniedPage />
              ),
            )
            .with({ name: "DevelopersUsersArea", params: { projectEnv: "live" } }, () => (
              <SandboxOnlyView
                icon="people-regular"
                onSelect={envType =>
                  Router.push("DevelopersUsersRoot", {
                    projectId,
                    projectEnv: lowerCase(envType),
                  })
                }
              />
            ))
            .with({ name: "DevelopersUsersArea", params: { projectEnv: "sandbox" } }, () =>
              canViewSandboxUser ? (
                <ClientContext.Provider value={sandboxPartnerAdminClient}>
                  <SandboxUsersArea />
                </ClientContext.Provider>
              ) : (
                <AccessDeniedPage />
              ),
            )
            .with({ name: "DevelopersWebhooksArea", params: { projectEnv: "sandbox" } }, () =>
              canViewWebhook ? (
                <ClientContext.Provider
                  value={
                    shouldUseProjectMemberToken
                      ? sandboxPartnerByProjectIdClient__projectMember(projectId)
                      : sandboxPartnerByProjectIdClient(projectId)
                  }
                >
                  <WebhooksArea />
                </ClientContext.Provider>
              ) : (
                <AccessDeniedPage />
              ),
            )
            .with({ name: "DevelopersWebhooksArea", params: { projectEnv: "live" } }, () =>
              canViewWebhook ? (
                <ClientContext.Provider
                  value={
                    shouldUseProjectMemberToken
                      ? livePartnerByProjectIdClient__projectMember(projectId)
                      : livePartnerByProjectIdClient(projectId)
                  }
                >
                  <WebhooksArea />
                </ClientContext.Provider>
              ) : (
                <AccessDeniedPage />
              ),
            )
            .with({ name: "DevelopersPaymentControl" }, () =>
              canViewPaymentControl ? (
                <ClientContext.Provider value={exposedInternalClient}>
                  <LakeScrollView>
                    <PaymentControlPage />
                    <Space height={16} />
                  </LakeScrollView>
                </ClientContext.Provider>
              ) : (
                <AccessDeniedPage />
              ),
            )
            .with({ name: "DevelopersConsentNotification" }, () =>
              canViewConsentNotification ? (
                <ClientContext.Provider value={exposedInternalClient}>
                  <ConsentNotificationPage />
                </ClientContext.Provider>
              ) : (
                <AccessDeniedPage />
              ),
            )
            .with({ name: "DevelopersServerConsentArea" }, ({ params: { projectEnv } }) =>
              canViewServerConsent ? (
                <ClientContext.Provider
                  value={
                    shouldUseProjectMemberToken
                      ? adminByProjectIdClient__projectMember(projectId)
                      : adminByProjectIdClient(projectId)
                  }
                >
                  {projectEnv === "sandbox" ? (
                    <ServerConsentSandboxPage />
                  ) : (
                    <ServerConsentLivePage />
                  )}
                </ClientContext.Provider>
              ) : (
                <AccessDeniedPage />
              ),
            )
            .otherwise(() => (
              <NotFoundPage />
            ))}
        </ScrollView>
      </View>
    </ScrollView>
  );
};
