import { useFocusReset } from "@swan-io/chicane";
import { Box } from "@swan-io/lake/src/components/Box";
import { Breadcrumbs, BreadcrumbsRoot } from "@swan-io/lake/src/components/Breadcrumbs";
import { InformationTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Space } from "@swan-io/lake/src/components/Space";
import { useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { NotFoundPage } from "../pages/NotFoundPage";
import { OnboardingListPage } from "../pages/OnboardingListPage";
import { t } from "../utils/i18n";
import { Router, onboardingRoutes } from "../utils/routes";
import { LakeDocumentationLink } from "./DocumentationLink";
import { OnboardingDetails } from "./OnboardingDetails";

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
});

type Props = {
  membershipEmail: string | undefined;
};

export const OnboardingArea = ({ membershipEmail }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const route = Router.useRoute(onboardingRoutes);
  const containerRef = useRef(null);

  const rootLevelCrumbs = useMemo(
    () => [
      {
        label: t("onboarding.all"),
        link: Router.OnboardingRoot({ projectId, projectEnv }),
      },
    ],
    [projectEnv, projectId],
  );

  useFocusReset({ route, containerRef });

  return (
    <BreadcrumbsRoot rootLevelCrumbs={rootLevelCrumbs}>
      <Box direction="row" justifyContent="spaceBetween" alignItems="center">
        <Breadcrumbs />

        <Box direction="row" justifyContent="end" alignItems="center">
          <LakeDocumentationLink to="onboarding">{t("common.learnMore")}</LakeDocumentationLink>
          <Space width={16} />
          <InformationTooltip text={t("onboarding.subtitle")} />
        </Box>
      </Box>

      <Space height={16} />

      <View ref={containerRef} style={styles.container}>
        {match(route)
          .with({ name: "OnboardingRoot" }, ({ params }) => (
            <OnboardingListPage membershipEmail={membershipEmail} params={params} />
          ))
          .with({ name: "OnboardingDetailArea" }, ({ params: { onboardingId } }) => (
            <OnboardingDetails onboardingId={onboardingId} />
          ))
          .otherwise(() => (
            <NotFoundPage />
          ))}
      </View>
    </BreadcrumbsRoot>
  );
};
