import { isNullish } from "@swan-io/lake/src/utils/nullish";
import { ReactNode, createContext, useContext, useMemo } from "react";
import { EnvType } from "../graphql/admin";

export type ProjectEnv = Lowercase<EnvType>;

type ProjectInfo = {
  projectId: string;
  projectEnv: ProjectEnv;
};

const ProjectInfoContext = createContext<ProjectInfo | undefined>(undefined);

export const ProjectInfoProvider = ({
  projectId,
  projectEnv,
  children,
}: ProjectInfo & { children: ReactNode }) => (
  <ProjectInfoContext.Provider
    value={useMemo(() => ({ projectId, projectEnv }), [projectId, projectEnv])}
  >
    {children}
  </ProjectInfoContext.Provider>
);

export const useProjectInfo = () => {
  const projectInfo = useContext(ProjectInfoContext);

  if (isNullish(projectInfo)) {
    throw new Error("Missing <ProjectInfoProvider />");
  }

  return projectInfo;
};
