import { ScrollView } from "@swan-io/lake/src/components/ScrollView";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { backgroundColor, colors, spacings } from "@swan-io/lake/src/constants/design";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { AccessDeniedPage } from "../pages/AccessDeniedPage";
import { t } from "../utils/i18n";
import { Router, insightsRoutes } from "../utils/routes";
import { LiveOnlyView } from "./LiveOnlyView";
import { Menu, Sidebar } from "./Sidebar";

const styles = StyleSheet.create({
  base: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: "stretch",
  },
  contentContainer: {
    borderRightWidth: 1,
    borderColor: colors.gray[50],
    backgroundColor: backgroundColor.default,
    flexGrow: 1,
    paddingHorizontal: spacings[24],
    paddingTop: spacings[16],
  },
  container: {
    flexGrow: 1,
    flexShrink: 1,
    maxWidth: 2560,
    marginHorizontal: "auto",
    flexDirection: "row",
  },
});

export const SandboxInsightsArea = () => {
  const { projectId, projectEnv } = useProjectInfo();
  const route = Router.useRoute(insightsRoutes);
  const canViewInsights = usePermissions(projectEnv).insights.read;

  const menu: Menu = [
    {
      matchRoutes: ["InsightsOverview"],
      to: Router.InsightsOverview({ projectId, projectEnv }),
      icon: "board-regular",
      iconActive: "board-filled",
      name: t("menu.overview"),
    },
    {
      matchRoutes: ["InsightsAllTransactions", "InsightsCards", "InsightsCreditTransfer"],
      to: Router.InsightsAllTransactions({ projectId, projectEnv }),
      icon: "payment-regular",
      iconActive: "payment-filled",
      name: t("menu.transactionsAndCards"),
    },
    {
      matchRoutes: ["InsightsOnboardingAndAccounts"],
      to: Router.InsightsOnboardingAndAccounts({ projectId, projectEnv }),
      icon: "task-list-square-ltr-regular",
      iconActive: "task-list-square-ltr-filled",
      name: t("menu.onboardingAndAccounts"),
    },
    {
      matchRoutes: ["InsightsIdentityVerification"],
      to: Router.InsightsIdentityVerification({ projectId, projectEnv }),
      icon: "person-regular",
      iconActive: "person-filled",
      name: t("menu.identityVerification"),
    },
    {
      matchRoutes: ["InsightsConsents"],
      to: Router.InsightsConsents({ projectId, projectEnv }),
      icon: "lock-closed-regular",
      iconActive: "lock-closed-filled",
      name: t("menu.consents"),
    },
  ];

  if (canViewInsights === false) {
    return <AccessDeniedPage />;
  }

  return (
    <ScrollView horizontal={true} style={commonStyles.fill} contentContainerStyle={styles.base}>
      <View style={styles.container}>
        <Sidebar menu={menu} />

        <View role="main" style={styles.contentContainer}>
          {match(route)
            .with(
              { name: "InsightsAllTransactions" },
              { name: "InsightsCards" },
              { name: "InsightsCreditTransfer" },
              () => (
                <TabView
                  tabs={[
                    {
                      label: t("insights.allTransactions"),
                      url: Router.InsightsAllTransactions({ projectId, projectEnv }),
                    },
                    {
                      label: t("insights.cards"),
                      url: Router.InsightsCards({ projectId, projectEnv }),
                    },
                    {
                      label: t("insights.creditTransfers"),
                      url: Router.InsightsCreditTransfer({ projectId, projectEnv }),
                    },
                  ]}
                  otherLabel={t("common.tabs.other")}
                />
              ),
            )
            .otherwise(() => null)}

          <LiveOnlyView
            icon="board-regular"
            onSelect={() => {
              if (route != null) {
                Router.push(route.name, { projectId, projectEnv: "live" });
              }
            }}
          />
        </View>
      </View>
    </ScrollView>
  );
};
