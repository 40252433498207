import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useForm } from "@swan-io/use-form";
import { useCallback } from "react";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { DocumentationLink } from "../../components/DocumentationLink";
import { SimulatorResponses } from "../../components/SimulatorReponses";
import { TrackPressable } from "../../components/TrackPressable";
import {
  SupportingDocumentCollectionStatus,
  UpdateSupportingDocumentCollectionStatusDocument,
} from "../../graphql/sandbox-partner-admin";
import { t } from "../../utils/i18n";
import { validateRequired } from "../../utils/validations";

const styles = StyleSheet.create({
  grid: {
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 1080,
  },
});

const statusList: { value: SupportingDocumentCollectionStatus; name: string }[] = [
  {
    value: "WaitingForDocument" as const,
    name: t("simulatorForm.supportingDocumentCollection.waitingForDocument"),
  },
  {
    value: "PendingReview" as const,
    name: t("simulatorForm.supportingDocumentCollection.pendingReview"),
  },
  { value: "Approved" as const, name: t("simulatorForm.supportingDocumentCollection.approved") },
  {
    value: "Rejected" as const,
    name: t("simulatorForm.supportingDocumentCollection.rejected"),
  },
  {
    value: "Canceled" as const,
    name: t("simulatorForm.supportingDocumentCollection.canceled"),
  },
];

export const UpdateSupportingDocumentCollectionStatusPage = () => {
  const [simulate, simulation] = useMutation(UpdateSupportingDocumentCollectionStatusDocument);

  const documentResult = simulation.mapOkToResult(simulation =>
    match(simulation.updateSupportingDocumentCollectionStatus)
      .with(
        { __typename: "UpdateSupportingDocumentCollectionStatusSuccessPayload" },
        ({ supportingDocumentCollection }) =>
          Result.Ok([
            { key: "supportingDocumentCollectionId", value: supportingDocumentCollection.id },
          ]),
      )
      .otherwise(({ __typename }) => Result.Error({ rejection: __typename })),
  );

  const { Field, submitForm, formStatus } = useForm<{
    status: SupportingDocumentCollectionStatus;
    supportingDocumentCollectionId: string;
  }>({
    supportingDocumentCollectionId: {
      initialValue: "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
    status: {
      initialValue: "WaitingForDocument",
    },
  });

  const handleSubmit = useCallback(() => {
    submitForm({
      onSuccess: values => {
        const option = Option.allFromDict(values);

        if (option.isSome()) {
          const { status, supportingDocumentCollectionId } = option.get();

          return simulate({
            input: {
              supportingDocumentCollectionId,
              status,
            },
          });
        }
      },
    });
  }, [simulate, submitForm]);

  return (
    <Form style={commonStyles.fill}>
      <Tile
        description={
          <LakeText>
            {t("simulatorPage.updateSupportingDocumentCollectionStatusDescription")}

            <DocumentationLink to="simulatorSupportingCollectionStatus">
              {t("common.learnMore")}
            </DocumentationLink>
          </LakeText>
        }
      >
        <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
          <Field name="supportingDocumentCollectionId">
            {({ value, onChange, error, valid }) => (
              <LakeLabel
                label={`${t("simulatorForm.supportingDocumentCollectionId")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    error={error}
                    valid={valid}
                    placeholder={t("simulatorForm.supportingDocumentCollectionIdPlaceholder")}
                    value={value}
                    onChangeText={onChange}
                  />
                )}
              />
            )}
          </Field>

          <Field name="status">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.status")}
                render={id => (
                  <LakeSelect id={id} items={statusList} value={value} onValueChange={onChange} />
                )}
              />
            )}
          </Field>
        </Grid>
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Submit supporting document collection status form">
          <LakeButton
            size="small"
            color="current"
            loading={formStatus === "submitting"}
            onPress={handleSubmit}
          >
            {t("simulatorForm.submitButton")}
          </LakeButton>
        </TrackPressable>

        <Space width={12} />

        <SimulatorResponses
          fields={[
            {
              key: "supportingDocumentCollectionId",
              label: t("simulatorForm.supportingDocumentCollectionId"),
              placeholder: "-",
            },
          ]}
          results={documentResult}
        />
      </Box>
    </Form>
  );
};
