import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useForm } from "@swan-io/use-form";
import { useCallback } from "react";
import { StyleSheet } from "react-native";
import { P, match } from "ts-pattern";
import { DocumentationLink } from "../../components/DocumentationLink";
import { SimulatorResponses } from "../../components/SimulatorReponses";
import { TrackPressable } from "../../components/TrackPressable";
import {
  SupportingDocumentStatus,
  UpdateSupportingDocumentStatusDocument,
} from "../../graphql/sandbox-partner-admin";
import { t } from "../../utils/i18n";
import { validateRequired } from "../../utils/validations";

const styles = StyleSheet.create({
  grid: {
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 1080,
  },
});

const statusList: { value: SupportingDocumentStatus; name: string }[] = [
  {
    value: "NotUploaded" as const,
    name: t("simulatorForm.supportingDocument.notUploaded"),
  },
  {
    value: "Refused" as const,
    name: t("simulatorForm.supportingDocument.refused"),
  },
  { value: "Uploaded" as const, name: t("simulatorForm.supportingDocument.uploaded") },
  {
    value: "Validated" as const,
    name: t("simulatorForm.supportingDocument.validated"),
  },
  {
    value: "WaitingForUpload" as const,
    name: t("simulatorForm.supportingDocument.waitingForUpload"),
  },
];

export const UpdateSupportingDocumentStatusPage = () => {
  const [simulate, simulation] = useMutation(UpdateSupportingDocumentStatusDocument);

  const documentResult = simulation.mapOkToResult(simulation =>
    match(simulation.updateSupportingDocumentStatus)
      .with(
        { __typename: "UpdateSupportingDocumentStatusSuccessPayload" },
        ({ supportingDocument }) =>
          Result.Ok([{ key: "supportingDocumentId", value: supportingDocument.id }]),
      )
      .otherwise(({ __typename }) => Result.Error({ rejection: __typename })),
  );

  const { Field, submitForm, formStatus } = useForm<{
    status: SupportingDocumentStatus;
    supportingDocumentId: string;
  }>({
    supportingDocumentId: {
      initialValue: "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
    status: {
      initialValue: "WaitingForUpload",
    },
  });

  const handleSubmit = useCallback(() => {
    submitForm({
      onSuccess: fields => {
        return match(Option.allFromDict(fields))
          .with(Option.P.Some(P.select()), ({ supportingDocumentId, status }) => {
            return simulate({
              input: {
                supportingDocumentId,
                status,
              },
            });
          })
          .otherwise(() => {});
      },
    });
  }, [simulate, submitForm]);

  return (
    <Form style={commonStyles.fill}>
      <Tile
        description={
          <LakeText>
            {t("simulatorPage.updateSupportingDocumentCollectionStatusDescription")}

            <DocumentationLink to="simulatorSupportingCollectionStatus">
              {t("common.learnMore")}
            </DocumentationLink>
          </LakeText>
        }
      >
        <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
          <Field name="supportingDocumentId">
            {({ value, onChange, error, valid }) => (
              <LakeLabel
                label={`${t("simulatorForm.supportingDocumentId")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    error={error}
                    valid={valid}
                    placeholder={t("simulatorForm.supportingDocumentIdPlaceholder")}
                    value={value}
                    onChangeText={onChange}
                  />
                )}
              />
            )}
          </Field>

          <Field name="status">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.status")}
                render={id => (
                  <LakeSelect id={id} items={statusList} value={value} onValueChange={onChange} />
                )}
              />
            )}
          </Field>
        </Grid>
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Submit supporting document collection status form">
          <LakeButton
            size="small"
            color="current"
            loading={formStatus === "submitting"}
            onPress={handleSubmit}
          >
            {t("simulatorForm.submitButton")}
          </LakeButton>
        </TrackPressable>

        <Space width={12} />

        <SimulatorResponses
          fields={[
            {
              key: "supportingDocumentId",
              label: t("simulatorForm.supportingDocumentId"),
              placeholder: "-",
            },
          ]}
          results={documentResult}
        />
      </Box>
    </Form>
  );
};
