import { Tag } from "@swan-io/lake/src/components/Tag";
import { match } from "ts-pattern";
import { AccountStatus, ConsentStatus, VerificationStatus } from "../graphql/partner";
import { t } from "../utils/i18n";

type VerificationStatusTagProps = {
  verificationStatus: VerificationStatus;
};

type AccountStatusTagProps = {
  accountStatus: AccountStatus;
};

export const VerificationStatusTag = ({ verificationStatus }: VerificationStatusTagProps) => {
  return match(verificationStatus)
    .with("NotStarted", () => (
      <Tag color="gray">{t("accountHolder.verificationStatus.notStarted")}</Tag>
    ))
    .with("Pending", () => (
      <Tag color="shakespear">{t("accountHolder.verificationStatus.pending")}</Tag>
    ))
    .with("Verified", () => (
      <Tag color="positive">{t("accountHolder.verificationStatus.verified")}</Tag>
    ))
    .with("Refused", () => (
      <Tag color="negative">{t("accountHolder.verificationStatus.refused")}</Tag>
    ))
    .with("WaitingForInformation", () => (
      <Tag color="warning">{t("accountHolder.verificationStatus.waitingForInformation")}</Tag>
    ))
    .exhaustive();
};

export const AccountStatusTag = ({ accountStatus }: AccountStatusTagProps) => {
  return match(accountStatus)
    .with("Opened", () => <Tag color="positive">{accountStatus}</Tag>)
    .with("Suspended", () => <Tag color="warning">{accountStatus}</Tag>)
    .with("Closing", () => <Tag color="shakespear">{accountStatus}</Tag>)
    .with("Closed", () => <Tag color="negative">{accountStatus}</Tag>)
    .exhaustive();
};

type ConsentStatusTagProps = {
  status: ConsentStatus;
};

export const ConsentStatusTag = ({ status }: ConsentStatusTagProps) => {
  return match(status)
    .with("Accepted", () => <Tag color="positive">{t("consentStatus.accepted")}</Tag>)
    .with("Canceled", () => <Tag color="gray">{t("consentStatus.canceled")}</Tag>)
    .with("CredentialRefused", () => (
      <Tag color="negative">{t("consentStatus.credentialRefused")}</Tag>
    ))
    .with("CustomerRefused", () => <Tag color="negative">{t("consentStatus.customerRefused")}</Tag>)
    .with("Expired", () => <Tag color="negative">{t("consentStatus.expired")}</Tag>)
    .with("Failed", () => <Tag color="negative">{t("consentStatus.failed")}</Tag>)
    .with("Started", () => <Tag color="sunglow">{t("consentStatus.started")}</Tag>)
    .with("OperationCommitting", () => (
      <Tag color="gray">{t("consentStatus.operationCommitting")}</Tag>
    ))
    .with("Created", () => <Tag color="shakespear">{t("consentStatus.created")}</Tag>)
    .exhaustive();
};
