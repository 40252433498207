import { TileGrid } from "@swan-io/lake/src/components/Tile";
import { CapitalDepositDocumentFragment } from "../graphql/partner";
import { CapitalDepositDocumentTile } from "./CapitalDepositDocumentTile";

type Props = {
  capitalDepositCaseId: string;
  documents: CapitalDepositDocumentFragment[];
};

export const CapitalDepositDocuments = ({ documents, capitalDepositCaseId }: Props) => {
  return (
    <TileGrid>
      {documents.map(document => (
        <CapitalDepositDocumentTile
          key={document.id}
          capitalDepositCaseId={capitalDepositCaseId}
          document={document}
        />
      ))}
    </TileGrid>
  );
};
