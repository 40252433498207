import { AsyncData, Option, Result } from "@swan-io/boxed";
import { encodeSearch, pushUnsafe, useLocation } from "@swan-io/chicane";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { InformationTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { TileGridPlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { colors } from "@swan-io/lake/src/constants/design";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { capitalize } from "@swan-io/lake/src/utils/string";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { CopyTextButton } from "../components/CopyTextButton";
import { Credentials } from "../components/Credentials";
import { ErrorView } from "../components/ErrorView";
import { PostmanLinks } from "../components/PostmanLinks";
import { RedirectUris } from "../components/RedirectUris";
import { TrackPressable } from "../components/TrackPressable";
import {
  GenerateOAuthSecretDocument,
  GetApiPageDocument,
  ProjectStatus,
  UpdateOAuthClientDocument,
} from "../graphql/admin";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { env } from "../utils/env";
import { formatNestedMessage, t } from "../utils/i18n";

const styles = StyleSheet.create({
  base: {
    paddingBottom: 40,
  },
  link: {
    color: colors.current.primary,
    display: "inline-block",
  },
});

type Props = {
  projectStatus?: ProjectStatus;
};

const POSTMAN_URL = "https://www.postman.com/";

export const ApiPage = ({ projectStatus }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const {
    raw: { path },
    search,
  } = useLocation();

  const [generateOAuthSecret, oAuth2SecretGeneration] = useMutation(GenerateOAuthSecretDocument);

  const [data, { refresh }] = useQuery(GetApiPageDocument, {
    projectId,
    env: capitalize(projectEnv),
  });

  const [updateOAuthClient, oAuthClientUpdate] = useMutation(UpdateOAuthClientDocument);

  return (
    <View style={styles.base}>
      <Box direction="row" justifyContent="end" alignItems="center">
        <InformationTooltip text={t("api.subtitle")} />
      </Box>

      <Space height={16} />

      {match(
        data.mapOkToResult(query =>
          query.oAuthClient == null ? Result.Error(undefined) : Result.Ok(query.oAuthClient),
        ),
      )
        .with(AsyncData.P.NotAsked, () => null)
        .with(AsyncData.P.Loading, () => (
          <TileGridPlaceholder numberOfItems={projectEnv === "live" ? 2 : 3} withTabs={false} />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), oAuthClient => {
          const clientSecret = oAuth2SecretGeneration
            .toOption()
            .flatMap(value => value.toOption())
            .flatMap<string | undefined>(data =>
              Option.fromNullable(data.generateProjectOAuthSecret.oAuthVisibleClientSecret),
            )
            .getOr(oAuthClient.oAuthVisibleClientSecret ?? undefined);

          return (
            <>
              {projectStatus === "Initiated" && (
                <>
                  <LakeAlert
                    variant="warning"
                    title={t("credentials.talkToAnExpertDescription")}
                    callToAction={
                      <TrackPressable action="Talk to an expert">
                        <LakeButton
                          mode="tertiary"
                          icon="person-call-filled"
                          color="warning"
                          onPress={() => {
                            pushUnsafe(
                              `${path}${encodeSearch({ ...search, activate: "true", step: "TalkToAnExpert" })}`,
                            );
                          }}
                        >
                          {t("credentials.talkToAnExpert")}
                        </LakeButton>
                      </TrackPressable>
                    }
                  />

                  <Space height={24} />
                </>
              )}

              <TileGrid>
                <Tile
                  title={t("credentials.graphQlTitle")}
                  description={
                    <LakeText>
                      {formatNestedMessage("credentials.graphQlText", {
                        graphqlLink: (
                          <Link style={styles.link} to="https://graphql.org/" target="blank">
                            {t("common.graphQL")}
                          </Link>
                        ),
                        learnMoreLink: (
                          <Link
                            style={styles.link}
                            to={"https://docs.swan.io/api/overview"}
                            target="blank"
                          >
                            {t("common.learnMore")}
                          </Link>
                        ),
                      })}
                    </LakeText>
                  }
                >
                  <LakeLabel
                    label={t("credentials.graphQlLabel")}
                    render={id => {
                      const value =
                        projectEnv === "live"
                          ? env.GRAPHQL_LIVE_PARTNER_ENDPOINT
                          : env.GRAPHQL_SANDBOX_PARTNER_ENDPOINT;

                      return (
                        <Box direction="row">
                          <LakeTextInput id={id} readOnly={true} value={value} />
                          <Space width={12} />
                          <CopyTextButton value={value} />
                        </Box>
                      );
                    }}
                  />
                </Tile>

                {projectEnv === "sandbox" && (
                  <Tile
                    title={t("credentials.postman.title")}
                    description={
                      <LakeText>
                        {formatNestedMessage("credentials.postman.description", {
                          link: (
                            <Link to={POSTMAN_URL} style={styles.link} target="blank">
                              {t("credentials.postman.link")}
                            </Link>
                          ),
                        })}
                      </LakeText>
                    }
                  >
                    <PostmanLinks
                      clientId={oAuthClient.oAuthClientId}
                      clientSecret={oAuthClient.oAuthVisibleClientSecret ?? ""}
                    />
                  </Tile>
                )}

                <Tile
                  title={t("credentials.oauthTitle")}
                  description={
                    <LakeText>
                      {t("credentials.oauthText")}{" "}
                      <Link
                        to={"https://docs.swan.io/api/authentication"}
                        style={styles.link}
                        target="blank"
                      >
                        {t("common.learnMore")}
                      </Link>
                    </LakeText>
                  }
                >
                  <Credentials
                    projectId={projectId}
                    clientId={oAuthClient.oAuthClientId}
                    clientSecret={clientSecret}
                    createdOn={oAuthClient.createdOn ?? ""}
                    oAuth2SecretGeneration={oAuth2SecretGeneration}
                    generateOAuthSecret={generateOAuthSecret}
                  />
                </Tile>

                {clientSecret != null ? (
                  <Tile
                    title={t("redirectUris.label")}
                    description={<LakeText>{t("redirectUris.description")}</LakeText>}
                  >
                    <RedirectUris
                      fetchingUpdateOAuthClient={oAuthClientUpdate.isLoading()}
                      onChange={(uris, type) => {
                        updateOAuthClient({
                          updateOAuthClientRequest: {
                            projectId,
                            env: capitalize(projectEnv),
                            redirectUris: uris,
                          },
                        })
                          .mapOkToResult(filterRejectionsToResult)
                          .tapOk(() => {
                            refresh();
                            showToast({
                              variant: "success",
                              title: match(type)
                                .with("addition", () => t("toast.success.redirectUriCreated"))
                                .with("removal", () => t("toast.success.redirectUriDeleted"))
                                .exhaustive(),
                            });
                          })
                          .tapError(error =>
                            showToast({ variant: "error", error, title: translateError(error) }),
                          );
                      }}
                      uris={oAuthClient.redirectUris ?? []}
                    />
                  </Tile>
                ) : null}
              </TileGrid>
            </>
          );
        })
        .exhaustive()}
    </View>
  );
};
