import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Space } from "@swan-io/lake/src/components/Space";
import { colors } from "@swan-io/lake/src/constants/design";
import { StyleSheet, View } from "react-native";
import { t } from "../utils/i18n";
import { LakeDocumentationLink } from "./DocumentationLink";

const styles = StyleSheet.create({
  subtitle: {
    fontStyle: "italic",
  },
});

export const UserIdentificationProcessHelp = () => {
  return (
    <View>
      <LakeHeading level={3} variant="h5">
        {t("user.details.identifications.level.expert.detailedTitle")}
      </LakeHeading>

      <LakeText variant="smallRegular">
        {t("user.details.identifications.level.expert.description")}
      </LakeText>

      <LakeDocumentationLink to="userIdentificationsExpert">
        {t("common.learnMore")}
      </LakeDocumentationLink>

      <Space height={24} />

      <LakeHeading level={3} variant="h5">
        {t("user.details.identifications.level.qes.detailedTitle")}
      </LakeHeading>

      <LakeText variant="smallRegular">
        {t("user.details.identifications.level.qes.description")}
      </LakeText>

      <Box direction="row" alignItems="center">
        <Icon name="info-regular" color={colors.gray[500]} size={15} />
        <Space width={8} />

        <LakeText variant="smallRegular">
          {t("user.details.identifications.level.qes.countAsExpert")}
        </LakeText>
      </Box>

      <LakeDocumentationLink to="userIdentificationsQes">
        {t("common.learnMore")}
      </LakeDocumentationLink>

      <Space height={24} />

      <LakeHeading level={3} variant="h5">
        {t("user.details.identifications.level.pvid.detailedTitle")}
      </LakeHeading>

      <LakeText variant="smallRegular" style={styles.subtitle}>
        {t("user.details.identifications.level.pvid.subtitle")}
      </LakeText>

      <LakeText variant="smallRegular">
        {t("user.details.identifications.level.pvid.description")}
      </LakeText>

      <Box direction="row" alignItems="center">
        <Icon name="info-regular" color={colors.gray[500]} size={15} />
        <Space width={8} />

        <LakeText variant="smallRegular">
          {t("user.details.identifications.level.pvid.countAsExpert")}
        </LakeText>
      </Box>

      <LakeDocumentationLink to="userIdentificationsPvid">
        {t("common.learnMore")}
      </LakeDocumentationLink>
    </View>
  );
};
