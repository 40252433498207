import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { pick } from "@swan-io/lake/src/utils/object";
import { useForm } from "@swan-io/use-form";
import { useCallback } from "react";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { SimulatorResponses } from "../../components/SimulatorReponses";
import { TrackPressable } from "../../components/TrackPressable";
import {
  FundingLimitSettingsChangeRequestStatus,
  UpdateFundingLimitSettingsChangeRequestDocument,
} from "../../graphql/sandbox-partner-admin";
import { t } from "../../utils/i18n";
import { validateRequired } from "../../utils/validations";

const styles = StyleSheet.create({
  grid: {
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 1080,
  },
});

const statusList: { value: FundingLimitSettingsChangeRequestStatus; name: string }[] = [
  {
    value: "WaitingForInformation" as const,
    name: t("simulatorForm.fundingLimitRequestStatus.waitingForInformation"),
  },
  { value: "Pending" as const, name: t("simulatorForm.fundingLimitRequestStatus.pending") },
  { value: "Approved" as const, name: t("simulatorForm.fundingLimitRequestStatus.approved") },
  {
    value: "Refused" as const,
    name: t("simulatorForm.fundingLimitRequestStatus.refused"),
  },
];

export const UpdateFundingLimitSettingsChangeRequestPage = () => {
  const [simulate, simulation] = useMutation(UpdateFundingLimitSettingsChangeRequestDocument);

  const changeRequestResult = simulation.mapOkToResult(simulation =>
    match(simulation.updateFundingLimitSettingsChangeRequest)
      .with(
        { __typename: "UpdateFundingLimitSettingsChangeRequestSuccessPayload" },
        ({ fundingLimitSettingsChangeRequest }) =>
          Result.Ok([{ key: "accountHolderId", value: fundingLimitSettingsChangeRequest.id }]),
      )
      .otherwise(({ __typename }) => Result.Error({ rejection: __typename })),
  );

  const { Field, FieldsListener, submitForm, formStatus } = useForm<{
    status: FundingLimitSettingsChangeRequestStatus;
    fundingLimitSettingsChangeRequestId: string;
    approvedFundingLimit: string;
    approvedInstantFundingLimit: string;
  }>({
    fundingLimitSettingsChangeRequestId: {
      initialValue: "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
    status: {
      initialValue: "WaitingForInformation",
    },
    approvedFundingLimit: {
      initialValue: "",
      sanitize: value => value.trim(),
    },
    approvedInstantFundingLimit: {
      initialValue: "",
      sanitize: value => value.trim(),
    },
  });

  const handleSubmit = useCallback(() => {
    submitForm({
      onSuccess: values => {
        const option = Option.allFromDict(
          pick(values, ["fundingLimitSettingsChangeRequestId", "status"]),
        );

        if (option.isSome()) {
          const { fundingLimitSettingsChangeRequestId, status } = option.get();

          const { approvedFundingLimit, approvedInstantFundingLimit } = values;

          return simulate({
            input: {
              fundingLimitSettingsChangeRequestId,
              status,
              approvedFundingLimit: approvedFundingLimit
                .map(value => ({ amount: { value, currency: "EUR" } }))
                .toUndefined(),
              approvedInstantFundingLimit: approvedInstantFundingLimit
                .map(value => ({
                  amount: { value, currency: "EUR" },
                }))
                .toUndefined(),
            },
          });
        }
      },
    });
  }, [simulate, submitForm]);

  return (
    <Form style={commonStyles.fill}>
      <Tile>
        <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
          <Field name="fundingLimitSettingsChangeRequestId">
            {({ value, onChange, error, valid }) => (
              <LakeLabel
                label={`${t("simulatorForm.fundingLimitSettingsChangeRequestId")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    error={error}
                    valid={valid}
                    placeholder={t("simulatorForm.fundingLimitSettingsChangeRequestIdPlaceholder")}
                    value={value}
                    onChangeText={onChange}
                  />
                )}
              />
            )}
          </Field>

          <Field name="status">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.status")}
                render={id => (
                  <LakeSelect id={id} items={statusList} value={value} onValueChange={onChange} />
                )}
              />
            )}
          </Field>

          <FieldsListener names={["status"]}>
            {({ status }) =>
              status.value === "Approved" ? (
                <Field name="approvedFundingLimit">
                  {({ value, onChange, valid, error, onBlur }) => (
                    <LakeLabel
                      label={t("simulatorForm.approvedFundingLimit")}
                      render={id => (
                        <LakeTextInput
                          id={id}
                          value={value}
                          placeholder={t("simulatorForm.amountPlaceholder")}
                          inputMode="decimal"
                          unit="€"
                          valid={valid}
                          error={error}
                          onChangeText={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  )}
                </Field>
              ) : null
            }
          </FieldsListener>

          <FieldsListener names={["status"]}>
            {({ status }) =>
              status.value === "Approved" ? (
                <Field name="approvedInstantFundingLimit">
                  {({ value, onChange, valid, error, onBlur }) => (
                    <LakeLabel
                      label={t("simulatorForm.approvedInstantFundingLimit")}
                      render={id => (
                        <LakeTextInput
                          id={id}
                          value={value}
                          placeholder={t("simulatorForm.amountPlaceholder")}
                          inputMode="decimal"
                          unit="€"
                          valid={valid}
                          error={error}
                          onChangeText={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  )}
                </Field>
              ) : null
            }
          </FieldsListener>
        </Grid>
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Submit funding limit settings form">
          <LakeButton
            size="small"
            color="current"
            loading={formStatus === "submitting"}
            onPress={handleSubmit}
          >
            {t("simulatorForm.submitButton")}
          </LakeButton>
        </TrackPressable>

        <Space width={12} />

        <SimulatorResponses
          fields={[
            {
              key: "accountHolderId",
              label: t("simulatorForm.accountHolderId"),
              placeholder: "-",
            },
          ]}
          results={changeRequestResult}
        />
      </Box>
    </Form>
  );
};
