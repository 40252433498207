import { Box } from "@swan-io/lake/src/components/Box";
import { FlatList } from "@swan-io/lake/src/components/FlatList";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Pressable } from "@swan-io/lake/src/components/Pressable";
import { Space } from "@swan-io/lake/src/components/Space";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors, spacings } from "@swan-io/lake/src/constants/design";
import { isNotNullish, isNullish } from "@swan-io/lake/src/utils/nullish";
import { useState } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { TrackPressable } from "./TrackPressable";

const styles = StyleSheet.create({
  row: {
    borderBottomColor: colors.gray[100],
    borderBottomWidth: 1,
    paddingVertical: spacings[12],
  },
  uriButton: {
    alignItems: "center",
    justifyContent: "center",
  },
});

const getInputError = (value: string): string | undefined => {
  const [protocol, remainingUrl] = value.split("://");
  const regexProtocol = /^(http|https)$/g; // Switch to /^[A-Za-z][A-Za-z0-9+-.]{0,}$/ if we support PKCE one day // https://www.ietf.org/rfc/rfc2396.txt
  const regexRemainingUrl = /^[A-Za-z0-9\-._~:/?#[\]@!$&'()*+,;=]+$/g;

  if (isNullish(protocol) || !regexProtocol.test(protocol)) {
    return t("redirectUris.protocolError");
  }

  if (isNullish(remainingUrl) || !regexRemainingUrl.test(remainingUrl)) {
    return t("redirectUris.urlFormatError");
  }

  return;
};

type RedirectUriProps = {
  value: string;
  loading: boolean;
  canEdit: boolean;
  onRemove: () => void;
};

const RedirectUri = ({ value, loading, onRemove, canEdit }: RedirectUriProps) => {
  return (
    <Box direction="row" alignItems="center" style={styles.row}>
      <LakeText color={colors.gray[800]} numberOfLines={1} style={commonStyles.fill}>
        {value}
      </LakeText>

      {canEdit && (
        <TrackPressable action="Remove redirect URI">
          <Pressable style={styles.uriButton} disabled={loading} onPress={onRemove}>
            <Icon name="delete-regular" size={20} color={colors.negative.primary} />
          </Pressable>
        </TrackPressable>
      )}
    </Box>
  );
};

type Props = {
  fetchingUpdateOAuthClient: boolean;
  onChange: (uris: string[], type: "addition" | "removal") => void;
  style?: StyleProp<ViewStyle>;
  uris: string[];
};

export const RedirectUris = ({ fetchingUpdateOAuthClient, onChange, style, uris }: Props) => {
  const { projectEnv } = useProjectInfo();
  const canAddNewLinks = usePermissions(projectEnv).oAuthSettings.write;

  const [newUri, setNewUri] = useState("");
  const [error, setError] = useState<string>();

  const canEdit = canAddNewLinks;

  const addLink = () => {
    const inputError = getInputError(newUri);

    if (isNotNullish(inputError)) {
      return setError(inputError);
    }

    setNewUri("");
    onChange([...uris, newUri], "addition");
  };

  const handleNewUriChange = (value: string) => {
    setError(undefined);
    setNewUri(value);
  };

  return (
    <View style={style}>
      {canEdit && (
        <Box direction="row">
          <View style={commonStyles.fill}>
            <LakeTextInput
              placeholder="https://"
              value={newUri}
              onChangeText={handleNewUriChange}
              onSubmitEditing={addLink}
              error={error}
            />
          </View>

          <Space width={16} />

          <TrackPressable action="Add new redirect URI">
            <LakeTooltip
              placement="right"
              content={t("common.action.denied")}
              disabled={canAddNewLinks}
            >
              <LakeButton
                disabled={!canAddNewLinks}
                mode="secondary"
                icon="add-filled"
                size="small"
                onPress={addLink}
              >
                {t("credentials.addNewLink")}
              </LakeButton>
            </LakeTooltip>
          </TrackPressable>
        </Box>
      )}

      {uris.length > 0 && canEdit ? <Space height={8} /> : null}

      <FlatList
        role="list"
        keyExtractor={(uri, index) => `${uri}-${index}`}
        data={uris}
        ItemSeparatorComponent={<Space height={4} />}
        renderItem={({ item: uri }) => (
          <RedirectUri
            value={uri}
            canEdit={canEdit}
            loading={fetchingUpdateOAuthClient}
            onRemove={() =>
              onChange(
                uris.filter(item => item !== uri),
                "removal",
              )
            }
          />
        )}
      />
    </View>
  );
};
