import { AsyncData, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import {
  ActionCell,
  Cell,
  CopyableTextCell,
  HeaderCell,
  TextCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnConfig,
  LinkConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { colors, negativeSpacings, texts } from "@swan-io/lake/src/constants/design";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import dayjs from "dayjs";
import { ReactNode, useMemo, useState } from "react";
import { StyleSheet } from "react-native";
import { P, match } from "ts-pattern";
import { GetFundingSourcesDocument, GetFundingSourcesQuery } from "../graphql/partner";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { formatNestedMessage, locale, t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { Connection } from "./Connection";
import { ErrorView } from "./ErrorView";
import { TrackPressable } from "./TrackPressable";

const styles = StyleSheet.create({
  link: {
    fontWeight: texts.semibold.fontWeight,
    textDecorationLine: "underline",
  },
});

type Edge = GetEdge<NonNullable<NonNullable<GetFundingSourcesQuery["account"]>["fundingSources"]>>;

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  accountId: string;
};

const getRowLink = ({
  item: {
    node: { id: fundingSourceId },
  },
  extraInfo: { projectEnv, projectId, accountId },
}: LinkConfig<Edge, ExtraInfo>) => (
  <Link
    to={Router.AccountDetailFundingSourcesDetail({
      accountId,
      projectEnv,
      fundingSourceId,
      projectId,
    })}
  />
);
const keyExtractor = ({ node: { id } }: Edge) => id;

const PER_PAGE = 20;

const stickedToStartColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 200,
    id: "status",
    title: t("fundingSources.scheme"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { node } }) => (
      <Cell>
        {match(node.paymentMandate)
          .with(
            { __typename: "SEPAPaymentDirectDebitMandate", scheme: "SepaDirectDebitB2b" },
            () => <Tag color="darkPink">{t("fundingSources.scheme.b2b")}</Tag>,
          )
          .with(
            { __typename: "SEPAPaymentDirectDebitMandate", scheme: "SepaDirectDebitCore" },
            () => <Tag color="live">{t("fundingSources.scheme.core")}</Tag>,
          )
          .with({ __typename: "InternalPaymentDirectDebitMandate" }, () => null) // ignored for now
          .exhaustive()}
      </Cell>
    ),
  },
  {
    width: 150,
    id: "name",
    title: t("fundingSources.name"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { node } }) => <TextCell text={node.name ?? ""} />,
  },
];

const columns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 400,
    id: "id",
    title: t("fundingSources.id"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { node } }) => (
      <CopyableTextCell
        text={node.id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 430,
    id: "paymentMandateId",
    title: t("fundingSources.paymentMandateId"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { node } }) => (
      <CopyableTextCell
        text={node.paymentMandate.id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 200,
    id: "createdAt",
    title: t("fundingSources.createdAt"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { createdAt },
      },
    }) => <TextCell text={dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />,
  },
  {
    width: 200,
    id: "enablamentDate",
    title: t("fundingSources.enablementDate"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { node } }) =>
      match(node.statusInfo)
        .with({ __typename: "EnabledFundingSourceStatusInfo" }, info => (
          <TextCell
            text={dayjs(info.enabledAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
          />
        ))
        .with({ __typename: "CanceledFundingSourceStatusInfo" }, ({ canceledEnabledAt }) =>
          isNotNullish(canceledEnabledAt) ? (
            <TextCell
              text={dayjs(canceledEnabledAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
            />
          ) : null,
        )
        .otherwise(() => null),
  },
  {
    width: 200,
    id: "cancelationDate",
    title: t("fundingSources.cancelationDate"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { node } }) =>
      match(node.statusInfo)
        .with({ __typename: "CanceledFundingSourceStatusInfo" }, info => (
          <TextCell
            text={dayjs(info.canceledAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
          />
        ))
        .otherwise(() => null),
  },
  {
    width: 200,
    id: "rejectionDate",
    title: t("fundingSources.rejectionDate"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { node } }) =>
      match(node.statusInfo)
        .with({ __typename: "RejectedFundingSourceStatusInfo" }, info => (
          <TextCell
            text={dayjs(info.rejectedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
          />
        ))
        .otherwise(() => null),
  },
  {
    width: 200,
    id: "updatedAt",
    title: t("fundingSources.updateDate"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { node } }) => (
      <TextCell text={dayjs(node.updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
    ),
  },
];

const stickedToEndColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 240,
    id: "verificationStatus",
    title: t("fundingSources.verificationStatus"),
    renderTitle: ({ title }) => <HeaderCell align="right" text={title} />,
    renderCell: ({ item: { node } }) => (
      <Cell align="right">
        <LakeText color={colors.gray[900]}>
          {match(node.accountVerification.statusInfo.status)
            .with("Verified", () => (
              <Tag color="positive">{t("fundingSources.accountVerification.verified")}</Tag>
            ))
            .with("PendingVerification", () => (
              <Tag color="gray">{t("fundingSources.accountVerification.pendingVerification")}</Tag>
            ))
            .with("PendingReview", () => (
              <Tag color="shakespear">{t("fundingSources.accountVerification.pendingReview")}</Tag>
            ))
            .with("Rejected", () => (
              <Tag color="negative">{t("fundingSources.accountVerification.rejected")}</Tag>
            ))
            .exhaustive()}
        </LakeText>
      </Cell>
    ),
  },
  {
    width: 130,
    id: "status",
    title: t("fundingSources.status"),
    renderTitle: ({ title }) => <HeaderCell align="right" text={title} />,
    renderCell: ({ item: { node } }) => (
      <Cell align="right">
        <LakeText color={colors.gray[900]}>
          {match(node.statusInfo.status)
            .with("Enabled", () => <Tag color="positive">{t("fundingSources.status.enabled")}</Tag>)
            .with("Canceled", () => <Tag color="gray">{t("fundingSources.status.canceled")}</Tag>)
            .with("Suspended", () => (
              <Tag color="warning">{t("fundingSources.status.suspended")}</Tag>
            ))
            .with("Pending", () => (
              <Tag color="shakespear">{t("fundingSources.status.pending")}</Tag>
            ))
            .with("Rejected", () => (
              <Tag color="negative">{t("fundingSources.status.rejected")}</Tag>
            ))
            .exhaustive()}
        </LakeText>
      </Cell>
    ),
  },
  {
    width: 48,
    id: "actions",
    title: "",
    renderTitle: () => null,
    renderCell: ({ isHovered }) => (
      <Cell align="right">
        <ActionCell>
          <Icon
            name="chevron-right-filled"
            color={isHovered ? colors.gray[700] : colors.gray[200]}
            size={16}
          />
        </ActionCell>
      </Cell>
    ),
  },
];

type Props = {
  accountId: string;
};

export const AccountDetailFundingSourceList = ({ accountId }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const [data, { isLoading, reload, setVariables }] = useQuery(GetFundingSourcesDocument, {
    accountId,
    first: PER_PAGE,
  });

  const extraInfo: ExtraInfo = useMemo(() => {
    return { projectEnv, projectId, accountId };
  }, [projectEnv, projectId, accountId]);

  const totalCount = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ account }) => account?.fundingSources?.totalCount ?? 0);

  const shouldShowTopBar = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ account }) => (account?.fundingSources?.totalCount ?? 0) > 0)
    .getOr(true);

  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <>
      {shouldShowTopBar ? (
        <>
          <Box direction="row" alignItems="center">
            <TrackPressable action="Refresh funding sources list">
              <LakeButton
                ariaLabel={t("common.refresh")}
                mode="secondary"
                size="small"
                icon="arrow-counterclockwise-filled"
                loading={isRefreshing}
                onPress={() => {
                  setIsRefreshing(true);
                  reload().tap(() => setIsRefreshing(false));
                }}
              />
            </TrackPressable>

            <Fill minWidth={16} />

            {totalCount
              .map<ReactNode>(count => (
                <Tag size="large" color="partner">
                  {t("fundingSources.counter", { count })}
                </Tag>
              ))
              .toNull()}
          </Box>

          <Space height={8} />
        </>
      ) : null}

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={20}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.account?.fundingSources}>
            {fundingSources => (
              <VirtualizedList
                variant="default"
                marginHorizontal={negativeSpacings[24]}
                data={fundingSources?.edges ?? []}
                keyExtractor={keyExtractor}
                extraInfo={extraInfo}
                columns={columns}
                stickedToStartColumns={stickedToStartColumns}
                stickedToEndColumns={stickedToEndColumns}
                headerHeight={48}
                rowHeight={48}
                getRowLink={getRowLink}
                onEndReached={() => {
                  if (fundingSources?.pageInfo.hasNextPage === true) {
                    setVariables({ after: fundingSources?.pageInfo.endCursor ?? undefined });
                  }
                }}
                loading={{
                  isLoading,
                  count: 20,
                }}
                renderEmptyList={() => (
                  <EmptyView
                    icon="lake-inbox-empty"
                    title={t("fundingSources.empty.title")}
                    subtitle={formatNestedMessage("fundingSources.empty.subtitle", {
                      learnMore: (
                        <Link
                          style={styles.link}
                          target="blank"
                          to={`https://docs.swan.io/topics/accounts/funding/`}
                        >
                          {t("common.learnMore")}
                        </Link>
                      ),
                    })}
                  />
                )}
              />
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};
