import { Array, AsyncData, Option, Result } from "@swan-io/boxed";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { ReadOnlyFieldList } from "@swan-io/lake/src/components/ReadOnlyFieldList";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { TileGridPlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { colors } from "@swan-io/lake/src/constants/design";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { capitalize } from "@swan-io/lake/src/utils/string";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import {
  Document,
  SupportingDocumentCollection,
  SupportingDocumentCollectionRef,
} from "@swan-io/shared-business/src/components/SupportingDocumentCollection";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import {
  DeleteSupportingDocumentDocument,
  GenerateSupportingDocumentUploadUrlDocument,
  GetSupportingDocumentListDocument,
  RequestSupportingDocumentReviewDocument,
  SupportingDocumentPurposeEnum,
} from "../graphql/partner";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { NewSupportingDocument } from "../pages/NewSupportingDocument";
import { locale, t } from "../utils/i18n";
import { Router, onboardingDetailRoutes } from "../utils/routes";
import { CopyTextButton } from "./CopyTextButton";
import { ErrorView } from "./ErrorView";
import { LinkInputButton } from "./LinkInputButton";

const styles = StyleSheet.create({
  list: {
    listStyleType: "disc",
  },
  listItem: {
    display: "list-item",
    listStyleType: "disc",
    marginLeft: "1em",
  },
});

type Props = {
  onboardingId: string;
  supportingDocumentCollectionId: string;
};

export const SupportingDocumentsCollectionArea = ({
  onboardingId,
  supportingDocumentCollectionId,
}: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const canUploadSupportingDocument = usePermissions(projectEnv).dataOnboarding.write;
  const [confirmReviewOpened, setConfirmReviewOpened] = useState(false);

  const route = Router.useRoute(onboardingDetailRoutes);
  const supportingDocumentCollectionRef =
    useRef<SupportingDocumentCollectionRef<SupportingDocumentPurposeEnum>>(null);

  const [data, { reload }] = useQuery(GetSupportingDocumentListDocument, {
    supportingDocumentCollectionId,
  });

  const [requestSupportingDocumentReviewDocument, reviewRequest] = useMutation(
    RequestSupportingDocumentReviewDocument,
  );

  const [generateSupportingDocument] = useMutation(GenerateSupportingDocumentUploadUrlDocument);
  const [deleteSupportingDocument] = useMutation(DeleteSupportingDocumentDocument);

  const requestReview = () => {
    requestSupportingDocumentReviewDocument({
      input: { supportingDocumentCollectionId },
    })
      .mapOk(data => data.requestSupportingDocumentCollectionReview)
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(() => {
        reload();
        setConfirmReviewOpened(false);
      })
      .tapError(error => {
        showToast({ variant: "error", error, title: translateError(error) });
      });
  };

  return (
    <View>
      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <TileGridPlaceholder withTabs={false} />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(
          AsyncData.P.Done(
            Result.P.Ok(
              P.select({
                supportingDocumentCollection: P.nonNullable,
                projectInfo: P.nonNullable,
              }),
            ),
          ),
          ({ supportingDocumentCollection, projectInfo: { supportingDocumentSettings } }) => {
            const { collectMode = "EndCustomer" } = supportingDocumentSettings ?? {};
            const docs = Array.filterMap(
              supportingDocumentCollection.supportingDocuments,
              document =>
                match(document)
                  .returnType<Option<Document<SupportingDocumentPurposeEnum>>>()
                  .with(P.nullish, () => Option.None())
                  .with(
                    { statusInfo: { __typename: "SupportingDocumentNotUploadedStatusInfo" } },
                    () => Option.None(),
                  )
                  .with(
                    {
                      statusInfo: {
                        __typename: "SupportingDocumentWaitingForUploadStatusInfo",
                      },
                    },
                    () => Option.None(),
                  )
                  .with(
                    { statusInfo: { __typename: "SupportingDocumentValidatedStatusInfo" } },
                    document =>
                      Option.Some({
                        purpose: document.supportingDocumentPurpose,
                        file: {
                          id: document.id,
                          name: document.statusInfo.filename,
                          statusInfo: { status: "Validated" },
                        },
                      }),
                  )
                  .with(
                    { statusInfo: { __typename: "SupportingDocumentRefusedStatusInfo" } },
                    document =>
                      Option.Some({
                        purpose: document.supportingDocumentPurpose,
                        file: {
                          id: document.id,
                          name: document.statusInfo.filename,
                          statusInfo: { status: "Refused", reason: document.statusInfo.reason },
                        },
                      }),
                  )
                  .with(
                    { statusInfo: { __typename: "SupportingDocumentUploadedStatusInfo" } },
                    document =>
                      Option.Some({
                        purpose: document.supportingDocumentPurpose,
                        file: {
                          id: document.id,
                          name: document.statusInfo.filename,
                          statusInfo: { status: "Uploaded" },
                        },
                      }),
                  )
                  .exhaustive(),
            );

            const isReadOnly = match({
              collectMode,
              status: supportingDocumentCollection.statusInfo.status,
            })
              .with({ collectMode: "API", status: "WaitingForDocument" }, () => false)
              .otherwise(() => true);

            // When no supporting documents are required, the collection remains indefinitely
            // in a `PendingReview` status, even though no further action is possible
            const isEmptyCollectionWithoutRequirements = match({
              collectMode,
              supportingDocumentCollection,
              docs,
            })
              .with(
                {
                  collectMode: "API",
                  supportingDocumentCollection: {
                    statusInfo: { status: "PendingReview" },
                    requiredSupportingDocumentPurposes: P.union(P.nullish, []),
                  },
                  docs: [],
                },
                () => true,
              )
              .otherwise(() => false);

            return (
              <TileGrid>
                <Tile
                  title={t("supportingDocumentCollection.title")}
                  footer={
                    isEmptyCollectionWithoutRequirements ? (
                      <LakeAlert
                        anchored={true}
                        variant="info"
                        title={t("supportingDocumentCollection.pendingReviewWithNoRequiredDocs")}
                      />
                    ) : null
                  }
                >
                  <ReadOnlyFieldList>
                    <LakeLabel
                      readOnly={true}
                      label={t("supportingDocumentCollection.id")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>
                          {supportingDocumentCollection.id}
                        </LakeText>
                      )}
                      actions={
                        <LakeCopyButton
                          valueToCopy={supportingDocumentCollection.id}
                          copyText={t("copyButton.copyTooltip")}
                          copiedText={t("copyButton.copiedTooltip")}
                        />
                      }
                    />

                    {isEmptyCollectionWithoutRequirements ? null : (
                      <LakeLabel
                        readOnly={true}
                        label={t("supportingDocumentCollection.status")}
                        render={() =>
                          match(supportingDocumentCollection.statusInfo.status)
                            .with("Approved", () => (
                              <Tag color="positive">
                                {t("supportingDocumentCollection.status.Approved")}
                              </Tag>
                            ))
                            .with("Canceled", () => (
                              <Tag color="gray">
                                {t("supportingDocumentCollection.status.Canceled")}
                              </Tag>
                            ))
                            .with("PendingReview", () => (
                              <Tag color="shakespear">
                                {t("supportingDocumentCollection.status.PendingReview")}
                              </Tag>
                            ))
                            .with("Rejected", () => (
                              <Tag color="negative">
                                {t("supportingDocumentCollection.status.Rejected")}
                              </Tag>
                            ))
                            .with("WaitingForDocument", () => (
                              <Tag color="warning">
                                {t("supportingDocumentCollection.status.WaitingForDocument")}
                              </Tag>
                            ))
                            .exhaustive()
                        }
                      />
                    )}

                    {match(supportingDocumentCollection.statusInfo)
                      .with(
                        { __typename: "SupportingDocumentCollectionApprovedStatusInfo" },
                        ({ approvedAt }) => (
                          <LakeLabel
                            readOnly={true}
                            label={t("supportingDocumentCollection.approvedAt")}
                            render={() => (
                              <LakeText color={colors.gray[900]}>
                                {dayjs(approvedAt).format(
                                  `${locale.dateFormat} ${locale.timeFormat}`,
                                )}
                              </LakeText>
                            )}
                          />
                        ),
                      )
                      .with(
                        { __typename: "SupportingDocumentCollectionRejectedStatusInfo" },
                        ({ rejectedAt }) => (
                          <LakeLabel
                            readOnly={true}
                            label={t("supportingDocumentCollection.rejectedAt")}
                            render={() => (
                              <LakeText color={colors.gray[900]}>
                                {dayjs(rejectedAt).format(
                                  `${locale.dateFormat} ${locale.timeFormat}`,
                                )}
                              </LakeText>
                            )}
                          />
                        ),
                      )
                      .with(
                        { __typename: "SupportingDocumentCollectionCanceledStatusInfo" },
                        ({ canceledAt }) => (
                          <LakeLabel
                            readOnly={true}
                            label={t("supportingDocumentCollection.canceledAt")}
                            render={() => (
                              <LakeText color={colors.gray[900]}>
                                {dayjs(canceledAt).format(
                                  `${locale.dateFormat} ${locale.timeFormat}`,
                                )}
                              </LakeText>
                            )}
                          />
                        ),
                      )
                      .otherwise(() => null)}

                    {supportingDocumentCollection.requiredSupportingDocumentPurposes.length > 0 &&
                    supportingDocumentCollection.statusInfo.status === "WaitingForDocument" &&
                    collectMode !== "API" ? (
                      <LakeLabel
                        type="view"
                        label={t(
                          "transaction.supportingDocumentCollection.requestedSupportingDocuments",
                        )}
                        render={() => (
                          <View role="list" style={styles.list}>
                            {supportingDocumentCollection.requiredSupportingDocumentPurposes.map(
                              purpose => (
                                <View role="listitem" style={styles.listItem} key={purpose.name}>
                                  <LakeText color={colors.gray[900]}>
                                    {capitalize(
                                      purpose.name
                                        .replace(
                                          /([A-Z])([a-z])/g,
                                          (_, $1, $2) => ` ${String($1).toLowerCase()}${$2}`,
                                        )
                                        .trim(),
                                    )}
                                  </LakeText>
                                </View>
                              ),
                            )}
                          </View>
                        )}
                      />
                    ) : null}

                    {collectMode !== "API" ? (
                      <LakeLabel
                        type="view"
                        label={t("transaction.supportingDocumentCollection.portalUrl")}
                        render={() => (
                          <Box direction="row">
                            <LakeTextInput
                              readOnly={true}
                              value={supportingDocumentCollection.supportingDocumentCollectionUrl}
                              hideErrors={true}
                            />

                            <Space width={12} />

                            <CopyTextButton
                              value={supportingDocumentCollection.supportingDocumentCollectionUrl}
                            />

                            <Space width={12} />

                            <LinkInputButton
                              size="small"
                              external={true}
                              to={supportingDocumentCollection.supportingDocumentCollectionUrl}
                            />
                          </Box>
                        )}
                      />
                    ) : null}
                  </ReadOnlyFieldList>
                </Tile>

                <View>
                  <Tile title={t("supportingDocumentCollection.documents")}>
                    <SupportingDocumentCollection
                      ref={supportingDocumentCollectionRef}
                      status={supportingDocumentCollection.statusInfo.status}
                      readOnly={isReadOnly}
                      showIds={true}
                      generateUpload={({
                        fileName,
                        purpose,
                      }: {
                        fileName: string;
                        purpose: SupportingDocumentPurposeEnum;
                      }) =>
                        generateSupportingDocument({
                          input: {
                            filename: fileName,
                            supportingDocumentPurpose: purpose,
                            supportingDocumentCollectionId,
                          },
                        })
                          .mapOk(data => data.generateSupportingDocumentUploadUrl)
                          .mapOkToResult(filterRejectionsToResult)
                          .mapOk(({ upload, supportingDocumentId }) => ({
                            upload,
                            id: supportingDocumentId,
                          }))
                      }
                      documents={docs}
                      requiredDocumentPurposes={supportingDocumentCollection.requiredSupportingDocumentPurposes.map(
                        item => item.name,
                      )}
                      onRemoveFile={({ id }) =>
                        deleteSupportingDocument({ input: { id } }).mapOkToResult(
                          filterRejectionsToResult,
                        )
                      }
                    />

                    {isReadOnly ? null : (
                      <LakeButtonGroup paddingBottom={0}>
                        <LakeButton
                          icon="add-circle-filled"
                          mode="tertiary"
                          color="gray"
                          size="small"
                          onPress={() => {
                            Router.push("OnboardingDetailSupportingDocumentsNew", {
                              projectId,
                              projectEnv,
                              onboardingId,
                              supportingDocumentCollectionId,
                            });
                          }}
                        >
                          {t("supportingDocument.uploadOther")}
                        </LakeButton>
                      </LakeButtonGroup>
                    )}
                  </Tile>

                  {isReadOnly ? null : (
                    <LakeButtonGroup>
                      <LakeButton onPress={() => setConfirmReviewOpened(true)} color="current">
                        {t("supportingDocument.requestReview")}
                      </LakeButton>
                    </LakeButtonGroup>
                  )}

                  <LakeModal
                    visible={match(route)
                      .with({ name: "OnboardingDetailSupportingDocumentsNew" }, () => !isReadOnly)
                      .otherwise(() => false)}
                    color="current"
                    icon="add-circle-regular"
                    title={t("supportingDocument.new")}
                  >
                    <NewSupportingDocument
                      canUploadSupportingDocument={canUploadSupportingDocument}
                      supportingDocumentCollectionId={supportingDocumentCollection.id}
                      onSave={document => {
                        if (supportingDocumentCollectionRef.current != null) {
                          supportingDocumentCollectionRef.current.addDocument(document);
                        }
                        Router.push("OnboardingDetailSupportingDocuments", {
                          projectId,
                          projectEnv,
                          onboardingId,
                          supportingDocumentCollectionId,
                        });
                      }}
                      onPressCancel={() => {
                        Router.push("OnboardingDetailSupportingDocuments", {
                          projectId,
                          projectEnv,
                          onboardingId,
                          supportingDocumentCollectionId,
                        });
                      }}
                    />
                  </LakeModal>

                  <LakeModal
                    icon="warning-regular"
                    title={t("supportingDocument.confirmRequestReview.title")}
                    visible={confirmReviewOpened}
                  >
                    <>
                      <LakeText color={colors.gray[900]}>
                        {t("supportingDocument.confirmRequestReview.description")}
                      </LakeText>

                      <Space height={32} />

                      <LakeButtonGroup paddingBottom={0}>
                        <LakeButton
                          color="gray"
                          mode="secondary"
                          onPress={() => setConfirmReviewOpened(false)}
                          grow={true}
                        >
                          {t("common.cancel")}
                        </LakeButton>

                        <LakeButton
                          color="current"
                          onPress={requestReview}
                          loading={reviewRequest.isLoading()}
                          grow={true}
                        >
                          {t("common.confirm")}
                        </LakeButton>
                      </LakeButtonGroup>
                    </>
                  </LakeModal>
                </View>
              </TileGrid>
            );
          },
        )
        .otherwise(() => (
          <ErrorView />
        ))}
    </View>
  );
};
