import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useFocusReset } from "@swan-io/chicane";
import { useQuery } from "@swan-io/graphql-client";
import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { TilePlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { Suspense, useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { WebhookSubscriptionDocument } from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { NotFoundPage } from "../pages/NotFoundPage";
import { WebhookSubscriptionEventLog } from "../pages/WebhookSubscriptionEventLog";
import { t } from "../utils/i18n";
import { Router, webhookDetailRoutes } from "../utils/routes";
import { ErrorView } from "./ErrorView";
import { Redirect } from "./Redirect";
import { WebhookSubscriptionDetailGeneral } from "./WebhookSubscriptionDetailGeneral";
import { WebhookSubscriptionEventLogs } from "./WebhookSubscriptionEventLogs";

type Props = {
  webhookSubscriptionId: string;
};

const styles = StyleSheet.create({
  routeContainer: {
    flexGrow: 1,
  },
});

export const WebhookSubscriptionDetail = ({ webhookSubscriptionId }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const route = Router.useRoute(webhookDetailRoutes);
  const containerRef = useRef(null);

  useFocusReset({ route, containerRef });

  const [data, { reload }] = useQuery(WebhookSubscriptionDocument, {
    id: webhookSubscriptionId,
  });

  useCrumb(
    useMemo(
      () =>
        data
          .toOption()
          .flatMap(result => result.toOption())
          .flatMap(data => Option.fromNullable(data.webhookSubscription))
          .map(webhookSubscription => ({
            label: webhookSubscription.label,
            link: Router.DevelopersWebhooksSubscriptionRoot({
              projectId,
              projectEnv,
              webhookSubscriptionId,
            }),
          }))
          .toUndefined(),
      [projectId, webhookSubscriptionId, projectEnv, data],
    ),
  );

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
      <>
        <Space height={72} />
        <TilePlaceholder />
      </>
    ))
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok({ webhookSubscription: P.nullish })), () => (
      <Redirect to={Router.DevelopersWebhooksRoot({ projectId, projectEnv })} />
    ))
    .with(
      AsyncData.P.Done(Result.P.Ok({ webhookSubscription: P.select(P.nonNullable) })),
      webhookSubscription => {
        return (
          <>
            {match(route)
              .with(
                { name: "DevelopersWebhooksSubscriptionRoot" },
                { name: "DevelopersWebhooksSubscriptionEventLogs" },
                () => (
                  <>
                    <TabView
                      tabs={[
                        {
                          label: t("webhook.general"),
                          url: Router.DevelopersWebhooksSubscriptionRoot({
                            projectId,
                            projectEnv,
                            webhookSubscriptionId,
                          }),
                        },
                        {
                          label: t("webhook.eventLogs"),
                          url: Router.DevelopersWebhooksSubscriptionEventLogs({
                            projectId,
                            projectEnv,
                            webhookSubscriptionId,
                          }),
                        },
                      ]}
                      otherLabel={t("common.tabs.other")}
                    />

                    <Space height={24} />
                  </>
                ),
              )
              .otherwise(() => null)}

            <View ref={containerRef} style={styles.routeContainer}>
              {match(route)
                .with({ name: "DevelopersWebhooksSubscriptionRoot" }, () => (
                  <Suspense fallback={<TilePlaceholder />}>
                    <WebhookSubscriptionDetailGeneral
                      webhookSubscriptionId={webhookSubscriptionId}
                      webhookSubscription={webhookSubscription}
                      onSave={() => {}}
                      reexecuteQuery={() => {
                        reload();
                      }}
                    />
                  </Suspense>
                ))
                .with(
                  { name: "DevelopersWebhooksSubscriptionEventLog" },
                  ({ params: { logId } }) => (
                    <WebhookSubscriptionEventLog
                      webhookSubscriptionId={webhookSubscriptionId}
                      logId={logId}
                      webhookSubscription={webhookSubscription}
                    />
                  ),
                )
                .with({ name: "DevelopersWebhooksSubscriptionEventLogs" }, ({ params }) => (
                  <WebhookSubscriptionEventLogs
                    webhookSubscriptionId={webhookSubscriptionId}
                    webhookEventTypes={webhookSubscription.eventTypes}
                    params={params}
                  />
                ))
                .otherwise(() => (
                  <NotFoundPage />
                ))}
            </View>
          </>
        );
      },
    )
    .exhaustive();
};
