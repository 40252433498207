import { Box } from "@swan-io/lake/src/components/Box";
import { SwanLogo } from "@swan-io/lake/src/components/SwanLogo";
import { backgroundColor, colors, radii, spacings } from "@swan-io/lake/src/constants/design";
import { StyleSheet, View } from "react-native";

const TRANSPARENT = "transparent";

const styles = StyleSheet.create({
  header: {
    backgroundColor: backgroundColor.accented,
    alignItems: "stretch",
    height: 64,
    flexDirection: "row",
    justifyContent: "center",
    boxShadow: "0 1px rgba(0, 0, 0, 0.06)",
  },
  content: {
    width: "100%",
    maxWidth: 2560,
    paddingHorizontal: 20,
  },
  logo: {
    height: "auto",
    width: 70,
  },
  navigation: {
    height: 64,
    borderTopWidth: 3,
    borderTopColor: TRANSPARENT,
    borderBottomWidth: 1,
    borderBottomColor: colors.gray[100],
  },
  navigationWrapper: {
    width: "100%",
    paddingHorizontal: 20,
    maxWidth: 2560,
    marginHorizontal: "auto",
  },
  navigationItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: spacings[24],
    width: spacings[96],
    borderRadius: radii[6],
    marginRight: spacings[8],
    backgroundColor: colors.gray[100],
  },
  shimmer: {
    opacity: 0.5,
    position: "absolute",
    backgroundImage: `linear-gradient(to right, ${backgroundColor.default}, ${colors.gray[100]} 50%, ${backgroundColor.default})`,
    width: "200%",
    top: 0,
    left: "100%",
    bottom: 0,
    animationDuration: "2000ms",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
    animationKeyframes: {
      to: {
        transform: "translateX(-200%)",
      },
    },
  },
});

type Props = {
  shimmer?: boolean;
};

export const ProjectPlaceholderView = ({ shimmer = true }: Props) => {
  return (
    <>
      <View style={styles.header}>
        <Box direction="row" alignItems="center" style={styles.content}>
          <SwanLogo style={styles.logo} />
        </Box>
      </View>

      <Box grow={1}>
        {shimmer ? <Box style={styles.shimmer} /> : null}

        <Box style={styles.navigation} justifyContent="center">
          <Box style={styles.navigationWrapper} direction="row">
            <View style={styles.navigationItem} />
            <View style={styles.navigationItem} />
            <View style={styles.navigationItem} />
            <View style={styles.navigationItem} />
          </Box>
        </Box>
      </Box>
    </>
  );
};
