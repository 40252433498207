export const env = {
  VERSION: __env.VERSION,
  DEVELOPER_OAUTH2_ENDPOINT: __env.CLIENT_OAUTH_SERVER_URL,
  EXPLORER_URL: __env.CLIENT_EXPLORER_URL,
  GRAPHQL_LIVE_PARTNER_ENDPOINT: __env.PUBLIC_GRAPHQL_LIVE_PARTNER_ENDPOINT,
  GRAPHQL_SANDBOX_PARTNER_ENDPOINT: __env.PUBLIC_GRAPHQL_SANDBOX_PARTNER_ENDPOINT,
  HUBSPOT_ENV: __env.CLIENT_HUBSPOT_ENV,
  DASHBOARD_URL: __env.DASHBOARD_URL,
  ONBOARDING_LIVE_URL: __env.CLIENT_ONBOARDING_LIVE_URL,
  ONBOARDING_SANDBOX_URL: __env.CLIENT_ONBOARDING_SANDBOX_URL,
  BANKING_LIVE_URL: __env.CLIENT_BANKING_LIVE_URL,
  BANKING_SANDBOX_URL: __env.CLIENT_BANKING_SANDBOX_URL,
  IDENTITY_URL: __env.IDENTITY_URL,
  TGGL_API_KEY: __env.TGGL_API_KEY,
};
