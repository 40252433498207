import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabelledCheckbox } from "@swan-io/lake/src/components/LakeCheckbox";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { TilePlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { useBoolean } from "@swan-io/lake/src/hooks/useBoolean";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useForm } from "@swan-io/use-form";
import { P, match } from "ts-pattern";
import { DocumentationLink } from "../components/DocumentationLink";
import { ErrorView } from "../components/ErrorView";
import { TrackPressable } from "../components/TrackPressable";
import {
  DeleteCardPartnerControlDocument,
  GetCardPartnerControlPageDocument,
  GetCardPartnerControlPageQuery,
  UpdateCardPartnerControlDocument,
} from "../graphql/exposed-internal";
import { usePermissions } from "../hooks/usePermissions";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { validateNumeric, validateUrl } from "../utils/validations";

const MAX_TIMEOUT_SANDBOX = 10000;
const MAX_TIMEOUT_LIVE = 1500;

const defaultResponseItems: { name: string; value: boolean }[] = [
  { name: t("paymentControl.form.defaultResponse.accept"), value: true },
  { name: t("paymentControl.form.defaultResponse.reject"), value: false },
];

const generateRandomSecret = (length: number) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  return new Array<string>(length).fill("").reduce(acc => {
    const charIndex = Math.floor(Math.random() * characters.length);
    const char = characters.charAt(charIndex);
    return acc + char;
  }, "");
};

const getDefaultTimeout = (projectEnv: ProjectEnv) =>
  projectEnv === "live" ? MAX_TIMEOUT_LIVE : MAX_TIMEOUT_SANDBOX;

const PaymentControl = ({
  data,
  onSave,
}: {
  data: GetCardPartnerControlPageQuery;
  onSave: () => void;
}) => {
  const { projectEnv } = useProjectInfo();
  const canEditPaymentControl = usePermissions(projectEnv).paymentControl.write;

  const [enabled, setEnabled] = useBoolean(() => isNotNullish(data.getCardPartnerControl));

  const maxTimeout = projectEnv === "live" ? MAX_TIMEOUT_LIVE : MAX_TIMEOUT_SANDBOX;
  const formDisabled = !canEditPaymentControl;

  const [updatePartnerControl, update] = useMutation(UpdateCardPartnerControlDocument);
  const [deletePartnerControl, deletion] = useMutation(DeleteCardPartnerControlDocument);

  const generateSecret = () => {
    const secretDefaultLength = 15;
    const secret = generateRandomSecret(secretDefaultLength);
    setFieldValue("secret", secret);
  };

  const { Field, setFieldValue, submitForm } = useForm({
    endpointUrl: {
      initialValue: data.getCardPartnerControl?.endpoint ?? "",
      validate: validateUrl,
      sanitize: value => value.trim(),
    },
    defaultResponse: {
      initialValue: data.getCardPartnerControl?.defaultResponse ?? true,
    },
    timeout: {
      initialValue:
        data.getCardPartnerControl?.timeoutMs.toString() ??
        getDefaultTimeout(projectEnv).toString(),
      validate: validateNumeric({ min: 1, max: maxTimeout }),
      sanitize: value => value.trim(),
    },
    secret: {
      initialValue: data.getCardPartnerControl?.secret ?? "",
      sanitize: value => value.trim(),
    },
  });

  const handleSave = () => {
    if (!enabled) {
      return deletePartnerControl({})
        .tapOk(() => {
          showToast({ variant: "success", title: t("toast.success.cardPartnerControl") });
          onSave();
        })
        .tapError(error => {
          showToast({ variant: "error", error, title: translateError(error) });
        });
    }

    submitForm({
      onSuccess: values => {
        const option = Option.allFromDict(values);

        if (option.isSome()) {
          const { endpointUrl, defaultResponse, timeout, secret } = option.get();

          return updatePartnerControl({
            input: {
              endpoint: endpointUrl,
              defaultResponse,
              secret,
              timeoutMs: parseInt(timeout),
              protocol: "HttpJson",
            },
          })
            .tapOk(() => {
              showToast({ variant: "success", title: t("toast.success.cardPartnerControl") });
              onSave();
            })
            .tapError(error => {
              showToast({ variant: "error", error, title: translateError(error) });
            });
        }
      },
    });
  };

  return (
    <Box>
      <Tile
        title={t("paymentControl.title")}
        description={
          <LakeText>
            {t("paymentControl.subtitle")}{" "}
            <DocumentationLink to="paymentControl">{t("common.learnMore")}</DocumentationLink>
          </LakeText>
        }
      >
        <LakeLabelledCheckbox
          value={enabled}
          onValueChange={setEnabled.toggle}
          disabled={formDisabled}
          label={t("paymentControl.form.enabled")}
        />

        <Space height={16} />

        <Field name="endpointUrl">
          {({ value, valid, error, onChange, onBlur }) => (
            <LakeLabel
              label={t("paymentControl.form.endpointUrl")}
              render={id => (
                <LakeTextInput
                  id={id}
                  value={value}
                  valid={valid}
                  error={error}
                  onChangeText={onChange}
                  onBlur={onBlur}
                  disabled={formDisabled || !enabled}
                />
              )}
            />
          )}
        </Field>

        <Field name="defaultResponse">
          {({ value, onChange }) => (
            <LakeLabel
              label={t("paymentControl.form.defaultResponse")}
              render={id => (
                <LakeSelect
                  id={id}
                  value={value}
                  items={defaultResponseItems}
                  onValueChange={onChange}
                  disabled={formDisabled || !enabled}
                />
              )}
            />
          )}
        </Field>

        <Field name="timeout">
          {({ value, error, onChange, onBlur }) => (
            <LakeLabel
              label={t("paymentControl.form.timeout", {
                maxDuration: maxTimeout,
                env: projectEnv,
              })}
              render={id => (
                <LakeTextInput
                  id={id}
                  inputMode="numeric"
                  value={value}
                  error={error}
                  onChangeText={onChange}
                  onBlur={onBlur}
                  unit="ms"
                  disabled={formDisabled || !enabled}
                />
              )}
            />
          )}
        </Field>

        <Box direction="row" alignItems="end">
          <Field name="secret">
            {({ value, onChange, onBlur }) => (
              <LakeLabel
                label={t("paymentControl.form.secret")}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    disabled={formDisabled || !enabled}
                  />
                )}
                actions={
                  <LakeTooltip placement="right" content={t("paymentControl.form.generateSecret")}>
                    <TrackPressable action="Generate secret">
                      <LakeButton
                        disabled={formDisabled || !enabled}
                        mode="secondary"
                        size="small"
                        icon="arrow-counterclockwise-filled"
                        ariaLabel={t("paymentControl.form.generateSecret")}
                        onPress={generateSecret}
                      />
                    </TrackPressable>
                  </LakeTooltip>
                }
              />
            )}
          </Field>
        </Box>
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Save payment control">
          <LakeTooltip
            placement="left"
            content={t("common.action.denied")}
            disabled={!formDisabled}
          >
            <LakeButton
              size="small"
              color="current"
              loading={update.isLoading() || deletion.isLoading()}
              onPress={() => void handleSave()}
              disabled={formDisabled}
            >
              {t("common.save")}
            </LakeButton>
          </LakeTooltip>
        </TrackPressable>
      </Box>
    </Box>
  );
};

export const PaymentControlPage = () => {
  const [data, { reload }] = useQuery(GetCardPartnerControlPageDocument, {});

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <TilePlaceholder />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
      <PaymentControl
        data={data}
        onSave={() => {
          // perform a full-reload to make sure the form is remounted
          reload();
        }}
      />
    ))
    .exhaustive();
};
