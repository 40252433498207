import { Option } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Space } from "@swan-io/lake/src/components/Space";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { pick } from "@swan-io/lake/src/utils/object";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useForm } from "@swan-io/use-form";
import dayjs from "dayjs";
import { useCallback, useMemo } from "react";
import { Rifm } from "rifm";
import { languages } from "../constants/languages";
import {
  AccountLanguage,
  GenerateAccountStatementDocument,
  StatementType,
} from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { locale, rifmDateProps, t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { validateDate } from "../utils/validations";

type FormProps = {
  language: AccountLanguage;
  openingDate: string;
  closingDate: string;
  statementType: StatementType;
};

type Props = {
  accountId: string;
  onSave: () => void;
  creationDate: string;
  canCreateAccountStatements: boolean;
};

const statementTypeList: StatementType[] = ["PDF", "CSV"];

export const NewAccountStatementPage = ({
  accountId,
  onSave: onExternalSave,
  creationDate,
  canCreateAccountStatements,
}: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const [generateStatement] = useMutation(GenerateAccountStatementDocument);

  const onPressCancel = useCallback(() => {
    Router.push("AccountDetailAccountStatementsRoot", { projectId, projectEnv, accountId });
  }, [projectId, projectEnv, accountId]);

  const onSave = () => {
    return submitForm({
      onSuccess: values => {
        const option = Option.allFromDict(pick(values, ["openingDate", "closingDate"]));

        if (option.isSome()) {
          const { openingDate, closingDate } = option.get();

          return generateStatement({
            input: {
              accountId,
              openingDate: dayjs
                .utc(openingDate, locale.dateFormat)
                .format("YYYY-MM-DDT00:00:00.000+01:00"),
              closingDate: dayjs
                .utc(closingDate, locale.dateFormat)
                .format("YYYY-MM-DDT23:59:59.999+01:00"),
              statementType: values.statementType.toUndefined(),
              language: values.language.toUndefined(),
            },
          })
            .tapOk(() => {
              showToast({ variant: "success", title: t("toast.success.newStatementCreated") });
              onExternalSave();
            })
            .tapError(error => {
              showToast({ variant: "error", error, title: translateError(error) });
            });
        }
      },
    });
  };

  const { Field, FieldsListener, formStatus, submitForm } = useForm<FormProps>({
    openingDate: {
      initialValue: "",
      strategy: "onBlur",
      validate: (value, { getFieldValue }) => {
        // account statements use UTC+1
        const openingDate = dayjs.utc(value, locale.dateFormat).subtract(1, "hour");

        const closingDate = dayjs
          .utc(getFieldValue("closingDate"), locale.dateFormat)
          .subtract(1, "hour");

        if (openingDate.isBefore(dayjs(creationDate).subtract(1, "day"))) {
          return t("accountStatements.new.openingDateBeforeCreationAccount", {
            creationDate: dayjs(creationDate).format(locale.dateFormat),
          });
        }

        //check if statements are longer than 3 months
        if (dayjs(closingDate).isAfter(dayjs(openingDate).add(3, "months"))) {
          return t("accountStatements.new.dateRangeLonger");
        }

        return validateDate(value);
      },
    },
    closingDate: {
      initialValue: "",
      strategy: "onBlur",
      validate: (value, { getFieldValue }) => {
        const openingDate = dayjs
          .utc(getFieldValue("openingDate"), locale.dateFormat)
          .subtract(1, "hour");

        const closingDate = dayjs.utc(value, locale.dateFormat).subtract(1, "hour");
        //check if statements are longer than 3 months
        if (dayjs(closingDate).isAfter(dayjs(openingDate).add(3, "months"))) {
          return t("accountStatements.new.dateRangeLonger");
        }

        if (closingDate.isAfter(dayjs())) {
          return t("accountStatements.new.dateInTheFuture");
        }

        if (closingDate.isBefore(openingDate)) {
          return t("accountStatements.new.closingIsBeforeOpening");
        }

        return validateDate(value);
      },
    },
    statementType: {
      initialValue: "PDF",
    },
    language: {
      initialValue: "en",
    },
  });

  const languageOptions = useMemo(
    () =>
      languages.map(country => ({
        name: country.native,
        value: country.id,
      })),
    [],
  );

  return (
    <>
      <FieldsListener names={["openingDate", "closingDate"]}>
        {({ openingDate, closingDate }) =>
          openingDate.error === t("accountStatements.new.dateRangeLonger") ||
          closingDate.error === t("accountStatements.new.dateRangeLonger") ? (
            <>
              <Space height={12} />

              <LakeAlert variant={"error"} title={t("accountStatements.new.alert.title")}>
                <LakeText>{t("accountStatements.new.alert.subtitle")}</LakeText>
              </LakeAlert>

              <Space height={12} />
            </>
          ) : null
        }
      </FieldsListener>

      <Field name="openingDate">
        {({ value, onChange, error, onBlur }) => (
          <LakeLabel
            label={t("accountStatements.new.openingDate")}
            render={id => (
              <Rifm value={value} onChange={onChange} {...rifmDateProps}>
                {({ value, onChange }) => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={locale.datePlaceholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error}
                  />
                )}
              </Rifm>
            )}
          />
        )}
      </Field>

      <Field name="closingDate">
        {({ value, onChange, error, onBlur }) => (
          <LakeLabel
            label={t("accountStatements.new.closingDate")}
            render={id => (
              <Rifm value={value} onChange={onChange} {...rifmDateProps}>
                {({ value, onChange }) => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={locale.datePlaceholder}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error}
                  />
                )}
              </Rifm>
            )}
          />
        )}
      </Field>

      <Field name="statementType">
        {({ value, onChange }) => (
          <LakeLabel
            label={t("accountStatements.new.format")}
            render={id => (
              <LakeSelect
                id={id}
                value={value}
                items={statementTypeList.map(type => ({ name: type, value: type }))}
                onValueChange={onChange}
              />
            )}
          />
        )}
      </Field>

      <Field name="language">
        {({ value, onChange }) => (
          <LakeLabel
            label={t("accountStatements.new.language")}
            render={id => (
              <LakeSelect id={id} value={value} items={languageOptions} onValueChange={onChange} />
            )}
          />
        )}
      </Field>

      <LakeButtonGroup>
        <LakeButton color="gray" mode="secondary" onPress={onPressCancel} grow={true}>
          {t("common.cancel")}
        </LakeButton>

        <LakeTooltip
          placement="left"
          content={t("common.action.denied")}
          disabled={canCreateAccountStatements}
          containerStyle={commonStyles.fill}
        >
          <LakeButton
            color="current"
            onPress={onSave}
            loading={formStatus === "submitting"}
            disabled={!canCreateAccountStatements}
            grow={true}
          >
            {t("common.save")}
          </LakeButton>
        </LakeTooltip>
      </LakeButtonGroup>
    </>
  );
};
