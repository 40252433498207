import { AsyncData, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeDownloadButton } from "@swan-io/lake/src/components/LakeDownloadButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { ReadOnlyFieldList } from "@swan-io/lake/src/components/ReadOnlyFieldList";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { TileGridPlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { colors } from "@swan-io/lake/src/constants/design";
import { isNotNullish, isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { countries } from "@swan-io/shared-business/src/constants/countries";
import dayjs from "dayjs";
import { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { GetFundingSourceDocument } from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { locale, t } from "../utils/i18n";
import { printMaskedIbanFormat } from "../utils/iban";
import { Router } from "../utils/routes";
import { ErrorView } from "./ErrorView";

type Props = {
  accountId: string;
  fundingSourceId: string;
};

const styles = StyleSheet.create({
  columns: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  column: {
    flexBasis: "500px",
    flexGrow: 1,
  },
  unknownValue: {
    fontStyle: "italic",
  },
});

const UNKNOWN_VALUE = <LakeText style={styles.unknownValue}>{t("common.unknown")}</LakeText>;

export const AccountDetailFundingSourceDetail = ({ fundingSourceId, accountId }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const [data] = useQuery(GetFundingSourceDocument, { fundingSourceId });

  useCrumb(
    useMemo(() => {
      return {
        label: fundingSourceId,
        link: Router.AccountDetailFundingSourcesDetail({
          projectId,
          projectEnv,
          accountId,
          fundingSourceId,
        }),
      };
    }, [projectId, accountId, projectEnv, fundingSourceId]),
  );

  return match(data.mapOk(data => data.fundingSource))
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <TileGridPlaceholder withTabs={false} />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.nullish)), () => <ErrorView />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select(P.nonNullable))), fundingSource => {
      const { paymentMandate } = fundingSource;

      return (
        <View style={styles.columns}>
          <View style={styles.column}>
            <Tile title={t("fundingSources.fundingSource")}>
              <ReadOnlyFieldList>
                <LakeLabel
                  type="view"
                  label={t("fundingSources.name")}
                  render={() =>
                    isNotNullishOrEmpty(fundingSource.name) ? (
                      <LakeText color={colors.gray[900]}>{fundingSource.name}</LakeText>
                    ) : (
                      UNKNOWN_VALUE
                    )
                  }
                />

                <LakeLabel
                  type="view"
                  label={t("fundingSources.status")}
                  render={() => (
                    <LakeText color={colors.gray[900]}>
                      {match(fundingSource.statusInfo.status)
                        .with("Enabled", () => (
                          <Tag color="positive">{t("fundingSources.status.enabled")}</Tag>
                        ))
                        .with("Canceled", () => (
                          <Tag color="gray">{t("fundingSources.status.canceled")}</Tag>
                        ))
                        .with("Suspended", () => (
                          <Tag color="warning">{t("fundingSources.status.suspended")}</Tag>
                        ))
                        .with("Pending", () => (
                          <Tag color="shakespear">{t("fundingSources.status.pending")}</Tag>
                        ))
                        .with("Rejected", () => (
                          <Tag color="negative">{t("fundingSources.status.rejected")}</Tag>
                        ))
                        .exhaustive()}
                    </LakeText>
                  )}
                />

                <LakeLabel
                  type="view"
                  label={t("fundingSources.id")}
                  render={() => <LakeText color={colors.gray[900]}>{fundingSource.id}</LakeText>}
                  actions={
                    <LakeCopyButton
                      valueToCopy={fundingSource.id}
                      copyText={t("copyButton.copyTooltip")}
                      copiedText={t("copyButton.copiedTooltip")}
                    />
                  }
                />

                <LakeLabel
                  type="view"
                  label={t("fundingSources.createdAt")}
                  render={() => (
                    <LakeText color={colors.gray[900]}>
                      {dayjs(fundingSource.createdAt).format(
                        `${locale.dateFormat} ${locale.timeFormat}`,
                      )}
                    </LakeText>
                  )}
                />

                <LakeLabel
                  type="view"
                  label={t("fundingSources.enablementDate")}
                  render={() =>
                    match(fundingSource.statusInfo)
                      .with({ __typename: "EnabledFundingSourceStatusInfo" }, ({ enabledAt }) => (
                        <LakeText color={colors.gray[900]}>
                          {dayjs(enabledAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                        </LakeText>
                      ))
                      .with(
                        { __typename: "CanceledFundingSourceStatusInfo" },
                        ({ canceledEnabledAt }) =>
                          isNotNullish(canceledEnabledAt) ? (
                            <LakeText color={colors.gray[900]}>
                              {dayjs(canceledEnabledAt).format(
                                `${locale.dateFormat} ${locale.timeFormat}`,
                              )}
                            </LakeText>
                          ) : (
                            UNKNOWN_VALUE
                          ),
                      )
                      .otherwise(() => UNKNOWN_VALUE)
                  }
                />

                <LakeLabel
                  type="view"
                  label={t("fundingSources.cancelationDate")}
                  render={() =>
                    match(fundingSource.statusInfo)
                      .with({ __typename: "CanceledFundingSourceStatusInfo" }, ({ canceledAt }) => (
                        <LakeText color={colors.gray[900]}>
                          {dayjs(canceledAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                        </LakeText>
                      ))
                      .otherwise(() => UNKNOWN_VALUE)
                  }
                />

                <LakeLabel
                  type="view"
                  label={t("fundingSources.rejectionDate")}
                  render={() =>
                    match(fundingSource.statusInfo)
                      .with({ __typename: "RejectedFundingSourceStatusInfo" }, ({ rejectedAt }) => (
                        <LakeText color={colors.gray[900]}>
                          {dayjs(rejectedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                        </LakeText>
                      ))
                      .otherwise(() => UNKNOWN_VALUE)
                  }
                />

                <LakeLabel
                  type="view"
                  label={t("fundingSources.updateDate")}
                  render={() => (
                    <LakeText color={colors.gray[900]}>
                      {dayjs(fundingSource.updatedAt).format(
                        `${locale.dateFormat} ${locale.timeFormat}`,
                      )}
                    </LakeText>
                  )}
                />
              </ReadOnlyFieldList>
            </Tile>

            <Space height={24} />

            <Tile title={t("fundingSources.accountVerification")}>
              <ReadOnlyFieldList>
                <LakeLabel
                  type="view"
                  label={t("fundingSources.status")}
                  render={() => (
                    <LakeText color={colors.gray[900]}>
                      {match(fundingSource.accountVerification.statusInfo.status)
                        .with("Verified", () => (
                          <Tag color="positive">
                            {t("fundingSources.accountVerification.verified")}
                          </Tag>
                        ))
                        .with("PendingVerification", () => (
                          <Tag color="gray">
                            {t("fundingSources.accountVerification.pendingVerification")}
                          </Tag>
                        ))
                        .with("PendingReview", () => (
                          <Tag color="shakespear">
                            {t("fundingSources.accountVerification.pendingReview")}
                          </Tag>
                        ))
                        .with("Rejected", () => (
                          <Tag color="negative">
                            {t("fundingSources.accountVerification.rejected")}
                          </Tag>
                        ))
                        .exhaustive()}
                    </LakeText>
                  )}
                />

                <LakeLabel
                  type="view"
                  label={t("fundingSources.id")}
                  render={() => (
                    <LakeText color={colors.gray[900]}>
                      {fundingSource.accountVerification.id}
                    </LakeText>
                  )}
                  actions={
                    <LakeCopyButton
                      valueToCopy={fundingSource.accountVerification.id}
                      copyText={t("copyButton.copyTooltip")}
                      copiedText={t("copyButton.copiedTooltip")}
                    />
                  }
                />

                <LakeLabel
                  type="view"
                  label={t("fundingSources.createdAt")}
                  render={() => (
                    <LakeText color={colors.gray[900]}>
                      {dayjs(fundingSource.accountVerification.createdAt).format(
                        `${locale.dateFormat} ${locale.timeFormat}`,
                      )}
                    </LakeText>
                  )}
                />

                <LakeLabel
                  type="view"
                  label={t("fundingSources.updateDate")}
                  render={() => (
                    <LakeText color={colors.gray[900]}>
                      {dayjs(fundingSource.accountVerification.updatedAt).format(
                        `${locale.dateFormat} ${locale.timeFormat}`,
                      )}
                    </LakeText>
                  )}
                />

                {match(fundingSource.accountVerification.statusInfo)
                  .with({ __typename: "VerifiedStatusInfo" }, ({ verifiedAt }) => (
                    <LakeLabel
                      type="view"
                      label={t("fundingSources.accountVerification.verifiedDate")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>
                          {dayjs(verifiedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                        </LakeText>
                      )}
                    />
                  ))
                  .with({ __typename: "RejectedVerificationStatusInfo" }, ({ rejectedAt }) => (
                    <LakeLabel
                      type="view"
                      label={t("fundingSources.accountVerification.rejectedDate")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>
                          {dayjs(rejectedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                        </LakeText>
                      )}
                    />
                  ))
                  .otherwise(() => null)}
              </ReadOnlyFieldList>
            </Tile>
          </View>

          {paymentMandate.__typename === "SEPAPaymentDirectDebitMandate" && (
            <>
              <Space width={24} />

              <View style={styles.column}>
                <Tile title={t("fundingSources.paymentMandate")}>
                  <ReadOnlyFieldList>
                    <LakeLabel
                      type="view"
                      label={t("fundingSources.scheme")}
                      render={() =>
                        match(paymentMandate.scheme)
                          .with("SepaDirectDebitB2b", () => (
                            <Tag color="darkPink">{t("fundingSources.scheme.b2b")}</Tag>
                          ))
                          .with("SepaDirectDebitCore", () => (
                            <Tag color="live">{t("fundingSources.scheme.core")}</Tag>
                          ))
                          .exhaustive()
                      }
                    />

                    <LakeLabel
                      type="view"
                      label={t("fundingSources.id")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>{paymentMandate.id}</LakeText>
                      )}
                      actions={
                        <LakeCopyButton
                          valueToCopy={paymentMandate.id}
                          copyText={t("copyButton.copyTooltip")}
                          copiedText={t("copyButton.copiedTooltip")}
                        />
                      }
                    />

                    <LakeLabel
                      type="view"
                      label={t("fundingSources.paymentMandate.reference")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>{paymentMandate.reference}</LakeText>
                      )}
                      actions={
                        <LakeCopyButton
                          valueToCopy={paymentMandate.reference}
                          copyText={t("copyButton.copyTooltip")}
                          copiedText={t("copyButton.copiedTooltip")}
                        />
                      }
                    />

                    <LakeLabel
                      type="view"
                      label={t("fundingSources.paymentMandate.sequence")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>{paymentMandate.sequence}</LakeText>
                      )}
                    />

                    <LakeLabel
                      type="view"
                      label={t("fundingSources.paymentMandate.creditorIdentifier")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>
                          {paymentMandate.creditor.identifier}
                        </LakeText>
                      )}
                      actions={
                        <LakeCopyButton
                          valueToCopy={paymentMandate.creditor.identifier}
                          copyText={t("copyButton.copyTooltip")}
                          copiedText={t("copyButton.copiedTooltip")}
                        />
                      }
                    />

                    <LakeLabel
                      type="view"
                      label={t("fundingSources.paymentMandate.creditorName")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>{paymentMandate.creditor.name}</LakeText>
                      )}
                    />

                    <LakeLabel
                      type="view"
                      label={t("fundingSources.paymentMandate.creditorCountry")}
                      render={() => {
                        const country = countries.find(
                          ({ cca3 }) => cca3 === paymentMandate.creditor.address.country,
                        );

                        return country ? (
                          <LakeText
                            color={colors.gray[900]}
                          >{`${country.flag} ${country.name}`}</LakeText>
                        ) : (
                          UNKNOWN_VALUE
                        );
                      }}
                    />

                    <LakeLabel
                      type="view"
                      label={t("fundingSources.paymentMandate.debtorName")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>{paymentMandate.debtor.name}</LakeText>
                      )}
                    />

                    <LakeLabel
                      type="view"
                      label={t("fundingSources.paymentMandate.debtorIban")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>
                          {printMaskedIbanFormat(paymentMandate.debtor.iban)}
                        </LakeText>
                      )}
                      actions={
                        <LakeCopyButton
                          valueToCopy={paymentMandate.debtor.iban}
                          copyText={t("copyButton.copyTooltip")}
                          copiedText={t("copyButton.copiedTooltip")}
                        />
                      }
                    />

                    <LakeLabel
                      type="view"
                      label={t("fundingSources.paymentMandate.debtorAddress1")}
                      render={() =>
                        isNotNullishOrEmpty(paymentMandate.debtor.address?.addressLine1) ? (
                          <LakeText color={colors.gray[900]}>
                            {paymentMandate.debtor.address?.addressLine1}
                          </LakeText>
                        ) : (
                          UNKNOWN_VALUE
                        )
                      }
                    />

                    <LakeLabel
                      type="view"
                      label={t("fundingSources.paymentMandate.debtorAddress2")}
                      render={() =>
                        isNotNullishOrEmpty(paymentMandate.debtor.address?.addressLine2) ? (
                          <LakeText color={colors.gray[900]}>
                            {paymentMandate.debtor.address?.addressLine2}
                          </LakeText>
                        ) : (
                          UNKNOWN_VALUE
                        )
                      }
                    />

                    <LakeLabel
                      type="view"
                      label={t("fundingSources.paymentMandate.debtorPostalCode")}
                      render={() =>
                        isNotNullishOrEmpty(paymentMandate.debtor.address?.postalCode) ? (
                          <LakeText color={colors.gray[900]}>
                            {paymentMandate.debtor.address?.postalCode}
                          </LakeText>
                        ) : (
                          UNKNOWN_VALUE
                        )
                      }
                    />

                    <LakeLabel
                      type="view"
                      label={t("fundingSources.paymentMandate.debtorCity")}
                      render={() =>
                        isNotNullishOrEmpty(paymentMandate.debtor.address?.city) ? (
                          <LakeText color={colors.gray[900]}>
                            {paymentMandate.debtor.address?.city}
                          </LakeText>
                        ) : (
                          UNKNOWN_VALUE
                        )
                      }
                    />

                    <LakeLabel
                      type="view"
                      label={t("fundingSources.paymentMandate.debtorCountry")}
                      render={() => {
                        const country = countries.find(
                          ({ cca3 }) => cca3 === paymentMandate.debtor.address?.country,
                        );

                        return country ? (
                          <LakeText
                            color={colors.gray[900]}
                          >{`${country.flag} ${country.name}`}</LakeText>
                        ) : (
                          UNKNOWN_VALUE
                        );
                      }}
                    />

                    <LakeLabel
                      type="view"
                      label={t("fundingSources.paymentMandate.documentUrl")}
                      render={() =>
                        isNotNullishOrEmpty(paymentMandate.mandateDocumentUrl) ? (
                          <LakeText color={colors.gray[900]}>
                            {t("fundingSources.paymentMandate.documentFilename") + ".pdf"}
                          </LakeText>
                        ) : (
                          UNKNOWN_VALUE
                        )
                      }
                      actions={
                        isNotNullishOrEmpty(paymentMandate.mandateDocumentUrl) ? (
                          <LakeDownloadButton
                            url={paymentMandate.mandateDocumentUrl}
                            filename={t("fundingSources.paymentMandate.documentFilename") + ".pdf"}
                          />
                        ) : null
                      }
                    />

                    <LakeLabel
                      type="view"
                      label={t("fundingSources.signatureDate")}
                      render={() =>
                        isNotNullishOrEmpty(paymentMandate.signatureDate) ? (
                          <LakeText color={colors.gray[900]}>
                            {dayjs(paymentMandate.signatureDate).format(
                              `${locale.dateFormat} ${locale.timeFormat}`,
                            )}
                          </LakeText>
                        ) : (
                          UNKNOWN_VALUE
                        )
                      }
                    />

                    <LakeLabel
                      type="view"
                      label={t("fundingSources.createdAt")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>
                          {dayjs(paymentMandate.createdAt).format(
                            `${locale.dateFormat} ${locale.timeFormat}`,
                          )}
                        </LakeText>
                      )}
                    />

                    <LakeLabel
                      type="view"
                      label={t("fundingSources.updateDate")}
                      render={() => (
                        <LakeText color={colors.gray[900]}>
                          {dayjs(paymentMandate.updatedAt).format(
                            `${locale.dateFormat} ${locale.timeFormat}`,
                          )}
                        </LakeText>
                      )}
                    />
                  </ReadOnlyFieldList>
                </Tile>
              </View>
            </>
          )}
        </View>
      );
    })
    .exhaustive();
};
