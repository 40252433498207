import { Icon, IconName } from "@swan-io/lake/src/components/Icon";
import { Link } from "@swan-io/lake/src/components/Link";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { colors } from "@swan-io/lake/src/constants/design";
import { useMemo } from "react";
import { StyleSheet } from "react-native";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";

const styles = StyleSheet.create({
  item: {
    display: "flex",
  },
});

export const SimulatorHome = () => {
  const { projectId } = useProjectInfo();

  const links: { link: string; title: string; description: string; icon: IconName }[] =
    useMemo(() => {
      return [
        {
          link: Router.SandboxDevelopersSimulatorSepaCreditTransferReceptionIn({ projectId }),
          title: t("simulatorPage.SCT"),
          description: t("simulatorPage.SCT.description"),
          icon: "arrow-swap-regular",
        },
        {
          link: Router.SandboxDevelopersSimulatorSepaDirectDebitReceptionOut({ projectId }),
          title: t("simulatorPage.SDD"),
          description: t("simulatorPage.SDD.description"),
          icon: "arrow-swap-regular",
        },
        {
          link: Router.SandboxDevelopersSimulatorInternationalCreditTransferBooking({ projectId }),
          title: t("simulatorPage.ICT"),
          description: t("simulatorPage.ICT.description"),
          icon: "earth-regular",
        },
        {
          link: Router.SandboxDevelopersSimulatorCardAuthorizationOut({ projectId }),
          title: t("simulatorPage.card"),
          description: t("simulatorPage.card.description"),
          icon: "payment-regular",
        },
        {
          link: Router.SandboxDevelopersSimulatorXPaySimulateDigitalization({ projectId }),
          title: t("simulatorPage.xPay"),
          description: t("simulatorPage.xPay.description"),
          icon: "phone-regular",
        },
        {
          link: Router.SandboxDevelopersSimulatorAccountHolderUpdateVerificationStatus({
            projectId,
          }),
          title: t("simulatorPage.accountHolder"),
          description: t("simulatorPage.accountHolder.description"),
          icon: "person-accounts-regular",
        },
        {
          link: Router.SandboxDevelopersSimulatorMerchantProfilesUpdateProfileStatus({
            projectId,
          }),
          title: t("simulatorPage.merchantProfiles"),
          description: t("simulatorPage.merchantProfiles.description"),
          icon: "building-shop-regular",
        },
        {
          link: Router.SandboxDevelopersSimulatorMerchantPaymentsRoot({
            projectId,
          }),
          title: t("simulatorPage.merchantPayments"),
          description: t("simulatorPage.merchantPayments.description"),
          icon: "shopping-bag-regular",
        },
        {
          link: Router.SandboxDevelopersSimulatorSandboxUsersCreateIdentification({
            projectId,
          }),
          title: t("simulatorPage.sandboxUsers"),
          description: t("simulatorPage.sandboxUsers.description"),
          icon: "person-regular",
        },
        {
          link: Router.SandboxDevelopersSimulatorBillingGenerateInvoice({
            projectId,
          }),
          title: t("simulatorPage.billing"),
          description: t("simulatorPage.billing.description"),
          icon: "money-calculator-regular",
        },
        {
          link: Router.SandboxDevelopersSimulatorCapitalDepositValidate({
            projectId,
          }),
          title: t("simulatorPage.capitalDeposit"),
          description: t("simulatorPage.capitalDeposit.description"),
          icon: "receipt-money-regular",
        },
      ];
    }, [projectId]);

  return (
    <TileGrid>
      {links.map(item => {
        return (
          <Link to={item.link} style={styles.item} key={item.link}>
            {({ hovered }) => (
              <Tile
                flexGrow={1}
                title={item.title}
                description={item.description}
                hovered={hovered}
                icon={<Icon name={item.icon} size={20} color={colors.current.primary} />}
              />
            )}
          </Link>
        );
      })}
    </TileGrid>
  );
};
