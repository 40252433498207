import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { deriveUnion } from "@swan-io/lake/src/utils/function";
import { useForm } from "@swan-io/use-form";
import { useMemo } from "react";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { DocumentationLink } from "../../components/DocumentationLink";
import { SimulatorResponses } from "../../components/SimulatorReponses";
import { TrackPressable } from "../../components/TrackPressable";
import {
  PhysicalCardRenewalStatus,
  SimulatePhysicalCardRenewalProcessDocument,
} from "../../graphql/sandbox-partner-admin";
import { t } from "../../utils/i18n";
import { validateRequired } from "../../utils/validations";

const styles = StyleSheet.create({
  grid: {
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 1080,
  },
});

export const PhysicalCardRenewalSimulatorPage = () => {
  const [simulate, simulation] = useMutation(SimulatePhysicalCardRenewalProcessDocument);

  const result = simulation.mapOkToResult(simulation =>
    match(simulation.response)
      .with(
        { __typename: "SimulatePhysicalCardRenewalProcessSuccessPayload" },
        ({ physicalCard: { identifier } }) =>
          Result.Ok([{ key: "physicalCardIdentifier", value: identifier ?? "-" }]),
      )
      .otherwise(({ __typename }) => Result.Error({ rejection: __typename })),
  );

  const cardStatus = useMemo(() => {
    return deriveUnion<PhysicalCardRenewalStatus>({
      ToRenew: true,
      Renewed: true,
    }).array.map(value => ({ name: value, value }));
  }, []);

  const { Field, submitForm, formStatus } = useForm<{
    cardId: string;
    status: PhysicalCardRenewalStatus;
  }>({
    cardId: {
      initialValue: "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
    status: {
      initialValue: "ToRenew",
      strategy: "onBlur",
      validate: validateRequired,
    },
  });

  const onSubmit = () =>
    submitForm({
      onSuccess: values =>
        Option.allFromDict(values)
          .map(input => simulate({ input }))
          .toUndefined(),
    });

  return (
    <Form style={commonStyles.fill}>
      <Tile
        description={
          <LakeText>
            {t("simulatorPage.simulationPhysicalCardRenewal.description")}{" "}
            <DocumentationLink to="simulatorCard">{t("common.learnMore")}</DocumentationLink>
          </LakeText>
        }
      >
        <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
          <Field name="cardId">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={`${t("simulatorForm.cardId")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.cardIdPlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="status">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.status")}
                render={id => (
                  <LakeSelect id={id} value={value} items={cardStatus} onValueChange={onChange} />
                )}
              />
            )}
          </Field>
        </Grid>
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Submit physical card renewal form">
          <LakeButton
            size="small"
            color="current"
            loading={formStatus === "submitting"}
            onPress={onSubmit}
          >
            {t("simulatorForm.submitButton")}
          </LakeButton>
        </TrackPressable>

        <Space width={12} />

        <SimulatorResponses
          fields={[
            {
              key: "physicalCardIdentifier",
              label: t("simulatorForm.physicalCardIdentifier"),
              placeholder: "-",
            },
          ]}
          results={result}
        />
      </Box>
    </Form>
  );
};
