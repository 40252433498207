import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors, radii } from "@swan-io/lake/src/constants/design";
import { emptyToUndefined } from "@swan-io/lake/src/utils/nullish";
import { countries as countryList } from "@swan-io/shared-business/src/constants/countries";
import { useForm } from "@swan-io/use-form";
import { useMemo } from "react";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { DocumentationLink } from "../../components/DocumentationLink";
import { SimulatorResponses } from "../../components/SimulatorReponses";
import { TrackPressable } from "../../components/TrackPressable";
import { getCurrencies } from "../../constants/currencies";
import { CardType } from "../../graphql/admin";
import { SimulateCardOutCreditDocument } from "../../graphql/sandbox-partner-admin";
import { t } from "../../utils/i18n";
import { validateRequired } from "../../utils/validations";

const styles = StyleSheet.create({
  grid: {
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 1080,
  },
  optionCurrencySymbol: {
    width: "3rem",
    backgroundColor: colors.gray[50],
    borderRadius: radii[4],
    display: "inline-block",
    textAlign: "center",
    boxShadow: `inset 0 0 0 1px ${colors.gray[100]}`,
  },
  optionFlag: {
    display: "inline-block",
    width: 21,
  },
});

type OptionalCardType = CardType | "";

type FormValues = {
  cardId: string;
  amount: string;
  terminalId: string;
  merchantId: string;
  merchantCode: string;
  merchantName: string;
  merchantCity: string;
  merchantPostalCode: string;
  merchantCountry: string;
  originalAmount: string;
  originalCurrency: string | undefined;
  cardType: OptionalCardType;
};

export const CardOutCreditSimulatorPage = () => {
  const [simulate, simulation] = useMutation(SimulateCardOutCreditDocument);

  const cardTypes = useMemo(() => {
    const types: OptionalCardType[] = ["", "Physical", "Virtual"];
    return types.map(value => ({ name: value === "" ? t("common.none") : value, value }));
  }, []);

  const transactionResult = simulation.mapOkToResult(simulation =>
    match(simulation.response)
      .with({ __typename: "SimulateOutgoingCardCreditSuccessPayload" }, ({ transactionId }) =>
        Result.Ok([{ key: "transactionId", value: transactionId }]),
      )
      .otherwise(({ __typename }) => Result.Error({ rejection: __typename })),
  );

  const { Field, submitForm, formStatus } = useForm<FormValues>({
    cardId: {
      initialValue: "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
    amount: {
      initialValue: "",
      sanitize: value => value.replace(/ /g, "").replace(/,/g, "."),
      strategy: "onBlur",
      validate: validateRequired,
    },
    originalAmount: {
      initialValue: "",
      sanitize: value => value.replace(/ /g, "").replace(/,/g, "."),
      strategy: "onBlur",
    },
    originalCurrency: {
      initialValue: undefined,
    },
    terminalId: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    merchantId: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    merchantCode: {
      initialValue: "",
      validate: value => {
        if ((value.match(/\d+/g) ?? []).join("") !== value) {
          return t("common.form.invalidMerchantCode");
        }
      },
      sanitize: value => value.trim(),
    },
    merchantName: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    merchantCity: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    merchantPostalCode: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    merchantCountry: {
      initialValue: "",
      strategy: "onBlur",
    },
    cardType: {
      initialValue: "",
    },
  });

  const onSubmit = () =>
    submitForm({
      onSuccess: values =>
        Option.allFromDict(values)
          .map(
            ({
              cardId,
              amount,
              originalAmount,
              originalCurrency = "",
              terminalId,
              merchantId,
              merchantCode,
              merchantName,
              merchantCity,
              merchantCountry,
              merchantPostalCode,
              cardType,
            }) => {
              const currency = "EUR";

              return simulate({
                input: {
                  cardId,
                  amount: { value: amount, currency: "EUR" },
                  originalAmount: {
                    value: emptyToUndefined(originalAmount) ?? amount,
                    currency: emptyToUndefined(originalCurrency) ?? currency,
                  },
                  terminalId: emptyToUndefined(terminalId),
                  merchantId: emptyToUndefined(merchantId),
                  merchantCategoryCode: emptyToUndefined(merchantCode),
                  merchantName: emptyToUndefined(merchantName),
                  merchantCity: emptyToUndefined(merchantCity),
                  merchantCountry: emptyToUndefined(merchantCountry),
                  merchantPostalCode: emptyToUndefined(merchantPostalCode),
                  cardType: emptyToUndefined(cardType),
                },
              });
            },
          )
          .toUndefined(),
    });

  const currencies = useMemo(
    () =>
      getCurrencies().map(currency => ({
        name: currency.name,
        icon: (
          <LakeText
            color={colors.gray[700]}
            variant="smallRegular"
            style={styles.optionCurrencySymbol}
          >
            {currency.symbol}
          </LakeText>
        ),
        value: currency.code,
      })),
    [],
  );

  const countries = useMemo(
    () => [
      {
        name: "",
        icon: (
          <LakeText color={colors.gray[900]} style={styles.optionFlag}>
            {null}
          </LakeText>
        ),
        value: "",
      },
      ...countryList.map(country => ({
        name: country.name,
        icon: (
          <LakeText color={colors.gray[900]} style={styles.optionFlag}>
            {country.flag}
          </LakeText>
        ),
        value: country.cca3,
      })),
    ],
    [],
  );

  return (
    <Form style={commonStyles.fill}>
      <Tile
        description={
          <LakeText>
            {t("simulatorPage.creditCardOutDescription")}{" "}
            <DocumentationLink to="simulatorCard">{t("common.learnMore")}</DocumentationLink>
          </LakeText>
        }
      >
        <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
          <Field name="cardId">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={`${t("simulatorForm.cardId")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.cardIdPlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="cardType">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.cardType")}
                render={id => (
                  <LakeSelect id={id} value={value} items={cardTypes} onValueChange={onChange} />
                )}
              />
            )}
          </Field>

          <Field name="amount">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={`${t("simulatorForm.amount")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.amountPlaceholder")}
                    inputMode="decimal"
                    unit="€"
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="originalAmount">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.originalAmount")}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.originalAmountPlaceholder")}
                    inputMode="decimal"
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="originalCurrency">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.originalCurrency")}
                render={id => (
                  <LakeSelect id={id} value={value} items={currencies} onValueChange={onChange} />
                )}
              />
            )}
          </Field>

          <Field name="terminalId">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.terminalId")}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.terminalIdPlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="merchantId">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.merchantId")}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.merchantIdPlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="merchantCode">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.merchantCode")}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.merchantCodePlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="merchantName">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.merchantName")}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.merchantNamePlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="merchantCity">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.merchantCity")}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.merchantCityPlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="merchantPostalCode">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.merchantPostalCode")}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.merchantPostalCodePlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="merchantCountry">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.merchantCountry")}
                render={id => (
                  <LakeSelect id={id} value={value} items={countries} onValueChange={onChange} />
                )}
              />
            )}
          </Field>
        </Grid>
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Submit card out credit form">
          <LakeButton
            size="small"
            color="current"
            loading={formStatus === "submitting"}
            onPress={onSubmit}
          >
            {t("simulatorForm.submitButton")}
          </LakeButton>
        </TrackPressable>

        <Space width={12} />

        <SimulatorResponses
          fields={[
            {
              key: "transactionId",
              label: t("simulatorForm.transactionId"),
              placeholder: "-",
            },
          ]}
          results={transactionResult}
        />
      </Box>
    </Form>
  );
};
