import { deriveUnion } from "@swan-io/lake/src/utils/function";
import { TransactionTypeEnum } from "../graphql/partner";

export const transactionTypes = deriveUnion<TransactionTypeEnum>({
  InternalCreditTransferOut: true,
  InternalCreditTransferOutReturn: true,
  InternalCreditTransferOutRecall: true,
  InternalCreditTransferIn: true,
  InternalCreditTransferInReturn: true,
  InternalCreditTransferInRecall: true,
  SepaCreditTransferOut: true,
  SepaInstantCreditTransferOut: true,
  SepaInstantCreditTransferIn: true,
  SepaCreditTransferOutReturn: true,
  SepaInstantCreditTransferOutRecall: true,
  SepaInstantCreditTransferInRecall: true,
  SepaCreditTransferOutRecall: true,
  SepaCreditTransferIn: true,
  SepaCreditTransferInReturn: true,
  SepaCreditTransferInRecall: true,
  FeesOut: true,
  FeesIn: true,
  SepaDirectDebitIn: true,
  SepaDirectDebitInReturn: true,
  SepaDirectDebitInReversal: true,
  SepaDirectDebitOut: true,
  SepaDirectDebitOutReturn: true,
  SepaDirectDebitOutReversal: true,
  CardInCredit: true,
  CardOutAuthorization: true,
  CardOutDebit: true,
  CardOutDebitReversal: true,
  CardOutCredit: true,
  CardOutCreditReversal: true,
  InternalDirectDebitIn: true,
  InternalDirectDebitInReturn: true,
  InternalDirectDebitOut: true,
  InternalDirectDebitOutReturn: true,
  CheckIn: true,
  CheckInReturn: true,
  InternationalCreditTransferIn: true,
  InternationalCreditTransferOut: true,
  InternationalCreditTransferInReturn: true,
  InternationalCreditTransferOutReturn: true,
});
