import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { parseToRgb } from "polished";
import { isMatching, match, P } from "ts-pattern";

const lengthUnits = ["px", "in", "cm", "mm", "pt", "pc"] as const;

type LengthUnit = (typeof lengthUnits)[number];

const isLengthUnit = isMatching(P.union(...lengthUnits));

const parseSize = (value: string): { value: number; unit: LengthUnit } => {
  const size = parseFloat(value);
  const unit = size.toString() === value ? "px" : value.substr(-2);

  if (isNaN(size) || !isLengthUnit(unit)) {
    throw new Error(`Invalid SVG Size: ${value}`);
  }

  return {
    value: size,
    unit,
  };
};

// convert length value according to https://oreillymedia.github.io/Using_SVG/guide/units.html
const getPxSize = ({ value, unit }: { value: number; unit: LengthUnit }): number =>
  match(unit)
    .with("px", () => value)
    .with("in", () => value * 96)
    .with("cm", () => value * 37.795)
    .with("mm", () => value * 3.7795)
    .with("pt", () => value * 1.3333)
    .with("pc", () => value * 16)
    .exhaustive();

export const getSvgSize = (svg: SVGElement): { width: number; height: number } => {
  // first we try to get width and height from "width" and "height" attributes
  const widthAttr = svg.getAttribute("width") ?? "";
  const heightAttr = svg.getAttribute("height") ?? "";

  const width = widthAttr !== "" ? getPxSize(parseSize(widthAttr)) : NaN;
  const height = heightAttr !== "" ? getPxSize(parseSize(heightAttr)) : NaN;

  if (!isNaN(width) || !isNaN(height)) {
    return { width, height };
  }

  // if height and width aren't provided, we get values from viewBox attribute
  const viewBox = svg.getAttribute("viewBox");
  const [, , boxWidthAttr = "", boxHeightAttr = ""] = viewBox?.split(" ") ?? [];

  const boxWidth = boxWidthAttr !== "" ? parseInt(boxWidthAttr) : NaN;
  const boxHeight = boxHeightAttr !== "" ? parseInt(boxHeightAttr) : NaN;

  if (isNaN(boxWidth) || isNaN(boxHeight)) {
    throw new Error("Invalid SVG Imported: an SVG must have a viewBox attribute");
  }

  return {
    width: boxWidth,
    height: boxHeight,
  };
};

// Function use to avoid sending an SVG with too large size which make the backend crash
export const changeSvgSize = (svg: SVGElement, maxSize: number): SVGElement => {
  const svgSize = getSvgSize(svg);
  if (svgSize.width < maxSize && svgSize.height < maxSize) {
    return svg;
  }

  const svgRatio = svgSize.width / svgSize.height;
  let width = 0;
  let height = 0;

  if (svgRatio > 1) {
    width = maxSize;
    height = width / svgRatio;
  } else {
    height = maxSize;
    width = height * svgRatio;
  }

  const newSvg = svg.cloneNode(true) as SVGElement;
  newSvg.setAttribute("width", width.toString());
  newSvg.setAttribute("height", height.toString());

  return newSvg;
};

const isSvgColored = (element: Element): boolean => {
  const fill = element.getAttribute("fill") ?? "";
  const stroke = element.getAttribute("stroke") ?? "";
  const style = element.getAttribute("style") ?? "";

  if (element.tagName === "style") {
    return element.innerHTML.includes("fill") || element.innerHTML.includes("stroke");
  }

  if (fill !== "" || stroke !== "" || style !== "") {
    return true;
  }

  return [...element.children].some(children => isSvgColored(children));
};

const fillSvgColor = (svg: SVGElement, color: string): SVGElement => {
  const tags = ["rectangle", "circle", "ellipse", "line", "polyline", "polygon", "path"];

  const fillElement = (element: Element, color: string): void => {
    if (tags.includes(element.tagName)) {
      element.setAttribute("fill", color);
    }

    [...element.children].forEach(children => fillElement(children, color));
  };

  const newSvg = svg.cloneNode(true) as SVGElement;

  fillElement(newSvg, color);

  return newSvg;
};

/**
 * This function create a matrix which can be used in <feColorMatrix> element
 * More info here: https://developer.mozilla.org/en-US/docs/Web/SVG/Element/feColorMatrix
 * ⚠️  At the moment this color works well with white, black, red, green and blue, but not with other colors
 *   We only need white and black today, if we need more customization in the future, we should work more on matrix
 */
const getColorMatrix = (color: string): string => {
  const rgb = parseToRgb(color);
  const red = rgb.red / 255;
  const green = rgb.green / 255;
  const blue = rgb.blue / 255;
  const alpha = "alpha" in rgb ? rgb.alpha : 1;

  // prettier-ignore
  const matrix = [
    red,   red,   red,   red,   red,   // R
    green, green, green, green, green, // G
    blue,  blue,  blue,  blue,  blue,  // B
    0,     0,     0,     alpha, 0,     // A
  ]

  return matrix.join(" ");
};

// this function make the svg monochrome
export const changeSvgColor = (svg: SVGElement, color: string): SVGElement => {
  // handle edge case where there isn't any children with a color
  if (!isSvgColored(svg)) {
    return fillSvgColor(svg, color);
  }

  const tagsToNotChange = ["mask"];

  const changeElementColor = (element: Element, color: string): void => {
    if (element.tagName === "style") {
      const css = element.innerHTML;
      const regex =
        /#[A-F0-9]{6,6}|#[A-F0-9]{3,3}|rgb\(\d{1,3},\d{1,3},\d{1,3}\)|rgba\(\d{1,3},\d{1,3},\d{1,3},\d\.?\d{0,}\)/gim;

      const cssWithColor = css.replace(regex, color);
      element.innerHTML = cssWithColor;
    }

    if (element.tagName === "feColorMatrix") {
      const matrix = getColorMatrix(color);
      element.setAttribute("type", "matrix");
      element.setAttribute("values", matrix);
    }

    const isElementToChange = !tagsToNotChange.includes(element.tagName);

    const fill = (isElementToChange ? element.getAttribute("fill") : null) ?? "";
    const stroke = (isElementToChange ? element.getAttribute("stroke") : null) ?? "";
    const stopColor = (isElementToChange ? element.getAttribute("stop-color") : null) ?? "";
    const style = (isElementToChange ? element.getAttribute("style") : null) ?? "";

    if (fill !== "" && fill !== "none") {
      element.setAttribute("fill", color);
    }
    if (stroke !== "" && stroke !== "none") {
      element.setAttribute("stroke", color);
    }
    // stop-color is used for gradients
    if (stopColor !== "" && stopColor !== "none") {
      element.setAttribute("stop-color", color);
    }
    if (style !== "") {
      const styleWithColor = style
        .split(";")
        .map(rule => {
          const [property, value] = rule.split(":");

          if (
            isNotNullish(property) &&
            // stop-color is used for gradients
            ["fill", "stroke", "stop-color"].includes(property) &&
            value !== "none"
          ) {
            return `${property}:${color}`;
          }

          return rule;
        })
        .join(";");

      element.setAttribute("style", styleWithColor);
    }

    [...element.children].forEach(children => changeElementColor(children, color));
  };

  const newSvg = svg.cloneNode(true) as SVGElement;

  changeElementColor(newSvg, color);

  return newSvg;
};
