import { AsyncData, Option, Result } from "@swan-io/boxed";
import { ClientError, useMutation, useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeRadio } from "@swan-io/lake/src/components/LakeRadio";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { MultiSelect } from "@swan-io/lake/src/components/MultiSelect";
import { Pressable } from "@swan-io/lake/src/components/Pressable";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { colors } from "@swan-io/lake/src/constants/design";
import { emptyToUndefined } from "@swan-io/lake/src/utils/nullish";
import { useForm } from "@swan-io/use-form";
import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { v4 as uuid } from "uuid";
import {
  ProbeWebhookDocument,
  ProbeWebhookMutation,
  WebhookEventTypesDocument,
} from "../graphql/partner";
import { t } from "../utils/i18n";
import {
  validateRequired,
  validateRequiredArray,
  validateUrl,
  validateUuid,
} from "../utils/validations";
import { CopyTextButton } from "./CopyTextButton";
import { ErrorView } from "./ErrorView";
import { TrackPressable } from "./TrackPressable";

const styles = StyleSheet.create({
  testStatus: {
    position: "absolute",
    right: 0,
    bottom: 0,
  },
  radio: {
    alignItems: "center",
    flexDirection: "row",
  },
});

type TestStatusProps = {
  testState: ProbeWebhookMutation;
};

const TestStatus = ({ testState }: TestStatusProps) => {
  return (
    <Box direction="row" alignItems="center" justifyContent="end" style={styles.testStatus}>
      {match(testState.probeWebhookEndpoint)
        .with(
          {
            __typename: "ProbeWebhookEndpointSuccessPayload",
            responseStatus: P.number,
          },
          ({ responseStatus }) =>
            (responseStatus >= 200 && responseStatus < 300) || responseStatus === 304 ? (
              <>
                <LakeText color={colors.positive.primary}>{t("webhookForm.test.succeed")}</LakeText>
                <Space width={8} />
                <Icon name="checkmark-filled" size={20} color={colors.positive.primary} />
              </>
            ) : (
              <>
                <LakeText color={colors.negative.primary}>
                  {t("webhookForm.test.failed", {
                    code: responseStatus,
                  })}
                </LakeText>

                <Space width={8} />
                <Icon name="dismiss-filled" size={20} color={colors.negative.primary} />
              </>
            ),
        )
        .with(
          { __typename: "ProbeWebhookEndpointSuccessPayload", responseStatus: P.nullish },
          { __typename: "InternalErrorRejection" },
          { __typename: "InvalidArgumentRejection" },
          () => (
            <>
              <LakeText color={colors.negative.primary}>
                {t("webhookForm.test.internalError")}
              </LakeText>

              <Space width={8} />
              <Icon name="dismiss-filled" size={20} color={colors.negative.primary} />
            </>
          ),
        )
        .exhaustive()}
    </Box>
  );
};

export type WebhookOptions = {
  enabled: boolean;
  label: string;
  endpoint: string;
  eventTypes: string[];
  secret: string | null | undefined;
};

type Props = {
  initialEditorState?: WebhookOptions;
  showActivationButtons?: boolean;
  onSave: (editorState: WebhookOptions) => void;
};

export type SaveRef = { save: () => void };

export const WebhookSubscriptionEditor = forwardRef<SaveRef | undefined, Props>(
  ({ initialEditorState, onSave, showActivationButtons = false }: Props, ref) => {
    const [data] = useQuery(WebhookEventTypesDocument, {}, { suspense: true });

    const [probeWebhookDocument] = useMutation(ProbeWebhookDocument);

    const [webhookProbe, setWebhookProbe] = useState<
      AsyncData<Result<ProbeWebhookMutation, ClientError>>
    >(AsyncData.NotAsked());

    const { Field, submitForm, getFieldValue } = useForm({
      enabled: {
        initialValue: initialEditorState?.enabled ?? true,
      },
      label: {
        initialValue: initialEditorState?.label ?? "",
        strategy: "onBlur",
        validate: validateRequired,
        sanitize: value => value.trim(),
      },
      endpoint: {
        initialValue: initialEditorState?.endpoint ?? "",
        validate: validateUrl,
        sanitize: value => value.trim(),
      },
      eventTypes: {
        initialValue: initialEditorState?.eventTypes ?? [],
        validate: validateRequiredArray,
      },
      secret: {
        initialValue: initialEditorState?.secret ?? "",
        validate: validateUuid,
      },
    });

    useImperativeHandle(ref, () => {
      return {
        save: () =>
          submitForm({
            onSuccess: ({ enabled, ...values }) =>
              Option.allFromDict(values)
                .map(values => onSave({ ...values, enabled: enabled.getOr(true) }))
                .toUndefined(),
          }),
      };
    });

    const items = useMemo(
      () =>
        data.mapOk(data =>
          data.webhookEventTypes.map(eventType => ({
            value: eventType,
            label: eventType,
            group: eventType.split(".")[0] as string,
          })),
        ),
      [data],
    );

    return match(items)
      .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <LoadingView />)
      .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
      .with(AsyncData.P.Done(Result.P.Ok(P.select())), items => {
        return (
          <View role="form">
            {showActivationButtons && (
              <>
                <Space height={8} />

                <Box direction="row">
                  <Field name="enabled">
                    {({ value, onChange }) => (
                      <>
                        <Pressable role="radio" style={styles.radio} onPress={() => onChange(true)}>
                          <LakeRadio value={value} color="current" />
                          <Space width={12} />
                          <Tag color="positive">{t("webhookForm.enabled")}</Tag>
                        </Pressable>

                        <Space width={24} />

                        <Pressable
                          role="radio"
                          style={styles.radio}
                          onPress={() => onChange(false)}
                        >
                          <LakeRadio value={!value} color="current" />
                          <Space width={12} />
                          <Tag color="gray">{t("webhookForm.disabled")}</Tag>
                        </Pressable>
                      </>
                    )}
                  </Field>
                </Box>

                <Space height={16} />
              </>
            )}

            <Field name="label">
              {({ value, onChange, valid, error, onBlur }) => (
                <LakeLabel
                  label={t("webhookForm.label.label")}
                  render={id => (
                    <LakeTextInput
                      id={id}
                      value={value}
                      onChangeText={onChange}
                      onBlur={onBlur}
                      valid={valid}
                      error={error}
                    />
                  )}
                />
              )}
            </Field>

            <Field name="endpoint">
              {({ value, onChange, valid, error, onBlur }) => (
                <LakeLabel
                  label={t("webhookForm.endpoint.label")}
                  render={id => (
                    <View>
                      <LakeTextInput
                        id={id}
                        value={value}
                        onChangeText={onChange}
                        valid={valid}
                        error={error}
                        onBlur={onBlur}
                      >
                        <Space width={8} />

                        <Box direction="row">
                          <TrackPressable action="Test webhook">
                            <LakeButton
                              icon="play-filled"
                              loading={webhookProbe.isLoading()}
                              mode="secondary"
                              size="small"
                              onPress={() => {
                                setWebhookProbe(AsyncData.Loading());

                                probeWebhookDocument({
                                  input: {
                                    endpoint: value,
                                    secret: emptyToUndefined(getFieldValue("secret")),
                                  },
                                }).tap(result => setWebhookProbe(AsyncData.Done(result)));
                              }}
                            >
                              {t("webhookForm.test")}
                            </LakeButton>
                          </TrackPressable>
                        </Box>
                      </LakeTextInput>

                      {match(webhookProbe)
                        .with(AsyncData.P.Done(Result.P.Ok(P.select())), testState => (
                          <TestStatus testState={testState} />
                        ))
                        .otherwise(() => null)}
                    </View>
                  )}
                />
              )}
            </Field>

            <Field name="eventTypes">
              {({ value, onChange, error }) => (
                <LakeLabel
                  label={t("webhookForm.eventTypes.label")}
                  render={id => (
                    <MultiSelect
                      id={id}
                      color="current"
                      emptyResultText={t("common.noResult")}
                      filterPlaceholder={t("common.search.placeholder")}
                      items={items}
                      error={error}
                      onValueChange={onChange}
                      placeholder={t("webhookForm.eventTypes.placeholder")}
                      renderTagGroup={items =>
                        t("webhook.eventTypes.group", { count: items.length })
                      }
                      values={value}
                    />
                  )}
                />
              )}
            </Field>

            <Field name="secret">
              {({ value, onChange, valid, error, onBlur }) => (
                <LakeLabel
                  label={t("webhookForm.secret.label")}
                  render={id => (
                    <LakeTextInput
                      placeholder={t("webhookForm.secret.placeholder")}
                      id={id}
                      value={value}
                      onChangeText={onChange}
                      onBlur={onBlur}
                      valid={valid}
                      error={error}
                    />
                  )}
                  actions={
                    <>
                      <LakeTooltip
                        placement="left"
                        content={t("webhookForm.secret.generateSecret")}
                      >
                        <TrackPressable action="Generate webhook secret">
                          <LakeButton
                            mode="secondary"
                            size="small"
                            icon="arrow-counterclockwise-filled"
                            ariaLabel={t("webhookForm.secret.generateSecret")}
                            onPress={() => {
                              onChange(uuid());
                            }}
                          />
                        </TrackPressable>
                      </LakeTooltip>

                      <Space width={12} />
                      <CopyTextButton value={value} />
                    </>
                  }
                />
              )}
            </Field>
          </View>
        );
      })
      .exhaustive();
  },
);
