import { Fill } from "@swan-io/lake/src/components/Fill";
import { Icon, IconName } from "@swan-io/lake/src/components/Icon";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Link } from "@swan-io/lake/src/components/Link";
import { PressableText } from "@swan-io/lake/src/components/Pressable";
import {
  SidebarNavigationTracker,
  SidebarNavigationTrackerActiveMarker,
} from "@swan-io/lake/src/components/SidebarNavigationTracker";
import { Space } from "@swan-io/lake/src/components/Space";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import {
  backgroundColor,
  colors,
  invariantColors,
  shadows,
  spacings,
} from "@swan-io/lake/src/constants/design";
import { useBoolean } from "@swan-io/lake/src/hooks/useBoolean";
import { useHover } from "@swan-io/lake/src/hooks/useHover";
import { usePersistedState } from "@swan-io/lake/src/hooks/usePersistedState";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { ReactElement, useCallback, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { t } from "../utils/i18n";
import {
  RouteName,
  Router,
  dataRoutes,
  developerRoots,
  insightsRoutes,
  settingsRoots,
} from "../utils/routes";
import { TrackPressable } from "./TrackPressable";

const COLLAPSED_WIDTH = 70;
const ICON_SIZE = 22;
const SIDEBAR_WIDTH = 280;

const styles = StyleSheet.create({
  sidebarContainer: {
    alignSelf: "stretch",
    borderRightWidth: 1,
    borderRightColor: colors.gray[100],
    flexGrow: 0,
    flexShrink: 0,
    position: "absolute",
    zIndex: 1,
    height: "100%",
    width: SIDEBAR_WIDTH,
    transitionDuration: "250ms",
    transitionProperty: "width",
    transitionTimingFunction: "ease-in-out",
  },
  shadow: {
    position: "absolute",
    bottom: 0,
    right: 0,
    top: 0,
    width: 10,
    boxShadow: shadows.tileHover,
    transitionDuration: "300ms",
    transitionProperty: "opacity",
    transitionTimingFunction: "ease-in-out",
    opacity: 0,
  },
  opaque: {
    opacity: 1,
  },
  sidebarContents: {
    paddingBottom: spacings[20],
    paddingLeft: spacings[20],
    flexGrow: 1,
    backgroundColor: backgroundColor.default,
  },
  expandedText: {
    opacity: 1,
    transform: "translateX(0px)",
  },
  collapsed: {
    width: COLLAPSED_WIDTH,
  },
  navItem: {
    paddingVertical: spacings[8],
    paddingHorizontal: spacings[12],
    borderRightWidth: 3,
    borderColor: invariantColors.transparent,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: colors.gray[500],
  },
  active: {
    color: colors.current[500],
    backgroundColor: colors.gray[50],
  },
  unselectable: {
    userSelect: "none",
  },
  hovered: {
    color: colors.gray[900],
    borderRightWidth: 3,
    borderColor: colors.gray[900],
  },
  text: {
    transitionDuration: "400ms",
    transitionTimingFunction: "ease-in-out",
    opacity: 0,
    transform: "translateX(-8px)",
  },
  lock: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: spacings[20],
    paddingHorizontal: 14,
    color: colors.gray[500],
  },
  spacer: {
    transitionDuration: "100ms",
    transitionTimingFunction: "ease-in-out",
    width: SIDEBAR_WIDTH,
  },
  unlockedSpacer: {
    width: COLLAPSED_WIDTH,
  },
  menuItem: {
    borderColor: colors.gray[300],
    transitionDuration: "200ms",
    transitionTimingFunction: "ease-in-out",
  },
  submenuItem: {
    borderLeftWidth: 1,
    borderColor: colors.gray[200],
    transform: "translateX(1px)",
  },
  expandedSubmenuItem: {
    borderColor: colors.gray[300],
    transform: "translateX(20px)",
  },
});

type MenuState = {
  isHovered: boolean;
  isUnlocked: boolean;
};

export type MenuItemProps = {
  menuState: MenuState;
  level?: number;
  currentRouteName?: string;
  item: MenuItem;
};

export type MenuItem = {
  matchRoutes: RouteName[];
  to: string;
  icon: IconName;
  iconActive: IconName;
  name: string;
  hidden?: boolean;
  submenu?: MenuItem[];
  children?: ReactElement;
};

export type Menu = MenuItem[];

type Props = {
  menu: Menu;
};

const routes = [...dataRoutes, ...insightsRoutes, ...settingsRoots, ...developerRoots];

const Item = ({ menuState, level = 0, item, currentRouteName }: MenuItemProps) => {
  if (item.hidden === true) {
    return null;
  }

  const { to, name, icon, submenu, iconActive, matchRoutes, children } = item;
  const isActive = matchRoutes.some(name => name === currentRouteName);
  const { isHovered, isUnlocked } = menuState;

  return (
    <>
      <View
        key={`navigation-${to}`}
        style={[
          styles.menuItem,
          level > 0 && styles.submenuItem,
          level > 0 && (isHovered || !isUnlocked) && styles.expandedSubmenuItem,
        ]}
      >
        <Link
          to={to}
          aria-label={name}
          numberOfLines={1}
          style={({ hovered }) => [styles.unselectable, hovered && styles.hovered]}
        >
          {({ hovered }) => {
            const inactiveColor = hovered ? colors.gray[900] : colors.gray[500];

            return (
              <LakeText style={[styles.navItem, isActive && styles.active]}>
                <Icon
                  name={isActive ? iconActive : icon}
                  size={ICON_SIZE}
                  color={isActive ? colors.current[500] : inactiveColor}
                />

                <Space width={12} />

                <LakeText
                  color={isActive ? colors.current[500] : inactiveColor}
                  variant="medium"
                  style={[styles.text, isHovered || !isUnlocked ? styles.expandedText : null]}
                >
                  {name}

                  {isNotNullish(children) && (
                    <>
                      <Space width={8} />

                      {children}
                    </>
                  )}
                </LakeText>

                {isActive ? (
                  <SidebarNavigationTrackerActiveMarker color={colors.current[500]} />
                ) : null}
              </LakeText>
            );
          }}
        </Link>

        {submenu?.map(subitem => (
          <Item
            key={subitem.to}
            item={subitem}
            level={level + 1}
            menuState={menuState}
            currentRouteName={currentRouteName}
          />
        ))}
      </View>
    </>
  );
};

export const Sidebar = ({ menu }: Props) => {
  const route = Router.useRoute(routes);
  const [isHovered, setIsHovered] = useBoolean(false);
  const containerRef = useRef(null);
  const [isUnlocked, setIsUnlocked] = usePersistedState("swan__sidebarFloating", false);

  const toggle = useCallback(() => {
    setIsUnlocked(!isUnlocked);
  }, [isUnlocked, setIsUnlocked]);

  useHover(containerRef, {
    onHoverStart: setIsHovered.on,
    onHoverEnd: setIsHovered.off,
  });

  return (
    <>
      <View style={[styles.spacer, isUnlocked && styles.unlockedSpacer]} />

      <View
        ref={containerRef}
        style={[styles.sidebarContainer, isHovered || !isUnlocked ? null : styles.collapsed]}
      >
        <View role="none" style={[styles.shadow, isHovered && isUnlocked && styles.opaque]} />

        <SidebarNavigationTracker contentContainerStyle={styles.sidebarContents}>
          <Space height={16} />

          <View role="navigation" style={commonStyles.fill}>
            {menu.map(item => (
              <Item
                key={item.to}
                item={item}
                currentRouteName={route?.name}
                menuState={{
                  isHovered,
                  isUnlocked,
                }}
              />
            ))}

            <Fill minHeight={16} />

            <TrackPressable action={`${isUnlocked ? "Dock" : "Undock"} sidebar`}>
              <PressableText onPress={toggle} style={styles.lock}>
                <Icon
                  name={match({ isHovered, isUnlocked })
                    .returnType<IconName>()
                    .with(
                      { isUnlocked: false, isHovered: false },
                      { isUnlocked: false, isHovered: true },
                      () => "panel-left-contract-regular",
                    )
                    .with({ isHovered: false, isUnlocked: true }, () => "panel-left-expand-filled")
                    .with({ isHovered: true, isUnlocked: true }, () => "panel-left-regular")
                    .exhaustive()}
                  size={20}
                />

                <Space width={12} />

                <LakeText
                  numberOfLines={1}
                  variant="medium"
                  style={[styles.text, isHovered || !isUnlocked ? styles.expandedText : null]}
                >
                  {isUnlocked ? t("sidebar.lock") : t("sidebar.unlock")}
                </LakeText>
              </PressableText>
            </TrackPressable>
          </View>
        </SidebarNavigationTracker>
      </View>
    </>
  );
};
