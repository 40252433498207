import dayjs, { Dayjs } from "dayjs";

export type DiffPeriodUnit = "minute" | "hour" | "day" | "week" | "month" | "year";

const diffPeriods: { maximum: number; unit: DiffPeriodUnit }[] = [
  { maximum: 60, unit: "minute" },
  { maximum: 24, unit: "hour" },
  { maximum: 7, unit: "day" },
  { maximum: 4, unit: "week" },
  { maximum: 12, unit: "month" },
];

export const getDiffFromNow = (date: Dayjs): { value: number; unit: DiffPeriodUnit } => {
  const now = dayjs();

  for (const { maximum, unit } of diffPeriods) {
    const diff = now.diff(date, unit);

    if (diff <= maximum) {
      return { value: diff, unit };
    }
  }

  return { value: now.diff(date, "year"), unit: "year" };
};
