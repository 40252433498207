import { Box } from "@swan-io/lake/src/components/Box";
import { Icon, IconName } from "@swan-io/lake/src/components/Icon";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Space } from "@swan-io/lake/src/components/Space";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors } from "@swan-io/lake/src/constants/design";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { EnvType } from "../graphql/admin";
import { t } from "../utils/i18n";
import { EnvironmentSelector } from "./EnvironmentSelector";

type Props = {
  onSelect: (env: EnvType) => void;
  icon: IconName;
  customText?: string;
};

export const LiveOnlyView = ({ onSelect, icon, customText }: Props) => (
  <Box alignItems="center" justifyContent="center" style={commonStyles.fill}>
    <Icon color={colors.current.primary} name={icon} size={42} />
    <Space height={12} />
    <LakeText variant="medium">{t("sandbox.wantToSwitchToLive")}</LakeText>

    {isNotNullish(customText) ? (
      <LakeText variant="medium">{customText}</LakeText>
    ) : (
      <LakeText variant="medium">{t("sandbox.sectionOnlyExistsInLive")}</LakeText>
    )}

    <Space height={20} />
    <EnvironmentSelector onSelect={onSelect} />
  </Box>
);
