type Currency = {
  code: string;
  digits: number;
  name: string;
  symbol: string;
};

export const getCurrencies = (): Currency[] => [
  {
    code: "USD",
    digits: 2,
    name: "US Dollar",
    symbol: "$",
  },
  {
    code: "CAD",
    digits: 2,
    name: "Canadian Dollar",
    symbol: "CA$",
  },
  {
    code: "EUR",
    digits: 2,
    name: "Euro",
    symbol: "€",
  },
  {
    code: "AED",
    digits: 2,
    name: "United Arab Emirates Dirham",
    symbol: "AED",
  },
  {
    code: "AFN",
    digits: 0,
    name: "Afghan Afghani",
    symbol: "Af",
  },
  {
    code: "ALL",
    digits: 0,
    name: "Albanian Lek",
    symbol: "ALL",
  },
  {
    code: "AMD",
    digits: 0,
    name: "Armenian Dram",
    symbol: "AMD",
  },
  {
    code: "ARS",
    digits: 2,
    name: "Argentine Peso",
    symbol: "AR$",
  },
  {
    code: "AUD",
    digits: 2,
    name: "Australian Dollar",
    symbol: "AU$",
  },
  {
    code: "AZN",
    digits: 2,
    name: "Azerbaijani Manat",
    symbol: "man.",
  },
  {
    code: "BAM",
    digits: 2,
    name: "Bosnia-Herzegovina Convertible Mark",
    symbol: "KM",
  },
  {
    code: "BDT",
    digits: 2,
    name: "Bangladeshi Taka",
    symbol: "Tk",
  },
  {
    code: "BGN",
    digits: 2,
    name: "Bulgarian Lev",
    symbol: "BGN",
  },
  {
    code: "BHD",
    digits: 3,
    name: "Bahraini Dinar",
    symbol: "BD",
  },
  {
    code: "BIF",
    digits: 0,
    name: "Burundian Franc",
    symbol: "FBu",
  },
  {
    code: "BND",
    digits: 2,
    name: "Brunei Dollar",
    symbol: "BN$",
  },
  {
    code: "BOB",
    digits: 2,
    name: "Bolivian Boliviano",
    symbol: "Bs",
  },
  {
    code: "BRL",
    digits: 2,
    name: "Brazilian Real",
    symbol: "R$",
  },
  {
    code: "BWP",
    digits: 2,
    name: "Botswanan Pula",
    symbol: "BWP",
  },
  {
    code: "BYN",
    digits: 2,
    name: "Belarusian Ruble",
    symbol: "Br",
  },
  {
    code: "BZD",
    digits: 2,
    name: "Belize Dollar",
    symbol: "BZ$",
  },
  {
    code: "CDF",
    digits: 2,
    name: "Congolese Franc",
    symbol: "CDF",
  },
  {
    code: "CHF",
    digits: 2,
    name: "Swiss Franc",
    symbol: "CHF",
  },
  {
    code: "CLP",
    digits: 0,
    name: "Chilean Peso",
    symbol: "CL$",
  },
  {
    code: "CNY",
    digits: 2,
    name: "Chinese Yuan",
    symbol: "CN¥",
  },
  {
    code: "COP",
    digits: 0,
    name: "Colombian Peso",
    symbol: "CO$",
  },
  {
    code: "CRC",
    digits: 0,
    name: "Costa Rican Colón",
    symbol: "₡",
  },
  {
    code: "CVE",
    digits: 2,
    name: "Cape Verdean Escudo",
    symbol: "CV$",
  },
  {
    code: "CZK",
    digits: 2,
    name: "Czech Republic Koruna",
    symbol: "Kč",
  },
  {
    code: "DJF",
    digits: 0,
    name: "Djiboutian Franc",
    symbol: "Fdj",
  },
  {
    code: "DKK",
    digits: 2,
    name: "Danish Krone",
    symbol: "Dkr",
  },
  {
    code: "DOP",
    digits: 2,
    name: "Dominican Peso",
    symbol: "RD$",
  },
  {
    code: "DZD",
    digits: 2,
    name: "Algerian Dinar",
    symbol: "DA",
  },
  {
    code: "EEK",
    digits: 2,
    name: "Estonian Kroon",
    symbol: "Ekr",
  },
  {
    code: "EGP",
    digits: 2,
    name: "Egyptian Pound",
    symbol: "EGP",
  },
  {
    code: "ERN",
    digits: 2,
    name: "Eritrean Nakfa",
    symbol: "Nfk",
  },
  {
    code: "ETB",
    digits: 2,
    name: "Ethiopian Birr",
    symbol: "Br",
  },
  {
    code: "GBP",
    digits: 2,
    name: "British Pound Sterling",
    symbol: "£",
  },
  {
    code: "GEL",
    digits: 2,
    name: "Georgian Lari",
    symbol: "GEL",
  },
  {
    code: "GHS",
    digits: 2,
    name: "Ghanaian Cedi",
    symbol: "GH₵",
  },
  {
    code: "GNF",
    digits: 0,
    name: "Guinean Franc",
    symbol: "FG",
  },
  {
    code: "GTQ",
    digits: 2,
    name: "Guatemalan Quetzal",
    symbol: "GTQ",
  },
  {
    code: "HKD",
    digits: 2,
    name: "Hong Kong Dollar",
    symbol: "HK$",
  },
  {
    code: "HNL",
    digits: 2,
    name: "Honduran Lempira",
    symbol: "HNL",
  },
  {
    code: "HRK",
    digits: 2,
    name: "Croatian Kuna",
    symbol: "kn",
  },
  {
    code: "HUF",
    digits: 0,
    name: "Hungarian Forint",
    symbol: "Ft",
  },
  {
    code: "IDR",
    digits: 0,
    name: "Indonesian Rupiah",
    symbol: "Rp",
  },
  {
    code: "ILS",
    digits: 2,
    name: "Israeli New Sheqel",
    symbol: "₪",
  },
  {
    code: "INR",
    digits: 2,
    name: "Indian Rupee",
    symbol: "Rs",
  },
  {
    code: "IQD",
    digits: 0,
    name: "Iraqi Dinar",
    symbol: "IQD",
  },
  {
    code: "IRR",
    digits: 0,
    name: "Iranian Rial",
    symbol: "IRR",
  },
  {
    code: "ISK",
    digits: 0,
    name: "Icelandic Króna",
    symbol: "Ikr",
  },
  {
    code: "JMD",
    digits: 2,
    name: "Jamaican Dollar",
    symbol: "J$",
  },
  {
    code: "JOD",
    digits: 3,
    name: "Jordanian Dinar",
    symbol: "JD",
  },
  {
    code: "JPY",
    digits: 0,
    name: "Japanese Yen",
    symbol: "¥",
  },
  {
    code: "KES",
    digits: 2,
    name: "Kenyan Shilling",
    symbol: "Ksh",
  },
  {
    code: "KHR",
    digits: 2,
    name: "Cambodian Riel",
    symbol: "KHR",
  },
  {
    code: "KMF",
    digits: 0,
    name: "Comorian Franc",
    symbol: "CF",
  },
  {
    code: "KRW",
    digits: 0,
    name: "South Korean Won",
    symbol: "₩",
  },
  {
    code: "KWD",
    digits: 3,
    name: "Kuwaiti Dinar",
    symbol: "KD",
  },
  {
    code: "KZT",
    digits: 2,
    name: "Kazakhstani Tenge",
    symbol: "KZT",
  },
  {
    code: "LBP",
    digits: 0,
    name: "Lebanese Pound",
    symbol: "LB£",
  },
  {
    code: "LKR",
    digits: 2,
    name: "Sri Lankan Rupee",
    symbol: "SLRs",
  },
  {
    code: "LTL",
    digits: 2,
    name: "Lithuanian Litas",
    symbol: "Lt",
  },
  {
    code: "LVL",
    digits: 2,
    name: "Latvian Lats",
    symbol: "Ls",
  },
  {
    code: "LYD",
    digits: 3,
    name: "Libyan Dinar",
    symbol: "LD",
  },
  {
    code: "MAD",
    digits: 2,
    name: "Moroccan Dirham",
    symbol: "MAD",
  },
  {
    code: "MDL",
    digits: 2,
    name: "Moldovan Leu",
    symbol: "MDL",
  },
  {
    code: "MGA",
    digits: 0,
    name: "Malagasy Ariary",
    symbol: "MGA",
  },
  {
    code: "MKD",
    digits: 2,
    name: "Macedonian Denar",
    symbol: "MKD",
  },
  {
    code: "MMK",
    digits: 0,
    name: "Myanma Kyat",
    symbol: "MMK",
  },
  {
    code: "MOP",
    digits: 2,
    name: "Macanese Pataca",
    symbol: "MOP$",
  },
  {
    code: "MUR",
    digits: 0,
    name: "Mauritian Rupee",
    symbol: "MURs",
  },
  {
    code: "MXN",
    digits: 2,
    name: "Mexican Peso",
    symbol: "MX$",
  },
  {
    code: "MYR",
    digits: 2,
    name: "Malaysian Ringgit",
    symbol: "RM",
  },
  {
    code: "MZN",
    digits: 2,
    name: "Mozambican Metical",
    symbol: "MTn",
  },
  {
    code: "NAD",
    digits: 2,
    name: "Namibian Dollar",
    symbol: "N$",
  },
  {
    code: "NGN",
    digits: 2,
    name: "Nigerian Naira",
    symbol: "₦",
  },
  {
    code: "NIO",
    digits: 2,
    name: "Nicaraguan Córdoba",
    symbol: "C$",
  },
  {
    code: "NOK",
    digits: 2,
    name: "Norwegian Krone",
    symbol: "Nkr",
  },
  {
    code: "NPR",
    digits: 2,
    name: "Nepalese Rupee",
    symbol: "NPRs",
  },
  {
    code: "NZD",
    digits: 2,
    name: "New Zealand Dollar",
    symbol: "NZ$",
  },
  {
    code: "OMR",
    digits: 3,
    name: "Omani Rial",
    symbol: "OMR",
  },
  {
    code: "PAB",
    digits: 2,
    name: "Panamanian Balboa",
    symbol: "B/.",
  },
  {
    code: "PEN",
    digits: 2,
    name: "Peruvian Nuevo Sol",
    symbol: "S/.",
  },
  {
    code: "PHP",
    digits: 2,
    name: "Philippine Peso",
    symbol: "₱",
  },
  {
    code: "PKR",
    digits: 0,
    name: "Pakistani Rupee",
    symbol: "PKRs",
  },
  {
    code: "PLN",
    digits: 2,
    name: "Polish Zloty",
    symbol: "zł",
  },
  {
    code: "PYG",
    digits: 0,
    name: "Paraguayan Guarani",
    symbol: "₲",
  },
  {
    code: "QAR",
    digits: 2,
    name: "Qatari Rial",
    symbol: "QR",
  },
  {
    code: "RON",
    digits: 2,
    name: "Romanian Leu",
    symbol: "RON",
  },
  {
    code: "RSD",
    digits: 0,
    name: "Serbian Dinar",
    symbol: "din.",
  },
  {
    code: "RUB",
    digits: 2,
    name: "Russian Ruble",
    symbol: "RUB",
  },
  {
    code: "RWF",
    digits: 0,
    name: "Rwandan Franc",
    symbol: "RWF",
  },
  {
    code: "SAR",
    digits: 2,
    name: "Saudi Riyal",
    symbol: "SR",
  },
  {
    code: "SDG",
    digits: 2,
    name: "Sudanese Pound",
    symbol: "SDG",
  },
  {
    code: "SEK",
    digits: 2,
    name: "Swedish Krona",
    symbol: "Skr",
  },
  {
    code: "SGD",
    digits: 2,
    name: "Singapore Dollar",
    symbol: "S$",
  },
  {
    code: "SOS",
    digits: 0,
    name: "Somali Shilling",
    symbol: "Ssh",
  },
  {
    code: "SYP",
    digits: 0,
    name: "Syrian Pound",
    symbol: "SY£",
  },
  {
    code: "THB",
    digits: 2,
    name: "Thai Baht",
    symbol: "฿",
  },
  {
    code: "TND",
    digits: 3,
    name: "Tunisian Dinar",
    symbol: "DT",
  },
  {
    code: "TOP",
    digits: 2,
    name: "Tongan Paʻanga",
    symbol: "T$",
  },
  {
    code: "TRY",
    digits: 2,
    name: "Turkish Lira",
    symbol: "TL",
  },
  {
    code: "TTD",
    digits: 2,
    name: "Trinidad and Tobago Dollar",
    symbol: "TT$",
  },
  {
    code: "TWD",
    digits: 2,
    name: "New Taiwan Dollar",
    symbol: "NT$",
  },
  {
    code: "TZS",
    digits: 0,
    name: "Tanzanian Shilling",
    symbol: "TSh",
  },
  {
    code: "UAH",
    digits: 2,
    name: "Ukrainian Hryvnia",
    symbol: "₴",
  },
  {
    code: "UGX",
    digits: 0,
    name: "Ugandan Shilling",
    symbol: "USh",
  },
  {
    code: "UYU",
    digits: 2,
    name: "Uruguayan Peso",
    symbol: "$U",
  },
  {
    code: "UZS",
    digits: 0,
    name: "Uzbekistan Som",
    symbol: "UZS",
  },
  {
    code: "VEF",
    digits: 2,
    name: "Venezuelan Bolívar",
    symbol: "Bs.F.",
  },
  {
    code: "VND",
    digits: 0,
    name: "Vietnamese Dong",
    symbol: "₫",
  },
  {
    code: "XAF",
    digits: 0,
    name: "CFA Franc BEAC",
    symbol: "FCFA",
  },
  {
    code: "XOF",
    digits: 0,
    name: "CFA Franc BCEAO",
    symbol: "CFA",
  },
  {
    code: "YER",
    digits: 0,
    name: "Yemeni Rial",
    symbol: "YR",
  },
  {
    code: "ZAR",
    digits: 2,
    name: "South African Rand",
    symbol: "R",
  },
  {
    code: "ZMK",
    digits: 0,
    name: "Zambian Kwacha",
    symbol: "ZK",
  },
  {
    code: "ZWL",
    digits: 0,
    name: "Zimbabwean Dollar",
    symbol: "ZWL$",
  },
];
