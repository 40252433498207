import { Array, AsyncData, Dict, Future, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import {
  ActionCell,
  Cell,
  CopyableTextCell,
  HeaderCell,
  LinkCell,
  TextCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnCellConfig,
  ColumnConfig,
  ColumnTitleConfig,
  LinkConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { colors, negativeSpacings } from "@swan-io/lake/src/constants/design";
import { identity } from "@swan-io/lake/src/utils/function";
import {
  emptyToUndefined,
  isNotNullish,
  isNullish,
  nullishOrEmptyToUndefined,
} from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import {
  FilterCheckboxDef,
  FiltersStack,
  FiltersState,
} from "@swan-io/shared-business/src/components/Filters";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { P, match } from "ts-pattern";
import { VerificationStatusTag } from "../components/BusinessTags";
import { Connection } from "../components/Connection";
import { ErrorView } from "../components/ErrorView";
import { FilterTiles } from "../components/FilterTiles";
import { TrackPressable } from "../components/TrackPressable";
import {
  AccountHolderOrderByFieldInput,
  AccountHolderOrderByInput,
  AccountHolderType,
  GetAccountHoldersVerificationCountersDocument,
  GetAccountHoldersWithCountDocument,
  GetAccountHoldersWithCountQuery,
  OrderByDirection,
  VerificationStatus,
} from "../graphql/partner";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { formatCount, locale, t } from "../utils/i18n";
import { useFiltersTracking } from "../utils/matomo";
import { RouteParams, Router } from "../utils/routes";

type Props = {
  params: RouteParams["HoldersVerificationArea"];
};

type Edge = GetEdge<GetAccountHoldersWithCountQuery["accountHolders"]>;

const keyExtractor = ({ node: { id } }: Edge) => id;

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  onChangeSort?: (sortBy: AccountHolderOrderByInput) => void;
  sortBy?: AccountHolderOrderByInput;
};

const getRowLink = ({
  item: {
    node: { id },
  },
  extraInfo: { projectEnv, projectId },
}: LinkConfig<Edge, ExtraInfo>) => (
  <Link to={Router.HoldersDetailRoot({ projectId, projectEnv, accountHolderId: id })} />
);

const stickedToStartColumns = [
  {
    width: 150,
    id: "type",
    title: t("accountHolder.type"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: {
          info: { type },
        },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => {
      return (
        <Cell>
          {match(type)
            .with("Individual", () => (
              <Tag color="darkPink" icon="person-regular">
                {t("accountHolder.individual")}
              </Tag>
            ))
            .with("Company", () => (
              <Tag color="shakespear" icon="building-regular">
                {t("accountHolder.company")}
              </Tag>
            ))
            .exhaustive()}
        </Cell>
      );
    },
  },
];

const columns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 280,
    id: "name",
    title: t("accountHolder.name"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: {
          info: { name },
        },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => <TextCell variant="medium" text={name} />,
  },
  {
    width: 300,
    id: "id",
    title: t("accountHolder.id"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { id },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <CopyableTextCell
        text={id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 230,
    id: "documentCollection",
    title: t("accountHolder.documentCollection"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { supportingDocumentCollections, onboarding },
      },
      extraInfo: { projectEnv, projectId },
    }: ColumnCellConfig<Edge, ExtraInfo>) => {
      const supportingDocumentCollection = supportingDocumentCollections.edges[0];

      if (isNullish(supportingDocumentCollection) || isNullish(onboarding)) {
        return null;
      }

      return (
        <LinkCell
          buttonPosition="end"
          onPress={() =>
            Router.push("OnboardingDetailSupportingDocuments", {
              projectId,
              projectEnv,
              onboardingId: onboarding.id,
              supportingDocumentCollectionId: supportingDocumentCollection.node.id,
            })
          }
        >
          {match(supportingDocumentCollection.node.statusInfo.status)
            .with("Canceled", () => (
              <Tag color="gray">{t("accountHolder.onboardingDocumentsCollection.canceled")}</Tag>
            ))
            .with("PendingReview", () => (
              <Tag color="shakespear">
                {t("accountHolder.onboardingDocumentsCollection.pendingReview")}
              </Tag>
            ))
            .with("Approved", () => (
              <Tag color="positive">
                {t("accountHolder.onboardingDocumentsCollection.approved")}
              </Tag>
            ))
            .with("Rejected", () => (
              <Tag color="negative">
                {t("accountHolder.onboardingDocumentsCollection.rejected")}
              </Tag>
            ))
            .with("WaitingForDocument", () => (
              <Tag color="warning">{t("accountHolder.verificationStatus.waitingForDocument")}</Tag>
            ))
            .exhaustive()}
        </LinkCell>
      );
    },
  },
  {
    width: 250,
    id: "updatedAt",
    title: t("accountHolder.updatedAt"),
    renderTitle: ({ title, extraInfo }: ColumnTitleConfig<ExtraInfo>) => (
      <TrackPressable action="Sort account holders by update date">
        <HeaderCell
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "updatedAt", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "updatedAt"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
          text={title}
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { updatedDate },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <TextCell text={dayjs(updatedDate).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
    ),
  },
];

const stickedToEndColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 200,
    id: "verificationStatus",
    title: t("accountHolder.verificationStatus"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => (
      <HeaderCell align="right" text={title} />
    ),
    renderCell: ({
      item: {
        node: { verificationStatus },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <Cell align="right">
        <VerificationStatusTag verificationStatus={verificationStatus} />
      </Cell>
    ),
  },
  {
    width: 48,
    id: "actions",
    title: t("common.table.actions"),
    renderTitle: () => null,
    renderCell: ({ isHovered }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <Cell align="right">
        <ActionCell>
          <Icon
            name="chevron-right-filled"
            color={isHovered ? colors.gray[700] : colors.gray[200]}
            size={16}
          />
        </ActionCell>
      </Cell>
    ),
  },
];

const typeList = ["Company", "Individual"] as const;
const statusList = ["Canceled", "Enabled", "Suspended"] as const;

const verificationStatusList = [
  "NotStarted",
  "Pending",
  "WaitingForInformation",
] satisfies VerificationStatus[];

const PER_PAGE = 20;

export const AccountHolderVerificationListPage = ({ params }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  useCrumb(
    useMemo(
      () => ({
        label: t("accountHolders.verifications"),
        link: Router.HoldersVerificationArea({ projectId, projectEnv }),
      }),
      [projectEnv, projectId],
    ),
  );

  const filters: AccountHolderFilters = useMemo(() => {
    return {
      types: isNotNullish(params.types)
        ? Array.filterMap(params.types, item =>
            match(item)
              .with(...typeList, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,
      status: isNotNullish(params.status)
        ? Array.filterMap(params.status, item =>
            match(item)
              .with(...statusList, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,
    } as const;
  }, [params.types, params.status]);

  const sortBy: AccountHolderOrderByInput = useMemo(() => {
    return {
      field: match(params.sortBy)
        .returnType<AccountHolderOrderByFieldInput>()
        .with("createdAt", "updatedAt", identity)
        .otherwise(() => "createdAt"),
      direction: match(params.direction)
        .returnType<OrderByDirection>()
        .with("Asc", "Desc", identity)
        .otherwise(() => "Desc"),
    };
  }, [params.sortBy, params.direction]);

  const verificationStatus = match(params.verificationStatus)
    .with(
      "NotStarted",
      "Pending",
      "WaitingForInformation",
      verificationStatus => verificationStatus,
    )
    .otherwise(() => undefined);

  const search = nullishOrEmptyToUndefined(params.search);
  const hasSearchOrFilters = isNotNullish(search) || Object.values(filters).some(isNotNullish);

  const [data, { isLoading, reload, setVariables }] = useQuery(GetAccountHoldersWithCountDocument, {
    first: PER_PAGE,
    filters: {
      ...filters,
      search,
      verificationStatus:
        verificationStatus != null ? [verificationStatus] : verificationStatusList,
    },
    orderBy: sortBy,
  });

  const [counters, { reload: reloadCounters }] = useQuery(
    GetAccountHoldersVerificationCountersDocument,
    {},
  );

  const extraInfo: ExtraInfo = useMemo(
    () => ({
      projectEnv,
      projectId,
      onChangeSort: ({ field, direction }) => {
        Router.push("HoldersVerificationArea", {
          ...params,
          sortBy: field ?? undefined,
          direction: direction ?? undefined,
        });
      },
      sortBy,
    }),
    [projectEnv, projectId, sortBy, params],
  );

  const holders = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ accountHolders }) => accountHolders);

  const notStartedAccountHoldersCount = counters
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ notStarted }) => notStarted.totalCount);
  const pendingAccountHoldersCount = counters
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ pending }) => pending.totalCount);
  const waitingForInformationAccountHoldersCount = counters
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ waitingForInformation }) => waitingForInformation.totalCount);

  const totalCount = holders.map(({ totalCount }) => totalCount);

  useFiltersTracking({
    filters,
    totalCount: totalCount.getOr(0),
    loaded: data.isDone(),
  });

  return (
    <>
      <FilterTiles
        filters={[
          {
            value: "NotStarted",
            name: t("accountHolder.verificationStatus.notStarted"),
            help: t("accountHolder.verificationStatus.notStarted.info"),
            color: colors.gray[900],
            count: notStartedAccountHoldersCount,
          },
          {
            value: "WaitingForInformation",
            name: t("accountHolder.verificationStatus.waitingForInformation"),
            help: t("accountHolder.verificationStatus.waitingForInformation.info"),
            color: colors.warning[500],
            count: waitingForInformationAccountHoldersCount,
          },
          {
            value: "Pending",
            name: t("accountHolder.verificationStatus.pending"),
            help: t("accountHolder.verificationStatus.pending.info"),
            color: colors.shakespear[500],
            count: pendingAccountHoldersCount,
          },
        ]}
        selectedFilter={params.verificationStatus}
        onFilterPressed={filter =>
          Router.push("HoldersVerificationArea", {
            projectEnv,
            projectId,
            ...filters,
            verificationStatus: filter,
          })
        }
      />

      <AccountHolderFiltersForm
        filters={filters}
        search={search}
        totalCount={totalCount}
        onRefresh={() => Future.all([reload(), reloadCounters()])}
        onChangeFilters={filters => {
          Router.replace("HoldersVerificationArea", { ...params, ...filters, verificationStatus });
        }}
        onChangeSearch={search => {
          Router.replace("HoldersVerificationArea", { ...params, search, verificationStatus });
        }}
      />

      <Space height={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={PER_PAGE}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.accountHolders}>
            {accountHolders => (
              <VirtualizedList
                variant="default"
                marginHorizontal={negativeSpacings[24]}
                data={accountHolders.edges}
                keyExtractor={keyExtractor}
                extraInfo={extraInfo}
                stickedToStartColumns={stickedToStartColumns}
                columns={columns}
                stickedToEndColumns={stickedToEndColumns}
                headerHeight={48}
                rowHeight={48}
                loading={{
                  isLoading,
                  count: PER_PAGE,
                }}
                getRowLink={getRowLink}
                onEndReached={() => {
                  if (accountHolders.pageInfo.hasNextPage === true) {
                    setVariables({ after: accountHolders.pageInfo.endCursor ?? undefined });
                  }
                }}
                renderEmptyList={() =>
                  hasSearchOrFilters ? (
                    <EmptyView
                      icon="clipboard-search-regular"
                      title={t("common.list.noResults")}
                      subtitle={t("common.list.noResultsSuggestion")}
                    />
                  ) : (
                    <EmptyView
                      icon="lake-inbox-empty"
                      title={t("accountHolder.list.empty.title")}
                      subtitle={t("accountHolder.list.empty.subtitle")}
                    />
                  )
                }
              />
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};

const typeFilter: FilterCheckboxDef<AccountHolderType> = {
  type: "checkbox",
  label: t("accountHolder.type"),
  checkAllLabel: t("common.filters.all"),
  items: [
    { value: "Individual", label: t("accountHolder.individual") },
    { value: "Company", label: t("accountHolder.company") },
  ],
};

const filtersDefinition = {
  types: typeFilter,
};

type AccountHolderFilters = FiltersState<typeof filtersDefinition>;

type AccountHolderFiltersFormProps = {
  filters: AccountHolderFilters;
  search: string | undefined;
  totalCount: Option<number>;
  onChangeFilters: (filters: AccountHolderFilters) => void;
  onRefresh: () => Future<unknown>;
  onChangeSearch: (search: string | undefined) => void;
};

const AccountHolderFiltersForm = ({
  filters,
  search,
  totalCount,
  onChangeFilters,
  onRefresh,
  onChangeSearch,
}: AccountHolderFiltersFormProps) => {
  const availableFilters: { name: keyof AccountHolderFilters; label: string }[] = useMemo(
    () => [
      {
        name: "types",
        label: t("accountHolder.type"),
      },
    ],
    [],
  );

  const [openFilters, setOpenFilters] = useState(() =>
    Dict.entries(filters)
      .filter(([, value]) => isNotNullish(value))
      .map(([name]) => name),
  );

  useEffect(() => {
    setOpenFilters(openFilters => {
      const currentlyOpenFilters = new Set(openFilters);
      const openFiltersNotYetInState = Dict.entries(filters)
        .filter(([name, value]) => isNotNullish(value) && !currentlyOpenFilters.has(name))
        .map(([name]) => name);
      return [...openFilters, ...openFiltersNotYetInState];
    });
  }, [filters]);

  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <>
      <Box direction="row" alignItems="center">
        <FilterChooser
          filters={filters}
          openFilters={openFilters}
          label={t("common.filters")}
          onAddFilter={filter => setOpenFilters(openFilters => [...openFilters, filter])}
          availableFilters={availableFilters}
        />

        <Space width={16} />

        <TrackPressable action="Refresh account holders list">
          <LakeButton
            ariaLabel={t("common.refresh")}
            mode="secondary"
            size="small"
            icon="arrow-counterclockwise-filled"
            loading={isRefreshing}
            onPress={() => {
              setIsRefreshing(true);
              onRefresh().tap(() => setIsRefreshing(false));
            }}
          />
        </TrackPressable>

        <Fill minWidth={16} />

        <LakeSearchField
          placeholder={t("common.search")}
          initialValue={search ?? ""}
          onChangeText={text => onChangeSearch(emptyToUndefined(text))}
          renderEnd={() =>
            totalCount.map(count => <Tag color="partner">{formatCount(count)}</Tag>).toNull()
          }
        />
      </Box>

      <Space height={12} />

      <FiltersStack
        definition={filtersDefinition}
        filters={filters}
        openedFilters={openFilters}
        onChangeFilters={onChangeFilters}
        onChangeOpened={setOpenFilters}
      />
    </>
  );
};
