import { AsyncData, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { IconName } from "@swan-io/lake/src/components/Icon";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { TileGridPlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { useMemo } from "react";
import { P, match } from "ts-pattern";
import { GetTransactionDocument, MerchantCategory } from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { NotFoundPage } from "../pages/NotFoundPage";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { ErrorView } from "./ErrorView";
import { TransactionDetail } from "./TransactionDetail";
import { TransactionsDetailSupportingDocumentCollections } from "./TransactionDetailSupportingDocumentCollections";

type Props = {
  transactionId: string;
  accountId: string;
};

const merchantCategoryIcons: Record<MerchantCategory, IconName> = {
  Culture: "music-note-2-regular",
  Entertainment: "movies-and-tv-regular",
  Finance: "calculator-regular",
  Groceries: "cart-regular",
  HealthAndBeauty: "heart-pulse-regular",
  HomeAndUtilities: "home-regular",
  Other: "payment-regular",
  ProfessionalServices: "people-team-toolbox-regular",
  PublicAdministrations: "gavel-regular",
  Restaurants: "food-regular",
  Shopping: "shopping-bag-regular",
  Software: "laptop-regular",
  Transport: "vehicle-subway-regular",
  Travel: "airplane-regular",
};

export const getMerchantCategoryIcon = (category: MerchantCategory) =>
  merchantCategoryIcons[category];

export const AccountDetailTransactionDetailArea = ({ transactionId, accountId }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const route = Router.useRoute([
    "AccountDetailTransactionsDetail",
    "AccountDetailTransactionsDetailSupportingDocuments",
  ]);

  const [data, { reload }] = useQuery(GetTransactionDocument, { transactionId });

  useCrumb(
    useMemo(() => {
      return {
        label: transactionId,
        link: Router.AccountDetailTransactionsDetail({
          projectId,
          projectEnv,
          accountId,
          transactionId,
        }),
      };
    }, [projectId, accountId, projectEnv, transactionId]),
  );

  return (
    <>
      <TabView
        tabs={[
          {
            label: t("account.general"),
            url: Router.AccountDetailTransactionsDetail({
              projectId,
              projectEnv,
              accountId,
              transactionId,
            }),
          },

          {
            label: t("transaction.tab.supportingDocument"),
            url: Router.AccountDetailTransactionsDetailSupportingDocuments({
              transactionId,
              accountId,
              projectId,
              projectEnv,
            }),
          },
        ]}
        otherLabel={t("common.tabs.other")}
      />

      <Space height={24} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <TileGridPlaceholder withTabs={false} />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), ({ transaction, projectInfo }) => {
          return match(route)
            .with({ name: "AccountDetailTransactionsDetail" }, () => (
              <TransactionDetail transaction={transaction} reload={reload} />
            ))
            .with({ name: "AccountDetailTransactionsDetailSupportingDocuments" }, () => (
              <TransactionsDetailSupportingDocumentCollections
                supportingDocumentCollections={transaction.supportingDocumentCollections}
                supportingDocumentCollectMode={
                  projectInfo.supportingDocumentSettings?.collectMode ?? undefined
                }
              />
            ))
            .otherwise(() => <NotFoundPage />);
        })
        .exhaustive()}
    </>
  );
};
