import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { colors } from "@swan-io/lake/src/constants/design";
import { StyleSheet } from "react-native";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";

const styles = StyleSheet.create({
  flex: {
    display: "flex",
  },
  container: {
    alignItems: "center",
    borderBottomColor: colors.gray[100],
    borderBottomWidth: 1,
    height: 64,
    marginHorizontal: "auto",
    maxWidth: 2560,
    paddingHorizontal: 20,
    width: "100%",
  },
});

export const BackToDashboard = () => {
  const { projectId } = useProjectInfo();

  return (
    <Link to={Router.UserList({ projectId, projectEnv: "sandbox" })} style={styles.flex}>
      <Box direction="row" style={styles.container} alignItems="center">
        <Icon name="arrow-left-filled" color={colors.gray[600]} size={20} />
        <Space width={12} />

        <LakeText color={colors.gray[600]} variant="medium">
          {t("members.backToDashboard")}
        </LakeText>
      </Box>
    </Link>
  );
};
