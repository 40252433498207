import { AsyncData, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { P, match } from "ts-pattern";
import { BrandingSettingsForm } from "../components/BrandingSettingsForm";
import { GetCurrentBrandingPageDocument } from "../graphql/admin";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { ErrorView } from "./ErrorView";

export const BrandingCurrentTab = () => {
  const { projectId } = useProjectInfo();

  const [data] = useQuery(GetCurrentBrandingPageDocument, { projectId });

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <LoadingView />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), ({ project: { liveProjectSettings } }) => {
      const defaultValues = {
        projectName: liveProjectSettings?.name,
        logoUri: liveProjectSettings?.logoUri,
        accentColor: liveProjectSettings?.accentColor,
      };

      return (
        <BrandingSettingsForm onSave={() => {}} defaultValues={defaultValues} readonly={true} />
      );
    })
    .exhaustive();
};
