import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { FocusTrap } from "@swan-io/lake/src/components/FocusTrap";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { Space } from "@swan-io/lake/src/components/Space";
import { TransitionView } from "@swan-io/lake/src/components/TransitionView";
import { WithCurrentColor } from "@swan-io/lake/src/components/WithCurrentColor";
import { animations, backgroundColor, colors, spacings } from "@swan-io/lake/src/constants/design";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { Request } from "@swan-io/request";
import { useForm } from "@swan-io/use-form";
import { Image, StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import computer from "../assets/img/activate/computer.svg";
import { ErrorView } from "../components/ErrorView";
import { HUBSPOT_API_URL, PROJECT_CREATION_FORM_ID } from "../constants/hubspot";
import {
  CreateProjectDocument,
  GetCreateProjectPageDocument,
  GetCreateProjectPageQuery,
} from "../graphql/admin";
import { env } from "../utils/env";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { validateEmail, validateRequired } from "../utils/validations";

const getCookie = (name: string): string | null => {
  for (const pair of document.cookie.split(";")) {
    const [key, value] = pair.split("=");

    if (key?.trim() === name && isNotNullishOrEmpty(value)) {
      return decodeURIComponent(value);
    }
  }

  return null;
};

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
  },
  fill: {
    ...StyleSheet.absoluteFillObject,
    animationFillMode: "forwards",
  },
  containerEnter: {
    animationKeyframes: {
      "0%": { opacity: 0, transform: "translateZ(0px) translateX(100px)" },
    },
    animationDuration: "500ms",
    animationTimingFunction: "ease-in-out",
  },
  containerLeave: {
    animationKeyframes: {
      "100%": { opacity: 0, transform: "translateZ(0px) translateX(100px)" },
    },
    animationDuration: "500ms",
    animationTimingFunction: "ease-in-out",
  },
  createProjectContainer: {
    ...StyleSheet.absoluteFillObject,
    transform: "translateZ(0px)",
    flexDirection: "row",
    alignItems: "stretch",
  },
  contentsEnter: {
    animationKeyframes: {
      "0%": { transform: "translateZ(0px) translateX(100px)" },
    },
    animationDuration: "500ms",
    animationTimingFunction: "ease-in-out",
  },
  contentsLeave: {
    animationKeyframes: {
      "100%": { transform: "translateZ(0px) translateX(100px)" },
    },
    animationDuration: "500ms",
    animationTimingFunction: "ease-in-out",
  },
  contentsContainer: {
    flexGrow: 1,
    flexBasis: "70%",
    zIndex: 2,
    transform: "translateZ(0px)",
  },
  createProjectContents: {
    backgroundColor: backgroundColor.default,
    flexGrow: 1,
  },
  createProjectSidePicture: {
    width: "30%",
    backgroundColor: backgroundColor.accented,
    overflow: "hidden",
  },
  createProjectSideGradient: {
    ...StyleSheet.absoluteFillObject,
    opacity: 0.12,
    backgroundImage:
      "linear-gradient(180deg, #8266C4 0%, rgba(217, 97, 81, 0.5) 70.75%), linear-gradient(90deg, #6240b5, #6240b500)",
    backgroundSize: "100% 100%, 50px 100%",
    backgroundRepeat: "no-repeat, no-repeat",
  },
  createProjectSideImageContainer: {
    position: "absolute",
    top: spacings[32],
    left: spacings[32],
    right: spacings[32],
    bottom: spacings[32],
    transform: "translateZ(0px)",
  },
  createProjectSideImage: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  contents: {
    flexGrow: 1,
  },
  contentsRootContainer: {
    backgroundColor: backgroundColor.default,
    flexGrow: 1,
    paddingLeft: spacings[96],
    paddingRight: spacings[48],
  },
  scrollView: {
    paddingRight: spacings[48],
  },
  close: {
    position: "absolute",
    right: "100%",
    transform: "translateX(-20px)",
    top: 40,
  },
});

// Those key are set in swan.io Webflow configuration, inside
// the "custom code" section.
const COOKIE_NAME_EMAIL_KEY = "startNowForm_email";
const COOKIE_NAME_COMPANY_KEY = "startNowForm_company";

export const CreateProjectPage = () => {
  const [createProject] = useMutation(CreateProjectDocument);
  const [data] = useQuery(GetCreateProjectPageDocument, {});

  const { Field, formStatus, submitForm } = useForm({
    email: {
      initialValue: getCookie(COOKIE_NAME_EMAIL_KEY) ?? "",
      sanitize: value => value.trim(),
      validate: validateEmail,
    },
    name: {
      initialValue: getCookie(COOKIE_NAME_COMPANY_KEY) ?? "",
      sanitize: value => value.trim(),
      validate: validateRequired,
    },
  });

  const handleSubmit = (user: GetCreateProjectPageQuery["user"]) => {
    submitForm({
      onSuccess: values => {
        const option = Option.allFromDict(values);

        if (option.isNone()) {
          return;
        }

        const input = option.get();

        return createProject({ input })
          .mapOk(data => data.createProjectV2)
          .mapOkToResult(filterRejectionsToResult)
          .flatMapOk(result => {
            const { id } = result.project;

            return Request.make({
              url: `${HUBSPOT_API_URL}/${PROJECT_CREATION_FORM_ID}`,
              responseType: "json",
              method: "POST",
              headers: { "content-type": "application/json" },
              body: JSON.stringify({
                fields: [
                  {
                    name: "email",
                    value: input.email,
                  },
                  {
                    name: "forest_project_id",
                    value: id,
                  },
                  {
                    name: "forest_project_name",
                    value: input.name,
                  },
                  {
                    name: "project_environment_source",
                    value: env.HUBSPOT_ENV,
                  },
                  {
                    name: "firstname",
                    value: user?.firstName,
                  },
                  {
                    name: "lastname",
                    value: user?.preferredLastName,
                  },
                  {
                    name: "phone",
                    value: user?.mobilePhoneNumber,
                  },
                  {
                    name: "date_of_birth",
                    value: user?.birthDate,
                  },
                ],
              }),
            }).mapOk(() => id);
          })
          .tapOk(projectId => {
            Router.push("SettingsBrandingRoot", {
              projectId,
              projectEnv: "sandbox",
              firstVisit: "true",
            });
          });
      },
    });
  };

  return (
    <View style={styles.container}>
      <TransitionView
        style={styles.fill}
        enter={styles.containerEnter}
        leave={styles.containerLeave}
      >
        <FocusTrap
          focusLock={true}
          autoFocus={true}
          returnFocus={true}
          style={styles.createProjectContainer}
        >
          <TransitionView
            style={styles.contentsContainer}
            enter={styles.contentsEnter}
            leave={styles.contentsLeave}
          >
            <View style={styles.createProjectContents}>
              <WithCurrentColor variant="live">
                <View style={styles.contentsRootContainer}>
                  <View style={styles.contents}>
                    <LakeButton
                      style={styles.close}
                      mode="secondary"
                      onPress={() => {
                        history.back();
                      }}
                      icon="lake-close"
                      size="small"
                      ariaLabel={t("common.close")}
                    />

                    {match(data)
                      .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <LoadingView />)
                      .with(AsyncData.P.Done(Result.P.Error(P.select())), error => (
                        <ErrorView error={error} />
                      ))
                      .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
                        <LakeScrollView contentContainerStyle={styles.scrollView}>
                          <Space height={40} />
                          <Icon name="rocket-regular" size={64} color={colors.live.primary} />

                          <LakeHeading level={1} variant="h1">
                            {t("createProject.title")}
                          </LakeHeading>

                          <Space height={16} />
                          <LakeText>{t("createProject.description")}</LakeText>
                          <Space height={40} />

                          <Field name="email">
                            {({ value, valid, error, onChange, onBlur }) => (
                              <LakeLabel
                                label={t("profile.email")}
                                render={id => (
                                  <LakeTextInput
                                    id={id}
                                    placeholder="example@domain.com"
                                    value={value}
                                    valid={valid}
                                    error={error}
                                    onChangeText={onChange}
                                    onBlur={onBlur}
                                  />
                                )}
                              />
                            )}
                          </Field>

                          <Field name="name">
                            {({ value, valid, error, onBlur, onChange }) => (
                              <LakeLabel
                                label={t("createProject.projectName")}
                                render={id => (
                                  <LakeTextInput
                                    id={id}
                                    placeholder={t("createProject.projectNamePlaceholder")}
                                    value={value}
                                    valid={valid}
                                    error={error}
                                    onChangeText={onChange}
                                    onBlur={onBlur}
                                  />
                                )}
                              />
                            )}
                          </Field>

                          <Space height={20} />

                          <LakeButtonGroup>
                            <LakeButton
                              color="current"
                              loading={formStatus === "submitting"}
                              onPress={() => handleSubmit(data.user)}
                            >
                              {t("createProject.create")}
                            </LakeButton>
                          </LakeButtonGroup>
                        </LakeScrollView>
                      ))
                      .exhaustive()}
                  </View>
                </View>
              </WithCurrentColor>
            </View>
          </TransitionView>

          <View style={styles.createProjectSidePicture}>
            <View style={styles.createProjectSideGradient} />

            <TransitionView
              {...animations.fadeAndSlideInFromRight}
              style={styles.createProjectSideImageContainer}
            >
              <Image
                source={{
                  uri: computer,
                }}
                resizeMode="contain"
                style={styles.createProjectSideImage}
              />
            </TransitionView>
          </View>
        </FocusTrap>
      </TransitionView>
    </View>
  );
};
