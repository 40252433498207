import { AsyncData, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import {
  BalanceCell,
  Cell,
  CopyableTextCell,
  HeaderCell,
  TextCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import dayjs from "dayjs";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { P, match } from "ts-pattern";
import { ErrorView } from "../components/ErrorView";
import {
  GetFundingLimitChangeRequestsDocument,
  GetFundingLimitChangeRequestsQuery,
} from "../graphql/partner";
import { usePermissions } from "../hooks/usePermissions";
import { ProjectEnv, useProjectInfo } from "../hooks/useProjectInfo";
import { NewFundingLimitChangeRequest } from "../pages/NewFundingLimitChangeRequest";
import { formatCurrency, formatNestedMessage, locale, t } from "../utils/i18n";
import { Router, accountHolderDetailRoutes } from "../utils/routes";
import { Connection } from "./Connection";
import { TrackPressable } from "./TrackPressable";

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
};

type Edge = GetEdge<
  NonNullable<
    GetFundingLimitChangeRequestsQuery["accountHolder"]
  >["fundingLimitSettingsChangeRequests"]
>;

const keyExtractor = ({ node: { id } }: Edge) => id;

const EMPTY = <TextCell text="-" />;

type Props = {
  accountHolderId: string;
};

const stickedToStartColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 210,
    id: "fundingApprovedLimit",
    title: t("fundingRequests.approvedLimit"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { approved },
      },
    }) => {
      return isNotNullish(approved) ? (
        <BalanceCell
          align="left"
          value={Number(approved.fundingLimit.amount.value)}
          currency={approved.fundingLimit.amount.currency}
          formatCurrency={formatCurrency}
        />
      ) : (
        EMPTY
      );
    },
  },
  {
    width: 300,
    id: "fundingApprovedInstantLimit",
    title: t("fundingRequests.approvedInstantLimit"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { approved },
      },
    }) => {
      return isNotNullish(approved) ? (
        <BalanceCell
          align="left"
          value={Number(approved.instantFundingLimit.amount.value)}
          currency={approved.instantFundingLimit.amount.currency}
          formatCurrency={formatCurrency}
        />
      ) : (
        EMPTY
      );
    },
  },
];

const columns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 300,
    id: "fundingLimit",
    title: t("fundingRequests.fundingLimit"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { fundingLimit },
      },
    }) => (
      <BalanceCell
        align="left"
        value={Number(fundingLimit.amount.value)}
        currency={fundingLimit.amount.currency}
        formatCurrency={formatCurrency}
      />
    ),
  },
  {
    width: 300,
    id: "fundingInstantLimit",
    title: t("fundingRequests.fundingInstantLimit"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { instantFundingLimit },
      },
    }) => (
      <BalanceCell
        align="left"
        value={Number(instantFundingLimit.amount.value)}
        currency={instantFundingLimit.amount.currency}
        formatCurrency={formatCurrency}
      />
    ),
  },

  {
    width: 300,
    id: "id",
    title: t("fundingRequests.id"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { id },
      },
    }) => (
      <CopyableTextCell
        text={id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 200,
    id: "creationDate",
    title: t("fundingRequests.creationDate"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { createdAt },
      },
    }) =>
      isNotNullish(createdAt) ? (
        <TextCell text={dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
      ) : null,
  },
  {
    width: 200,
    id: "updateDate",
    title: t("fundingRequests.updateDate"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { updatedAt },
      },
    }) =>
      isNotNullish(updatedAt) ? (
        <TextCell text={dayjs(updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
      ) : null,
  },
];
const stickedToEndColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 200,
    id: "status",
    title: t("fundingRequests.status"),
    renderTitle: ({ title }) => <HeaderCell text={title} align="right" />,
    renderCell: ({
      item: {
        node: {
          statusInfo: { status },
        },
      },
    }) => (
      <Cell align="right">
        {match(status)
          .with("Approved", () => (
            <Tag color="positive">{t("fundingRequests.status.approved")}</Tag>
          ))
          .with("Pending", () => (
            <Tag color="shakespear">{t("fundingRequests.status.pending")}</Tag>
          ))
          .with("Refused", () => <Tag color="negative">{t("fundingRequests.status.refused")}</Tag>)
          .with("WaitingForInformation", () => (
            <Tag color="gray">{t("fundingRequests.status.waitingForInformation")}</Tag>
          ))

          .exhaustive()}
      </Cell>
    ),
  },
];

export const AccountHolderDetailFundingLimitChangeRequests = ({ accountHolderId }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const canCreateFundingLimit = usePermissions(projectEnv).dataAccountHolder.write;
  const route = Router.useRoute(accountHolderDetailRoutes);

  const [data, { isLoading, reload, setVariables }] = useQuery(
    GetFundingLimitChangeRequestsDocument,
    { id: accountHolderId, first: 20 },
  );

  const openNew = useCallback(() => {
    Router.push("HoldersDetailFundingLimitChangeRequestsNew", {
      projectId,
      projectEnv,
      accountHolderId,
    });
  }, [projectId, projectEnv, accountHolderId]);

  const extraInfo: ExtraInfo = useMemo(() => {
    return { projectEnv, projectId };
  }, [projectEnv, projectId]);

  const totalCount = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ accountHolder }) => accountHolder?.fundingLimitSettingsChangeRequests.totalCount ?? 0);

  const shouldShowTopBar = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(
      ({ accountHolder }) =>
        (accountHolder?.fundingLimitSettingsChangeRequests.totalCount ?? 0) > 0,
    )
    .getOr(true);

  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <>
      {shouldShowTopBar ? (
        <>
          <Box direction="row" alignItems="center">
            <TrackPressable action="New funding limit change request">
              <LakeTooltip
                placement="left"
                content={t("common.action.denied")}
                disabled={canCreateFundingLimit}
              >
                <LakeButton
                  size="small"
                  icon="add-circle-filled"
                  onPress={openNew}
                  color="current"
                  disabled={!canCreateFundingLimit}
                >
                  {t("common.new")}
                </LakeButton>
              </LakeTooltip>
            </TrackPressable>

            <Space width={8} />

            <TrackPressable action="Refresh funding limit change requests list">
              <LakeButton
                ariaLabel={t("common.refresh")}
                mode="secondary"
                size="small"
                icon="arrow-counterclockwise-filled"
                loading={isRefreshing}
                onPress={() => {
                  setIsRefreshing(true);
                  reload().tap(() => setIsRefreshing(false));
                }}
              />
            </TrackPressable>

            <Fill minWidth={16} />

            {totalCount
              .map<ReactNode>(count => (
                <Tag size="large" color="partner">
                  {t("fundingRequests.counter", { count })}
                </Tag>
              ))
              .toNull()}
          </Box>

          <Space height={8} />
        </>
      ) : null}

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={20}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data?.accountHolder?.fundingLimitSettingsChangeRequests}>
            {fundingLimitSettingsChangeRequests => (
              <VirtualizedList
                variant="default"
                marginHorizontal={negativeSpacings[24]}
                data={fundingLimitSettingsChangeRequests?.edges ?? []}
                keyExtractor={keyExtractor}
                extraInfo={extraInfo}
                columns={columns}
                stickedToStartColumns={stickedToStartColumns}
                stickedToEndColumns={stickedToEndColumns}
                headerHeight={48}
                rowHeight={48}
                onEndReached={() => {
                  if (fundingLimitSettingsChangeRequests?.pageInfo.hasNextPage === true) {
                    setVariables({
                      after: fundingLimitSettingsChangeRequests?.pageInfo.endCursor ?? undefined,
                    });
                  }
                }}
                loading={{
                  isLoading,
                  count: 20,
                }}
                renderEmptyList={() => (
                  <EmptyView
                    icon="lake-inbox-empty"
                    title={t("fundingRequests.empty.title")}
                    subtitle={formatNestedMessage("fundingRequests.empty.subtitle", {
                      createNewRequest: (
                        <LakeText variant="semibold">{t("fundingRequests.empty.new")}</LakeText>
                      ),
                    })}
                  >
                    <LakeButtonGroup justifyContent="center">
                      <TrackPressable action="New funding limit change request">
                        <LakeTooltip
                          placement="left"
                          content={t("common.action.denied")}
                          disabled={canCreateFundingLimit}
                        >
                          <LakeButton
                            size="small"
                            icon="add-circle-filled"
                            onPress={openNew}
                            color="current"
                            disabled={!canCreateFundingLimit}
                          >
                            {t("common.new")}
                          </LakeButton>
                        </LakeTooltip>
                      </TrackPressable>
                    </LakeButtonGroup>
                  </EmptyView>
                )}
              />
            )}
          </Connection>
        ))
        .exhaustive()}

      <LakeModal
        visible={match(route)
          .with({ name: "HoldersDetailFundingLimitChangeRequestsNew" }, () => true)
          .otherwise(() => false)}
        color="current"
        icon="add-circle-regular"
        title={t("fundingRequests.new")}
      >
        <NewFundingLimitChangeRequest
          accountHolderId={accountHolderId}
          onReload={() => {
            reload();
          }}
          canCreateFundingLimit={canCreateFundingLimit}
        />
      </LakeModal>
    </>
  );
};
