import { Future, Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { RightPanel } from "@swan-io/lake/src/components/RightPanel";
import { Space } from "@swan-io/lake/src/components/Space";
import { WithPartnerAccentColor } from "@swan-io/lake/src/components/WithPartnerAccentColor";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors, spacings } from "@swan-io/lake/src/constants/design";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useCallback, useRef, useState } from "react";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { InviteMemberDocument } from "../../graphql/admin";
import { useProjectInfo } from "../../hooks/useProjectInfo";
import { t } from "../../utils/i18n";
import { Role } from "../../utils/permissions";
import { AddAdminConfirmationModal } from "./AddAdminConfirmationModal";
import {
  MembershipBulk,
  ProjectMembershipEditor,
  ProjectMembershipEditorRef,
} from "./ProjectMembershipEditor";

const styles = StyleSheet.create({
  buttonsContainer: {
    paddingVertical: spacings[24],
    paddingHorizontal: spacings[40],
    borderTopWidth: 1,
    borderTopColor: colors.gray[100],
  },
  root: {
    padding: spacings[40],
  },
});

type Props = {
  visible: boolean;
  roleIdsByName: Record<Role, string>;
  onAdd: () => void;
  onPressClose: () => void;
  canManageTeam: boolean;
};

export const AddMemberPanel = ({
  visible,
  onAdd,
  onPressClose,
  roleIdsByName,
  canManageTeam,
}: Props) => {
  const { projectId } = useProjectInfo();
  const [inviteMember] = useMutation(InviteMemberDocument);
  const projectMembershipEditorRef = useRef<ProjectMembershipEditorRef>(null);

  const [addAdminConfirmationModalInfo, setAddAdminConfirmationModalInfo] = useState<
    Option<MembershipBulk>
  >(Option.None());

  const [isSaving, setIsSaving] = useState(false);

  const saveMemberships = useCallback(
    (membershipBulk: MembershipBulk) => {
      const roleId = roleIdsByName[membershipBulk.role];

      setIsSaving(true);
      return Future.all(
        membershipBulk.emails.map(email =>
          inviteMember({ input: { email, roleId, projectId } })
            .mapOkToResult(data =>
              data.inviteMember != undefined
                ? Result.Ok(data.inviteMember)
                : Result.Error(undefined),
            )
            .mapOkToResult(filterRejectionsToResult)
            .tapOk(({ membership }) => {
              if (membership.email != undefined) {
                showToast({
                  variant: "success",
                  title: t("toast.success.membersAdded", { email: membership.email }),
                });
                onAdd();
              }
            })
            .tapError(error => {
              match(error)
                .with({ __typename: "InviteMemberAlreadyPresentRejection" }, () => {
                  showToast({
                    variant: "warning",
                    error,
                    title: t("membership.invite.userAlreadyExists", { email }),
                  });
                  onAdd();
                })
                .otherwise(error => {
                  showToast({ variant: "error", error, title: translateError(error) });
                });
            }),
        ),
      )
        .map(Result.all)
        .tap(() => setIsSaving(false));
    },
    [inviteMember, roleIdsByName, projectId, onAdd],
  );

  const onSave = useCallback(
    (membershipBulk: MembershipBulk) => {
      if (membershipBulk.role === "Admin") {
        setAddAdminConfirmationModalInfo(
          Option.Some({
            emails: membershipBulk.emails,
            role: membershipBulk.role,
          }),
        );
      } else {
        saveMemberships(membershipBulk);
      }
    },
    [saveMemberships],
  );

  return (
    <>
      <RightPanel visible={visible} onPressClose={onPressClose}>
        <WithPartnerAccentColor color={colors.swan[500]}>
          <LakeScrollView style={styles.root}>
            <Icon name="add-circle-regular" size={40} color={colors.partner[500]} />
            <Space height={12} />

            <LakeHeading level={2} variant="h3">
              {t("membership.panel.title")}
            </LakeHeading>

            <Space height={12} />
            <ProjectMembershipEditor ref={projectMembershipEditorRef} onSave={onSave} />
          </LakeScrollView>
        </WithPartnerAccentColor>

        <Box
          direction="row"
          justifyContent="spaceBetween"
          alignItems="center"
          style={styles.buttonsContainer}
        >
          <LakeButton mode="secondary" style={commonStyles.fill} onPress={onPressClose}>
            {t("common.cancel")}
          </LakeButton>

          <Space width={24} />

          <LakeTooltip
            placement="left"
            content={t("common.action.denied")}
            disabled={canManageTeam}
            containerStyle={commonStyles.fill}
          >
            <LakeButton
              color="swan"
              mode="primary"
              disabled={!canManageTeam}
              onPress={() => {
                if (projectMembershipEditorRef.current != null) {
                  projectMembershipEditorRef.current.submit();
                }
              }}
              icon="add-circle-filled"
              style={commonStyles.fill}
              loading={isSaving}
            >
              {t("membership.invite")}
            </LakeButton>
          </LakeTooltip>
        </Box>
      </RightPanel>

      <AddAdminConfirmationModal
        info={addAdminConfirmationModalInfo}
        onConfirm={membershipBulk =>
          saveMemberships(membershipBulk).tapOk(() =>
            setAddAdminConfirmationModalInfo(Option.None()),
          )
        }
        onCancel={() => setAddAdminConfirmationModalInfo(Option.None())}
      />
    </>
  );
};
