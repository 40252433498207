import { Link } from "@swan-io/lake/src/components/Link";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { useMemo } from "react";
import { StyleSheet } from "react-native";
import { useProjectInfo } from "../../hooks/useProjectInfo";
import { t } from "../../utils/i18n";
import { Router } from "../../utils/routes";

const styles = StyleSheet.create({
  item: {
    display: "flex",
  },
});

export const MerchantPaymentsRoot = () => {
  const { projectId } = useProjectInfo();

  const links: { link: string; title: string; description: string }[] = useMemo(() => {
    return [
      {
        link: Router.SandboxDevelopersSimulatorMerchantPaymentsCardsIncomingAuthorization({
          projectId,
        }),
        title: t("simulatorPage.merchantPayments.card"),
        description: t("simulatorPage.merchantPayments.card.description"),
      },
      {
        link: Router.SandboxDevelopersSimulatorMerchantPaymentsChecksGenerateTest({
          projectId,
        }),
        title: t("simulatorPage.merchantPayments.check"),
        description: t("simulatorPage.merchantPayments.check.description"),
      },
      {
        link: Router.SandboxDevelopersSimulatorMerchantPaymentsSepaDirectDebitIncomingBooking({
          projectId,
        }),
        title: t("simulatorPage.merchantPayments.sepaDirectDebit"),
        description: t("simulatorPage.merchantPayments.sepaDirectDebit.description"),
      },
      {
        link: Router.SandboxDevelopersSimulatorMerchantPaymentsInternalDirectDebitIncomingBooking({
          projectId,
        }),
        title: t("simulatorPage.merchantPayments.internalDirectDebit"),
        description: t("simulatorPage.merchantPayments.internalDirectDebit.description"),
      },
    ];
  }, [projectId]);

  return (
    <TileGrid>
      {links.map(item => {
        return (
          <Link to={item.link} style={styles.item} key={item.link}>
            {({ hovered }) => (
              <Tile
                flexGrow={1}
                title={item.title}
                description={item.description}
                hovered={hovered}
              />
            )}
          </Link>
        );
      })}
    </TileGrid>
  );
};
