import { Dict } from "@swan-io/boxed";
import { Location, encodeSearch, getLocation, subscribeToLocation } from "@swan-io/chicane";
import { identity } from "@swan-io/lake/src/utils/function";
import {
  emptyToUndefined,
  isNotEmpty,
  isNotNullish,
  isNullish,
  isNullishOrEmpty,
} from "@swan-io/lake/src/utils/nullish";
import { capitalize } from "@swan-io/lake/src/utils/string";
import { windowSize } from "@swan-io/lake/src/utils/windowSize";
import { ReactNode, createContext, useContext, useEffect, useMemo, useRef } from "react";
import { P, isMatching, match } from "ts-pattern";
import { ProjectEnv } from "../hooks/useProjectInfo";
import { Router, finitePaths, finiteRouteNames } from "./routes";

const API_URL = "https://swan.matomo.cloud/matomo.php";

const API_VERSION = "1"; // according to matomo documentation, currently always set to 1
const REC = "1"; // according to matomo documentation, must be set to 1
const SITE_ID = __env.CLIENT_MATOMO_SITE_ID;

type Session = {
  role: string;
  projectEnv: ProjectEnv | undefined;
  projectId: string;
};

let session: Session | undefined = undefined;

export const sendMatomoEvent = (
  event:
    | { type: "PageView" }
    | { type: "Action"; category: string; name: string }
    | { type: "Search"; category: string; count: number; filters: string },
) => {
  if (isNullishOrEmpty(SITE_ID) || isNullishOrEmpty(session)) {
    return; // only track production or development testing events
  }

  // defined in matomo dashboard as "environment"
  const dimension2 = session.projectEnv != null ? capitalize(session.projectEnv) : undefined;
  const route = Router.getRoute(finiteRouteNames);
  const { width, height } = windowSize.get();

  // https://developer.matomo.org/api-reference/tracking-api
  const params = {
    idsite: SITE_ID,
    rec: REC,
    apiv: API_VERSION,

    rand: Date.now().toString(), // random number to prevent caching
    res: `${width}x${height}`,

    action_name: match(event.type)
      .with("PageView", () => {
        const { activate, step } = getLocation().search;

        return isNullish(activate)
          ? "PageView"
          : typeof step !== "string"
            ? "PageView/Activate"
            : `PageView/Activate/${step}`;
      })
      .otherwise(identity),

    url:
      window.location.origin +
      match(route?.name)
        .with(P.string, name => finitePaths[name])
        .otherwise(() => ""),

    dimension1: session.role, // defined in matomo dashboard as "role"
    ...(dimension2 && { dimension2 }),
    dimension3: session.projectId, // defined in matomo dashboard as "projectId"

    ...match(event)
      .with({ type: "Action" }, event => ({
        ca: "1",
        e_c: event.category,
        e_a: event.type,
        e_n: event.name,
      }))
      .with({ type: "Search" }, event => ({
        search: event.filters,
        search_cat: event.category,
        search_count: event.count.toString(),
      }))
      .otherwise(() => ({})),
  };

  if ("sendBeacon" in navigator) {
    try {
      navigator.sendBeacon(API_URL + encodeSearch(params));
    } catch {}
  }
};

export const useSessionTracking = ({
  loaded,
  role,
  projectEnv,
  projectId,
}: { loaded: boolean } & Session) => {
  const lastPath = useRef<string>("");

  const stableSession = useMemo(
    () => (loaded ? { role, projectEnv, projectId } : undefined),
    [loaded, role, projectEnv, projectId],
  );

  useEffect(() => {
    session = stableSession;

    const onLocation = (location: Location) => {
      // if activate project is displayed, we always track page view because only query param changes
      const isActivateProjectDisplayed = isNotNullish(location.search.activate);
      const nextPath = location.raw.path;

      if (lastPath.current !== nextPath || isActivateProjectDisplayed) {
        lastPath.current = nextPath;
        sendMatomoEvent({ type: "PageView" });
      }
    };

    onLocation(getLocation()); // initial call
    const unsubscribe = subscribeToLocation(onLocation);

    return () => {
      unsubscribe();
      session = undefined;
    };
  }, [stableSession]);
};

const isUnusedFilter = isMatching(P.union(P.nullish, "", []));

export const useFiltersTracking = ({
  filters,
  loaded,
  totalCount,
}: {
  filters: Record<string, unknown>;
  loaded: boolean;
  totalCount: number;
}) => {
  const category = useTrackingCategory();

  // filters without values to avoid sending sensitive data as user name, IBAN, etc.
  const filtersStr = Dict.entries(filters)
    .filter(([_, value]) => isUnusedFilter(value))
    .map(([key]) => key)
    .join(", ");

  useEffect(() => {
    if (loaded && isNotEmpty(filtersStr)) {
      sendMatomoEvent({
        type: "Search",
        category,
        count: totalCount,
        filters: filtersStr,
      });
    }
  }, [loaded, category, totalCount, filtersStr]);
};

const TrackingCategoryContext = createContext<string>("");
export const useTrackingCategory = () => useContext(TrackingCategoryContext);

export const TrackingProvider = ({
  children,
  category,
}: {
  children: ReactNode;
  category: string;
}) => {
  const contextValue = useContext(TrackingCategoryContext);

  const merged = useMemo(
    () =>
      match({
        a: emptyToUndefined(contextValue.trim()),
        b: emptyToUndefined(category.trim()),
      })
        .with({ a: P.string, b: P.string }, ({ a, b }) => `${a} > ${b}`)
        .with({ a: P.string, b: P.nullish }, ({ a }) => a)
        .with({ a: P.nullish, b: P.string }, ({ b }) => b)
        .with({ a: P.nullish, b: P.nullish }, () => "Uncategorized")
        .exhaustive(),
    [contextValue, category],
  );

  return (
    <TrackingCategoryContext.Provider value={merged}>{children}</TrackingCategoryContext.Provider>
  );
};
