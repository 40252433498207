import { Image, Path, Rect, Svg, Text, Tspan } from "@swan-io/lake/src/components/Svg";
import { WithPartnerAccentColor } from "@swan-io/lake/src/components/WithPartnerAccentColor";
import { colors } from "@swan-io/lake/src/constants/design";
import { StyleProp, ViewStyle } from "react-native";

type Props = {
  projectLogo: string;
  accentColor: string;
  style: StyleProp<ViewStyle>;
};

export const PhonePreview = ({ accentColor, projectLogo, style }: Props) => {
  return (
    <WithPartnerAccentColor scoped={true} color={accentColor}>
      <Svg style={style} viewBox="0 0 334 650">
        {/* Next button */}
        <Rect width={118} height={28} x={175} y={587} fill={colors.partner.primary} rx={6} />

        <Text
          fontWeight="bold"
          fontFamily="Inter-Bold, Inter"
          fontSize="10"
          fill={colors.partner.contrast}
        >
          <Tspan x={222.5} y={605}>
            Next
          </Tspan>
        </Text>

        {/* Previous button */}
        <Rect width={119} height={27} x={41.5} y={587.5} stroke="#ABAAAD" fill="none" rx={5.5} />

        <Text fontWeight="bold" fontFamily="Inter-Bold, Inter" fontSize="10" fill="#5C5A5F">
          <Tspan x={80} y={605}>
            Previous
          </Tspan>
        </Text>

        {/* Fake content */}
        <Rect width={128} height={8} x={41} y={208} fill="#C7C7C8" rx={4} />
        <Rect width={34} height={8} x={41} y={224} fill="#EDEDED" rx={4} />
        <Rect width={124} height={8} x={83} y={224} fill="#EDEDED" rx={4} />
        <Rect width={20} height={8} x={215} y={224} fill="#EDEDED" rx={4} />
        <Rect width={58} height={8} x={41} y={240} fill="#EDEDED" rx={4} />
        <Rect width={36} height={8} x={107} y={240} fill="#EDEDED" rx={4} />
        <Rect width={118} height={8} x={151} y={240} fill="#EDEDED" rx={4} />
        <Rect width={252} height={112} x={41} y={274} fill="#fff" rx={4} />
        <Rect width={125.02} height={8} x={62.49} y={310} fill="#C7C7C8" rx={4} />

        <Rect
          width={207.05}
          height={27}
          x={62.99}
          y={326.5}
          fill="#fff"
          stroke="#E8E7E8"
          rx={3.5}
        />

        <Rect width={125} height={8} x={74} y={336} fill="#EDEDED" rx={4} />
        <Rect width={143} height={8} x={43} y={410} fill="#EDEDED" rx={4} />
        <Rect width={98} height={8} x={194} y={410} fill="#EDEDED" rx={4} />
        <Rect width={51} height={8} x={43} y={426} fill="#EDEDED" rx={4} />
        <Rect width={25} height={8} x={102} y={426} fill="#EDEDED" rx={4} />
        <Rect width={77} height={8} x={135} y={426} fill="#EDEDED" rx={4} />
        <Rect width={107} height={8} x={43} y={442} fill="#EDEDED" rx={4} />

        {/* Header */}
        <Path fill="#FAFAFA" d="M17 43A35 35 0 0 1 52 8h230a35 35 0 0 1 35 35v57H17V43Z" />
        <Path fill="#FAFAFA" stroke="#EDEDED" d="M17.5 100.5h299v57h-299z" />
        <Rect width={35} height={12} x={243} y={119} fill="#E8E7E8" rx={4} />
        <Rect width={252} height={18} x={41} y={68} fill="#EDEDED" rx={4} />
        <Rect width={54} height={10} x={41} y={40} fill="#EDEDED" rx={4} />
        <Rect width={18} height={10} x={275} y={40} fill="#EDEDED" rx={4} />
        <Rect width={18} height={10} x={247} y={40} fill="#EDEDED" rx={4} />
        <Path fill="#E8E7E8" d="M17 99h300v1H17z" />

        <Path
          fill="#737276"
          d="M140 45a5 5 0 0 1 5-5h26a5 5 0 0 1 0 10h-26a5 5 0 0 1-5-5Zm44 0a5 5 0 0 1 10 0 5 5 0 0 1-10 0Z"
        />

        <Path
          fill="#212121"
          d="M286.22 123.47c.3-.3.77-.3 1.06 0l2.72 2.72 2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25c-.3.3-.77.3-1.06 0l-3.25-3.25a.75.75 0 0 1 0-1.06Z"
        />

        {/* Logo */}
        <Image
          xlinkHref={projectLogo}
          preserveAspectRatio="xMinYMid meet"
          x={41}
          y={112}
          height={27}
          width={142}
        />

        {/* Phone frame */}
        <Path
          fill="#000"
          d="M278.84 0a51.37 51.37 0 0 1 51.38 51.4v133.77a3.78 3.78 0 0 1 3.78 3.78v72.56a3.78 3.78 0 0 1-3.78 3.78V598.6a51.4 51.4 0 0 1-51.38 51.4H53.65a51.37 51.37 0 0 1-51.38-51.4V276.34A3.78 3.78 0 0 1 0 272.85V229a3.78 3.78 0 0 1 2.27-3.47v-12.7A3.78 3.78 0 0 1 0 209.36v-43.84a3.78 3.78 0 0 1 2.27-3.47v-23.28A3.78 3.78 0 0 1 0 135.29v-18.14a3.78 3.78 0 0 1 2.27-3.48V51.4A51.4 51.4 0 0 1 53.65 0h225.19Zm0 15.87H53.65A34.76 34.76 0 0 0 18.9 50.03v548.58a34.77 34.77 0 0 0 34.16 34.76h225.79a34.76 34.76 0 0 0 34.76-34.16V50.64a34.75 34.75 0 0 0-34.76-34.77Z"
        />
      </Svg>
    </WithPartnerAccentColor>
  );
};
