import { Dict, Future, Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { emptyToUndefined, isNotNullish } from "@swan-io/lake/src/utils/nullish";
import {
  FilterCheckboxDef,
  FiltersStack,
  FiltersState,
} from "@swan-io/shared-business/src/components/Filters";
import { useEffect, useMemo, useState } from "react";
import { CapitalDepositCaseStatus } from "../graphql/partner";
import { formatCount, t } from "../utils/i18n";
import { TrackPressable } from "./TrackPressable";

const statusFilter: FilterCheckboxDef<CapitalDepositCaseStatus> = {
  type: "checkbox",
  label: t("accountHolder.type"),
  checkAllLabel: t("common.filters.all"),
  items: [
    { value: "Completed", label: t("capitalDeposit.type.completed") },
    { value: "Initiated", label: t("capitalDeposit.type.initiated") },
    { value: "WaitingForNotaryTransfer", label: t("capitalDeposit.type.waitingForNotaryTransfer") },
    {
      value: "WaitingForRegisterExtract",
      label: t("capitalDeposit.type.waitingForRegisterExtract"),
    },
    { value: "WaitingForRequirements", label: t("capitalDeposit.type.waitingForRequirements") },
    {
      value: "WaitingForShareDepositCertificate",
      label: t("capitalDeposit.type.waitingForShareDepositCertificate"),
    },
  ],
};

const filtersDefinition = {
  status: statusFilter,
};

type CapitalDepositFilters = FiltersState<typeof filtersDefinition>;

type CapitalDepositFiltersFormProps = {
  filters: CapitalDepositFilters;
  search: string | undefined;
  totalCount: Option<number>;
  onChangeFilters: (filters: CapitalDepositFilters) => void;
  onRefresh: () => Future<unknown>;
  onChangeSearch: (search: string | undefined) => void;
};

export const CapitalDepositFiltersForm = ({
  filters,
  search,
  totalCount,
  onChangeFilters,
  onRefresh,
  onChangeSearch,
}: CapitalDepositFiltersFormProps) => {
  const availableFilters: { name: keyof CapitalDepositFilters; label: string }[] = useMemo(
    () => [
      {
        name: "status",
        label: t("capitalDeposit.status"),
      },
    ],
    [],
  );

  const [openFilters, setOpenFilters] = useState(() =>
    Dict.entries(filters)
      .filter(([, value]) => isNotNullish(value))
      .map(([name]) => name),
  );

  useEffect(() => {
    setOpenFilters(openFilters => {
      const currentlyOpenFilters = new Set(openFilters);
      const openFiltersNotYetInState = Dict.entries(filters)
        .filter(([name, value]) => isNotNullish(value) && !currentlyOpenFilters.has(name))
        .map(([name]) => name);
      return [...openFilters, ...openFiltersNotYetInState];
    });
  }, [filters]);

  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <>
      <Box direction="row" alignItems="center">
        <FilterChooser
          filters={filters}
          openFilters={openFilters}
          label={t("common.filters")}
          onAddFilter={filter => setOpenFilters(openFilters => [...openFilters, filter])}
          availableFilters={availableFilters}
        />

        <Space width={16} />

        <TrackPressable action="Refresh capital deposit filters list">
          <LakeButton
            ariaLabel={t("common.refresh")}
            loading={isRefreshing}
            mode="secondary"
            size="small"
            icon="arrow-counterclockwise-filled"
            onPress={() => {
              setIsRefreshing(true);
              onRefresh().tap(() => setIsRefreshing(false));
            }}
          />
        </TrackPressable>

        <Fill minWidth={16} />

        <LakeSearchField
          placeholder={t("common.search")}
          initialValue={search ?? ""}
          onChangeText={text => onChangeSearch(emptyToUndefined(text))}
          renderEnd={() =>
            totalCount.map(count => <Tag color="partner">{formatCount(count)}</Tag>).toNull()
          }
        />
      </Box>

      <Space height={12} />

      <FiltersStack
        definition={filtersDefinition}
        filters={filters}
        openedFilters={openFilters}
        onChangeFilters={onChangeFilters}
        onChangeOpened={setOpenFilters}
      />
    </>
  );
};
