import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useFocusReset } from "@swan-io/chicane";
import { useQuery } from "@swan-io/graphql-client";
import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { TileGridPlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { GetAccountMembershipDocument } from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { NotFoundPage } from "../pages/NotFoundPage";
import { t } from "../utils/i18n";
import { Router, accountMembershipsDetailRoutes } from "../utils/routes";
import { AccountMembershipCards } from "./AccountMembershipCards";
import { AccountMembershipDetailGeneral } from "./AccountMembershipDetailGeneral";
import { ErrorView } from "./ErrorView";
import { Redirect } from "./Redirect";

type Props = {
  accountMembershipId: string;
};

const styles = StyleSheet.create({
  routeContainer: {
    flexGrow: 1,
  },
});

export const AccountMembershipDetail = ({ accountMembershipId }: Props) => {
  const { projectEnv, projectId } = useProjectInfo();
  const route = Router.useRoute(accountMembershipsDetailRoutes);
  const containerRef = useRef(null);

  useFocusReset({ route, containerRef });

  const [data] = useQuery(GetAccountMembershipDocument, {
    id: accountMembershipId,
    maxIdentifications: 8,
  });

  useCrumb(
    useMemo(
      () =>
        data
          .toOption()
          .flatMap(result => result.toOption())
          .flatMap(data => Option.fromNullable(data.accountMembership))
          .map(accountMembership => ({
            label: accountMembership.email,
            link: Router.AccountMembershipsDetailRoot({
              projectId,
              projectEnv,
              accountMembershipId,
            }),
          }))
          .toUndefined(),
      [projectId, accountMembershipId, projectEnv, data],
    ),
  );

  return (
    <>
      <TabView
        tabs={[
          {
            label: t("accountMembership.general"),
            url: Router.AccountMembershipsDetailRoot({
              projectId,
              projectEnv,
              accountMembershipId,
            }),
          },
          {
            label: t("accountMembership.cards"),
            url: Router.AccountMembershipsDetailCards({
              projectId,
              projectEnv,
              accountMembershipId,
            }),
          },
        ]}
        otherLabel={t("common.tabs.other")}
      />

      <Space height={24} />

      <View ref={containerRef} style={styles.routeContainer}>
        {match(route)
          .with({ name: "AccountMembershipsDetailRoot" }, () =>
            match(data)
              .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
                <TileGridPlaceholder withTabs={false} />
              ))
              .with(AsyncData.P.Done(Result.P.Error(P.select())), error => (
                <ErrorView error={error} />
              ))
              .with(AsyncData.P.Done(Result.P.Ok({ accountMembership: P.nullish })), () => (
                <Redirect to={Router.AccountMembershipsRoot({ projectId, projectEnv })} />
              ))
              .with(
                AsyncData.P.Done(Result.P.Ok({ accountMembership: P.select(P.nonNullable) })),
                accountMembership => (
                  <LakeScrollView>
                    <AccountMembershipDetailGeneral accountMembership={accountMembership} />
                  </LakeScrollView>
                ),
              )
              .exhaustive(),
          )
          .with({ name: "AccountMembershipsDetailCards" }, ({ params }) => (
            <AccountMembershipCards params={params} accountMembershipId={accountMembershipId} />
          ))
          .otherwise(() => (
            <NotFoundPage />
          ))}
      </View>
    </>
  );
};
