import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Space } from "@swan-io/lake/src/components/Space";
import { colors } from "@swan-io/lake/src/constants/design";
import { ReactNode } from "react";
import { StyleSheet, View } from "react-native";

const styles = StyleSheet.create({
  container: {
    marginTop: 8,
    padding: 16,
    width: 410,
  },
});

type Props = {
  title?: string;
  children: ReactNode;
};

export const PopoverWrapper = ({ title = "", children }: Props) => (
  <View style={styles.container}>
    {title !== "" && (
      <>
        <LakeText color={colors.gray[900]} variant="semibold">
          {title}
        </LakeText>

        <Space height={20} />
      </>
    )}

    {children}
  </View>
);
