import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { TileGridPlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { useMemo } from "react";
import { P, match } from "ts-pattern";
import { CardDetailsDigitalCards } from "../components/CardDetailsDigitalCards";
import { CardDetailsGeneral } from "../components/CardDetailsGeneral";
import { CardDetailsTransactions } from "../components/CardDetailsTransactions";
import { ErrorView } from "../components/ErrorView";
import { Redirect } from "../components/Redirect";
import { GetCardDetailsPageDocument, GetCardDetailsPageQuery } from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { NotFoundPage } from "../pages/NotFoundPage";
import { getCardHolderName } from "../utils/card";
import { t } from "../utils/i18n";
import { Router, cardDetailRoutes } from "../utils/routes";

type Props = {
  cardId: string;
};

export type Card = NonNullable<GetCardDetailsPageQuery["card"]>;

export const CardDetailsPage = ({ cardId }: Props) => {
  const { projectEnv, projectId } = useProjectInfo();
  const route = Router.useRoute(cardDetailRoutes);

  const [data, { reload }] = useQuery(GetCardDetailsPageDocument, { cardId });

  useCrumb(
    useMemo(
      () =>
        data
          .toOption()
          .flatMap(result => result.toOption())
          .flatMap(data => Option.fromNullable(data.card))
          .map(card => ({
            label:
              isNotNullish(card.type) && isNotNullish(card.accountMembership.account?.id)
                ? t("cards.breadcrumb.title", {
                    cardHolderName: getCardHolderName(card).getOr(""),
                  })
                : t("common.unknown"),

            link: Router.CardDetailRoot({ projectId, projectEnv, cardId }),
          }))
          .toUndefined(),
      [projectId, projectEnv, data, cardId],
    ),
  );

  return (
    <>
      <TabView
        tabs={[
          {
            label: t("cards.general"),
            url: Router.CardDetailRoot({ projectId, projectEnv, cardId }),
          },
          {
            label: t("cards.digitalCards"),
            url: Router.CardDetailDigitalCards({ projectId, projectEnv, cardId }),
          },
          {
            label: t("cards.transactions"),
            url: Router.CardDetailTransactions({ projectId, projectEnv, cardId }),
          },
        ]}
        otherLabel={t("common.tabs.other")}
      />

      <Space height={24} />

      {match(route)
        .with({ name: "CardDetailRoot" }, () =>
          match(data)
            .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
              <TileGridPlaceholder withTabs={false} />
            ))
            .with(AsyncData.P.Done(Result.P.Error(P.select())), error => (
              <ErrorView error={error} />
            ))
            .with(AsyncData.P.Done(Result.P.Ok({ card: P.nullish })), () => (
              <Redirect to={Router.CardRoot({ projectId, projectEnv })} />
            ))
            .with(AsyncData.P.Done(Result.P.Ok({ card: P.select(P.nonNullable) })), card => (
              <LakeScrollView>
                <CardDetailsGeneral
                  key="card-general"
                  reexecuteQuery={() => {
                    reload();
                  }}
                  card={card}
                />
              </LakeScrollView>
            ))
            .exhaustive(),
        )
        .with({ name: "CardDetailDigitalCards" }, ({ params }) => (
          <CardDetailsDigitalCards
            key="card-digital-cards"
            cardId={params.cardId}
            params={params}
          />
        ))
        .with({ name: "CardDetailTransactions" }, ({ params }) => (
          <CardDetailsTransactions cardId={cardId} params={params} />
        ))
        .otherwise(() => (
          <NotFoundPage />
        ))}
    </>
  );
};
