import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabelledCheckbox } from "@swan-io/lake/src/components/LakeCheckbox";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useForm } from "@swan-io/use-form";
import { electronicFormat } from "iban";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { DocumentationLink } from "../../components/DocumentationLink";
import { SimulatorResponses } from "../../components/SimulatorReponses";
import { TrackPressable } from "../../components/TrackPressable";
import { SimulateSctInReceptionDocument } from "../../graphql/sandbox-partner-admin";
import { t } from "../../utils/i18n";
import { validateIban, validateRequired } from "../../utils/validations";

const styles = StyleSheet.create({
  grid: {
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 1080,
  },
});

export const SctInReceptionSimulatorPage = () => {
  const [simulate, simulation] = useMutation(SimulateSctInReceptionDocument);

  const transactionResult = simulation.mapOkToResult(simulation =>
    match(simulation.response)
      .with(
        { __typename: "SimulateIncomingSepaCreditTransferReceptionSuccessPayload" },
        ({ transactionId }) => Result.Ok([{ key: "transactionId", value: transactionId }]),
      )
      .otherwise(({ __typename }) => Result.Error({ rejection: __typename })),
  );

  const { Field, formStatus, submitForm } = useForm({
    debtorName: {
      initialValue: "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
    creditorIban: {
      initialValue: "",
      validate: validateIban,
      sanitize: electronicFormat,
    },
    amount: {
      initialValue: "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.replace(/ /g, "").replace(/,/g, "."),
    },
    label: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    reference: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    isInstant: {
      initialValue: false,
      sanitize: value => value,
    },
  });

  const onSubmit = () => {
    submitForm({
      onSuccess: values => {
        const option = Option.allFromDict(values);

        if (option.isSome()) {
          const { amount, creditorIban, debtorName, label, reference, isInstant } = option.get();

          return simulate({
            input: {
              amount: { value: amount, currency: "EUR" },
              debtorIban: "FR2730003000706315734174B93",
              debtorName,
              creditorIban,
              creditorName: "Swan Customer Account",
              endToEndId: reference,
              label,
              isInstant,
            },
          });
        }
      },
    });
  };

  return (
    <Form style={commonStyles.fill}>
      <Tile
        description={
          <LakeText>
            {t("simulatorPage.receptionInDescription")}{" "}
            <DocumentationLink to="simulatorSepaCredit">{t("common.learnMore")}</DocumentationLink>
          </LakeText>
        }
      >
        <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
          <Field name="debtorName">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={`${t("simulatorForm.debtorName")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.debtorNamePlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="creditorIban">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={`${t("simulatorForm.creditorIban")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.creditorIbanPlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="amount">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={`${t("simulatorForm.amount")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.amountPlaceholder")}
                    inputMode="decimal"
                    unit="€"
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="reference">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.reference")}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.referencePlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="label">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.label")}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.labelPlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>
        </Grid>

        <Field name="isInstant">
          {({ value, onChange }) => (
            <LakeLabelledCheckbox
              value={value}
              onValueChange={onChange}
              label={t("simulatorForm.isInstant")}
            />
          )}
        </Field>
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Submit SCT in reception form">
          <LakeButton
            size="small"
            color="current"
            loading={formStatus === "submitting"}
            onPress={onSubmit}
          >
            {t("simulatorForm.submitButton")}
          </LakeButton>
        </TrackPressable>

        <Space width={12} />

        <SimulatorResponses
          fields={[
            {
              key: "transactionId",
              label: t("simulatorForm.transactionId"),
              placeholder: "-",
            },
          ]}
          results={transactionResult}
        />
      </Box>
    </Form>
  );
};
