import { useFocusReset } from "@swan-io/chicane";
import { Box } from "@swan-io/lake/src/components/Box";
import { Breadcrumbs, BreadcrumbsRoot } from "@swan-io/lake/src/components/Breadcrumbs";
import { Space } from "@swan-io/lake/src/components/Space";
import { useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { AccountListPage } from "../pages/AccountListPage";
import { NotFoundPage } from "../pages/NotFoundPage";
import { t } from "../utils/i18n";
import { Router, accountDetailRoutes, accountRoutes } from "../utils/routes";
import { AccountDetail } from "./AccountDetail";
import { LearnMore } from "./LearnMore";

const styles = StyleSheet.create({
  topBanner: {
    zIndex: 1,
  },
  container: {
    flexGrow: 1,
  },
});

export const AccountsArea = () => {
  const { projectId, projectEnv } = useProjectInfo();
  const route = Router.useRoute(accountRoutes);
  const detailRoutes = Router.useRoute(accountDetailRoutes);
  const containerRef = useRef(null);

  const rootLevelCrumbs = useMemo(
    () => [
      {
        label: t("accounts.all"),
        link: Router.AccountsRoot({ projectId, projectEnv }),
      },
    ],
    [projectEnv, projectId],
  );

  useFocusReset({ route, containerRef });

  return (
    <BreadcrumbsRoot rootLevelCrumbs={rootLevelCrumbs}>
      <Box
        direction="row"
        justifyContent="spaceBetween"
        alignItems="center"
        style={styles.topBanner}
      >
        <Breadcrumbs />

        {match(detailRoutes)
          .with(
            { name: "AccountDetailFundingSources" },
            { name: "AccountDetailFundingSourcesDetail" },
            () => <LearnMore link="fundingSource" description="fundingSources.subtitle" />,
          )
          .with(
            { name: "AccountDetailReceivedDirectDebitMandates" },
            { name: "AccountDetailReceivedDirectDebitMandatesDetail" },
            () => (
              <LearnMore
                link="receivedDirectDebitMandate"
                description="receivedDirectDebitMandates.account.subtitle"
              />
            ),
          )
          .otherwise(() => (
            <LearnMore link="account" description="accounts.subtitle" />
          ))}
      </Box>

      <Space height={16} />

      <View ref={containerRef} style={styles.container}>
        {match(route)
          .with({ name: "AccountsRoot" }, ({ params }) => <AccountListPage params={params} />)
          .with({ name: "AccountDetailArea" }, ({ params: { accountId } }) => (
            <AccountDetail accountId={accountId} />
          ))
          .otherwise(() => (
            <NotFoundPage />
          ))}
      </View>
    </BreadcrumbsRoot>
  );
};
