import { Box } from "@swan-io/lake/src/components/Box";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tick } from "@swan-io/lake/src/components/Tick";
import { ColorScale, colors } from "@swan-io/lake/src/constants/design";
import { match } from "ts-pattern";
import { t } from "../../utils/i18n";
import { Role } from "../../utils/permissions";

type TagProps = {
  color: ColorScale;
  text: string;
};
const Tag = ({ color, text }: TagProps) => (
  <Box direction="row" alignItems="center">
    <Tick color={color[500]} size="small" />
    <Space width={4} />

    <LakeText color={color[600]} variant="smallRegular">
      {text}
    </LakeText>
  </Box>
);

export const CriticityTag = ({ role }: { role: Role }) =>
  match<Role>(role)
    .with("SandboxDev", () => (
      <Tag color={colors.positive} text={t("membership.role.criticity.low")} />
    ))
    .with("Contributor", () => (
      <Tag color={colors.positive} text={t("membership.role.criticity.low")} />
    ))
    .with("Support", () => (
      <Tag color={colors.warning} text={t("membership.role.criticity.medium")} />
    ))
    .with("Manager", () => (
      <Tag color={colors.negative} text={t("membership.role.criticity.high")} />
    ))
    .with("Admin", () => (
      <Tag color={colors.negative} text={t("membership.role.criticity.critical")} />
    ))
    .exhaustive();
