import { useFocusReset } from "@swan-io/chicane";
import { Box } from "@swan-io/lake/src/components/Box";
import { Breadcrumbs, BreadcrumbsRoot } from "@swan-io/lake/src/components/Breadcrumbs";
import { InformationTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Space } from "@swan-io/lake/src/components/Space";
import { useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { CardDetailsPage } from "../pages/CardDetailsPage";
import { CardListPage } from "../pages/CardListPage";
import { NotFoundPage } from "../pages/NotFoundPage";
import { t } from "../utils/i18n";
import { Router, cardRoutes } from "../utils/routes";
import { LakeDocumentationLink } from "./DocumentationLink";

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
});

export const CardArea = () => {
  const { projectEnv, projectId } = useProjectInfo();
  const route = Router.useRoute(cardRoutes);
  const containerRef = useRef(null);

  const rootLevelCrumbs = useMemo(
    () => [
      {
        label: t("cards.all"),
        link: Router.CardRoot({ projectId, projectEnv }),
      },
    ],
    [projectEnv, projectId],
  );

  useFocusReset({ route, containerRef });

  return (
    <BreadcrumbsRoot rootLevelCrumbs={rootLevelCrumbs}>
      <Box direction="row" justifyContent="spaceBetween" alignItems="center">
        <Breadcrumbs />

        <Box direction="row" justifyContent="end" alignItems="center">
          <LakeDocumentationLink to="cards">{t("common.learnMore")}</LakeDocumentationLink>
          <Space width={16} />
          <InformationTooltip text={t("cards.subtitle")} />
        </Box>
      </Box>

      <Space height={16} />

      <View ref={containerRef} style={styles.container}>
        {match(route)
          .with({ name: "CardRoot" }, ({ params }) => <CardListPage params={params} />)
          .with({ name: "CardDetailArea" }, ({ params: { cardId } }) => (
            <CardDetailsPage cardId={cardId} />
          ))
          .otherwise(() => (
            <NotFoundPage />
          ))}
      </View>
    </BreadcrumbsRoot>
  );
};
