import { encodeSearch, pushUnsafe, useLocation } from "@swan-io/chicane";
import { FocusTrap } from "@swan-io/lake/src/components/FocusTrap";
import { TransitionView } from "@swan-io/lake/src/components/TransitionView";
import { WithCurrentColor } from "@swan-io/lake/src/components/WithCurrentColor";
import { animations, backgroundColor, spacings } from "@swan-io/lake/src/constants/design";
import { useEffect, useState } from "react";
import { Image, StyleSheet, View } from "react-native";
import building from "../assets/img/activate/building.svg";
import phone from "../assets/img/activate/phone.svg";
import rocket from "../assets/img/activate/rocket.svg";
import sign from "../assets/img/activate/sign.svg";
import { GetHomepageQuery, ProjectStatus } from "../graphql/admin";
import { ProjectActivationPage } from "../pages/ProjectActivationPage";

const BACKGROUND_COLOR = "rgba(0, 0, 0, 0.6)";

const styles = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
  },
  inert: {
    pointerEvents: "none",
  },
  overlayEnter: {
    animationKeyframes: {
      "0%": { opacity: 0 },
    },
    animationDuration: "200ms",
    animationTimingFunction: "ease-in-out",
  },
  overlayLeave: {
    animationKeyframes: {
      "100%": { opacity: 0 },
    },
    animationDuration: "200ms",
    animationTimingFunction: "ease-in-out",
  },
  fill: {
    ...StyleSheet.absoluteFillObject,
    animationFillMode: "forwards",
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: BACKGROUND_COLOR,
  },
  containerEnter: {
    animationKeyframes: {
      "0%": { opacity: 0, transform: "translateZ(0px) translateX(100px)" },
    },
    animationDuration: "500ms",
    animationTimingFunction: "ease-in-out",
  },
  containerLeave: {
    animationKeyframes: {
      "100%": { opacity: 0, transform: "translateZ(0px) translateX(100px)" },
    },
    animationDuration: "500ms",
    animationTimingFunction: "ease-in-out",
  },
  activateProjectContainer: {
    ...StyleSheet.absoluteFillObject,
    transform: "translateZ(0px)",
    flexDirection: "row",
    alignItems: "stretch",
  },
  contentsEnter: {
    animationKeyframes: {
      "0%": { transform: "translateZ(0px) translateX(100px)" },
    },
    animationDuration: "500ms",
    animationTimingFunction: "ease-in-out",
  },
  contentsLeave: {
    animationKeyframes: {
      "100%": { transform: "translateZ(0px) translateX(100px)" },
    },
    animationDuration: "500ms",
    animationTimingFunction: "ease-in-out",
  },
  contentsContainer: {
    flexGrow: 1,
    flexBasis: "70%",
    zIndex: 2,
    transform: "translateZ(0px)",
  },
  activateProjectContents: {
    backgroundColor: backgroundColor.default,
    flexGrow: 1,
  },
  activateProjectSidePicture: {
    width: "30%",
    backgroundColor: backgroundColor.accented,
    overflow: "hidden",
  },
  activateProjectSideGradient: {
    ...StyleSheet.absoluteFillObject,
    opacity: 0.12,
    backgroundImage:
      "linear-gradient(180deg, #8266C4 0%, rgba(217, 97, 81, 0.5) 70.75%), linear-gradient(90deg, #6240b5, #6240b500)",
    backgroundSize: "100% 100%, 50px 100%",
    backgroundRepeat: "no-repeat, no-repeat",
  },
  activateProjectSideImageContainer: {
    position: "absolute",
    top: spacings[32],
    left: spacings[32],
    right: spacings[32],
    bottom: spacings[32],
    transform: "translateZ(0px)",
  },
  activateProjectSideImage: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
});

type Props = {
  currentUser: GetHomepageQuery["user"];
  projectName?: string;
  visible: boolean;
  projectStatus: ProjectStatus;
  onProjectUpdate: () => void;
  contractSigned: boolean;
};

export const ActivateProjectLayer = ({
  currentUser,
  projectName,
  projectStatus,
  visible,
  onProjectUpdate,
  contractSigned,
}: Props) => {
  const { path, search } = useLocation();

  const [delayedVisible, setDelayedVisible] = useState(visible);

  useEffect(() => {
    setDelayedVisible(visible);
  }, [visible]);

  return (
    <View style={[styles.container, !visible && styles.inert]}>
      <TransitionView style={styles.fill} enter={styles.overlayEnter} leave={styles.overlayLeave}>
        {visible ? <View style={styles.overlay} /> : null}
      </TransitionView>

      <TransitionView
        style={styles.fill}
        enter={styles.containerEnter}
        leave={styles.containerLeave}
      >
        {delayedVisible ? (
          <FocusTrap
            focusLock={true}
            autoFocus={true}
            returnFocus={true}
            style={styles.activateProjectContainer}
          >
            <TransitionView
              style={styles.contentsContainer}
              enter={styles.contentsEnter}
              leave={styles.contentsLeave}
            >
              {visible ? (
                <View style={styles.activateProjectContents}>
                  <WithCurrentColor variant="live">
                    <ProjectActivationPage
                      projectName={projectName}
                      projectStatus={projectStatus}
                      onPressClose={() => {
                        const { activate, ...rest } = search;
                        const encoded = encodeSearch(rest);
                        pushUnsafe(`/${path.join("/")}${encoded}`);
                      }}
                      currentUser={currentUser ?? undefined}
                      onProjectUpdate={onProjectUpdate}
                      contractSigned={contractSigned}
                    />
                  </WithCurrentColor>
                </View>
              ) : null}
            </TransitionView>

            <View style={styles.activateProjectSidePicture}>
              <View style={styles.activateProjectSideGradient} />

              <TransitionView
                {...animations.fadeAndSlideInFromRight}
                style={styles.activateProjectSideImageContainer}
              >
                {search.step === "TalkToAnExpert" ? (
                  <Image
                    source={{
                      uri: phone,
                    }}
                    resizeMode="contain"
                    style={styles.activateProjectSideImage}
                  />
                ) : null}
              </TransitionView>

              <TransitionView
                {...animations.fadeAndSlideInFromRight}
                style={styles.activateProjectSideImageContainer}
              >
                {search.step === "OpenLiveAccount" ? (
                  <Image
                    source={{
                      uri: building,
                    }}
                    resizeMode="contain"
                    style={styles.activateProjectSideImage}
                  />
                ) : null}
              </TransitionView>

              <TransitionView
                {...animations.fadeAndSlideInFromRight}
                style={styles.activateProjectSideImageContainer}
              >
                {search.step === "ReviewAndSignContracts" ? (
                  <Image
                    source={{
                      uri: sign,
                    }}
                    resizeMode="contain"
                    style={styles.activateProjectSideImage}
                  />
                ) : null}
              </TransitionView>

              <TransitionView
                {...animations.fadeAndSlideInFromRight}
                style={styles.activateProjectSideImageContainer}
              >
                {search.step == null || search.step === "PlayWithSandbox" ? (
                  <Image
                    source={{
                      uri: rocket,
                    }}
                    resizeMode="contain"
                    style={styles.activateProjectSideImage}
                  />
                ) : null}
              </TransitionView>
            </View>
          </FocusTrap>
        ) : null}
      </TransitionView>
    </View>
  );
};
