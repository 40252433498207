import { SeverityLevel, captureException, init } from "@sentry/browser";
import { P, match } from "ts-pattern";
import { env } from "./env";

export { setUser as setSentryUser } from "@sentry/browser";

export const initSentry = () => {
  init({
    enabled: import.meta.env.PROD,
    release: env.VERSION,
    dsn: "https://a8d00197b4f04de8a0cafd65971ddded@o427297.ingest.sentry.io/5431827",
    normalizeDepth: 5,

    environment: match({
      dev: import.meta.env.DEV,
      url: env.DASHBOARD_URL,
    })
      .with({ dev: true }, () => "dev")
      .with({ url: P.string.includes("master") }, () => "master")
      .with({ url: P.string.includes("preprod") }, () => "preprod")
      .otherwise(() => "prod"),
  });
};

type Context = Partial<{
  level: SeverityLevel;
  tags: Record<string, string>;
  extra: Record<string, unknown>;
}>;

export const logFrontendError = (exception: Error, { extra, level, tags }: Context = {}) => {
  captureException(exception, { extra, level, tags });
};
