import { AsyncData, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { IconName } from "@swan-io/lake/src/components/Icon";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { TileGridPlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { useMemo } from "react";
import { P, match } from "ts-pattern";
import { ErrorView } from "../components/ErrorView";
import { SandboxOnlyView } from "../components/SandboxOnlyView";
import { ServerConsentCurrentTab } from "../components/ServerConsentCurrentTab";
import { ServerConsentDraftTab } from "../components/ServerConsentDraftTab";
import { ServerConsentPendingTab } from "../components/ServerConsentPendingTab";
import { ServerConsentDocument } from "../graphql/admin";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { NotFoundPage } from "./NotFoundPage";

type Tab = {
  url: string;
  icon: IconName;
  hidden: boolean;
  label: string;
};

export const ServerConsentLivePage = () => {
  const { projectId, projectEnv } = useProjectInfo();

  const [data] = useQuery(ServerConsentDocument, { projectId });

  const route = Router.useRoute([
    "DevelopersServerConsentDraft",
    "DevelopersServerConsentPending",
    "DevelopersServerConsentCurrent",
  ]);

  const tabs = useMemo<Tab[]>(() => {
    return [
      {
        url: Router.DevelopersServerConsentDraft({ projectId, projectEnv }),
        label: t("serverConsent.tab.draft"),
        icon:
          route?.name === "DevelopersServerConsentDraft"
            ? ("edit-filled" as const)
            : ("edit-regular" as const),
        hidden: false,
      },
      {
        url: Router.DevelopersServerConsentPending({ projectId, projectEnv }),
        icon:
          route?.name === "DevelopersServerConsentPending"
            ? ("clock-filled" as const)
            : ("clock-regular" as const),
        label: t("serverConsent.tab.pending"),
        hidden: false,
      },
      {
        url: Router.DevelopersServerConsentCurrent({ projectId, projectEnv }),
        icon:
          route?.name === "DevelopersServerConsentCurrent"
            ? ("live-filled" as const)
            : ("live-regular" as const),
        label: t("serverConsent.tab.current"),
        hidden: false,
      },
    ].filter(tab => !tab.hidden);
  }, [projectId, projectEnv, route?.name]);

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <TileGridPlaceholder />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok({ project: { allowsServerConsent: true } })), () => (
      <>
        <TabView hideIfSingleItem={false} tabs={tabs} otherLabel={t("common.tabs.other")} />
        <Space height={20} />

        {match(route?.name)
          .with("DevelopersServerConsentDraft", () => <ServerConsentDraftTab />)
          .with("DevelopersServerConsentPending", () => <ServerConsentPendingTab />)
          .with("DevelopersServerConsentCurrent", () => <ServerConsentCurrentTab />)
          .with(P.nullish, () => <NotFoundPage />)
          .exhaustive()}
      </>
    ))
    .otherwise(() => (
      <SandboxOnlyView
        icon="window-dev-tools-regular"
        onSelect={() =>
          Router.push("DevelopersServerConsentDraft", { projectId, projectEnv: "sandbox" })
        }
        customText={t("serverConsent.featureNotEnabled")}
      />
    ));
};
