export const roles = ["SandboxDev", "Contributor", "Support", "Manager", "Admin"] as const;

export const rights = [
  "Data",
  "Tools",
  "Settings",
  "Insights",
  "ToolsViewOnly",
  "SettingsViewOnly",
  "SensitiveOperations",
] as const;

export type Role = (typeof roles)[number];

export type Right = (typeof rights)[number];

export const commonRights = ["Data", "Tools", "Settings", "Insights"] satisfies Right[];
