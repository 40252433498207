import { Array, AsyncData, Dict, Future, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  LinkConfig,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import { identity } from "@swan-io/lake/src/utils/function";
import {
  emptyToUndefined,
  isNotNullish,
  nullishOrEmptyToUndefined,
} from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import {
  FilterCheckboxDef,
  FiltersStack,
  FiltersState,
} from "@swan-io/shared-business/src/components/Filters";
import { useCallback, useEffect, useMemo, useState } from "react";
import { P, match } from "ts-pattern";
import { AccountList, ExtraInfo } from "../components/AccountList";
import { Connection } from "../components/Connection";
import { ErrorView } from "../components/ErrorView";
import { TrackPressable } from "../components/TrackPressable";
import {
  AccountListFragment,
  AccountOrderByFieldInput,
  AccountOrderByInput,
  AccountStatus,
  GetAccountsDocument,
  OrderByDirection,
  PaymentLevel,
} from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { formatCount, t } from "../utils/i18n";
import { useFiltersTracking } from "../utils/matomo";
import { RouteParams, Router } from "../utils/routes";

type Props = {
  params: RouteParams["AccountsRoot"];
};

const paymentLevelsList = ["Unlimited", "Limited"] as const;
const statusList = ["Opened", "Suspended", "Closing", "Closed"] as const;

const PER_PAGE = 20;

export const AccountListPage = ({ params }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const filters: AccountFilters = useMemo(() => {
    return {
      paymentLevels: isNotNullish(params.paymentLevels)
        ? Array.filterMap(params.paymentLevels, item =>
            match(item)
              .with(...paymentLevelsList, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,
      status: isNotNullish(params.status)
        ? Array.filterMap(params.status, item =>
            match(item)
              .with(...statusList, value => Option.Some(value))
              .otherwise(() => Option.None()),
          )
        : undefined,
    } as const;
  }, [params.paymentLevels, params.status]);

  const sortBy: AccountOrderByInput = useMemo(() => {
    return {
      field: match(params.sortBy)
        .returnType<AccountOrderByFieldInput>()
        .with("createdAt", "updatedAt", identity)
        .otherwise(() => "createdAt"),
      direction: match(params.direction)
        .returnType<OrderByDirection>()
        .with("Asc", "Desc", identity)
        .otherwise(() => "Desc"),
    };
  }, [params.sortBy, params.direction]);

  const search = nullishOrEmptyToUndefined(params.search);
  const hasSearchOrFilters = isNotNullish(search) || Object.values(filters).some(isNotNullish);

  const [data, { isLoading, reload, setVariables }] = useQuery(GetAccountsDocument, {
    first: PER_PAGE,
    filters: { ...filters, search },
    orderBy: sortBy,
  });

  const extraInfo: ExtraInfo = useMemo(() => {
    return {
      projectEnv,
      projectId,
      onChangeSort: ({ field, direction }) => {
        Router.push("AccountsRoot", {
          ...params,
          sortBy: field ?? undefined,
          direction: direction ?? undefined,
        });
      },
      sortBy,
      reexecuteQuery: reload,
    };
  }, [projectEnv, projectId, reload, sortBy, params]);

  const getRowLink = useCallback(
    ({
      item: {
        node: { id },
      },
      extraInfo: { projectEnv, projectId },
    }: LinkConfig<GetEdge<AccountListFragment>, ExtraInfo>) => (
      <Link to={Router.AccountDetailRoot({ projectId, projectEnv, accountId: id })} />
    ),
    [],
  );

  const accounts = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ accounts }) => accounts);

  const totalCount = accounts.map(({ totalCount }) => totalCount);

  useFiltersTracking({
    filters,
    totalCount: totalCount.getOr(0),
    loaded: data.isDone(),
  });

  return (
    <>
      <AccountFiltersForm
        filters={filters}
        search={search}
        totalCount={totalCount}
        onRefresh={reload}
        onChangeFilters={filters => {
          Router.replace("AccountsRoot", { ...params, ...filters });
        }}
        onChangeSearch={search => {
          Router.replace("AccountsRoot", { ...params, search });
        }}
      />

      <Space height={8} />

      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <VirtualizedListPlaceholder
            headerHeight={48}
            rowHeight={48}
            count={PER_PAGE}
            marginHorizontal={negativeSpacings[24]}
          />
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data.accounts}>
            {accounts => (
              <AccountList
                accounts={accounts.edges}
                hasSearchOrFilters={hasSearchOrFilters}
                onEndReached={() => {
                  if (accounts.pageInfo.hasNextPage === true) {
                    setVariables({ after: accounts.pageInfo.endCursor ?? undefined });
                  }
                }}
                isLoading={isLoading}
                perPage={PER_PAGE}
                extraInfo={extraInfo}
                emptyListTitle={t("accounts.list.empty.title")}
                getRowLink={getRowLink}
              />
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};

const paymentLevelFilter: FilterCheckboxDef<PaymentLevel> = {
  type: "checkbox",
  label: t("account.paymentLevel"),
  checkAllLabel: t("common.filters.all"),
  items: [
    { value: "Unlimited", label: t("accounts.filters.paymentLevels.unlimited") },
    { value: "Limited", label: t("accounts.filters.paymentLevels.limited") },
  ],
};

const statusFilter: FilterCheckboxDef<AccountStatus> = {
  type: "checkbox",
  label: t("account.status"),
  checkAllLabel: t("common.filters.all"),
  items: [
    { value: "Opened", label: t("account.status.opened") },
    { value: "Suspended", label: t("account.status.suspended") },
    { value: "Closed", label: t("account.status.closed") },
    { value: "Closing", label: t("account.status.closing") },
  ],
};

const filtersDefinition = {
  paymentLevels: paymentLevelFilter,
  status: statusFilter,
};

type AccountFilters = FiltersState<typeof filtersDefinition>;

type AccountFiltersFormProps = {
  filters: AccountFilters;
  search: string | undefined;
  totalCount: Option<number>;
  onChangeFilters: (filters: AccountFilters) => void;
  onRefresh: () => Future<unknown>;
  onChangeSearch: (search: string | undefined) => void;
};

const AccountFiltersForm = ({
  filters,
  search,
  totalCount,
  onChangeFilters,
  onRefresh,
  onChangeSearch,
}: AccountFiltersFormProps) => {
  const availableFilters: { name: keyof AccountFilters; label: string }[] = useMemo(
    () => [
      {
        name: "paymentLevels",
        label: t("account.paymentLevel"),
      },
      {
        name: "status",
        label: t("account.status"),
      },
    ],
    [],
  );

  const [openFilters, setOpenFilters] = useState(() =>
    Dict.entries(filters)
      .filter(([, value]) => isNotNullish(value))
      .map(([name]) => name),
  );

  useEffect(() => {
    setOpenFilters(openFilters => {
      const currentlyOpenFilters = new Set(openFilters);
      const openFiltersNotYetInState = Dict.entries(filters)
        .filter(([name, value]) => isNotNullish(value) && !currentlyOpenFilters.has(name))
        .map(([name]) => name);
      return [...openFilters, ...openFiltersNotYetInState];
    });
  }, [filters]);

  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <>
      <Box direction="row" alignItems="center">
        <FilterChooser
          filters={filters}
          openFilters={openFilters}
          label={t("common.filters")}
          onAddFilter={filter => setOpenFilters(openFilters => [...openFilters, filter])}
          availableFilters={availableFilters}
        />

        <Space width={16} />

        <TrackPressable action="Refresh accounts list">
          <LakeButton
            ariaLabel={t("common.refresh")}
            mode="secondary"
            size="small"
            icon="arrow-counterclockwise-filled"
            loading={isRefreshing}
            onPress={() => {
              setIsRefreshing(true);
              onRefresh().tap(() => setIsRefreshing(false));
            }}
          />
        </TrackPressable>

        <Fill minWidth={16} />

        <LakeSearchField
          placeholder={t("common.search")}
          initialValue={search ?? ""}
          onChangeText={text => onChangeSearch(emptyToUndefined(text))}
          renderEnd={() =>
            totalCount.map(count => <Tag color="partner">{formatCount(count)}</Tag>).toNull()
          }
        />
      </Box>

      <Space height={12} />

      <FiltersStack
        definition={filtersDefinition}
        filters={filters}
        openedFilters={openFilters}
        onChangeFilters={onChangeFilters}
        onChangeOpened={setOpenFilters}
      />
    </>
  );
};
