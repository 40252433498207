import { Box } from "@swan-io/lake/src/components/Box";
import { Icon, IconName } from "@swan-io/lake/src/components/Icon";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { colors, spacings } from "@swan-io/lake/src/constants/design";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { Except } from "type-fest";
import { AccountMembershipPermissionsFragment } from "../graphql/partner";
import { t } from "../utils/i18n";

const styles = StyleSheet.create({
  rightsIcon: {
    marginHorizontal: spacings[4],
  },
  separator: {
    width: 1,
    alignSelf: "stretch",
    backgroundColor: colors.gray[200],
    marginHorizontal: spacings[4],
  },
  permissionsContainer: {
    padding: 4,
  },
});

type Props = {
  accountMembership: Except<AccountMembershipPermissionsFragment, "__typename">;
};

export const MembershipPermissionsTag = ({ accountMembership }: Props) => {
  const hasSomeRights = match(accountMembership)
    .with(
      { canViewAccount: true },
      { canInitiatePayments: true },
      { canManageBeneficiaries: true },
      { canManageAccountMembership: true },
      () => true,
    )
    .otherwise(() => false);

  const hasSomeCards = accountMembership.activeCards.totalCount > 0;

  return (
    <LakeTooltip
      placement="left"
      content={
        <View style={styles.permissionsContainer}>
          <PermissionLine
            iconName="eye-regular"
            isAuthorized={accountMembership.canViewAccount}
            authorizedMessage={t("members.permission.canViewAccount")}
            notAuthorizedMessage={t("members.permission.not.canViewAccount")}
          />

          <Space height={4} />

          <PermissionLine
            iconName="arrow-swap-regular"
            isAuthorized={accountMembership.canInitiatePayments}
            authorizedMessage={t("members.permission.canInitiatePayments")}
            notAuthorizedMessage={t("members.permission.not.canInitiatePayments")}
          />

          <Space height={4} />

          <PermissionLine
            iconName="person-add-regular"
            isAuthorized={accountMembership.canManageBeneficiaries}
            authorizedMessage={t("members.permission.canManageBeneficiaries")}
            notAuthorizedMessage={t("members.permission.not.canManageBeneficiaries")}
          />

          <Space height={4} />

          <PermissionLine
            iconName="settings-regular"
            isAuthorized={accountMembership.canManageAccountMembership}
            authorizedMessage={t("members.permission.canManageAccountMembership")}
            notAuthorizedMessage={t("members.permission.not.canManageAccountMembership")}
          />

          <Space height={4} />

          <PermissionLine
            iconName="payment-regular"
            isAuthorized={accountMembership.activeCards.totalCount > 0}
            authorizedMessage={t("members.permission.hasCard", {
              count: accountMembership.activeCards.totalCount,
            })}
            notAuthorizedMessage={t("members.permission.hasCard", {
              count: accountMembership.activeCards.totalCount,
            })}
          />
        </View>
      }
    >
      {match({ hasSomeRights, hasSomeCards })
        .with({ hasSomeRights: true }, { hasSomeCards: true }, () => (
          <Tag color="gray">
            <>
              {accountMembership.canViewAccount ? (
                <Icon
                  name="eye-regular"
                  size={16}
                  color={colors.swan[500]}
                  style={styles.rightsIcon}
                />
              ) : null}

              {accountMembership.canInitiatePayments ? (
                <Icon
                  name="arrow-swap-regular"
                  size={16}
                  color={colors.swan[500]}
                  style={styles.rightsIcon}
                />
              ) : null}

              {accountMembership.canManageBeneficiaries ? (
                <Icon
                  name="person-add-regular"
                  size={16}
                  color={colors.swan[500]}
                  style={styles.rightsIcon}
                />
              ) : null}

              {accountMembership.canManageAccountMembership ? (
                <Icon
                  name="settings-regular"
                  size={16}
                  color={colors.swan[500]}
                  style={styles.rightsIcon}
                />
              ) : null}

              {hasSomeRights && hasSomeCards ? <View style={styles.separator} /> : null}

              {hasSomeCards ? (
                <>
                  <Icon
                    name="payment-regular"
                    size={16}
                    color={colors.swan[500]}
                    style={styles.rightsIcon}
                  />

                  <LakeText color={colors.swan[500]} variant="smallRegular">
                    {accountMembership.activeCards.totalCount}
                  </LakeText>
                </>
              ) : null}
            </>
          </Tag>
        ))
        .otherwise(() => null)}
    </LakeTooltip>
  );
};

type PermissionLineProps = {
  iconName: IconName;
  isAuthorized: boolean;
  authorizedMessage: string;
  notAuthorizedMessage: string;
};

const PermissionLine = ({
  iconName,
  isAuthorized,
  authorizedMessage,
  notAuthorizedMessage,
}: PermissionLineProps) => (
  <Box direction="row" alignItems="center">
    <Icon
      name={iconName}
      size={20}
      color={isAuthorized ? colors.gray.contrast : colors.gray[500]}
    />

    <Space width={8} />

    <LakeText variant="smallRegular" color={isAuthorized ? colors.gray.contrast : colors.gray[500]}>
      {isAuthorized ? authorizedMessage : notAuthorizedMessage}
    </LakeText>
  </Box>
);
