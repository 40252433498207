import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabelledCheckbox } from "@swan-io/lake/src/components/LakeCheckbox";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors, radii } from "@swan-io/lake/src/constants/design";
import { deriveUnion } from "@swan-io/lake/src/utils/function";
import {
  emptyToUndefined,
  isNullishOrEmpty,
  nullishOrEmptyToUndefined,
} from "@swan-io/lake/src/utils/nullish";
import { countries as countryList } from "@swan-io/shared-business/src/constants/countries";
import { toOptionalValidator, useForm } from "@swan-io/use-form";
import dayjs from "dayjs";
import { useMemo } from "react";
import { StyleSheet } from "react-native";
import { Rifm } from "rifm";
import { match } from "ts-pattern";
import { DocumentationLink } from "../../components/DocumentationLink";
import { SimulatorResponses } from "../../components/SimulatorReponses";
import { TrackPressable } from "../../components/TrackPressable";
import { getCurrencies } from "../../constants/currencies";
import {
  CardAuthorizationOutcome,
  CardAuthorizationType,
  CardTransactionCategory,
  SimulateCardOutAuthorizationDocument,
  SimulationCardType,
} from "../../graphql/sandbox-partner-admin";
import { locale, rifmDateProps, rifmTimeProps, t } from "../../utils/i18n";
import { validateDate, validateRequired, validateTime } from "../../utils/validations";

const styles = StyleSheet.create({
  grid: {
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 1080,
  },
  optionFlag: {
    display: "inline-block",
    width: 21,
  },
  optionCurrencySymbol: {
    width: "3rem",
    backgroundColor: colors.gray[50],
    borderRadius: radii[4],
    display: "inline-block",
    textAlign: "center",
    boxShadow: `inset 0 0 0 1px ${colors.gray[100]}`,
  },
});

type FormValues = {
  cardId: string;
  amount: string;
  terminalId: string;
  merchantId: string;
  merchantCode: string;
  merchantName: string;
  merchantCity: string;
  merchantCountry: string;
  merchantPostalCode: string;
  cardTransactionCategory: CardTransactionCategory;
  originalAmount: string;
  originalCurrency: string | undefined;
  authorizationType: CardAuthorizationType;
  outcome: OptionalCardAuthorizationOutcome;
  cardType: SimulationCardType;
  expirationDate: string;
  expirationTime: string;
  allowsPartialAuthorization: boolean;
};

type OptionalCardAuthorizationOutcome = "" | CardAuthorizationOutcome;

export const CardOutAuthorizationSimulatorPage = () => {
  const [simulate, simulation] = useMutation(SimulateCardOutAuthorizationDocument);

  const outcomes = useMemo(() => {
    return deriveUnion<CardAuthorizationOutcome>({
      Accepted: true,
      AccountClosed: true,
      AccountSuspended: true,
      AmountInvalid: true,
      AtmWithdrawalAmountLimitExceeded: true,
      AtmWithdrawalNumberLimitExceeded: true,
      CanceledByCardHolder: true,
      CardExpired: true,
      CardHolderVerificationMethodMissing: true,
      CardNotActivated: true,
      CardNumberInvalid: true,
      CardOutOfOrder: true,
      CardPermanentlyBlocked: true,
      CardSuspended: true,
      CardUnknown: true,
      ChipCryptogramControlRefusal: true,
      ContactlessAmountLimitExceeded: true,
      ContactlessCumulativeAmountLimitExceeded: true,
      DigitalCardDeactivated: true,
      DigitalCardEnrollmentInvalid: true,
      DigitalCardRefusal: true,
      DigitalCardSuspended: true,
      DigitalCardTokenInvalid: true,
      DomesticTransactionNotAllowed: true,
      DoNotHonor: true,
      FraudRossAndersonRefusal: true,
      InconsistentEmvAmount: true,
      InPersonTransactionsNotAuthorized: true,
      InvalidAuthorizationResponse: true,
      InvalidExpirationDate: true,
      InvalidPinAttemptsExceeded: true,
      InvalidSecurityNumber: true,
      MagstripeNotSupported: true,
      MerchantNotFound: true,
      MerchantShouldResubmitAuthorization: true,
      MiscellaneousReason: true,
      MissingExpirationDate: true,
      PartialApproval: true,
      PartialCancelation: true,
      PinInvalid: true,
      PinRequired: true,
      SchemeAccountConversionError: true,
      SchemeStandInRefusal: true,
      SchemeTransactionBlockingRefusal: true,
      SecurityViolation: true,
      SoftDecline: true,
      SwanTechnicalErrorOccurred: true,
      SwanTimeout: true,
      TerminalVerificationResultsInvalid: true,
      ThreeDsError: true,
      TransactionAmountLimitExceeded: true,
      TransactionCurrencyIncorrect: true,
      TransactionDuplicated: true,
      TransactionInvalid: true,
      TransactionNotAllowed: true,
      TransactionNotAuthorizedForCardHolder: true,
      UnableToDetectTransactionEnvironment: true,
    }).array.map(name => ({ name, value: name }));
  }, []);

  const fields = [
    {
      key: "transactionId",
      label: t("simulatorForm.transactionId"),
      placeholder: "-",
    },
    {
      key: "rejectedReason",
      label: t("simulatorForm.rejectedReason"),
    },
  ];

  const results = simulation.mapOkToResult(simulation =>
    match(simulation.response)
      .with(
        { __typename: "SimulateOutgoingCardAuthorizationSuccessPayload" },
        ({ transactionId }) => Result.Ok([{ key: "transactionId", value: transactionId }]),
      )
      .with(
        { __typename: "SimulateOutgoingCardAuthorizationRejectPayload" },
        ({ rejectedReason, transactionId }) =>
          Result.Ok([
            { key: "transactionId", value: transactionId },
            { key: "rejectedReason", value: rejectedReason },
          ]),
      )
      .otherwise(({ __typename }) => Result.Error({ rejection: __typename })),
  );

  const { Field, submitForm, formStatus } = useForm<FormValues>({
    cardId: {
      initialValue: "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
    amount: {
      initialValue: "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.replace(/ /g, "").replace(/,/g, "."),
    },
    terminalId: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    merchantId: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    merchantCode: {
      initialValue: "",
      validate: value => {
        if ((value.match(/\d+/g) ?? []).join("") !== value) {
          return t("common.form.invalidMerchantCode");
        }
      },
      sanitize: value => value.trim(),
    },
    merchantName: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    merchantCity: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    merchantCountry: {
      initialValue: "",
      strategy: "onBlur",
    },
    merchantPostalCode: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    cardTransactionCategory: {
      initialValue: "InStore",
      strategy: "onBlur",
    },
    originalAmount: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.replace(/ /g, "").replace(/,/g, "."),
    },
    originalCurrency: {
      initialValue: undefined,
    },
    authorizationType: {
      initialValue: "Classic",
      strategy: "onBlur",
    },
    outcome: {
      initialValue: "",
      strategy: "onBlur",
    },
    cardType: {
      initialValue: "Virtual",
      strategy: "onBlur",
    },
    expirationDate: {
      initialValue: "",
      validate: toOptionalValidator(validateDate),
    },
    expirationTime: {
      initialValue: "",
      validate: toOptionalValidator(validateTime),
    },
    allowsPartialAuthorization: {
      initialValue: false,
    },
  });

  const onSubmit = () =>
    submitForm({
      onSuccess: values =>
        Option.allFromDict(values)
          .map(
            ({
              amount,
              originalAmount,
              originalCurrency,
              terminalId,
              merchantId,
              merchantCode,
              merchantName,
              merchantCity,
              merchantCountry,
              merchantPostalCode,
              outcome,
              expirationDate,
              expirationTime,
              allowsPartialAuthorization,
              ...input
            }) => {
              const currency = "EUR";

              return simulate({
                input: {
                  ...input,
                  amount: { value: amount, currency },
                  originalAmount: {
                    value: emptyToUndefined(originalAmount) ?? amount,
                    currency: nullishOrEmptyToUndefined(originalCurrency) ?? currency,
                  },
                  terminalId: emptyToUndefined(terminalId),
                  merchant: {
                    id: emptyToUndefined(merchantId),
                    code: emptyToUndefined(merchantCode),
                    name: emptyToUndefined(merchantName),
                    city: emptyToUndefined(merchantCity),
                    country: emptyToUndefined(merchantCountry),
                    postalCode: emptyToUndefined(merchantPostalCode),
                  },
                  outcome: nullishOrEmptyToUndefined(outcome),
                  expirationDateTime:
                    isNullishOrEmpty(expirationDate) || isNullishOrEmpty(expirationTime)
                      ? undefined
                      : dayjs(
                          `${expirationDate} ${expirationTime}`,
                          `${locale.dateFormat} ${locale.timeFormat}`,
                        ).toISOString(),
                  allowsPartialAuthorization,
                },
              });
            },
          )
          .toUndefined(),
    });

  const cardTransactionCategories = useMemo(() => {
    return deriveUnion<CardTransactionCategory>({
      InStore: true,
      eCommerce: true,
      eCommerceWith3DS: true,
      Withdrawal: true,
      Other: true,
    }).array.map(value => ({ name: value, value }));
  }, []);

  const cardAuthorizationTypes = useMemo(() => {
    return deriveUnion<CardAuthorizationType>({
      Classic: true,
      PreAuthorization: true,
      DataRequest: true,
    }).array.map(value => ({ name: value, value }));
  }, []);

  const cardTypes = useMemo(() => {
    return deriveUnion<SimulationCardType>({
      Virtual: true,
      Physical: true,
    }).array.map(value => ({ name: value, value }));
  }, []);

  const countries = useMemo(
    () => [
      {
        name: t("common.none"),
        icon: (
          <LakeText color={colors.gray[900]} style={styles.optionFlag}>
            {null}
          </LakeText>
        ),
        value: "",
      },
      ...countryList.map(country => ({
        name: country.name,
        icon: (
          <LakeText color={colors.gray[900]} style={styles.optionFlag}>
            {country.flag}
          </LakeText>
        ),
        value: country.cca3,
      })),
    ],
    [],
  );

  const currencies = useMemo(
    () => [
      { name: t("common.none"), icon: <></>, value: undefined },
      ...getCurrencies().map(currency => ({
        name: currency.name,
        icon: (
          <LakeText
            color={colors.gray[700]}
            variant="smallRegular"
            style={styles.optionCurrencySymbol}
          >
            {currency.symbol}
          </LakeText>
        ),
        value: currency.code,
      })),
    ],
    [],
  );

  return (
    <Form style={commonStyles.fill}>
      <Tile
        description={
          <LakeText>
            {t("simulatorPage.authorizeCardOutDescription")}{" "}
            <DocumentationLink to="simulatorCard">{t("common.learnMore")}</DocumentationLink>
          </LakeText>
        }
      >
        <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
          <Field name="cardId">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={`${t("simulatorForm.cardId")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.cardIdPlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="amount">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={`${t("simulatorForm.amount")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.amountPlaceholder")}
                    inputMode="decimal"
                    unit="€"
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="originalAmount">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.originalAmount")}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.originalAmountPlaceholder")}
                    inputMode="decimal"
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="originalCurrency">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.originalCurrency")}
                render={id => (
                  <LakeSelect id={id} value={value} items={currencies} onValueChange={onChange} />
                )}
              />
            )}
          </Field>

          <Field name="terminalId">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.terminalId")}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.terminalIdPlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="merchantId">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.merchantId")}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.merchantIdPlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="merchantCode">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.merchantCode")}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.merchantCodePlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="merchantName">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.merchantName")}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.merchantNamePlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="merchantCity">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.merchantCity")}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.merchantCityPlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="merchantPostalCode">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.merchantPostalCode")}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.merchantPostalCodePlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="merchantCountry">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.merchantCountry")}
                render={id => (
                  <LakeSelect id={id} value={value} items={countries} onValueChange={onChange} />
                )}
              />
            )}
          </Field>

          <Field name="cardTransactionCategory">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.cardTransactionCategory")}
                render={id => (
                  <LakeSelect
                    id={id}
                    value={value}
                    items={cardTransactionCategories}
                    onValueChange={onChange}
                  />
                )}
              />
            )}
          </Field>

          <Field name="authorizationType">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.cardAuthorizationType")}
                render={id => (
                  <LakeSelect
                    id={id}
                    value={value}
                    items={cardAuthorizationTypes}
                    onValueChange={onChange}
                  />
                )}
              />
            )}
          </Field>

          <Field name="outcome">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.cardAuthorizationOutcome")}
                render={id => (
                  <LakeSelect
                    id={id}
                    value={value}
                    items={[{ name: t("common.none"), value: "" }, ...outcomes]}
                    onValueChange={onChange}
                  />
                )}
              />
            )}
          </Field>

          <Field name="cardType">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.cardType")}
                render={id => (
                  <LakeSelect id={id} value={value} items={cardTypes} onValueChange={onChange} />
                )}
              />
            )}
          </Field>

          <Field name="expirationDate">
            {({ value, error, valid, onChange, onBlur }) => (
              <Rifm value={value} onChange={onChange} {...rifmDateProps}>
                {({ value, onChange }) => (
                  <LakeLabel
                    label={t("simulatorForm.expirationDate")}
                    render={id => (
                      <LakeTextInput
                        id={id}
                        value={value}
                        placeholder={locale.datePlaceholder}
                        valid={valid}
                        error={error}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                )}
              </Rifm>
            )}
          </Field>

          <Field name="expirationTime">
            {({ value, valid, error, onChange, onBlur }) => (
              <Rifm value={value} onChange={onChange} {...rifmTimeProps}>
                {({ value, onChange }) => (
                  <LakeLabel
                    label={t("simulatorForm.expirationTime")}
                    render={id => (
                      <LakeTextInput
                        id={id}
                        value={value}
                        placeholder={locale.timePlaceholder}
                        valid={valid}
                        error={error}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                )}
              </Rifm>
            )}
          </Field>

          <Field name="allowsPartialAuthorization">
            {({ value, onChange }) => (
              <LakeLabelledCheckbox
                value={value}
                onValueChange={onChange}
                label={t("simulatorForm.allowsPartialAuthorization")}
              />
            )}
          </Field>
        </Grid>
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Submit card out authorization form">
          <LakeButton
            size="small"
            color="current"
            loading={formStatus === "submitting"}
            onPress={onSubmit}
          >
            {t("simulatorForm.submitButton")}
          </LakeButton>
        </TrackPressable>

        <Space width={12} />
        <SimulatorResponses fields={fields} results={results} />
      </Box>
    </Form>
  );
};
