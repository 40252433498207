import { AsyncData, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Link } from "@swan-io/lake/src/components/Link";
import { Separator } from "@swan-io/lake/src/components/Separator";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { TilePlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { colors, radii, spacings } from "@swan-io/lake/src/constants/design";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { CSSProperties } from "react";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { CardProductsWizard } from "../components/CardProductsWizard";
import { ErrorView } from "../components/ErrorView";
import { Redirect } from "../components/Redirect";
import { LoggedUserInfoFragment } from "../graphql/admin";
import { GetCardProductsDocument, GetCardProductsQuery } from "../graphql/exposed-internal";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { formatCurrency, t } from "../utils/i18n";
import { Router, settingsCardProductsRoots } from "../utils/routes";
import { CardProductsDetailPage } from "./CardProductsDetailPage";

const styles = StyleSheet.create({
  root: {
    flexGrow: 1,
  },
  defaultCardColumn: {
    flexBasis: "33.333%",
    maxWidth: 500,
    alignSelf: "flex-start",
  },
  otherCardsColumn: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  scrollViewContainer: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: "stretch",
  },
  grid: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: "stretch",
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  cardDesignContainer: {
    ...StyleSheet.absoluteFillObject,
  },
  addNewLink: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderStyle: "dashed",
    borderColor: colors.gray[500],
    borderWidth: 1,
    borderRadius: radii[8],
    flexGrow: 1,
    minHeight: 200,
  },
  card: {
    width: "33.333%",
    paddingHorizontal: spacings[16],
    paddingBottom: spacings[32],
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    minWidth: 400,
  },
  cardTileLink: {
    flexGrow: 1,
    alignItems: "stretch",
    display: "flex",
    flexDirection: "column",
  },
  cardTag: {
    position: "absolute",
    top: spacings[12],
    left: spacings[12],
  },
});

const IMAGE_STYLE: CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "auto",
  borderRadius: 10,
  objectFit: "contain",
  objectPosition: "50% 50%",
};

type CardTileProps = {
  cardProduct: NonNullable<GetCardProductsQuery["projectInfo"]["cardProducts"]>[number];
  small?: boolean;
};

const CardTile = ({ cardProduct, small = false }: CardTileProps) => {
  const { projectId, projectEnv } = useProjectInfo();

  const isDraft =
    cardProduct.status === "PendingReview" &&
    cardProduct.cardDesigns.length === 1 &&
    cardProduct.cardDesigns[0]?.status === "Draft";
  const isPending =
    cardProduct.status === "PendingReview" &&
    cardProduct.cardDesigns.some(
      design =>
        design.status === "ToReview" || design.status === "Enabled" || design.status === "Disabled",
    );
  const isRedesigning =
    cardProduct.status === "Enabled" &&
    cardProduct.cardDesigns.some(
      design => design.status === "Draft" || design.status === "ToReview",
    );
  const notDisabledCardDesigns = cardProduct.cardDesigns.filter(
    design => design.status !== "Disabled",
  );
  const isEnabled =
    cardProduct.status === "Enabled" &&
    notDisabledCardDesigns.length === 1 &&
    notDisabledCardDesigns[0]?.status === "Enabled";

  const currentDesign = isDraft
    ? cardProduct.cardDesigns[0]
    : isPending
      ? cardProduct.cardDesigns.find(
          design =>
            design.status === "ToReview" ||
            design.status === "Enabled" ||
            design.status === "Disabled",
        )
      : (cardProduct.cardDesigns.find(item => item.status === "Enabled") ??
        cardProduct.cardDesigns.find(item => item.status === "Draft"));

  const cardDesignUrl = currentDesign?.cardDesignUrl;

  return (
    <Link
      to={
        projectEnv === "live"
          ? Router.LiveSettingsCardProductsDetailDraft({ projectId, cardProductId: cardProduct.id })
          : Router.SandboxSettingsCardProductsDetailCurrent({
              projectId,
              cardProductId: cardProduct.id,
            })
      }
      style={styles.cardTileLink}
    >
      <Tile flexGrow={1}>
        <View>
          <svg viewBox="0 0 1536 969" />

          {cardDesignUrl != null ? (
            <View style={styles.cardDesignContainer}>
              <img src={cardDesignUrl} style={IMAGE_STYLE} />

              <View style={styles.cardTag}>
                {isDraft ? (
                  <Tag color="warning">{t("cardProducts.status.draft")}</Tag>
                ) : isPending ? (
                  <Tag color="warning">{t("cardProducts.status.pending")}</Tag>
                ) : cardProduct.status === "Suspended" ? (
                  <Tag color="gray">{t("cardProducts.status.suspended")}</Tag>
                ) : isRedesigning ? (
                  <Tag color="shakespear">{t("cardProducts.status.redesigning")}</Tag>
                ) : isEnabled ? (
                  <Tag color="live">{t("cardProducts.status.enabled")}</Tag>
                ) : null}
              </View>
            </View>
          ) : null}
        </View>

        <Space height={24} />

        <LakeHeading
          level={2}
          variant="h3"
          color={colors.current[500]}
          align={small ? "center" : "left"}
        >
          {cardProduct.name ??
            (cardProduct.defaultCardProduct
              ? t("cardProducts.defaultCardProduct")
              : t("cardProducts.untitledCard"))}
        </LakeHeading>

        <Space height={12} />

        <LakeText variant="smallRegular" align={small ? "center" : "left"}>
          {t("cardProducts.maxSpendingLimits", {
            companySpendingLimit: formatCurrency(
              Number(cardProduct.companySpendingLimit.amount.value),
              cardProduct.companySpendingLimit.amount.currency,
            ),
            individualSpendingLimit: formatCurrency(
              Number(cardProduct.individualSpendingLimit.amount.value),
              cardProduct.individualSpendingLimit.amount.currency,
            ),
          })}
        </LakeText>

        <Space height={24} />

        <LakeLabel
          label={t("cardProducts.cardProductId")}
          render={() => (
            <LakeText variant="smallRegular" color={colors.gray[500]} numberOfLines={1}>
              {cardProduct.id}
            </LakeText>
          )}
          actions={
            <LakeCopyButton
              valueToCopy={cardProduct.id}
              copyText={t("copyButton.copyTooltip")}
              copiedText={t("copyButton.copiedTooltip")}
            />
          }
        />
      </Tile>
    </Link>
  );
};

type Props = {
  user: LoggedUserInfoFragment;
};

export const CardProductsPage = ({ user }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const canEditCardProducts = usePermissions(projectEnv).settingsCard.write;

  const route = Router.useRoute(settingsCardProductsRoots);
  const [data, { reload }] = useQuery(GetCardProductsDocument, {});

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
      <View style={styles.grid}>
        <View style={styles.card}>
          <TilePlaceholder />
        </View>

        <View style={styles.card}>
          <TilePlaceholder />
        </View>

        <View style={styles.card}>
          <TilePlaceholder />
        </View>
      </View>
    ))
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => {
      const defaultCardProduct = data?.projectInfo.cardProducts?.find(
        card => card.defaultCardProduct,
      );
      const otherCardProducts =
        data?.projectInfo.cardProducts?.filter(
          card => !card.defaultCardProduct && card.status !== "Disabled",
        ) ?? [];

      return (
        <>
          {match(route)
            .with(
              { name: "SettingsCardProducts" },
              { name: "SettingsCardProductsNew" },
              ({ name }) => (
                <Box direction="row" alignItems="stretch" style={styles.root}>
                  {defaultCardProduct != null && otherCardProducts.length === 0 ? (
                    <>
                      <View style={styles.defaultCardColumn}>
                        <CardTile cardProduct={defaultCardProduct} />
                        <Space height={32} />
                      </View>

                      <Space width={72} />
                      <Separator horizontal={true} />
                      <Space width={48} />
                    </>
                  ) : null}

                  {otherCardProducts.length === 0 ? (
                    <View style={styles.otherCardsColumn}>
                      <EmptyView
                        icon="payment-regular"
                        title={t("cardProducts.addNewCardProduct")}
                        subtitle={t("cardProducts.addNewCardProduct.description")}
                      >
                        <Space height={16} />

                        <LakeTooltip
                          placement="left"
                          content={t("common.action.denied")}
                          disabled={canEditCardProducts}
                        >
                          <LakeButton
                            color="current"
                            icon="add-circle-filled"
                            disabled={!canEditCardProducts}
                            onPress={() =>
                              Router.push("SettingsCardProductsNew", { projectId, projectEnv })
                            }
                          >
                            {t("common.new")}
                          </LakeButton>
                        </LakeTooltip>
                      </EmptyView>
                    </View>
                  ) : (
                    <View style={styles.scrollViewContainer}>
                      <LakeScrollView>
                        <View style={styles.grid}>
                          {defaultCardProduct != null ? (
                            <View style={styles.card} key={defaultCardProduct.id}>
                              <CardTile cardProduct={defaultCardProduct} small={true} />
                            </View>
                          ) : null}

                          {otherCardProducts.map(cardProduct => (
                            <View style={styles.card} key={cardProduct.id}>
                              <CardTile cardProduct={cardProduct} small={true} />
                            </View>
                          ))}

                          <View style={styles.card}>
                            <LakeTooltip
                              placement="left"
                              content={t("common.action.denied")}
                              disabled={canEditCardProducts}
                            >
                              <Link
                                disabled={!canEditCardProducts}
                                to={Router.SettingsCardProductsNew({ projectId, projectEnv })}
                                style={styles.addNewLink}
                              >
                                <Icon
                                  size={28}
                                  name="add-circle-regular"
                                  color={colors.gray[500]}
                                />

                                <LakeText color={colors.gray[500]} variant="semibold">
                                  {t("cardProducts.addNewCardProduct")}
                                </LakeText>
                              </Link>
                            </LakeTooltip>
                          </View>

                          {/* add empty cards at the end to counter the centered justifyContent*/}
                          <View style={styles.card} />
                          <View style={styles.card} />
                        </View>
                      </LakeScrollView>
                    </View>
                  )}

                  <LakeModal
                    visible={name === "SettingsCardProductsNew"}
                    icon="add-circle-regular"
                    title={t("cardProducts.newCardProduct")}
                    onPressClose={() =>
                      Router.push("SettingsCardProducts", { projectId, projectEnv })
                    }
                  >
                    <CardProductsWizard
                      canEditCardProducts={canEditCardProducts}
                      onSave={() => {
                        Router.push("SettingsCardProducts", { projectId, projectEnv });
                        reload();
                      }}
                    />
                  </LakeModal>
                </Box>
              ),
            )
            .with({ name: "SettingsCardProductsDetailArea" }, ({ params: { cardProductId } }) => {
              const cardProduct = data?.projectInfo.cardProducts?.find(
                card => card.id === cardProductId,
              );
              if (cardProduct == null) {
                return <Redirect to={Router.SettingsCardProducts({ projectId, projectEnv })} />;
              }
              return (
                <CardProductsDetailPage
                  canEditCardProducts={canEditCardProducts}
                  cardProduct={cardProduct}
                  onSave={() => {
                    reload();
                  }}
                  user={user}
                />
              );
            })
            .otherwise(() => (
              <ErrorView />
            ))}
        </>
      );
    })
    .exhaustive();
};
