import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useForm } from "@swan-io/use-form";
import { match } from "ts-pattern";
import { DocumentationLink } from "../../components/DocumentationLink";
import { SimulatorResponses } from "../../components/SimulatorReponses";
import { TrackPressable } from "../../components/TrackPressable";
import { SimulateApproveCapitalDepositCaseDocument } from "../../graphql/sandbox-partner-admin";
import { t } from "../../utils/i18n";
import { validateRequired } from "../../utils/validations";

export const ValidateCapitalDepositPage = () => {
  const { Field, submitForm, formStatus } = useForm<{ capitalDepositCaseId: string }>({
    capitalDepositCaseId: {
      initialValue: "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
  });

  const [simulate, simulation] = useMutation(SimulateApproveCapitalDepositCaseDocument);

  const result = simulation.mapOkToResult(simulation =>
    match(simulation.response)
      .with({ __typename: "SimulateApproveCapitalDepositCaseSuccessPayload" }, ({ id }) =>
        Result.Ok([{ key: "capitalDepositCaseId", value: id }]),
      )
      .otherwise(({ __typename }) => Result.Error({ rejection: __typename })),
  );

  const onSubmit = () =>
    submitForm({
      onSuccess: values =>
        Option.allFromDict(values)
          .map(input => simulate({ input }))
          .toUndefined(),
    });

  return (
    <Form style={commonStyles.fill}>
      <Tile
        description={
          <LakeText>
            {t("simulatorPage.capitalDeposit.validateDescription")}{" "}
            <DocumentationLink to="simulatorCapitalDeposit">
              {t("common.learnMore")}
            </DocumentationLink>
          </LakeText>
        }
      >
        <Grid numColumns={2}>
          <Field name="capitalDepositCaseId">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={`${t("simulatorForm.capitalDepositId")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.capitalDepositIdPlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>
        </Grid>
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Submit physical card delivery form">
          <LakeButton
            size="small"
            color="current"
            loading={formStatus === "submitting"}
            onPress={onSubmit}
          >
            {t("simulatorForm.submitButton")}
          </LakeButton>
        </TrackPressable>

        <Space width={12} />

        <SimulatorResponses
          results={result}
          fields={[
            {
              key: "capitalDepositCaseId",
              label: t("simulatorForm.capitalDepositId"),
              placeholder: "-",
            },
          ]}
        />
      </Box>
    </Form>
  );
};
