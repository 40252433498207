import { Array, Option } from "@swan-io/boxed";
import { FlatList } from "@swan-io/lake/src/components/FlatList";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Link } from "@swan-io/lake/src/components/Link";
import { Popover } from "@swan-io/lake/src/components/Popover";
import { colors, spacings } from "@swan-io/lake/src/constants/design";
import { useDisclosure } from "@swan-io/lake/src/hooks/useDisclosure";
import { memo, useCallback, useMemo, useRef } from "react";
import { Image, StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { LoggedUserInfoFragment } from "../graphql/admin";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { TrackPressable } from "./TrackPressable";

const styles = StyleSheet.create({
  button: {
    paddingRight: spacings[16],
  },
  logo: {
    height: 20,
    width: 64,
    display: "flex",
  },
  textBrand: {
    maxWidth: 160,
  },
  container: {
    borderRadius: 8,
    width: 440,
  },
  head: {
    paddingHorizontal: spacings[24],
    paddingVertical: spacings[12],
    paddingTop: spacings[12], // overwrite LakeLabel default style
    backgroundColor: colors.gray[50],
    borderBottomWidth: 1,
    borderBottomColor: colors.gray[100],
  },
  list: {
    maxHeight: 220,
    paddingTop: spacings[12],
  },
  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    transitionDuration: "150ms",
    transitionProperty: "background-color",
    paddingHorizontal: spacings[24],
    paddingVertical: spacings[8],
    justifyContent: "space-between",
  },
  activeItem: {
    backgroundColor: colors.gray[50],
  },
  hoveredItem: {
    backgroundColor: colors.gray[50],
  },
  pressedItem: {
    backgroundColor: colors.gray[100],
  },
  createButton: {
    padding: spacings[12],
  },
});

type ItemProps = {
  name: string;
  id: string;
  onPress?: () => void;
  isActive: boolean;
};

const Item = ({ name, id, onPress, isActive }: ItemProps) => (
  <TrackPressable action="Switch project">
    <Link
      onPress={onPress}
      to={Router.ProjectRoot({ projectId: id })}
      style={({ hovered, pressed }) => [
        styles.item,
        hovered && styles.hoveredItem,
        pressed && styles.pressedItem,
        isActive && styles.activeItem,
      ]}
    >
      <LakeText numberOfLines={1} color={isActive ? colors.current[500] : colors.gray[900]}>
        {name}
      </LakeText>

      {isActive && <Icon color={colors.positive[500]} name="checkmark-filled" size={14} />}
    </Link>
  </TrackPressable>
);

type Props = {
  user: LoggedUserInfoFragment;
};

export const ProjectSelectorButton = memo<Props>(({ user }) => {
  const { projectId } = useProjectInfo();
  const [isOpen, setIsOpen] = useDisclosure(false);
  const buttonRef = useRef<View>(null);

  const projects = useMemo(
    () =>
      Array.filterMap(user.memberships ?? [], membership =>
        Option.fromNullable(membership.project).flatMap(project => {
          const isProjectLive = match(project.status)
            .with(
              "PendingLiveReview",
              "LimitedLiveAccess",
              "BetaLiveAccess",
              "FullLiveAccess",
              () => true,
            )
            .otherwise(() => false);

          return Option.fromNullable(
            isProjectLive
              ? (project.liveProjectSettings ?? project.sandboxProjectSettings)
              : project.sandboxProjectSettings,
          ).map(settings => ({ ...settings, id: project.id }));
        }),
      ).sort((a, b) => (b.name > a.name ? -1 : 1)),
    [user],
  );

  const currentProject = Array.findMap(projects, project =>
    project.id === projectId ? Option.Some(project) : Option.None(),
  );

  const onPressNewApp = useCallback(() => {
    setIsOpen.close();
    setTimeout(() => {
      Router.push("BaseStart");
    }, 16);
  }, [setIsOpen]);

  return match(currentProject)
    .with(Option.P.None, () => null)
    .with(Option.P.Some(P.select()), currentProject => (
      <>
        <Popover
          referenceRef={buttonRef}
          describedBy="Project Selector"
          visible={isOpen}
          onDismiss={setIsOpen.close}
        >
          <View style={styles.container} tabIndex={0}>
            <LakeLabel
              label={t("projectSelectorButton.title", { projectName: currentProject.name })}
              color="current"
              type="view"
              style={styles.head}
              render={() => <LakeText color={colors.gray[900]}>{currentProject.id}</LakeText>}
              actions={
                <LakeCopyButton
                  valueToCopy={currentProject.id}
                  copyText={t("copyButton.copyTooltip")}
                  copiedText={t("copyButton.copiedTooltip")}
                />
              }
            />

            <FlatList
              role="list"
              style={styles.list}
              data={projects}
              keyExtractor={item => item.id}
              renderItem={({ item }) => (
                <Item
                  name={item.name}
                  id={item.id}
                  onPress={setIsOpen.close}
                  isActive={projectId === item.id}
                />
              )}
            />

            <View style={styles.createButton}>
              <TrackPressable action="New project">
                <LakeButton size="small" onPress={onPressNewApp} color="current">
                  {t("projectSelectorButton.new")}
                </LakeButton>
              </TrackPressable>
            </View>
          </View>
        </Popover>

        <LakeButton
          ref={buttonRef}
          onPress={setIsOpen.open}
          mode="secondary"
          icon="chevron-down-filled"
          ariaLabel={t("projectSelectorButton.show")}
          iconPosition="end"
          size="small"
          style={styles.button}
        >
          {currentProject.logoUri != null ? (
            <Image
              style={styles.logo}
              resizeMode="contain"
              source={{ uri: currentProject.logoUri }}
            />
          ) : (
            <LakeText
              color={colors.gray[800]}
              numberOfLines={1}
              variant="semibold"
              style={styles.textBrand}
            >
              {currentProject.name}
            </LakeText>
          )}
        </LakeButton>
      </>
    ))
    .exhaustive();
});
