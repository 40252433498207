import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useForm } from "@swan-io/use-form";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { DocumentationLink } from "../../components/DocumentationLink";
import { SimulatorResponses } from "../../components/SimulatorReponses";
import { TrackPressable } from "../../components/TrackPressable";
import { SimulateInvoiceGenerationDocument } from "../../graphql/sandbox-partner-admin";
import { formatCurrency, t } from "../../utils/i18n";
import { validateRequired } from "../../utils/validations";

const styles = StyleSheet.create({
  grid: {
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 1080,
  },
});

type FormValues = {
  accountId: string;
};

export const BillingGenerateInvoiceSimulatorPage = () => {
  const [simulate, simulation] = useMutation(SimulateInvoiceGenerationDocument);

  const transactionResult = simulation.mapOkToResult(simulation =>
    match(simulation.response)
      .with(
        {
          __typename: "SimulateInvoiceGenerationSuccessPayload",
        },
        ({ invoice }) =>
          Result.Ok(
            invoice != null
              ? [
                  ...(invoice.url != null ? [{ key: "url", value: invoice.url }] : []),
                  {
                    key: "amount",
                    value: formatCurrency(Number(invoice.amount.value), invoice.amount.currency),
                  },
                ]
              : [],
          ),
      )
      .otherwise(error => Result.Error({ rejection: error?.__typename ?? "Unknown" })),
  );

  const { Field, formStatus, submitForm } = useForm<FormValues>({
    accountId: {
      initialValue: "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
  });

  const onSubmit = () => {
    submitForm({
      onSuccess: values =>
        Option.allFromDict(values)
          .map(input => simulate({ input }))
          .toUndefined(),
    });
  };

  return (
    <Form style={commonStyles.fill}>
      <Tile
        description={
          <LakeText>
            {t("simulatorPage.billing.generateInvoiceDescription")}{" "}
            <DocumentationLink to="simulatorBilling">{t("common.learnMore")}</DocumentationLink>
          </LakeText>
        }
      >
        <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
          <Field name="accountId">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={`${t("simulatorForm.accountId")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.accountIdPlaceholder")}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    valid={valid}
                    error={error}
                  />
                )}
              />
            )}
          </Field>
        </Grid>
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Submit generate invoice">
          <LakeButton
            size="small"
            color="current"
            loading={formStatus === "submitting"}
            onPress={onSubmit}
          >
            {t("simulatorForm.submitButton")}
          </LakeButton>
        </TrackPressable>

        <Space width={12} />

        <SimulatorResponses
          fields={[
            {
              key: "url",
              label: t("simulatorForm.url"),
              placeholder: "-",
            },
            {
              key: "amount",
              label: t("simulatorForm.amount"),
              placeholder: "-",
            },
          ]}
          results={transactionResult}
        />
      </Box>
    </Form>
  );
};
