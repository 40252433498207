import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { deriveUnion } from "@swan-io/lake/src/utils/function";
import { isNotNullishOrEmpty, isNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { pick } from "@swan-io/lake/src/utils/object";
import { DatePicker } from "@swan-io/shared-business/src/components/DatePicker";
import { useForm } from "@swan-io/use-form";
import dayjs from "dayjs";
import { useMemo } from "react";
import { StyleSheet } from "react-native";
import { P, match } from "ts-pattern";
import { DocumentationLink } from "../../components/DocumentationLink";
import { SimulatorResponses } from "../../components/SimulatorReponses";
import { TrackPressable } from "../../components/TrackPressable";
import {
  ManualBankingFee,
  SimulateManualBankingFeeDocument,
} from "../../graphql/sandbox-partner-admin";
import { formatCurrency, t } from "../../utils/i18n";
import { validateRequired } from "../../utils/validations";

const styles = StyleSheet.create({
  grid: {
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 1080,
  },
});

type FormValues = {
  accountId: string;
  effectiveDate: string;
  type: ManualBankingFee;
  amount: string | undefined;
};

const defaultDate = dayjs().format("MM/DD/YYYY");

export const BillingChargeManualBankingFeeSimulatorPage = () => {
  const [simulate, simulation] = useMutation(SimulateManualBankingFeeDocument);

  const feeTypes = useMemo(() => {
    return deriveUnion<ManualBankingFee>({
      CirculationLetterDraftingFee: true,
      ConfirmationLetterDraftingFee: true,
      ImproperUseOfAccount: true,
      ProcessingJudicialOrAdministrativeSeizure: true,
      UnauthorizedOverdraft: true,
      SepaCreditorIdentifierRequest: true,
    }).array.map(name => ({ name, value: name }));
  }, []);

  const transactionResult = simulation.mapOkToResult(simulation =>
    match(simulation.response)
      .with(
        {
          __typename: "SimulateManualBankingFeeSuccessPayload",
          id: P.string,
          debitedAmount: { value: P.string, currency: P.string },
        },
        ({ id, debitedAmount }) =>
          Result.Ok([
            { key: "transactionId", value: id },
            {
              key: "debitedAmount",
              value: formatCurrency(Number(debitedAmount?.value), debitedAmount?.currency),
            },
          ]),
      )
      .otherwise(error => Result.Error({ rejection: error?.__typename ?? "Unknown" })),
  );

  const { Field, formStatus, submitForm, FieldsListener } = useForm<FormValues>({
    accountId: {
      initialValue: "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
    effectiveDate: {
      initialValue: defaultDate,
      strategy: "onBlur",
      validate: value => {
        if (isNullishOrEmpty(value) || !dayjs(value, "MM/DD/YYYY", true).isValid()) {
          return t("common.form.invalidDate");
        }
      },
      sanitize: value => value.trim(),
    },
    amount: {
      initialValue: undefined,
      strategy: "onBlur",
      validate: value => {
        if (isNullishOrEmpty(value)) {
          return t("common.form.required");
        }
      },
      sanitize: value =>
        isNotNullishOrEmpty(value) ? value.replace(/ /g, "").replace(/,/g, ".") : undefined,
    },
    type: {
      initialValue: "CirculationLetterDraftingFee",
      strategy: "onBlur",
      sanitize: value => value,
    },
  });

  const onSubmit = () => {
    submitForm({
      onSuccess: values =>
        Option.allFromDict(pick(values, ["accountId", "type", "effectiveDate"]))
          .map(({ accountId, type, effectiveDate }) => {
            const amount = values.amount.toUndefined();

            return simulate({
              input: {
                accountId,
                type,
                dueAmount: isNotNullishOrEmpty(amount)
                  ? { value: amount, currency: "EUR" }
                  : undefined,
                effectiveDate: new Date(effectiveDate).toISOString(),
              },
            });
          })
          .toUndefined(),
    });
  };

  return (
    <Form style={commonStyles.fill}>
      <Tile
        description={
          <LakeText>
            {t("simulatorPage.billing.chargeManualBankingFeeDescription")}{" "}
            <DocumentationLink to="simulatorBilling">{t("common.learnMore")}</DocumentationLink>
          </LakeText>
        }
      >
        <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
          <Field name="accountId">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={`${t("simulatorForm.accountId")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.accountIdPlaceholder")}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    valid={valid}
                    error={error}
                  />
                )}
              />
            )}
          </Field>

          <Field name="effectiveDate">
            {({ value, onChange, error }) => (
              <DatePicker
                label={`${t("simulatorForm.effectiveDate")} *`}
                value={value}
                error={error}
                format={"MM/DD/YYYY"}
                firstWeekDay={"monday"}
                onChange={onChange}
              />
            )}
          </Field>

          <Field name="type">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.feeType")}
                render={id => (
                  <LakeSelect id={id} value={value} items={feeTypes} onValueChange={onChange} />
                )}
              />
            )}
          </Field>

          <FieldsListener names={["type"]}>
            {({ type }) =>
              type.value === "ProcessingJudicialOrAdministrativeSeizure" ? (
                <Field name="amount">
                  {({ value, valid, error, onChange, onBlur }) => (
                    <LakeLabel
                      label={`${t("simulatorForm.amount")}`}
                      render={id => (
                        <LakeTextInput
                          id={id}
                          value={value}
                          placeholder={t("simulatorForm.amountPlaceholder")}
                          inputMode="decimal"
                          unit="€"
                          valid={valid}
                          error={error}
                          onChangeText={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  )}
                </Field>
              ) : null
            }
          </FieldsListener>
        </Grid>
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Submit charge manual banking fee">
          <LakeButton
            size="small"
            color="current"
            loading={formStatus === "submitting"}
            onPress={onSubmit}
          >
            {t("simulatorForm.submitButton")}
          </LakeButton>
        </TrackPressable>

        <Space width={12} />

        <SimulatorResponses
          fields={[
            {
              key: "transactionId",
              label: t("simulatorForm.transactionId"),
              placeholder: "-",
            },
            {
              key: "debitedAmount",
              label: t("simulatorForm.debitedAmount"),
              placeholder: "",
            },
          ]}
          results={transactionResult}
        />
      </Box>
    </Form>
  );
};
