import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useMutation, useQuery } from "@swan-io/graphql-client";
import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { TilePlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useCallback, useMemo } from "react";
import { P, match } from "ts-pattern";
import { Except } from "type-fest";
import { ErrorView } from "../components/ErrorView";
import { SandboxUserEditor } from "../components/SandboxUserEditor";
import {
  SandboxUserDocument,
  UpdateSandboxUserDocument,
  UpdateSandboxUserInput,
} from "../graphql/sandbox-partner-admin";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { NotFoundPage } from "./NotFoundPage";

type Props = {
  userId: string;
};

export const EditSandboxUserPage = ({ userId }: Props) => {
  const { projectId } = useProjectInfo();

  const [updateSandboxUser] = useMutation(UpdateSandboxUserDocument);

  const [data] = useQuery(SandboxUserDocument, { id: userId });

  const sandboxUser = data
    .toOption()
    .flatMap(x => x.toOption())
    .flatMap(data => Option.fromNullable(data.sandboxUserById))
    .toUndefined();

  useCrumb(
    useMemo(
      () =>
        isNotNullish(sandboxUser)
          ? {
              label: [sandboxUser.firstName, sandboxUser.lastName].filter(Boolean).join(" "),
              link: Router.SandboxDevelopersUsersEdit({ projectId, userId }),
            }
          : undefined,
      [projectId, userId, sandboxUser],
    ),
  );

  const onSave = useCallback(
    (values: Except<UpdateSandboxUserInput, "id">) => {
      return updateSandboxUser({ input: { id: userId, ...values } })
        .mapOk(data => data.updateSandboxUser)
        .mapOkToResult(data => (isNotNullish(data) ? Result.Ok(data) : Result.Error(undefined)))
        .mapOkToResult(filterRejectionsToResult)
        .tapOk(() => {
          showToast({ variant: "success", title: t("toast.success.sandboxUserCreated") });
          Router.push("DevelopersUsersRoot", { projectId, projectEnv: "sandbox" });
        })
        .tapError(error => {
          showToast({ variant: "error", error, title: translateError(error) });
        })
        .toPromise();
    },
    [updateSandboxUser, projectId, userId],
  );

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <TilePlaceholder />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), ({ sandboxUserById }) =>
      sandboxUserById == null ? (
        <NotFoundPage />
      ) : (
        <Tile title={t("sandboxUsers.editUser")}>
          <SandboxUserEditor projectId={projectId} user={sandboxUserById} onSave={onSave} />
        </Tile>
      ),
    )
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .exhaustive();
};
