import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Link } from "@swan-io/lake/src/components/Link";
import { ReadOnlyFieldList } from "@swan-io/lake/src/components/ReadOnlyFieldList";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { TileGridPlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { colors } from "@swan-io/lake/src/constants/design";
import { isNotNullish, isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { countries } from "@swan-io/shared-business/src/constants/countries";
import dayjs from "dayjs";
import { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { GetReceivedDirectDebitMandateDocument } from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { locale, t } from "../utils/i18n";
import { printMaskedIbanFormat } from "../utils/iban";
import { Router } from "../utils/routes";
import { ErrorView } from "./ErrorView";

type Props = {
  receivedDirectDebitMandateId: string;
  accountId: string;
};

const styles = StyleSheet.create({
  columns: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  column: {
    flexBasis: "500px",
    flexGrow: 1,
  },
  unknownValue: {
    fontStyle: "italic",
  },
});

const UNKNOWN_VALUE = <LakeText style={styles.unknownValue}>{t("common.unknown")}</LakeText>;

export const AccountDetailReceivedDirectDebitMandateDetail = ({
  receivedDirectDebitMandateId,
  accountId,
}: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const [data] = useQuery(GetReceivedDirectDebitMandateDocument, {
    id: receivedDirectDebitMandateId,
  });

  const creditorName = data
    .toOption()
    .flatMap(result => result.toOption())
    .flatMap(({ receivedDirectDebitMandate }) =>
      Option.fromNullable(receivedDirectDebitMandate?.creditor.name),
    )
    .getOr("");

  useCrumb(
    useMemo(() => {
      return {
        label: t("receivedDirectDebitMandates.details", { creditorName }),
        link: Router.AccountDetailReceivedDirectDebitMandatesDetail({
          projectId,
          projectEnv,
          accountId,
          receivedDirectDebitMandateId,
        }),
      };
    }, [projectId, accountId, projectEnv, receivedDirectDebitMandateId, creditorName]),
  );

  return match(data.mapOk(data => data.receivedDirectDebitMandate))
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <TileGridPlaceholder withTabs={false} />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.nullish)), () => <ErrorView />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select(P.nonNullable))), receivedDirectDebitMandate => (
      <View style={styles.columns}>
        <View style={styles.column}>
          <Tile title={t("receivedDirectDebitMandates.details.mandate")}>
            <ReadOnlyFieldList>
              <LakeLabel
                type="view"
                label={t("receivedDirectDebitMandates.scheme")}
                render={() => {
                  const scheme = match(receivedDirectDebitMandate)
                    .with(
                      { __typename: "InternalReceivedDirectDebitMandate" },
                      ({ internalScheme }) => internalScheme,
                    )
                    .with(
                      { __typename: "SEPAReceivedDirectDebitMandate" },
                      ({ sepaScheme }) => sepaScheme,
                    )
                    .exhaustive();

                  return match(scheme)
                    .with("InternalDirectDebitB2b", () => (
                      <Tag color="darkPink">
                        {t("receivedDirectDebitMandates.scheme.internalB2b")}
                      </Tag>
                    ))
                    .with("SepaDirectDebitB2b", () => (
                      <Tag color="darkPink">{t("receivedDirectDebitMandates.scheme.sepaB2b")}</Tag>
                    ))
                    .with("InternalDirectDebitStandard", () => (
                      <Tag color="live">
                        {t("receivedDirectDebitMandates.scheme.internalStandard")}
                      </Tag>
                    ))
                    .with("SepaDirectDebitCore", () => (
                      <Tag color="live">{t("receivedDirectDebitMandates.scheme.core")}</Tag>
                    ))
                    .exhaustive();
                }}
              />

              <LakeLabel
                type="view"
                label={t("receivedDirectDebitMandates.reference")}
                render={() => (
                  <LakeText color={colors.gray[900]}>
                    {receivedDirectDebitMandate.reference}
                  </LakeText>
                )}
              />

              <LakeLabel
                type="view"
                label={t("receivedDirectDebitMandates.id")}
                render={() => (
                  <LakeText color={colors.gray[900]}>{receivedDirectDebitMandate.id}</LakeText>
                )}
                actions={
                  <LakeCopyButton
                    valueToCopy={receivedDirectDebitMandate.id}
                    copyText={t("copyButton.copyTooltip")}
                    copiedText={t("copyButton.copiedTooltip")}
                  />
                }
              />

              <LakeLabel
                type="view"
                label={t("receivedDirectDebitMandates.status")}
                render={() =>
                  match(receivedDirectDebitMandate.statusInfo.status)
                    .with("Enabled", () => (
                      <Tag color="positive">{t("receivedDirectDebitMandates.status.enabled")}</Tag>
                    ))
                    .with("Canceled", () => (
                      <Tag color="gray">{t("receivedDirectDebitMandates.status.canceled")}</Tag>
                    ))
                    .with("ConsentInitiationPending", () => (
                      <Tag color="gray">
                        {t("receivedDirectDebitMandates.status.consentInitiationPending")}
                      </Tag>
                    ))
                    .with("ConsentPending", () => (
                      <Tag color="shakespear">
                        {t("receivedDirectDebitMandates.status.consentPending")}
                      </Tag>
                    ))
                    .with("Suspended", () => (
                      <Tag color="warning">{t("receivedDirectDebitMandates.status.suspended")}</Tag>
                    ))
                    .exhaustive()
                }
              />

              <LakeLabel
                type="view"
                label={t("receivedDirectDebitMandates.version")}
                render={() => (
                  <LakeText color={colors.gray[900]}>{receivedDirectDebitMandate.version}</LakeText>
                )}
              />

              <LakeLabel
                type="view"
                label={t("receivedDirectDebitMandates.signatureDate")}
                render={() =>
                  isNotNullish(receivedDirectDebitMandate.signatureDate) ? (
                    <LakeText color={colors.gray[900]}>
                      {dayjs(receivedDirectDebitMandate.signatureDate).format(
                        `${locale.dateFormat} ${locale.timeFormat}`,
                      )}
                    </LakeText>
                  ) : (
                    UNKNOWN_VALUE
                  )
                }
              />

              <LakeLabel
                type="view"
                label={t("receivedDirectDebitMandates.creationDate")}
                render={() => (
                  <LakeText color={colors.gray[900]}>
                    {dayjs(receivedDirectDebitMandate.createdAt).format(
                      `${locale.dateFormat} ${locale.timeFormat}`,
                    )}
                  </LakeText>
                )}
              />

              <LakeLabel
                type="view"
                label={t("receivedDirectDebitMandates.lastExecDate")}
                render={() =>
                  isNotNullish(receivedDirectDebitMandate.executedAt) ? (
                    <LakeText color={colors.gray[900]}>
                      {dayjs(receivedDirectDebitMandate.expiredAt).format(
                        `${locale.dateFormat} ${locale.timeFormat}`,
                      )}
                    </LakeText>
                  ) : (
                    UNKNOWN_VALUE
                  )
                }
              />

              <LakeLabel
                type="view"
                label={t("receivedDirectDebitMandates.lastUpdateDate")}
                render={() => (
                  <LakeText color={colors.gray[900]}>
                    {dayjs(receivedDirectDebitMandate.updatedAt).format(
                      `${locale.dateFormat} ${locale.timeFormat}`,
                    )}
                  </LakeText>
                )}
              />
            </ReadOnlyFieldList>
          </Tile>

          <Space height={24} />

          <Tile title={t("receivedDirectDebitMandates.details.debtor")}>
            <ReadOnlyFieldList>
              <LakeLabel
                type="view"
                label={t("receivedDirectDebitMandates.iban")}
                render={() => (
                  <LakeText color={colors.gray[900]}>
                    {printMaskedIbanFormat(receivedDirectDebitMandate.iban)}
                  </LakeText>
                )}
                actions={
                  <LakeCopyButton
                    valueToCopy={receivedDirectDebitMandate.iban}
                    copyText={t("copyButton.copyTooltip")}
                    copiedText={t("copyButton.copiedTooltip")}
                  />
                }
              />

              <LakeLabel
                type="view"
                label={t("transaction.accountHolder")}
                render={() => {
                  return isNotNullish(receivedDirectDebitMandate.account) ? (
                    <LakeText color={colors.gray[900]}>
                      {receivedDirectDebitMandate.account.name}
                    </LakeText>
                  ) : (
                    UNKNOWN_VALUE
                  );
                }}
                actions={
                  isNotNullish(receivedDirectDebitMandate.account) ? (
                    <Link
                      to={Router.AccountDetailRoot({
                        projectId,
                        projectEnv,
                        accountId: receivedDirectDebitMandate.account.id,
                      })}
                    >
                      <Icon size={20} name="arrow-right-filled" />
                    </Link>
                  ) : null
                }
              />
            </ReadOnlyFieldList>
          </Tile>
        </View>

        <Space width={24} />

        <View style={styles.column}>
          <Tile title={t("receivedDirectDebitMandates.details.creditor")}>
            <ReadOnlyFieldList>
              <LakeLabel
                type="view"
                label={t("receivedDirectDebitMandates.creditorIdentifier")}
                render={() => (
                  <LakeText color={colors.gray[900]}>
                    {receivedDirectDebitMandate.creditor.identifier}
                  </LakeText>
                )}
                actions={
                  <LakeCopyButton
                    valueToCopy={receivedDirectDebitMandate.creditor.identifier}
                    copyText={t("copyButton.copyTooltip")}
                    copiedText={t("copyButton.copiedTooltip")}
                  />
                }
              />

              <LakeLabel
                type="view"
                label={t("receivedDirectDebitMandates.creditorName")}
                render={() => (
                  <LakeText color={colors.gray[900]}>
                    {receivedDirectDebitMandate.creditor.name}
                  </LakeText>
                )}
              />

              <LakeLabel
                type="view"
                label={t("receivedDirectDebitMandates.ultimateCreditorName")}
                render={() =>
                  isNotNullishOrEmpty(receivedDirectDebitMandate.ultimateCreditorName) ? (
                    <LakeText color={colors.gray[900]}>
                      {receivedDirectDebitMandate.ultimateCreditorName}
                    </LakeText>
                  ) : (
                    UNKNOWN_VALUE
                  )
                }
              />

              <LakeLabel
                type="view"
                label={t("receivedDirectDebitMandates.address1")}
                render={() =>
                  isNotNullishOrEmpty(receivedDirectDebitMandate.creditor.address.addressLine1) ? (
                    <LakeText color={colors.gray[900]}>
                      {receivedDirectDebitMandate.creditor.address.addressLine1}
                    </LakeText>
                  ) : (
                    UNKNOWN_VALUE
                  )
                }
              />

              <LakeLabel
                type="view"
                label={t("receivedDirectDebitMandates.address2")}
                render={() =>
                  isNotNullishOrEmpty(receivedDirectDebitMandate.creditor.address.addressLine2) ? (
                    <LakeText color={colors.gray[900]}>
                      {receivedDirectDebitMandate.creditor.address.addressLine2}
                    </LakeText>
                  ) : (
                    UNKNOWN_VALUE
                  )
                }
              />

              <LakeLabel
                type="view"
                label={t("receivedDirectDebitMandates.postalCode")}
                render={() =>
                  isNotNullishOrEmpty(receivedDirectDebitMandate.creditor.address.postalCode) ? (
                    <LakeText color={colors.gray[900]}>
                      {receivedDirectDebitMandate.creditor.address.postalCode}
                    </LakeText>
                  ) : (
                    UNKNOWN_VALUE
                  )
                }
              />

              <LakeLabel
                type="view"
                label={t("receivedDirectDebitMandates.city")}
                render={() =>
                  isNotNullishOrEmpty(receivedDirectDebitMandate.creditor.address.city) ? (
                    <LakeText color={colors.gray[900]}>
                      {receivedDirectDebitMandate.creditor.address.city}
                    </LakeText>
                  ) : (
                    UNKNOWN_VALUE
                  )
                }
              />

              <LakeLabel
                type="view"
                label={t("receivedDirectDebitMandates.country")}
                render={() => {
                  const country = countries.find(
                    ({ cca3 }) => cca3 === receivedDirectDebitMandate.creditor.address.country,
                  );

                  return country ? (
                    <LakeText
                      color={colors.gray[900]}
                    >{`${country.flag} ${country.name}`}</LakeText>
                  ) : (
                    UNKNOWN_VALUE
                  );
                }}
              />
            </ReadOnlyFieldList>
          </Tile>
        </View>
      </View>
    ))
    .exhaustive();
};
