import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { useEffect } from "react";
import { match } from "ts-pattern";
import { usePermissions } from "../hooks/usePermissions";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { Redirect } from "./Redirect";

type Props = {
  projectId: string;
  isProjectLive: boolean;
  projectName: string;
  alreadyInvited?: string;
};

export const ProjectRoot = ({ projectId, projectName, isProjectLive, alreadyInvited }: Props) => {
  const livePermissions = usePermissions("live");

  useEffect(() => {
    match(alreadyInvited)
      .with("false", () => {
        showToast({
          variant: "success",
          title: t("toast.success.invitationAccepted", {
            projectName,
          }),
        });
      })
      .with("true", () => {
        showToast({ variant: "warning", title: t("toast.warning.memberAlreadyPresent") });
      });
  }, [alreadyInvited, projectName]);

  return (
    <Redirect
      to={Router.UserList({
        projectId,
        projectEnv: isProjectLive && livePermissions.dataUser.read ? "live" : "sandbox",
      })}
    />
  );
};
