import { Future, Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { invariantColors } from "@swan-io/lake/src/constants/design";
import { isNotNullish, isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { FileInput } from "@swan-io/shared-business/src/components/FileInput";
import { Validator, useForm } from "@swan-io/use-form";
import { ReactNode, forwardRef, useImperativeHandle, useState } from "react";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { default as swanLogoUrl } from "../assets/img/logo-swan.svg";
import { ColorInput } from "../components/ColorInput";
import { PhonePreview } from "../components/PhonePreview";
import { t } from "../utils/i18n";
import { validateHexColor, validateRequired } from "../utils/validations";

const styles = StyleSheet.create({
  phoneContainer: {
    width: 300,
  },
  phone: {
    display: "flex",
    flexGrow: 0,
    flexShrink: 1,
  },
});

export type BrandingSettings = {
  projectName: string | null | undefined;
  logoUri: string | null | undefined;
  accentColor: string | null | undefined;
};

export type SaveRef = {
  save: () => void;
};

type Props = {
  readonly?: boolean;
  isLogoRequired?: boolean;
  defaultValues: BrandingSettings;
  Header?: ReactNode;
  actions?: ReactNode;
  Footer?: ReactNode;
  onSave: (editorState: BrandingSettings) => void;
};

export const validateRequiredFile: Validator<File | undefined> = value => {
  if (value == null) {
    return t("common.form.required");
  }
};

export const BrandingSettingsForm = forwardRef<SaveRef | undefined, Props>(
  (
    {
      defaultValues: { projectName, logoUri, accentColor },
      readonly = false,
      isLogoRequired = true,
      Header,
      Footer,
      actions,
      onSave,
    }: Props,
    ref,
  ) => {
    const { Field, FieldsListener, submitForm } = useForm<{
      projectName: string;
      logoUri: File | undefined;
      accentColor: string;
    }>({
      projectName: {
        initialValue: projectName ?? t("projectSettings.branding.defaultName"),
        strategy: "onSubmit",
        validate: validateRequired,
        sanitize: value => value.trim(),
      },
      logoUri: {
        initialValue: undefined,
        validate: isLogoRequired ? validateRequiredFile : undefined,
        strategy: "onSubmit",
      },
      accentColor: {
        initialValue: accentColor?.slice(1) ?? invariantColors.defaultAccentColor.slice(1),
        strategy: "onSubmit",
        validate: validateHexColor,
      },
    });

    useImperativeHandle(ref, () => {
      return {
        save: () =>
          submitForm({
            onSuccess: values => {
              const option = Option.allFromDict(values);

              if (option.isSome()) {
                const { projectName, logoUri, accentColor } = option.get();

                const future = match(logoUri)
                  .returnType<Future<string | undefined>>()
                  .with(P.instanceOf(File), file => {
                    if (file.type === "image/png") {
                      return Future.make(resolve => {
                        const fileReader = new FileReader();

                        fileReader.onload = () => {
                          const result = fileReader.result as string;
                          resolve(result.replace("data:image/png;base64,", ""));
                        };

                        fileReader.readAsDataURL(file);
                      });
                    } else {
                      return Future.value(undefined);
                    }
                  })
                  .otherwise(() => Future.value(undefined));

                future.onResolve(logoUri => {
                  onSave({
                    accentColor: isNotNullishOrEmpty(accentColor) ? `#${accentColor}` : accentColor,
                    logoUri,
                    projectName,
                  });
                });
              }
            },
          }),
      };
    });

    const [logoUriForPreview, setLogoUriForPreview] = useState<string | undefined>(
      logoUri ?? undefined,
    );

    return (
      <Box direction="row" style={commonStyles.fill}>
        <View style={commonStyles.fill}>
          <LakeScrollView>
            {isNotNullish(Header) && (
              <>
                {Header}

                <Space height={24} />
              </>
            )}

            <Tile flexGrow={1} description={t("projectSettings.branding.subtitle")}>
              <Form>
                <Field name="logoUri">
                  {({ value, error, onChange }) => (
                    <LakeLabel
                      label={t("projectSettings.branding.logoInputLabel")}
                      render={() => (
                        <FileInput
                          disabled={readonly}
                          value={value ?? (logoUri != null ? { url: logoUri } : undefined)}
                          error={error}
                          icon="image-regular"
                          accept={["image/png"]}
                          maxSize={1000 * 1000} // 1MB
                          description={t("projectSettings.branding.logo.png")}
                          onFiles={files => {
                            const file = files[0];

                            if (file != null) {
                              void onChange(file);
                              setLogoUriForPreview(URL.createObjectURL(file));
                            }
                          }}
                        />
                      )}
                    />
                  )}
                </Field>

                <Space height={40} />

                <Field name="projectName">
                  {({ ref, value, onChange, onBlur, error }) => (
                    <LakeLabel
                      label={t("projectSettings.branding.nameInputLabel")}
                      render={id => (
                        <LakeTextInput
                          id={id}
                          ref={ref}
                          disabled={readonly}
                          value={value}
                          onChangeText={onChange}
                          onBlur={onBlur}
                          error={error}
                        />
                      )}
                    />
                  )}
                </Field>

                <Field name="accentColor">
                  {({ value, onChange, error }) => (
                    <LakeLabel
                      label={t("projectSettings.branding.colorLabel")}
                      render={id => (
                        <ColorInput
                          id={id}
                          disabled={readonly}
                          value={value}
                          onValueChange={onChange}
                          error={error}
                        />
                      )}
                    />
                  )}
                </Field>
              </Form>
            </Tile>

            <Space height={24} />

            {actions}

            {isNotNullish(Footer) && (
              <>
                <Space height={24} />

                {Footer}
              </>
            )}

            <Space height={24} />
          </LakeScrollView>
        </View>

        <Space width={32} />

        <View style={styles.phoneContainer}>
          <View style={StyleSheet.absoluteFill}>
            <FieldsListener names={["accentColor"]}>
              {({ accentColor }) => {
                // @swan-io/use-form does not trigger validate by default
                const isValidColor = /^[A-Fa-f0-9]{6}$/.test(accentColor.value);

                return (
                  <PhonePreview
                    projectLogo={logoUriForPreview ?? swanLogoUrl}
                    style={styles.phone}
                    accentColor={
                      isValidColor ? `#${accentColor.value}` : invariantColors.defaultAccentColor
                    }
                  />
                );
              }}
            </FieldsListener>

            <Space height={24} />
          </View>
        </View>
      </Box>
    );
  },
);
