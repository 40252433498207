import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeRadio } from "@swan-io/lake/src/components/LakeRadio";
import { LakeTagInput, TagInputRef } from "@swan-io/lake/src/components/LakeTagInput";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Pressable } from "@swan-io/lake/src/components/Pressable";
import { Separator } from "@swan-io/lake/src/components/Separator";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { colors, spacings } from "@swan-io/lake/src/constants/design";
import { mergeRefs } from "@swan-io/lake/src/utils/refs";
import { isValidEmail } from "@swan-io/shared-business/src/utils/validation";
import { useForm } from "@swan-io/use-form";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { isTranslationKey, t } from "../../utils/i18n";
import { Right, Role, commonRights, roles } from "../../utils/permissions";
import { validateEmail, validateRequired } from "../../utils/validations";
import { CriticityTag } from "./CriticityTag";

const styles = StyleSheet.create({
  item: {
    marginTop: spacings[24],
  },
  rightsList: {
    listStyle: "none",
    padding: 0,
    marginTop: spacings[4],
    marginBottom: 0,
  },
  rightsPlaceholder: {
    fontStyle: "italic",
  },
  hidden: {
    display: "none",
  },
  rights: {
    width: "50%",
  },
  list: {
    paddingLeft: spacings[24],
  },
  listItem: {
    listStylePosition: "outside",
    listStyleType: "disc",
    display: "list-item",
  },
  disabledTile: {
    opacity: 1,
    boxShadow: "none",
  },
});

const matrix: Record<
  Role,
  {
    sandbox: Right[];
    live: Right[];
  }
> = {
  SandboxDev: { sandbox: commonRights, live: [] },
  Contributor: {
    sandbox: commonRights,
    live: ["ToolsViewOnly", "SettingsViewOnly", "Insights"],
  },
  Support: { sandbox: [], live: ["Data"] },
  Manager: {
    sandbox: commonRights,
    live: commonRights,
  },
  Admin: {
    sandbox: commonRights,
    live: [...commonRights, "SensitiveOperations"],
  },
};

type RoleTileProps = {
  role: Role;
  selected: boolean;
  disabled: boolean;
  onPress: (role: Role) => void;
};

type RoleTooltipProps = {
  role: Role;
};

export const RoleTooltip = ({ role }: RoleTooltipProps) => {
  return (
    <>
      <LakeHeading variant="h5" level={5} color={colors.gray[0]}>
        {match(`membership.role.${role}`)
          .with(P.when(isTranslationKey), key => t(key))
          .exhaustive()}
      </LakeHeading>

      {matrix[role].sandbox.length > 0 ? (
        <>
          <Space height={12} />

          <LakeHeading variant="h6" level={6} color={colors.gray[0]}>
            {t("env.sandbox")}:
          </LakeHeading>

          <View style={styles.list} role="list">
            {matrix[role].sandbox.map(right => (
              <LakeText
                role="listitem"
                variant="smallRegular"
                color={colors.gray[0]}
                key={`sandbox-right-${right}`}
                style={styles.listItem}
              >
                {match(`membership.right.${right}`)
                  .with(P.when(isTranslationKey), key => t(key))
                  .otherwise(() => right)}
              </LakeText>
            ))}
          </View>
        </>
      ) : null}

      {matrix[role].live.length > 0 ? (
        <>
          <Space height={12} />

          <LakeHeading variant="h6" level={6} color={colors.gray[0]}>
            {t("env.live")}:
          </LakeHeading>

          <View style={styles.list} role="list">
            {matrix[role].live.map(right => (
              <LakeText
                role="listitem"
                variant="smallRegular"
                color={colors.gray[0]}
                key={`live-right-${right}`}
                style={styles.listItem}
              >
                {match(`membership.right.${right}`)
                  .with(P.when(isTranslationKey), key => t(key))
                  .otherwise(() => right)}
              </LakeText>
            ))}
          </View>
        </>
      ) : null}
    </>
  );
};

const RoleTile = ({ role, onPress, selected, disabled }: RoleTileProps) => (
  <Pressable style={styles.item} onPress={() => onPress(role)} disabled={disabled}>
    {({ hovered }) => (
      <Tile
        hovered={hovered}
        selected={selected}
        flexGrow={1}
        disabled={disabled && !selected}
        style={disabled && !selected && styles.disabledTile}
      >
        <Box direction="row" alignItems="center">
          {match({ disabled, selected })
            .with({ disabled: true, selected: false }, () => null)
            .with({ disabled: true, selected: true }, () => (
              <>
                <Icon name="checkmark-filled" color={colors.positive[500]} size={16} />
                <Space width={12} />
              </>
            ))
            .with({ disabled: false }, () => (
              <>
                <LakeRadio value={selected} disabled={disabled} />
                <Space width={12} />
              </>
            ))
            .exhaustive()}

          <LakeHeading color={colors.gray[900]} variant="h3" level={3}>
            {match(`membership.role.${role}`)
              .with(P.when(isTranslationKey), key => t(key))
              .exhaustive()}
          </LakeHeading>

          <Space width={12} />
          <CriticityTag role={role} />
        </Box>

        <Space height={4} />

        <LakeText color={colors.gray[500]} variant="smallRegular">
          {match(`membership.role.${role}.description`)
            .with(P.when(isTranslationKey), key => t(key))
            .exhaustive()}
        </LakeText>

        <Space height={12} />

        <Box direction="row">
          <Box direction="column" style={styles.rights}>
            <Box direction="row" alignItems="center">
              <Icon name="beaker-filled" size={16} color={colors.sandbox[600]} />
              <Space width={4} />

              <LakeText color={colors.sandbox[600]} variant="smallRegular">
                {t("env.sandbox")}
              </LakeText>
            </Box>

            {matrix[role].sandbox.length > 0 ? (
              <ul style={styles.rightsList}>
                {matrix[role].sandbox.map(right => (
                  <li key={`sandbox-right-${right}`}>
                    <LakeText variant="smallRegular">
                      {match(`membership.right.${right}`)
                        .with(P.when(isTranslationKey), key => t(key))
                        .otherwise(() => right)}
                    </LakeText>
                  </li>
                ))}
              </ul>
            ) : (
              <LakeText variant="smallRegular" style={styles.rightsPlaceholder}>
                {t("membership.right.noSandboxRights")}
              </LakeText>
            )}
          </Box>

          <Separator horizontal={true} />
          <Space width={24} />

          <Box direction="column" style={styles.rights}>
            <Box direction="row" alignItems="center">
              <Icon name="live-regular" size={16} color={colors.live[500]} />
              <Space width={4} />

              <LakeText color={colors.live[500]} variant="smallRegular">
                {t("env.live")}
              </LakeText>
            </Box>

            {matrix[role].live.length > 0 ? (
              <ul style={styles.rightsList}>
                {matrix[role].live.map(right => (
                  <li key={`live-right-${right}`}>
                    <LakeText variant="smallRegular">
                      {match(`membership.right.${right}`)
                        .with(P.when(isTranslationKey), key => t(key))
                        .otherwise(() => right)}
                    </LakeText>
                  </li>
                ))}
              </ul>
            ) : (
              <LakeText variant="smallRegular" style={styles.rightsPlaceholder}>
                {t("membership.right.noLiveRights")}
              </LakeText>
            )}
          </Box>
        </Box>
      </Tile>
    )}
  </Pressable>
);

export type ProjectMembershipEditorRef = {
  submit: () => void;
};

type EditorState = {
  email: string;
  role: Role;
};

export type MembershipBulk = {
  emails: string[];
  role: Role;
};

type Props = {
  readOnly?: boolean;
  initialEditorState?: EditorState;
  invitationInProgress?: boolean;
  onSave: (memberships: MembershipBulk) => void;
};

export const ProjectMembershipEditor = forwardRef<ProjectMembershipEditorRef, Props>(
  (
    { initialEditorState, readOnly = false, invitationInProgress = false, onSave },
    forwardedRef,
  ) => {
    const { Field, submitForm } = useForm<{ emails: string[]; role: Role }>({
      emails: {
        initialValue: initialEditorState != undefined ? [initialEditorState.email] : [],
        validate: emails => {
          if (emails.length === 0) {
            return t("common.required");
          }

          const errors = emails.map(validateEmail).filter(Boolean);
          if (errors.length > 0) {
            return errors[0];
          }
        },
      },
      role: {
        initialValue: initialEditorState != undefined ? initialEditorState.role : "SandboxDev",
        validate: validateRequired,
      },
    });

    const tagInputRef = useRef<TagInputRef>(null);

    useImperativeHandle(
      forwardedRef,
      () => ({
        submit: () => {
          tagInputRef?.current?.pushPendingValue();
          submitForm({
            onSuccess: values => {
              match(Option.allFromDict(values))
                .with(Option.P.Some(P.select()), ({ emails, role }) => {
                  onSave({ emails, role });
                })
                .otherwise(() => {});
            },
          });
        },
      }),
      [submitForm, onSave],
    );

    return (
      <>
        {invitationInProgress ? (
          <>
            <Space height={12} />
            <Tag color="shakespear">{t("accountMembership.status.invitationSent")}</Tag>
            <Space height={32} />
          </>
        ) : null}

        <LakeLabel
          label={t("common.email")}
          style={invitationInProgress && styles.hidden}
          render={id => (
            <Field name="emails">
              {({ value, onChange, error, ref }) => (
                <LakeTagInput
                  ref={mergeRefs([ref, tagInputRef])}
                  id={id}
                  validator={isValidEmail}
                  onValuesChanged={onChange}
                  values={value}
                  help={
                    initialEditorState != undefined ? undefined : t("membership.invitation.help")
                  }
                  error={error}
                  readOnly={initialEditorState != undefined}
                  validateOnBlur={true}
                />
              )}
            </Field>
          )}
        />

        <Field name="role">
          {({ value, onChange }) => (
            <>
              {roles.map((role, i) => (
                <RoleTile
                  key={`role-${role}-${i}`}
                  role={role}
                  selected={value === role}
                  onPress={() => onChange(role)}
                  disabled={readOnly}
                />
              ))}
            </>
          )}
        </Field>
      </>
    );
  },
);
