import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { colors, invariantColors } from "@swan-io/lake/src/constants/design";
import { memo } from "react";
import { StyleSheet, View } from "react-native";

const styles = StyleSheet.create({
  prefix: {
    position: "absolute",
    left: 12,
    top: 9,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    pointerEvents: "none",
  },
  preview: {
    height: 20,
    width: 20,
    borderRadius: 4,
    borderWidth: 1,
  },
  input: {
    paddingLeft: 56,
  },
});

type Props = {
  id?: string;
  disabled?: boolean;
  value: string;
  placeholder?: string;
  onValueChange: (text: string) => void;
  error?: string;
};

export const ColorInput = memo<Props>(
  ({ disabled = false, id, value, onValueChange, placeholder, error }) => {
    const isValid = /^[A-Fa-f0-9]{6}$/.test(value);
    const backgroundColor = isValid ? `#${value}` : invariantColors.white;
    const borderColor = isValid ? invariantColors.transparent : colors.gray[100];

    const handleChange = (text: string) => {
      onValueChange(text.toUpperCase().substring(0, 6));
    };

    return (
      <View>
        <LakeTextInput
          id={id}
          style={styles.input}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChangeText={handleChange}
          error={error}
        />

        <View style={styles.prefix}>
          <View style={[styles.preview, { backgroundColor, borderColor }]} role="none" />
          <Space width={8} />
          <LakeText color={colors.gray[900]}>#</LakeText>
        </View>
      </View>
    );
  },
);
