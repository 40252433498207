import { Future, Option, Result } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Space } from "@swan-io/lake/src/components/Space";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors, spacings } from "@swan-io/lake/src/constants/design";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { useCallback, useState } from "react";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { t } from "../../utils/i18n";
import { MembershipBulk } from "./ProjectMembershipEditor";

type Props = {
  info: Option<MembershipBulk>;
  onConfirm: (membershipBulk: MembershipBulk) => Future<Result<unknown, unknown>>;
  onCancel: () => void;
};

const styles = StyleSheet.create({
  buttonsContainer: {
    paddingTop: spacings[24],
  },
  list: {
    paddingLeft: spacings[24],
  },
  listItem: {
    listStylePosition: "outside",
    listStyleType: "disc",
    display: "list-item",
  },
});

export const AddAdminConfirmationModal = ({ onCancel, onConfirm, info }: Props) => {
  const [isLoading, setIsLoading] = useState(false);

  const onPressConfirm = useCallback(
    (info: MembershipBulk) => {
      setIsLoading(true);
      onConfirm(info).tap(() => setIsLoading(false));
    },
    [onConfirm],
  );

  const emailCount = info.map(({ emails }) => emails.length).getOr(0);

  return (
    <LakeModal
      icon="warning-regular"
      onPressClose={onCancel}
      color="warning"
      visible={info.isSome()}
      title={
        emailCount > 1
          ? t("membership.invitation.confirm.title.multiple", {
              count: emailCount,
            })
          : t("membership.invitation.confirm.title.single")
      }
    >
      {match(info)
        .with(Option.P.Some(P.select()), info => {
          return (
            <>
              {match(info.emails)
                .with([P.select(P.string)], email => (
                  <LakeText color={colors.gray[600]}>
                    {t("membership.invitation.confirm.description.single", {
                      email,
                    })}
                  </LakeText>
                ))
                .otherwise(emails => (
                  <>
                    <LakeText color={colors.gray[600]}>
                      {t("membership.invitation.confirm.description.multiple")}
                    </LakeText>

                    <View role="list" style={styles.list}>
                      {emails.map((email, i) => (
                        <LakeText style={styles.listItem} role="listitem" key={`confirm-mail-${i}`}>
                          {email}
                        </LakeText>
                      ))}
                    </View>
                  </>
                ))}

              <Box
                direction="row"
                justifyContent="spaceBetween"
                alignItems="center"
                style={styles.buttonsContainer}
              >
                <LakeButton mode="secondary" style={commonStyles.fill} onPress={onCancel}>
                  {t("common.cancel")}
                </LakeButton>

                <Space width={24} />

                <LakeButton
                  color="swan"
                  mode="primary"
                  onPress={() => onPressConfirm(info)}
                  style={commonStyles.fill}
                  loading={isLoading}
                >
                  {t("common.confirm")}
                </LakeButton>
              </Box>
            </>
          );
        })
        .with(Option.P.None, () => null)
        .exhaustive()}
    </LakeModal>
  );
};
