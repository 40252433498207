import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Pressable } from "@swan-io/lake/src/components/Pressable";
import { Space } from "@swan-io/lake/src/components/Space";
import { colors } from "@swan-io/lake/src/constants/design";
import { capitalize } from "@swan-io/lake/src/utils/string";
import { StyleSheet, View } from "react-native";
import { EnvType } from "../graphql/admin";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { TrackPressable } from "./TrackPressable";

const styles = StyleSheet.create({
  switch: {
    flexDirection: "row",
    padding: 4,
    borderRadius: 20,
    backgroundColor: colors.current.primary,
    transform: "translateZ(0px)",
  },
  handle: {
    position: "absolute",
    width: 24,
    height: 24,
    top: 4,
    left: 4,
    backgroundColor: colors.current.contrast,
    borderRadius: 16,
    transitionProperty: "transform",
    transitionDuration: "300ms",
    transitionTimingFunction: "ease-in-out",
  },
  switchItem: {
    width: 24,
    height: 24,
    borderRadius: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: "translateZ(0px)",
  },
  switchItemActive: {
    color: colors.current.primary,
  },
});

type Props = {
  onSelect: (env: EnvType) => void;
};

export const EnvironmentSelector = ({ onSelect }: Props) => {
  const { projectEnv } = useProjectInfo();

  const handleSelect = (env: EnvType) => {
    if (env !== capitalize(projectEnv)) {
      onSelect(env);
    }
  };

  return (
    <TrackPressable action="Select environment">
      <Pressable
        style={styles.switch}
        onPress={() => handleSelect(projectEnv === "live" ? "Sandbox" : "Live")}
      >
        <View
          style={[styles.handle, { transform: `translateX(${projectEnv === "live" ? 24 : 0}px)` }]}
        />

        <LakeTooltip
          placement="right"
          width={280}
          content={
            <View>
              <LakeText variant="medium" color={colors.gray.contrast}>
                {t("env.sandboxData")}
              </LakeText>

              <Space height={4} />
              <LakeText color={colors.gray.contrast}>{t("env.sandboxDataDescription")}</LakeText>
            </View>
          }
        >
          <LakeText
            color={colors.current.contrast}
            style={[styles.switchItem, projectEnv === "sandbox" && styles.switchItemActive]}
          >
            <Icon name="beaker-regular" size={16} />
          </LakeText>
        </LakeTooltip>

        <LakeTooltip
          placement="right"
          width={280}
          content={
            <View>
              <LakeText variant="medium" color={colors.gray.contrast}>
                {t("env.liveData")}
              </LakeText>

              <Space height={4} />
              <LakeText color={colors.gray.contrast}>{t("env.liveDataDescription")}</LakeText>
            </View>
          }
        >
          <LakeText
            color={colors.current.contrast}
            style={[styles.switchItem, projectEnv === "live" && styles.switchItemActive]}
          >
            <Icon name="live-regular" size={16} />
          </LakeText>
        </LakeTooltip>
      </Pressable>
    </TrackPressable>
  );
};
