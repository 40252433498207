import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors, radii } from "@swan-io/lake/src/constants/design";
import { pick } from "@swan-io/lake/src/utils/object";
import { useForm } from "@swan-io/use-form";
import { useMemo } from "react";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { DocumentationLink } from "../../components/DocumentationLink";
import { SimulatorResponses } from "../../components/SimulatorReponses";
import { TrackPressable } from "../../components/TrackPressable";
import { getCurrencies } from "../../constants/currencies";
import { SimulateCardCreditReverseDocument } from "../../graphql/sandbox-partner-admin";
import { t } from "../../utils/i18n";
import { validateRequired } from "../../utils/validations";

const styles = StyleSheet.create({
  grid: {
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 1080,
  },
  optionCurrencySymbol: {
    width: "3rem",
    backgroundColor: colors.gray[50],
    borderRadius: radii[4],
    display: "inline-block",
    textAlign: "center",
    boxShadow: `inset 0 0 0 1px ${colors.gray[100]}`,
  },
});

export const CardCreditReverseSimulatorPage = () => {
  const [simulate, simulation] = useMutation(SimulateCardCreditReverseDocument);

  const transactionResult = simulation.mapOkToResult(simulation =>
    match(simulation.response)
      .with(
        { __typename: "SimulateOutgoingCardCreditReverseSuccessPayload" },
        ({ transactionId }) => Result.Ok([{ key: "transactionId", value: transactionId }]),
      )
      .otherwise(({ __typename }) => Result.Error({ rejection: __typename })),
  );

  const { Field, submitForm, formStatus } = useForm<{
    transactionId: string;
    amount: string;
    originalAmount: string;
    originalCurrency: string | undefined;
  }>({
    transactionId: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.trim(),
      validate: validateRequired,
    },
    amount: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.replace(/ /g, "").replace(/,/g, "."),
    },
    originalAmount: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.replace(/ /g, "").replace(/,/g, "."),
    },
    originalCurrency: {
      initialValue: undefined,
    },
  });

  const onSubmit = () =>
    submitForm({
      onSuccess: values => {
        const option = Option.allFromDict(
          pick(values, ["transactionId", "amount", "originalAmount"]),
        );

        if (option.isSome()) {
          const { transactionId, amount, originalAmount } = option.get();

          const realOriginalCurrency = values.originalCurrency
            .flatMap(value => Option.fromNullable(value))
            .getOr("EUR");

          return simulate({
            input: {
              transactionId,
              amount: amount !== "" ? { value: amount, currency: "EUR" } : undefined,
              originalAmount:
                originalAmount !== ""
                  ? { value: originalAmount, currency: realOriginalCurrency }
                  : undefined,
            },
          });
        }
      },
    });

  const currencies = useMemo(
    () =>
      getCurrencies().map(currency => ({
        name: currency.name,
        icon: (
          <LakeText
            color={colors.gray[700]}
            variant="smallRegular"
            style={styles.optionCurrencySymbol}
          >
            {currency.symbol}
          </LakeText>
        ),
        value: currency.code,
      })),
    [],
  );

  return (
    <Form style={commonStyles.fill}>
      <Tile
        description={
          <LakeText>
            {t("simulatorPage.reverseCreditCardDescription")}{" "}
            <DocumentationLink to="simulatorCard">{t("common.learnMore")}</DocumentationLink>
          </LakeText>
        }
      >
        <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
          <Field name="transactionId">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={`${t("simulatorForm.transactionId")} *`}
                render={id => (
                  <LakeTextInput
                    value={value}
                    placeholder={t("simulatorForm.transactionIdPlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    id={id}
                  />
                )}
              />
            )}
          </Field>

          <Field name="amount">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.amount")}
                render={id => (
                  <LakeTextInput
                    value={value}
                    placeholder={t("simulatorForm.amountPlaceholder")}
                    inputMode="decimal"
                    unit="€"
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    id={id}
                  />
                )}
              />
            )}
          </Field>

          <Field name="originalAmount">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.originalAmount")}
                render={id => (
                  <LakeTextInput
                    value={value}
                    placeholder={t("simulatorForm.originalAmountPlaceholder")}
                    inputMode="decimal"
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    id={id}
                  />
                )}
              />
            )}
          </Field>

          <Field name="originalCurrency">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.originalCurrency")}
                render={id => (
                  <LakeSelect id={id} value={value} items={currencies} onValueChange={onChange} />
                )}
              />
            )}
          </Field>
        </Grid>
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Submit card credit reverse form">
          <LakeButton
            size="small"
            color="current"
            loading={formStatus === "submitting"}
            onPress={onSubmit}
          >
            {t("simulatorForm.submitButton")}
          </LakeButton>
        </TrackPressable>

        <Space width={12} />

        <SimulatorResponses
          fields={[
            {
              key: "transactionId",
              label: t("simulatorForm.transactionId"),
              placeholder: "-",
            },
          ]}
          results={transactionResult}
        />
      </Box>
    </Form>
  );
};
