import { useFocusReset } from "@swan-io/chicane";
import { Box } from "@swan-io/lake/src/components/Box";
import { Breadcrumbs, BreadcrumbsRoot } from "@swan-io/lake/src/components/Breadcrumbs";
import { InformationTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Space } from "@swan-io/lake/src/components/Space";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { CapitalDepositListPage } from "../pages/CapitalDepositListPage";
import { NotFoundPage } from "../pages/NotFoundPage";
import { t } from "../utils/i18n";
import { Router, capitalDepositRoutes } from "../utils/routes";
import { CapitalDepositDetailArea } from "./CapitalDepositDetailArea";
import { LakeDocumentationLink } from "./DocumentationLink";

const styles = StyleSheet.create({
  container: {
    ...commonStyles.fill,
  },
});

export const CapitalDepositArea = () => {
  const { projectId, projectEnv } = useProjectInfo();
  const route = Router.useRoute(capitalDepositRoutes);
  const containerRef = useRef(null);

  const rootLevelCrumbs = useMemo(
    () => [
      {
        label: t("capitalDeposit.all"),
        link: Router.CapitalDepositList({ projectId, projectEnv }),
      },
    ],
    [projectEnv, projectId],
  );

  useFocusReset({ route, containerRef });

  return (
    <BreadcrumbsRoot rootLevelCrumbs={rootLevelCrumbs}>
      <Box direction="row" justifyContent="spaceBetween" alignItems="center">
        <Breadcrumbs />

        <Box direction="row" justifyContent="end" alignItems="center">
          <LakeDocumentationLink to="capitalDeposit">{t("common.learnMore")}</LakeDocumentationLink>
          <Space width={16} />
          <InformationTooltip text={t("capitalDeposit.subtitle")} />
        </Box>
      </Box>

      <Space height={16} />

      <View ref={containerRef} style={styles.container}>
        {match(route)
          .with({ name: "CapitalDepositList" }, ({ params }) => (
            <CapitalDepositListPage params={params} />
          ))
          .with({ name: "CapitalDepositDetailArea" }, ({ params: { capitalDepositId } }) => (
            <CapitalDepositDetailArea capitalDepositId={capitalDepositId} />
          ))
          .otherwise(() => (
            <NotFoundPage />
          ))}
      </View>
    </BreadcrumbsRoot>
  );
};
