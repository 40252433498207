import { AsyncData, Future, Result } from "@swan-io/boxed";
import { ClientError } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Popover } from "@swan-io/lake/src/components/Popover";
import { Space } from "@swan-io/lake/src/components/Space";
import { colors } from "@swan-io/lake/src/constants/design";
import { useDisclosure } from "@swan-io/lake/src/hooks/useDisclosure";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { capitalize } from "@swan-io/lake/src/utils/string";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useRef, useState } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { P, match } from "ts-pattern";
import { EnvType, Exact, GenerateOAuthSecretMutation } from "../graphql/admin";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { env } from "../utils/env";
import { formatNestedMessage, t } from "../utils/i18n";
import { CopyTextButton } from "./CopyTextButton";
import { PopoverWrapper } from "./PopoverWrapper";
import { TrackPressable } from "./TrackPressable";
import { WarningText } from "./WarningText";

type Props = {
  projectId: string;
  clientId: string;
  clientSecret?: string;
  createdOn: string;
  style?: StyleProp<ViewStyle>;
  tempClientSecret?: string;
  oAuth2SecretGeneration: AsyncData<Result<GenerateOAuthSecretMutation, ClientError>>;
  generateOAuthSecret: (
    input: Exact<{
      projectId: string;
      env: EnvType;
    }>,
  ) => Future<Result<GenerateOAuthSecretMutation, ClientError>>;
};

export const Credentials = ({
  projectId,
  clientId,
  clientSecret,
  createdOn,
  style,
  oAuth2SecretGeneration,
  generateOAuthSecret,
}: Props) => {
  const { projectEnv } = useProjectInfo();
  const canGenerateClientSecrets = usePermissions(projectEnv).oAuthSettings.write;

  const [safeguardValue, setSafeguardValue] = useState("");
  const [isSafeguardPromptVisible, safeguardPrompt] = useDisclosure(false);
  const popoverAnchorRef = useRef<View>(null);

  const clientSecretToDisplay = match({ oAuth2SecretGeneration, clientSecret, projectEnv })
    // Live secret isn't accessible after initial generation
    .with(
      {
        oAuth2SecretGeneration: AsyncData.P.NotAsked,
        clientSecret: P.nonNullable,
        projectEnv: "live",
      },
      ({ clientSecret }) => ({ shouldWarn: false, canCopy: false, value: `...${clientSecret}` }),
    )
    .with(
      {
        oAuth2SecretGeneration: AsyncData.P.Done(P.any),
        clientSecret: P.nonNullable,
        projectEnv: "live",
      },
      ({ clientSecret }) => ({ shouldWarn: true, canCopy: true, value: clientSecret }),
    )
    .with({ clientSecret: P.nonNullable }, ({ clientSecret }) => ({
      shouldWarn: false,
      canCopy: true,
      value: clientSecret,
    }))
    .otherwise(() => null);

  const canGenerateSecret = canGenerateClientSecrets && oAuth2SecretGeneration.isNotAsked();

  const generateClientSecret = () => {
    // Check if safeguard value is correctly set ONLY if a secret existed before
    if (clientSecret != null) {
      if (clientId.slice(-4) !== safeguardValue) {
        return showToast({ variant: "error", title: t("toast.error.clientSecretWrongSafeGuard") });
      }
    }

    generateOAuthSecret({ projectId, env: capitalize(projectEnv) })
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(() =>
        showToast({ variant: "success", title: t("toast.success.clientSecretGenerated") }),
      )
      .tapError(error => showToast({ variant: "error", error, title: translateError(error) }));

    setSafeguardValue("");
    safeguardPrompt.close();
  };

  return (
    <View style={style}>
      <LakeLabel
        label={t("credentials.oauthLabel")}
        render={id => {
          const value = env.DEVELOPER_OAUTH2_ENDPOINT;

          return (
            <Box direction="row">
              <LakeTextInput id={id} readOnly={true} value={value} />
              <Space width={12} />
              <CopyTextButton value={value} />
            </Box>
          );
        }}
      />

      <Space height={8} />

      <LakeLabel
        label={t("credentials.clientIdLabel")}
        render={id => {
          const value = clientId;

          return (
            <Box direction="row">
              <LakeTextInput id={id} readOnly={true} value={value} />
              <Space width={12} />
              <CopyTextButton value={value} />
            </Box>
          );
        }}
      />

      <Space height={4} />

      <Box direction="row">
        <LakeText>
          {t("credentials.createdOn")}: {createdOn ? new Date(createdOn).toLocaleDateString() : "-"}
        </LakeText>
      </Box>

      <Space height={8} />

      <LakeLabel
        label={t("credentials.clientSecretLabel")}
        render={id => {
          return (
            <Box direction="row" ref={popoverAnchorRef}>
              {clientSecretToDisplay != null ? (
                <>
                  <LakeTextInput id={id} readOnly={true} value={clientSecretToDisplay.value} />
                  <Space width={12} />

                  {canGenerateSecret && (
                    <>
                      <LakeTooltip
                        placement="center"
                        content={
                          canGenerateClientSecrets
                            ? t("credentials.generateClientSecret")
                            : t("common.action.denied")
                        }
                      >
                        <TrackPressable action="Generate secret">
                          <LakeButton
                            mode="secondary"
                            size="small"
                            icon="arrow-counterclockwise-filled"
                            ariaLabel={t("credentials.generateClientSecret")}
                            onPress={safeguardPrompt.open}
                            disabled={!canGenerateClientSecrets}
                          />
                        </TrackPressable>
                      </LakeTooltip>

                      <Space width={12} />
                    </>
                  )}

                  {clientSecretToDisplay.canCopy ? (
                    <CopyTextButton value={clientSecretToDisplay.value} />
                  ) : null}
                </>
              ) : (
                <TrackPressable action="Generate secret">
                  <LakeTooltip
                    placement="center"
                    content={t("common.action.denied")}
                    disabled={canGenerateClientSecrets}
                  >
                    <LakeButton
                      mode="secondary"
                      size="small"
                      icon="arrow-counterclockwise-filled"
                      onPress={generateClientSecret}
                      disabled={!canGenerateClientSecrets}
                    >
                      {t("credentials.generateClientSecret")}
                    </LakeButton>
                  </LakeTooltip>
                </TrackPressable>
              )}
            </Box>
          );
        }}
      />

      <Popover
        referenceRef={popoverAnchorRef}
        describedBy={t("credentials.generateClientSecret")}
        onDismiss={safeguardPrompt.close}
        visible={isSafeguardPromptVisible}
      >
        <PopoverWrapper title={t("credentials.generateClientSecret")}>
          <LakeText color={colors.gray[800]}>{t("credentials.safeguardWarning1")}</LakeText>
          <Space height={12} />
          <LakeText color={colors.gray[800]}>{t("credentials.safeguardWarning2")}</LakeText>
          <Space height={12} />

          <LakeText color={colors.gray[800]}>
            {formatNestedMessage("credentials.safeguardWarning3", {
              clientId,
              bold: text => (
                <LakeText variant="semibold" color={colors.gray[800]}>
                  {text}
                </LakeText>
              ),
            })}
          </LakeText>

          <Space height={24} />

          <LakeTextInput
            placeholder={t("credentials.safeguardPlaceholder")}
            value={safeguardValue}
            onChangeText={setSafeguardValue}
            maxLength={4}
            hideErrors={true}
          />

          <Space height={12} />

          <TrackPressable action="Confirm safeguard">
            <LakeTooltip
              placement="center"
              content={t("common.action.denied")}
              disabled={canGenerateClientSecrets}
            >
              <LakeButton
                color="negative"
                size="small"
                onPress={generateClientSecret}
                loading={oAuth2SecretGeneration.isLoading()}
                disabled={!canGenerateClientSecrets}
              >
                {t("credentials.safeguardConfirm")}
              </LakeButton>
            </LakeTooltip>
          </TrackPressable>
        </PopoverWrapper>
      </Popover>

      {match(clientSecretToDisplay)
        .with({ shouldWarn: true }, () => (
          <>
            <Space height={4} />
            <WarningText>{t("credentials.clientSecretPostGeneration")}</WarningText>
          </>
        ))
        .otherwise(() => null)}
    </View>
  );
};
