import { useMutation } from "@swan-io/graphql-client";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { useCallback, useRef } from "react";
import { View } from "react-native";
import { TrackPressable } from "../components/TrackPressable";
import {
  SaveRef,
  WebhookOptions,
  WebhookSubscriptionEditor,
} from "../components/WebhookSubscriptionEditor";
import { AddWebhookSubscriptionDocument } from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";

type Props = {
  onSave: () => void;
  canEditWebhooks: boolean;
};

export const NewWebhookSubscriptionPage = ({ onSave: onExternalSave, canEditWebhooks }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const [addWebhookSubscription, webhookSubscriptionAddition] = useMutation(
    AddWebhookSubscriptionDocument,
  );
  const editorRef = useRef<SaveRef | undefined>();

  const onPressSave = useCallback(() => {
    if (isNotNullish(editorRef.current)) {
      editorRef.current.save();
    }
  }, []);

  const onPressCancel = useCallback(() => {
    Router.push("DevelopersWebhooksRoot", { projectId, projectEnv });
  }, [projectId, projectEnv]);

  const onSave = (values: WebhookOptions) =>
    addWebhookSubscription({
      input: {
        endpoint: values.endpoint,
        status: "Enabled",
        label: values.label,
        eventTypes: values.eventTypes,
        secret: values.secret === "" ? null : values.secret,
      },
    })
      .mapOk(data => data.addWebhookSubscription)
      .mapOkToResult(filterRejectionsToResult)
      .tapOk(() => {
        showToast({ variant: "success", title: t("toast.success.webhookCreated") });

        Router.push("DevelopersWebhooksRoot", { projectId, projectEnv });

        onExternalSave();
      })
      .tapError(error => {
        showToast({ variant: "error", error, title: t("toast.error.webhookCreated") });
      });

  return (
    <View>
      <WebhookSubscriptionEditor ref={editorRef} onSave={values => void onSave(values)} />

      <LakeButtonGroup>
        <TrackPressable action="Cancel new webhook">
          <LakeButton color="gray" mode="secondary" onPress={onPressCancel} grow={true}>
            {t("common.cancel")}
          </LakeButton>
        </TrackPressable>

        <TrackPressable action="Save new webhook">
          <LakeTooltip
            placement="left"
            content={t("common.action.denied")}
            disabled={canEditWebhooks}
            containerStyle={commonStyles.fill}
          >
            <LakeButton
              color="current"
              onPress={onPressSave}
              loading={webhookSubscriptionAddition.isLoading()}
              grow={true}
              disabled={!canEditWebhooks}
            >
              {t("common.save")}
            </LakeButton>
          </LakeTooltip>
        </TrackPressable>
      </LakeButtonGroup>
    </View>
  );
};
