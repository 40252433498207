import { Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabelledCheckbox } from "@swan-io/lake/src/components/LakeCheckbox";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors } from "@swan-io/lake/src/constants/design";
import { deriveUnion } from "@swan-io/lake/src/utils/function";
import { countries as countryList } from "@swan-io/shared-business/src/constants/countries";
import { useForm } from "@swan-io/use-form";
import { useMemo } from "react";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { DocumentationLink } from "../../components/DocumentationLink";
import { SimulatorResponses } from "../../components/SimulatorReponses";
import { TrackPressable } from "../../components/TrackPressable";
import { MerchantSubCategory } from "../../graphql/partner";
import {
  MerchantCategory,
  SimulateCardTransactionEnrichmentDocument,
  SimulateCardTransactionEnrichmentInput,
} from "../../graphql/sandbox-partner-admin";
import { t } from "../../utils/i18n";
import { validateRequired } from "../../utils/validations";

const styles = StyleSheet.create({
  grid: {
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 1080,
  },
  optionFlag: {
    display: "inline-block",
    width: 21,
  },
});

type NotNull<T> = { [P in keyof T]-?: NonNullable<T[P]> };

type Input = Omit<
  NotNull<Required<SimulateCardTransactionEnrichmentInput>>,
  "category" | "subcategory"
> & {
  category: MerchantCategory | undefined;
  subcategory: MerchantSubCategory | undefined;
};

const categories = deriveUnion<MerchantCategory>({
  Culture: true,
  Entertainment: true,
  Finance: true,
  Groceries: true,
  HealthAndBeauty: true,
  HomeAndUtilities: true,
  Other: true,
  ProfessionalServices: true,
  PublicAdministrations: true,
  Restaurants: true,
  Shopping: true,
  Software: true,
  Transport: true,
  Travel: true,
}).array.map(name => ({ name, value: name }));

const subcategories = deriveUnion<MerchantSubCategory>({
  Education: true,
  Museums: true,
  CinemasAndShows: true,
  GamblingAndBettingActivities: true,
  OtherLeisureActivities: true,
  StreamingPlatforms: true,
  ThemeParks: true,
  TicketsAndEvents: true,
  VideoGames: true,
  FinancialServices: true,
  Insurance: true,
  LiquorStore: true,
  SupermarketsAndOtherGroceryStores: true,
  FitnessAndSports: true,
  Hairdressing: true,
  Healthcare: true,
  Pharmacies: true,
  SpaAndBeautyTreatments: true,
  ConstructionAndOddJobs: true,
  EnergyProviders: true,
  Gardening: true,
  Laundries: true,
  PhoneAndInternetServicesProviders: true,
  RealEstate: true,
  CharityAndNonProfitOrganizations: true,
  ReligiousOrganizations: true,
  AdvertisingAndMarketing: true,
  BookkeepingAndConsultancy: true,
  CourierAndLogistics: true,
  IndustrialCleaning: true,
  LegalActivities: true,
  OtherProfessionalServices: true,
  StationaryServices: true,
  GovernmentAndCityCouncils: true,
  BarsAndRestaurants: true,
  CoffeeAndBakeries: true,
  FoodDelivery: true,
  PubsAndNightclubs: true,
  BooksAndNewspapers: true,
  CigarShops: true,
  ClothingShoesAndAccessories: true,
  ComputersAndElectronicDevices: true,
  DepartmentStores: true,
  Furniture: true,
  GamesAndToys: true,
  HardwareStores: true,
  HouseholdItems: true,
  Pets: true,
  SoftwareServices: true,
  CarRental: true,
  MetroBusAndTrains: true,
  MotorVehiclesRepairsAndAccessories: true,
  OtherTransportProviders: true,
  PrivateMobilityServices: true,
  ServiceStations: true,
  TollsAndParkings: true,
  Airlines: true,
  FerriesAndBoats: true,
  HotelsAndAccommodation: true,
  TravelAgents: true,
  AtmWithdrawal: true,
  OfficeRental: true,
  HrAndRecruiting: true,
  Flowers: true,
  OtherStores: true,
  PerfumesAndCosmetics: true,
  Other: true,
}).array.map(name => ({
  name,
  value: name,
}));

export const CardTransactionEnrichmentPage = () => {
  const [simulate, simulation] = useMutation(SimulateCardTransactionEnrichmentDocument);

  const transactionResult = simulation.mapOkToResult(simulation =>
    match(simulation.response)
      .with(
        { __typename: "SimulateCardTransactionEnrichmentSuccessPayload" },
        ({ transactionId }) => Result.Ok([{ key: "transactionId", value: transactionId }]),
      )
      .otherwise(({ __typename }) => Result.Error({ rejection: __typename })),
  );

  const countries = useMemo(
    () => [
      {
        name: t("common.none"),
        icon: (
          <LakeText color={colors.gray[900]} style={styles.optionFlag}>
            {null}
          </LakeText>
        ),
        value: "",
      },
      ...countryList.map(country => ({
        name: country.name,
        icon: (
          <LakeText color={colors.gray[900]} style={styles.optionFlag}>
            {country.flag}
          </LakeText>
        ),
        value: country.cca3,
      })),
    ],
    [],
  );

  const { Field, submitForm, formStatus } = useForm<Input>({
    transactionId: {
      initialValue: "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
    enrichedMerchantName: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    category: {
      initialValue: undefined,
      strategy: "onBlur",
    },
    subcategory: {
      initialValue: undefined,
      strategy: "onBlur",
    },
    address: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    city: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    postalCode: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    country: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    latitude: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    longitude: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    isSubscription: {
      initialValue: false,
      strategy: "onBlur",
    },
    contactEmail: {
      initialValue: "",
      strategy: "onBlur",
    },
    contactWebsite: {
      initialValue: "",
      strategy: "onBlur",
    },
    contactPhone: {
      initialValue: "",
      strategy: "onBlur",
    },
    carbonFootprint: {
      initialValue: "",
      strategy: "onBlur",
    },
  });

  const onSubmit = () =>
    submitForm({
      onSuccess: ({ transactionId, ...values }) =>
        transactionId
          .map(transactionId =>
            simulate({
              input: {
                transactionId,
                enrichedMerchantName: values.enrichedMerchantName
                  .filter(item => item !== "")
                  .toUndefined(),
                category: values.category.toUndefined(),
                subcategory: values.subcategory.toUndefined(),
                address: values.address.filter(item => item !== "").toUndefined(),
                city: values.city.filter(item => item !== "").toUndefined(),
                postalCode: values.postalCode.filter(item => item !== "").toUndefined(),
                country: values.country.filter(item => item !== "").toUndefined(),
                latitude: values.latitude.filter(item => item !== "").toUndefined(),
                longitude: values.longitude.filter(item => item !== "").toUndefined(),
                isSubscription: values.isSubscription.toUndefined(),
                contactEmail: values.contactEmail.filter(item => item !== "").toUndefined(),
                contactWebsite: values.contactWebsite.filter(item => item !== "").toUndefined(),
                contactPhone: values.contactPhone.filter(item => item !== "").toUndefined(),
                carbonFootprint: values.carbonFootprint.filter(item => item !== "").toUndefined(),
              },
            }),
          )
          .toUndefined(),
    });

  return (
    <Form style={commonStyles.fill}>
      <Tile
        description={
          <LakeText>
            {t("simulatorPage.cardTransactionEnrichmentPage")}{" "}
            <DocumentationLink to="simulatorCard">{t("common.learnMore")}</DocumentationLink>
          </LakeText>
        }
      >
        <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
          <Field name="transactionId">
            {({ value, onChange, valid, error, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.transactionId")}
                render={id => (
                  <LakeTextInput
                    value={value}
                    placeholder={t("simulatorForm.transactionIdPlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    id={id}
                  />
                )}
              />
            )}
          </Field>

          <Field name="enrichedMerchantName">
            {({ value, onChange, valid, error, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.enrichedMerchantName")}
                render={id => (
                  <LakeTextInput
                    value={value}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    id={id}
                  />
                )}
              />
            )}
          </Field>

          <Field name="category">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.category")}
                render={id => (
                  <LakeSelect id={id} value={value} items={categories} onValueChange={onChange} />
                )}
              />
            )}
          </Field>

          <Field name="subcategory">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.subcategory")}
                render={id => (
                  <LakeSelect
                    id={id}
                    value={value}
                    items={subcategories}
                    onValueChange={onChange}
                  />
                )}
              />
            )}
          </Field>

          <Field name="address">
            {({ value, onChange, valid, error, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.address")}
                render={id => (
                  <LakeTextInput
                    value={value}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    id={id}
                  />
                )}
              />
            )}
          </Field>

          <Field name="city">
            {({ value, onChange, valid, error, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.city")}
                render={id => (
                  <LakeTextInput
                    value={value}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    id={id}
                  />
                )}
              />
            )}
          </Field>

          <Field name="postalCode">
            {({ value, onChange, valid, error, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.postalCode")}
                render={id => (
                  <LakeTextInput
                    value={value}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    id={id}
                  />
                )}
              />
            )}
          </Field>

          <Field name="country">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.country")}
                render={id => (
                  <LakeSelect id={id} value={value} items={countries} onValueChange={onChange} />
                )}
              />
            )}
          </Field>

          <Field name="latitude">
            {({ value, onChange, valid, error, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.latitude")}
                render={id => (
                  <LakeTextInput
                    value={value}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    id={id}
                  />
                )}
              />
            )}
          </Field>

          <Field name="longitude">
            {({ value, onChange, valid, error, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.longitude")}
                render={id => (
                  <LakeTextInput
                    value={value}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    id={id}
                  />
                )}
              />
            )}
          </Field>

          <Field name="contactEmail">
            {({ value, onChange, valid, error, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.contactEmail")}
                render={id => (
                  <LakeTextInput
                    value={value}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    id={id}
                  />
                )}
              />
            )}
          </Field>

          <Field name="contactWebsite">
            {({ value, onChange, valid, error, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.contactWebsite")}
                render={id => (
                  <LakeTextInput
                    value={value}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    id={id}
                  />
                )}
              />
            )}
          </Field>

          <Field name="contactPhone">
            {({ value, onChange, valid, error, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.contactPhone")}
                render={id => (
                  <LakeTextInput
                    value={value}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    id={id}
                  />
                )}
              />
            )}
          </Field>

          <Field name="carbonFootprint">
            {({ value, onChange, valid, error, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.carbonFootprint")}
                render={id => (
                  <LakeTextInput
                    value={value}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    id={id}
                    unit="µg"
                  />
                )}
              />
            )}
          </Field>

          <Field name="isSubscription">
            {({ value, onChange }) => (
              <LakeLabelledCheckbox
                value={value}
                onValueChange={onChange}
                label={t("simulatorForm.isSubscription")}
              />
            )}
          </Field>
        </Grid>
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Submit card out debit form">
          <LakeButton
            size="small"
            color="current"
            loading={formStatus === "submitting"}
            onPress={onSubmit}
          >
            {t("simulatorForm.submitButton")}
          </LakeButton>
        </TrackPressable>

        <Space width={12} />

        <SimulatorResponses
          fields={[
            {
              key: "transactionId",
              label: t("simulatorForm.transactionId"),
              placeholder: "-",
            },
          ]}
          results={transactionResult}
        />
      </Box>
    </Form>
  );
};
