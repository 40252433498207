import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeCopyButton } from "@swan-io/lake/src/components/LakeCopyButton";
import { LakeDownloadButton } from "@swan-io/lake/src/components/LakeDownloadButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Link } from "@swan-io/lake/src/components/Link";
import { ReadOnlyFieldList } from "@swan-io/lake/src/components/ReadOnlyFieldList";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { colors } from "@swan-io/lake/src/constants/design";
import { isNotNullish, isNullish } from "@swan-io/lake/src/utils/nullish";
import dayjs from "dayjs";
import { useMemo } from "react";
import { StyleSheet } from "react-native";
import { P, match } from "ts-pattern";
import { languages } from "../constants/languages";
import { GetAccountQuery } from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { env } from "../utils/env";
import { formatCurrency, locale, t } from "../utils/i18n";
import { printMaskedIbanFormat } from "../utils/iban";
import { Router } from "../utils/routes";
import { useTgglFlag } from "../utils/tggl";
import { CopyTextButton } from "./CopyTextButton";

type Props = {
  account: NonNullable<GetAccountQuery["account"]>;
};

const styles = StyleSheet.create({
  unknownValue: {
    fontStyle: "italic",
  },
});

const UNKNOWN_VALUE = <LakeText style={styles.unknownValue}>{t("common.unknown")}</LakeText>;

export const AccountDetailGeneral = ({ account }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const { language, balances, IBAN, blockSDD, bankDetails } = account;

  const showAccountClosingLink = useTgglFlag("dashboardAccountClosingLink").getOr(false);

  const closingUrl = useMemo(() => {
    const url = new URL(projectEnv === "live" ? env.BANKING_LIVE_URL : env.BANKING_SANDBOX_URL);
    url.pathname = `/projects/${projectId}/accounts/${account.id}/close`;
    return url.toString();
  }, [account, projectId, projectEnv]);

  const legalRepresentativeName = Option.fromNullable(
    account.legalRepresentativeMembership.user,
  ).flatMap(user => Option.fromNullable(user.fullName));

  return (
    <>
      {match({
        paymentLevel: account.paymentLevel,
        balance: Option.fromNullable(account.balances?.available.value).map(Number).toNull(),
        status: account.statusInfo.status,
      })
        .with({ status: "Closing", balance: 0 }, () => null)
        .with({ status: "Closing", balance: P.number.negative() }, () => (
          <>
            <LakeAlert
              variant="error"
              title={t("accounts.closingWithNegativeBalance")}
              callToAction={
                <LakeButton
                  href="https://support.swan.io/hc/en-150/requests/new"
                  hrefAttrs={{ target: "blank" }}
                  mode="tertiary"
                  color="negative"
                  size="small"
                  icon="mail-regular"
                >
                  {t("accounts.closingWithNegativeBalance.contactSupport")}
                </LakeButton>
              }
            />

            <Space height={32} />
          </>
        ))
        .with({ status: "Closing", balance: P.number.positive() }, () => (
          <>
            <LakeAlert variant="warning" title={t("accounts.closingWithPositiveBalance")} />
            <Space height={32} />
          </>
        ))
        .with({ paymentLevel: "Limited" }, () => (
          <>
            <LakeAlert variant="warning" title={t("accounts.limitedWarning")} />
            <Space height={32} />
          </>
        ))
        .otherwise(() => null)}

      <TileGrid>
        <Tile title={t("account.account")}>
          <ReadOnlyFieldList>
            <LakeLabel
              type="view"
              label={t("account.number")}
              render={() => <LakeText color={colors.gray[900]}>{account.number}</LakeText>}
            />

            <LakeLabel
              type="view"
              label={t("account.name")}
              render={() => <LakeText color={colors.gray[900]}>{account.name}</LakeText>}
            />

            <LakeLabel
              type="view"
              label={t("account.paymentLevel")}
              render={() =>
                match(account.paymentLevel)
                  .with("Limited", value => <Tag color="warning">{value}</Tag>)
                  .with("Unlimited", value => <Tag color="positive">{value}</Tag>)
                  .with(P.nullish, () => null)
                  .exhaustive()
              }
            />

            <LakeLabel
              type="view"
              label={t("account.status")}
              render={() =>
                match(account.statusInfo.status)
                  .with("Opened", value => <Tag color="positive">{value}</Tag>)
                  .with("Suspended", value => <Tag color="negative">{value}</Tag>)
                  .with("Closing", value => <Tag color="warning">{value}</Tag>)
                  .with("Closed", value => <Tag color="gray">{value}</Tag>)
                  .exhaustive()
              }
              actions={match(account.statusInfo.status)
                .with("Opened", "Suspended", () => null)
                .with("Closing", "Closed", () => (
                  <LakeButton
                    mode="tertiary"
                    ariaLabel={t("common.moreInfo")}
                    icon="info-regular"
                    href="https://docs.swan.io/topics/accounts/closure/"
                    hrefAttrs={{ target: "blank" }}
                  />
                ))
                .exhaustive()}
            />

            {match(account.statusInfo)
              .with({ __typename: "AccountOpenedStatus" }, () => null)
              .with({ __typename: "AccountSuspendedStatus" }, () => null)
              .with(
                { __typename: "AccountClosedStatus" },
                { __typename: "AccountClosingStatus" },
                ({ reasonInfo: { type } }) => {
                  return match(type)
                    .with("PartnerReason", "ComplianceReason", type => {
                      return (
                        <LakeLabel
                          type="view"
                          label={t("account.closingType")}
                          render={() => (
                            <LakeText color={colors.gray[900]}>
                              {match(type)
                                .with("ComplianceReason", () => "Swan") // no translation, this is fixed
                                .with("PartnerReason", () => t("account.closingType.PartnerReason"))
                                .exhaustive()}
                            </LakeText>
                          )}
                        />
                      );
                    })
                    .otherwise(() => null);
                },
              )
              .exhaustive()}

            {match(account.statusInfo)
              .with({ __typename: "AccountOpenedStatus" }, () => null)
              .with({ __typename: "AccountSuspendedStatus" }, () => null)
              .with(
                { __typename: "AccountClosedStatus" },
                { __typename: "AccountClosingStatus" },
                ({ reasonInfo: { message } }) => {
                  return isNullish(message) ? null : (
                    <LakeLabel
                      type="view"
                      label={t("account.closingReasonMessage")}
                      render={() => <LakeText color={colors.gray[900]}>{message}</LakeText>}
                    />
                  );
                },
              )
              .exhaustive()}

            <LakeLabel
              label={t("account.language")}
              type="view"
              color="current"
              render={() => {
                const country = languages.find(item => item.id === language);

                if (isNotNullish(country)) {
                  return (
                    <Box direction="row" alignItems="center">
                      <LakeText color={colors.gray[900]}>{country.native}</LakeText>
                    </Box>
                  );
                } else {
                  return UNKNOWN_VALUE;
                }
              }}
            />

            <LakeLabel
              label={t("account.id")}
              type="view"
              render={() => <LakeText color={colors.gray[900]}>{account.id}</LakeText>}
              actions={
                <LakeCopyButton
                  valueToCopy={account.id}
                  copyText={t("copyButton.copyTooltip")}
                  copiedText={t("copyButton.copiedTooltip")}
                />
              }
            />

            <LakeLabel
              label={t("account.accountHolder")}
              type="view"
              render={() => (
                <Link
                  to={Router.HoldersDetailRoot({
                    projectId,
                    projectEnv,
                    accountHolderId: account.holder.id,
                  })}
                >
                  <LakeText color={colors.gray[900]}>{account.holder.info.name}</LakeText>
                </Link>
              )}
              actions={
                <Link
                  to={Router.HoldersDetailRoot({
                    projectId,
                    projectEnv,
                    accountHolderId: account.holder.id,
                  })}
                >
                  <Icon size={20} name="arrow-right-filled" />
                </Link>
              }
            />

            <LakeLabel
              label={t("account.createdAt")}
              type="view"
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {dayjs(account.createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                </LakeText>
              )}
            />

            <LakeLabel
              label={t("account.updatedAt")}
              type="view"
              render={() => (
                <LakeText color={colors.gray[900]}>
                  {dayjs(account.updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                </LakeText>
              )}
            />

            {match(account.statusInfo)
              .with({ __typename: "AccountClosingStatus" }, ({ closingAt }) => (
                <LakeLabel
                  label={t("account.closingRequested")}
                  type="view"
                  render={() => (
                    <LakeText color={colors.gray[900]}>
                      {dayjs(closingAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                    </LakeText>
                  )}
                />
              ))
              .with({ __typename: "AccountClosedStatus" }, ({ closedAt, closingAt }) => (
                <ReadOnlyFieldList>
                  <LakeLabel
                    label={t("account.closingRequested")}
                    type="view"
                    render={() => (
                      <LakeText color={colors.gray[900]}>
                        {dayjs(closingAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                      </LakeText>
                    )}
                  />

                  <LakeLabel
                    label={t("account.closed")}
                    type="view"
                    render={() => (
                      <LakeText color={colors.gray[900]}>
                        {dayjs(closedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)}
                      </LakeText>
                    )}
                  />
                </ReadOnlyFieldList>
              ))
              .otherwise(() => null)}
          </ReadOnlyFieldList>
        </Tile>

        <>
          <Tile title={t("account.balances")}>
            <ReadOnlyFieldList>
              <LakeLabel
                label={t("account.availableBalance")}
                type="view"
                render={() => {
                  if (isNullish(balances)) {
                    return UNKNOWN_VALUE;
                  }
                  const value = Number(balances.available.value);

                  return (
                    <LakeText
                      variant="medium"
                      color={
                        value > 0
                          ? colors.positive.primary
                          : value < 0
                            ? colors.negative.primary
                            : colors.gray[900]
                      }
                    >
                      {value >= 0 && "+"}
                      {formatCurrency(value, balances.available.currency)}
                    </LakeText>
                  );
                }}
              />

              <LakeLabel
                label={t("account.bookedBalance")}
                type="view"
                render={() => {
                  if (isNullish(balances)) {
                    return UNKNOWN_VALUE;
                  }
                  const value = Number(balances.booked.value);

                  return (
                    <LakeText
                      variant="medium"
                      color={
                        value > 0
                          ? colors.positive.primary
                          : value < 0
                            ? colors.negative.primary
                            : colors.gray[900]
                      }
                    >
                      {value >= 0 && "+"}
                      {formatCurrency(value, balances.booked.currency)}
                    </LakeText>
                  );
                }}
              />

              <LakeLabel
                label={t("account.pendingBalance")}
                type="view"
                render={() => {
                  if (isNullish(balances)) {
                    return UNKNOWN_VALUE;
                  }
                  const value = Number(balances.pending.value);

                  return (
                    <LakeText
                      variant="medium"
                      color={
                        value > 0
                          ? colors.positive.primary
                          : value < 0
                            ? colors.negative.primary
                            : colors.gray[900]
                      }
                    >
                      {value >= 0 && "+"}
                      {formatCurrency(value, balances.pending.currency)}
                    </LakeText>
                  );
                }}
              />

              <LakeLabel
                label={t("account.reservedBalance")}
                type="view"
                render={() => {
                  if (isNullish(balances)) {
                    return UNKNOWN_VALUE;
                  }
                  const value = Number(balances.reserved.value);

                  return (
                    <LakeText
                      variant="medium"
                      color={
                        value > 0
                          ? colors.positive.primary
                          : value < 0
                            ? colors.negative.primary
                            : colors.gray[900]
                      }
                    >
                      {value >= 0 && "+"}
                      {formatCurrency(value, balances.reserved.currency)}
                    </LakeText>
                  );
                }}
              />
            </ReadOnlyFieldList>
          </Tile>

          {isNotNullish(IBAN) && (
            <>
              <Space height={24} />

              <Tile title={t("account.sepa")}>
                <ReadOnlyFieldList>
                  <LakeLabel
                    label={t("account.bic")}
                    type="view"
                    render={() => <LakeText color={colors.gray[900]}>{account.BIC}</LakeText>}
                    actions={
                      <LakeCopyButton
                        valueToCopy={account.BIC}
                        copyText={t("copyButton.copyTooltip")}
                        copiedText={t("copyButton.copiedTooltip")}
                      />
                    }
                  />

                  <LakeLabel
                    label={t("account.iban")}
                    type="view"
                    render={() => (
                      <LakeText color={colors.gray[900]}>{printMaskedIbanFormat(IBAN)}</LakeText>
                    )}
                    actions={
                      <LakeCopyButton
                        valueToCopy={printMaskedIbanFormat(IBAN)}
                        copyText={t("copyButton.copyTooltip")}
                        copiedText={t("copyButton.copiedTooltip")}
                      />
                    }
                  />

                  {isNotNullish(blockSDD) && (
                    <LakeLabel
                      label={t("account.receivedSepaDirectDebit")}
                      type="view"
                      render={() =>
                        blockSDD ? (
                          <Tag color="negative">{t("account.receivedSepaDirectDebit.blocked")}</Tag>
                        ) : (
                          <Tag color="positive">{t("account.receivedSepaDirectDebit.allowed")}</Tag>
                        )
                      }
                    />
                  )}

                  {isNotNullish(bankDetails) && (
                    <LakeLabel
                      label={t("account.bankDetails")}
                      type="view"
                      render={() => (
                        <LakeText color={colors.gray[900]}>
                          {t("account.bankDetails.documentFilename") + ".pdf"}
                        </LakeText>
                      )}
                      actions={
                        <LakeDownloadButton
                          url={bankDetails}
                          filename={t("account.bankDetails.documentFilename") + ".pdf"}
                        />
                      }
                    />
                  )}
                </ReadOnlyFieldList>
              </Tile>
            </>
          )}

          {showAccountClosingLink &&
          (account.statusInfo.status === "Opened" || account.statusInfo.status === "Closing") ? (
            <>
              <Space height={24} />

              <Tile title={t("account.closeAccount")}>
                <ReadOnlyFieldList>
                  <LakeLabel
                    label={t("account.accountSettings.accountClosingLink")}
                    type="view"
                    render={() => {
                      return (
                        <Box direction="row">
                          <LakeTextInput
                            readOnly={true}
                            value={closingUrl}
                            help={match(legalRepresentativeName)
                              .with(Option.P.Some(P.select()), legalRepresentativeName =>
                                t(
                                  "account.accountSettings.accountClosingLink.instructions.withName",
                                  {
                                    legalRepresentativeName,
                                    legalRepresentativeEmail:
                                      account.legalRepresentativeMembership.email,
                                  },
                                ),
                              )
                              .otherwise(() =>
                                t("account.accountSettings.accountClosingLink.instructions", {
                                  legalRepresentativeEmail:
                                    account.legalRepresentativeMembership.email,
                                }),
                              )}
                          />

                          <Space width={12} />
                          <CopyTextButton value={closingUrl} />
                        </Box>
                      );
                    }}
                  />
                </ReadOnlyFieldList>
              </Tile>
            </>
          ) : null}
        </>
      </TileGrid>
    </>
  );
};
