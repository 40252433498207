import { Option } from "@swan-io/boxed";
import { encodeSearch, useLocation } from "@swan-io/chicane";
import { isNotNullish, isNullish } from "@swan-io/lake/src/utils/nullish";
import { useEffect } from "react";
import { sendClosePopupMessage } from "../states/popup";

export const PopupCallbackPage = () => {
  const {
    search: { membership, ...params },
  } = useLocation();

  const paramRedirectUrl = Array.isArray(params.redirectUrl)
    ? params.redirectUrl[0]
    : params.redirectUrl;

  const legacyRedirectUrl = [
    "/projects",
    isNullish(membership) || Array.isArray(membership) ? undefined : `/${membership}`,
    encodeSearch(params),
  ]
    .filter(isNotNullish)
    .join("");

  const redirectUrl = paramRedirectUrl ?? legacyRedirectUrl;

  useEffect(() => {
    sendClosePopupMessage(Option.Some(redirectUrl)).tapError(() => {
      // If we don't manage to close a popup, redirect from there
      window.location.replace(redirectUrl);
    });
  }, [redirectUrl]);

  return null;
};
