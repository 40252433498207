import { forwardRef, memo } from "react";
import {
  StyleProp,
  StyleSheet,
  ViewProps,
  ViewStyle,
  unstable_createElement as createElement,
} from "react-native";

const styles = StyleSheet.create({
  base: {
    borderWidth: 0,
  },
});

type Props = {
  allow?: string;
  tabIndex?: ViewProps["tabIndex"];
  height?: string | number;
  src: string;
  style?: StyleProp<ViewStyle>;
  title: string;
  width?: string | number;
  onLoad: (event: UIEvent) => void;
};

export const Iframe = memo(
  forwardRef<HTMLIFrameElement, Props>(({ style, tabIndex, ...props }, forwardedRef) =>
    createElement("iframe", {
      ref: forwardedRef,
      frameBorder: 0,
      tabIndex,
      style: [styles.base, style],
      ...props,
    }),
  ),
);
