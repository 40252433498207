import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useForm } from "@swan-io/use-form";
import { useCallback } from "react";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { DocumentationLink } from "../../components/DocumentationLink";
import { SimulatorResponses } from "../../components/SimulatorReponses";
import { TrackPressable } from "../../components/TrackPressable";
import {
  ProvisioningResponse,
  SimulateFinishInAppDocument,
} from "../../graphql/sandbox-partner-admin";
import { t } from "../../utils/i18n";
import { validateRequired } from "../../utils/validations";

const styles = StyleSheet.create({
  grid: {
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 1080,
  },
});

const provisioningResponseItems: { name: string; value: ProvisioningResponse }[] = [
  { name: t("simulatorForm.provisioningResponse.otp"), value: "OTP" },
  { name: t("simulatorForm.provisioningResponse.nootp"), value: "NoOTP" },
  { name: t("simulatorForm.provisioningResponse.decline"), value: "Decline" },
];

export const XPayFinishInAppSimulatorPage = () => {
  const [simulate, simulation] = useMutation(SimulateFinishInAppDocument);

  const result = simulation.mapOkToResult(simulation =>
    match(simulation.response)
      .with({ __typename: "SimulationSuccessfulResponse" }, () => Result.Ok([]))
      .otherwise(({ __typename }) => Result.Error({ rejection: __typename })),
  );

  const { Field, submitForm, formStatus } = useForm({
    digitalCardId: {
      initialValue: "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
    provisioningResponse: {
      initialValue: "OTP" as ProvisioningResponse,
    },
  });

  const handleSubmit = useCallback(
    () =>
      submitForm({
        onSuccess: values => {
          const option = Option.allFromDict(values);

          if (option.isSome()) {
            const { digitalCardId, provisioningResponse } = option.get();

            return simulate({
              input: {
                digitalCardId,
                provisioningResponse,
                responseChannel: "SMS",
              },
            });
          }
        },
      }),
    [submitForm, simulate],
  );

  return (
    <Form style={commonStyles.fill}>
      <Tile
        description={
          <LakeText>
            {t("simulatorPage.xPay.finishInAppDescription")}{" "}
            <DocumentationLink to="simulatorCardXPay">{t("common.learnMore")}</DocumentationLink>
          </LakeText>
        }
      >
        <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
          <Field name="digitalCardId">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={`${t("simulatorForm.digitalCardId")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    placeholder={t("simulatorForm.digitalCardIdPlaceholder")}
                    value={value}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    onSubmitEditing={handleSubmit}
                  />
                )}
              />
            )}
          </Field>

          <Field name="provisioningResponse">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.provisioningResponse")}
                render={id => (
                  <LakeSelect
                    id={id}
                    value={value}
                    items={provisioningResponseItems}
                    onValueChange={onChange}
                  />
                )}
              />
            )}
          </Field>
        </Grid>
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Submit Xpay finish in-app form">
          <LakeButton
            size="small"
            color="current"
            loading={formStatus === "submitting"}
            onPress={handleSubmit}
          >
            {t("simulatorForm.submitButton")}
          </LakeButton>
        </TrackPressable>

        <Space width={12} />
        <SimulatorResponses results={result} />
      </Box>
    </Form>
  );
};
