import {
  Cell,
  CopyableTextCell,
  HeaderCell,
  LinkCell,
  TextCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Link } from "@swan-io/lake/src/components/Link";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnConfig,
  LinkConfig,
  VirtualizedList,
} from "@swan-io/lake/src/components/VirtualizedList";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import dayjs from "dayjs";
import { match } from "ts-pattern";
import { GetCapitalDepositQuery } from "../graphql/partner";
import { ProjectEnv } from "../hooks/useProjectInfo";
import { formatCurrency, locale, t } from "../utils/i18n";
import { Router } from "../utils/routes";

type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  capitalDepositId: string;
};

type ShareholderType = NonNullable<
  GetCapitalDepositQuery["capitalDepositCase"]
>["shareholders"][number];

type Props = {
  shareholders: ShareholderType[];
  extraInfo: ExtraInfo;
};

const keyExtractor = ({ id }: ShareholderType) => id;

const getRowLink = ({
  item: { id },
  extraInfo: { projectEnv, projectId, capitalDepositId },
}: LinkConfig<ShareholderType, ExtraInfo>) => (
  <Link
    to={Router.CapitalDepositDetailShareholderRoot({
      projectId,
      projectEnv,
      capitalDepositId,
      shareholderId: id,
    })}
  />
);

const stickedToStartColumns: ColumnConfig<ShareholderType, ExtraInfo>[] = [
  {
    width: 240,
    id: "name",
    title: t("capitalDeposit.shareholders.name"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { info } }) =>
      match(info)
        .with({ __typename: "CompanyShareholder" }, ({ name }) => (
          <TextCell variant="medium" text={name} />
        ))
        .with({ __typename: "IndividualShareholder" }, ({ firstName, lastName }) => (
          <TextCell variant="medium" text={`${firstName} ${lastName}`} />
        ))
        .exhaustive(),
  },
];

const columns: ColumnConfig<ShareholderType, ExtraInfo>[] = [
  {
    width: 400,
    id: "id",
    title: t("capitalDeposit.shareholders.id"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { id } }) => (
      <CopyableTextCell
        text={id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 400,
    id: "onboarding",
    title: t("capitalDeposit.shareholders.onboardingId"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { onboarding }, extraInfo: { projectId, projectEnv } }) => {
      if (isNotNullish(onboarding)) {
        const onboardingId = onboarding.id;

        return (
          <LinkCell
            onPress={() =>
              Router.push("OnboardingDetailRoot", { projectId, projectEnv, onboardingId })
            }
          >
            {onboarding.id}
          </LinkCell>
        );
      }
      return null;
    },
  },
  {
    width: 250,
    id: "accountId",
    title: t("capitalDeposit.shareholders.accountId"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { accountId }, extraInfo: { projectId, projectEnv } }) => {
      if (isNotNullish(accountId)) {
        return (
          <LinkCell
            onPress={() => Router.push("AccountDetailRoot", { projectId, projectEnv, accountId })}
          >
            {accountId}
          </LinkCell>
        );
      }
      return null;
    },
  },
  {
    width: 240,
    id: "amount",
    title: t("capitalDeposit.amount"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { capitalDepositAmount } }) => (
      <TextCell
        text={
          capitalDepositAmount.value !== ""
            ? formatCurrency(Number(capitalDepositAmount.value), capitalDepositAmount.currency)
            : "-"
        }
      />
    ),
  },
  {
    width: 200,
    id: "uploadedDocuments",
    title: t("capitalDeposit.uploadedDocuments"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { documents } }) => {
      let numberOfValidatedDocuments = 0;
      documents.map(document => {
        if (
          document.statusInfo.status === "Validated" ||
          document.statusInfo.status === "Uploaded"
        ) {
          ++numberOfValidatedDocuments;
        }
      });
      return <TextCell text={String(numberOfValidatedDocuments)} />;
    },
  },
  {
    width: 200,
    id: "createdAt",
    title: t("capitalDeposit.createdAt"),
    renderTitle: ({ title }) => {
      return <HeaderCell text={title} />;
    },
    renderCell: ({ item: { createdAt } }) => (
      <TextCell text={dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
    ),
  },
  {
    width: 200,
    id: "updatedAt",
    title: t("capitalDeposit.updatedAt"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { updatedAt } }) => (
      <TextCell text={dayjs(updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
    ),
  },
];

const stickedToEndColumns: ColumnConfig<ShareholderType, ExtraInfo>[] = [
  {
    width: 220,
    id: "status",
    title: t("capitalDeposit.status"),
    renderTitle: ({ title }) => <HeaderCell text={title} align="right" />,
    renderCell: ({ item: { status } }) => (
      <Cell>
        {match(status)
          .with("CapitalFundsWiredToNotary", () => (
            <Tag color="positive">
              {t("capitalDeposit.shareholders.status.capitalFundsWiredToNotary")}
            </Tag>
          ))
          .with("CapitalTransferred", () => (
            <Tag color="shakespear">
              {t("capitalDeposit.shareholders.status.capitalTransferred")}
            </Tag>
          ))
          .with("PendingOnboarding", () => (
            <Tag color="gray">{t("capitalDeposit.shareholders.status.pendingOnboarding")}</Tag>
          ))
          .with("WaitingForTransfer", () => (
            <Tag color="warning">{t("capitalDeposit.shareholders.status.waitingForTransfer")}</Tag>
          ))
          .with("WaitingForVerification", () => (
            <Tag color="warning">
              {t("capitalDeposit.shareholders.status.waitingForVerification")}
            </Tag>
          ))
          .with("CapitalDepositCanceled", () => (
            <Tag color="gray">{t("capitalDeposit.shareholders.status.capitalDepositCanceled")}</Tag>
          ))
          .exhaustive()}
      </Cell>
    ),
  },
];

export const CapitalDepositShareholdersList = ({ shareholders, extraInfo }: Props) => {
  return (
    <VirtualizedList
      variant="default"
      marginHorizontal={negativeSpacings[24]}
      extraInfo={extraInfo}
      keyExtractor={keyExtractor}
      data={shareholders}
      stickedToStartColumns={stickedToStartColumns}
      columns={columns}
      stickedToEndColumns={stickedToEndColumns}
      headerHeight={48}
      rowHeight={48}
      getRowLink={getRowLink}
      renderEmptyList={() => (
        <EmptyView
          icon="lake-inbox-empty"
          title={t("capitalDeposit.shareholders.emptyListTitle")}
        />
      )}
    />
  );
};
