import { IconName } from "@swan-io/lake/src/components/Icon";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { ReactNode, useMemo } from "react";
import { BrandingCurrentTab } from "../components/BrandingCurrentTab";
import { BrandingDraftTab } from "../components/BrandingDraftTab";
import { BrandingPendingTab } from "../components/BrandingPendingTab";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { NotFoundPage } from "../pages/NotFoundPage";
import { t } from "../utils/i18n";
import { Router, brandingRoots } from "../utils/routes";

type BrandingSubRoutes = (typeof brandingRoots)[number];

type Tab = {
  url: BrandingSubRoutes;
  icon: IconName;
  hidden: boolean;
  label: string;
  render: () => ReactNode;
};

export const BrandingLivePage = () => {
  const { projectId, projectEnv } = useProjectInfo();
  const canEditBranding = usePermissions(projectEnv).settingsBranding.write;
  const route = Router.useRoute(brandingRoots);

  const tabs = useMemo<Tab[]>(
    () => [
      {
        url: "SettingsBrandingDraft",
        label: "Draft",
        icon: route?.name === "SettingsBrandingDraft" ? "edit-filled" : "edit-regular",
        hidden: !canEditBranding,
        render: () => <BrandingDraftTab />,
      },
      {
        url: "SettingsBrandingPending",
        icon: route?.name === "SettingsBrandingPending" ? "clock-filled" : "clock-regular",
        label: "Pending",
        hidden: false,
        render: () => <BrandingPendingTab />,
      },
      {
        url: "SettingsBrandingCurrent",
        icon: route?.name === "SettingsBrandingCurrent" ? "live-filled" : "live-regular",
        label: "Current",
        hidden: false,
        render: () => <BrandingCurrentTab />,
      },
    ],
    [route?.name, canEditBranding],
  );

  const currentTab =
    tabs.filter(t => !t.hidden).find(({ url }) => url === route?.name) ?? undefined;

  return (
    <>
      <TabView
        hideIfSingleItem={false}
        tabs={tabs
          .filter(t => !t.hidden)
          .map(tab => ({ ...tab, url: Router[tab.url]?.({ projectId, projectEnv: "live" }) }))}
        otherLabel={t("common.tabs.other")}
      />

      <Space height={32} />

      {currentTab?.render() ?? <NotFoundPage />}
    </>
  );
};
