import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeScrollView } from "@swan-io/lake/src/components/LakeScrollView";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { ReadOnlyFieldList } from "@swan-io/lake/src/components/ReadOnlyFieldList";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors } from "@swan-io/lake/src/constants/design";
import { emptyToUndefined } from "@swan-io/lake/src/utils/nullish";
import { capitalize } from "@swan-io/lake/src/utils/string";
import { useForm } from "@swan-io/use-form";
import { useState } from "react";
import { Pressable, StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { UpdateServerConsentProjectSettingsInput } from "../graphql/admin";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { env } from "../utils/env";
import { formatNestedMessage, t } from "../utils/i18n";
import { validateIpAddress, validatePublicKey } from "../utils/validations";
import { LakeDocumentationLink } from "./DocumentationLink";
import { TrackPressable } from "./TrackPressable";

type Props = {
  onSubmit?: (input: UpdateServerConsentProjectSettingsInput) => void;
  readOnly?: boolean;
  initialPublicKey?: string;
  initialAllowedIpAddresses?: string[];
  children?: JSX.Element;
  variant: "Sandbox" | "Pending" | "Draft" | "Current";
  disabled?: boolean;
  loading?: boolean;
  onCancelPending?: () => void;
};

const styles = StyleSheet.create({
  ipLine: {
    paddingVertical: 8,
  },
});

export const ServerConsentForm = ({
  readOnly,
  onSubmit,
  initialPublicKey,
  initialAllowedIpAddresses,
  children,
  variant,
  disabled,
  loading,
  onCancelPending,
}: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const canEditServerConsent = usePermissions(projectEnv).serverConsent.write;
  const [ipAddresses, setIpAddresses] = useState<string[]>(initialAllowedIpAddresses ?? []);

  const { Field: KeyField, submitForm: submitKeyForm } = useForm({
    publicKey: {
      initialValue: initialPublicKey ?? "",
      strategy: "onSuccessOrBlur",
      validate: validatePublicKey,
      sanitize: value => value.trim(),
    },
  });

  const {
    Field: IpField,
    submitForm: submitIpForm,
    resetForm: resetIpForm,
  } = useForm({
    ipAddress: {
      initialValue: "",
      strategy: "onSuccessOrBlur",
      validate: validateIpAddress,
      sanitize: value => value.trim(),
    },
  });

  const handleIpAddresses = () => {
    submitIpForm({
      onSuccess: ({ ipAddress }) => {
        if (ipAddress.isSome() && !ipAddresses.includes(ipAddress.get())) {
          setIpAddresses(prevIpAddresses => [...prevIpAddresses, ipAddress.get()]);
          resetIpForm();
        }
      },
    });
  };

  const onSubmitForm = () =>
    submitKeyForm({
      onSuccess: ({ publicKey }) => {
        if (publicKey.isSome()) {
          onSubmit?.({
            env: capitalize(projectEnv),
            projectId,
            publicKey: emptyToUndefined(publicKey.get()),
            allowedIpAddresses: ipAddresses,
            consentRedirectUrl: `${env.IDENTITY_URL}/consent`,
          });
        }
      },
    });

  return (
    <LakeScrollView>
      {projectEnv === "sandbox" && (
        <>
          <LakeAlert
            variant="warning"
            title={formatNestedMessage("serverConsent.form.alert", {
              url: (
                <LakeDocumentationLink to="serverConsent" color={colors.warning[600]}>
                  {t("serverConsent.linkDocumentation")}
                </LakeDocumentationLink>
              ),
            })}
          />

          <Space height={24} />
        </>
      )}

      <Form>
        <TileGrid>
          <Tile
            title={t("serverConsent.form.publicKey.title")}
            description={formatNestedMessage("serverConsent.form.publicKey.description", {
              documentationLink: (
                <LakeDocumentationLink to="serverConsent">
                  {t("common.learnMore")}
                </LakeDocumentationLink>
              ),
            })}
          >
            <KeyField name="publicKey">
              {({ value, valid, onChange, onBlur, error }) => (
                <LakeTextInput
                  disabled={readOnly}
                  multiline={true}
                  rows={10}
                  valid={valid}
                  value={value}
                  placeholder={t("serverConsent.form.publicKey.placeholder")}
                  error={error}
                  onChangeText={onChange}
                  onBlur={onBlur}
                />
              )}
            </KeyField>

            {children}
          </Tile>

          <Tile
            title={t("serverConsent.form.ipAddress.title")}
            description={t("serverConsent.form.ipAddress.description")}
          >
            <Box direction="row" alignItems="start">
              <IpField name="ipAddress">
                {({ value, error, valid, onChange, onBlur }) => (
                  <LakeTextInput
                    disabled={readOnly}
                    value={value}
                    placeholder={t("serverConsent.form.ipAddress.placeholder")}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    valid={valid}
                    onSubmitEditing={handleIpAddresses}
                  />
                )}
              </IpField>

              <Space width={16} />

              <LakeButton
                disabled={readOnly}
                mode="secondary"
                icon="add-circle-regular"
                size="small"
                onPress={handleIpAddresses}
              >
                {t("serverConsent.form.add")}
              </LakeButton>
            </Box>

            {ipAddresses.length > 0 && <Space height={16} />}

            <ReadOnlyFieldList>
              {ipAddresses.map(ipAddress => (
                <Box key={ipAddress} direction="row" style={styles.ipLine}>
                  <LakeText color={colors.gray[800]} numberOfLines={1} style={commonStyles.fill}>
                    {ipAddress}
                  </LakeText>

                  <Pressable
                    disabled={readOnly}
                    role="button"
                    onPress={() => {
                      setIpAddresses((prevIpAddresses: string[]) =>
                        prevIpAddresses.filter(value => value !== ipAddress),
                      );
                    }}
                  >
                    <Icon
                      name="delete-regular"
                      size={20}
                      color={readOnly === true ? colors.gray[300] : colors.negative.primary}
                    />
                  </Pressable>
                </Box>
              ))}
            </ReadOnlyFieldList>
          </Tile>
        </TileGrid>
      </Form>

      <Box direction="row" alignItems="start">
        {match(variant)
          .with("Sandbox", () => (
            <TrackPressable action="Save server consent">
              <LakeTooltip
                placement="left"
                content={t("common.action.denied")}
                disabled={canEditServerConsent}
              >
                <LakeButton
                  color="current"
                  size="small"
                  onPress={onSubmitForm}
                  loading={loading}
                  disabled={!canEditServerConsent}
                >
                  {t("common.save")}
                </LakeButton>
              </LakeTooltip>
            </TrackPressable>
          ))
          .with("Draft", () => (
            <Box direction="row" alignItems="start">
              <TrackPressable action="Send server consent to review">
                <LakeTooltip
                  placement="left"
                  content={t("common.action.denied")}
                  disabled={canEditServerConsent}
                >
                  <LakeButton
                    color="current"
                    size="small"
                    onPress={onSubmitForm}
                    icon="clock-filled"
                    disabled={disabled === true || !canEditServerConsent}
                  >
                    {t("serverConsent.form.sendToReview")}
                  </LakeButton>
                </LakeTooltip>
              </TrackPressable>
            </Box>
          ))
          .with("Pending", () => (
            <TrackPressable action="Cancel server consent">
              <LakeTooltip
                placement="left"
                content={t("common.action.denied")}
                disabled={canEditServerConsent}
              >
                <LakeButton
                  mode="secondary"
                  color={"negative"}
                  size="small"
                  onPress={onCancelPending}
                  loading={loading}
                  disabled={!canEditServerConsent}
                >
                  {t("serverConsent.form.cancel")}
                </LakeButton>
              </LakeTooltip>
            </TrackPressable>
          ))
          .with("Current", () => null)
          .exhaustive()}
      </Box>

      <Space height={24} />
    </LakeScrollView>
  );
};
