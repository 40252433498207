import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabelledCheckbox } from "@swan-io/lake/src/components/LakeCheckbox";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { colors } from "@swan-io/lake/src/constants/design";
import { useBoolean } from "@swan-io/lake/src/hooks/useBoolean";
import { deriveUnion } from "@swan-io/lake/src/utils/function";
import { isNotNullishOrEmpty, nullishOrEmptyToUndefined } from "@swan-io/lake/src/utils/nullish";
import { pick } from "@swan-io/lake/src/utils/object";
import { countries as countryList } from "@swan-io/shared-business/src/constants/countries";
import { useForm } from "@swan-io/use-form";
import dayjs from "dayjs";
import iban, { electronicFormat } from "iban";
import { useMemo, useState } from "react";
import { StyleSheet } from "react-native";
import { Rifm } from "rifm";
import { match } from "ts-pattern";
import { v4 as uuid } from "uuid";
import { DocumentationLink } from "../../components/DocumentationLink";
import { SimulatorResponses } from "../../components/SimulatorReponses";
import { TrackPressable } from "../../components/TrackPressable";
import {
  SepaReceivedDirectDebitMandateSequence,
  SepaReceivedDirectDebitMandateType,
  SimulateSddOutReceptionDocument,
} from "../../graphql/sandbox-partner-admin";
import { locale, rifmDateProps, rifmTimeProps, t } from "../../utils/i18n";
import {
  validateDate,
  validateIban,
  validateRequired,
  validateTime,
} from "../../utils/validations";

const styles = StyleSheet.create({
  grid: {
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 1080,
  },
  optionFlag: {
    display: "inline-block",
    width: 21,
  },
});

const defaultDateOnly = dayjs().format(locale.dateFormat);
const defaultTime = dayjs().format(locale.timeFormat);
const defaultCreditorIdentifier = "FR11ABC123456";
const defaultCreditorIban = "FR8814508000302986428271O12";
const defaultCreditorName = "Creditor Inc";

export const SddOutReceptionSimulatorPage = () => {
  const [simulate, simulation] = useMutation(SimulateSddOutReceptionDocument);
  const [initialMandateReference] = useState(() => uuid().replace(/-/g, ""));

  const transactionResult = simulation.mapOkToResult(simulation =>
    match(simulation.response)
      .with(
        { __typename: "SimulateOutgoingSepaDirectDebitReceptionSuccessPayload" },
        ({ transactionId }) => Result.Ok([{ key: "transactionId", value: transactionId }]),
      )
      .otherwise(({ __typename }) => Result.Error({ rejection: __typename })),
  );

  const [mandateIsAmended, setMandateIsAmended] = useBoolean(false);

  const { Field, formStatus, submitForm } = useForm<{
    amount: string;
    executionDateOnly: string;
    executionTime: string;
    creditorIban: string;
    creditorIdentifier: string;
    creditorName: string;
    creditorUltimateName: string;
    creditorCountry: string | undefined;
    debtorIban: string;
    endToEndId: string;
    mandateReference: string;
    previousMandateReference: string | undefined;
    previousCreditorIdentifier: string | undefined;
    previousDebtorIban: string | undefined;
    remittanceInformation: string;
    mandateType: SepaReceivedDirectDebitMandateType;
    mandateSequenceType: SepaReceivedDirectDebitMandateSequence;
  }>({
    amount: {
      initialValue: "",
      validate: validateRequired,
      strategy: "onBlur",
      sanitize: value => value.replace(/ /g, "").replace(/,/g, "."),
    },
    executionDateOnly: {
      initialValue: defaultDateOnly,
      validate: validateDate,
    },
    executionTime: {
      initialValue: defaultTime,
      validate: validateTime,
    },
    creditorIban: {
      initialValue: defaultCreditorIban,
      validate: validateIban,
      sanitize: electronicFormat,
    },
    creditorIdentifier: {
      initialValue: defaultCreditorIdentifier,
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
    creditorName: {
      initialValue: defaultCreditorName,
      validate: validateRequired,
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    creditorUltimateName: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    creditorCountry: {
      initialValue: "FRA",
      strategy: "onBlur",
    },
    debtorIban: {
      initialValue: "",
      validate: validateIban,
      sanitize: electronicFormat,
    },
    endToEndId: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    mandateReference: {
      initialValue: initialMandateReference,
      validate: validateRequired,
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    previousMandateReference: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value?.trim(),
    },
    previousCreditorIdentifier: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value?.trim(),
    },
    previousDebtorIban: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value?.trim(),
    },
    remittanceInformation: {
      initialValue: "",
      strategy: "onBlur",
      sanitize: value => value.trim(),
    },
    mandateType: {
      initialValue: "Core" as SepaReceivedDirectDebitMandateType,
      strategy: "onBlur",
    },
    mandateSequenceType: {
      initialValue: "Recurrent" as SepaReceivedDirectDebitMandateSequence,
      strategy: "onBlur",
    },
  });

  const onSubmit = () => {
    submitForm({
      onSuccess: values => {
        const option = Option.allFromDict(
          pick(values, [
            "amount",
            "creditorIban",
            "creditorIdentifier",
            "creditorName",
            "creditorUltimateName",
            "debtorIban",
            "executionDateOnly",
            "executionTime",
            "remittanceInformation",
            "mandateReference",
            "endToEndId",
            "mandateType",
            "mandateSequenceType",
          ]),
        );
        if (option.isSome()) {
          const {
            amount,
            creditorIban,
            creditorIdentifier,
            creditorName,
            creditorUltimateName,
            debtorIban,
            executionDateOnly,
            executionTime,
            remittanceInformation,
            mandateReference,
            endToEndId,
            mandateType,
            mandateSequenceType,
          } = option.get();
          const creditorCountry = values.creditorCountry.toUndefined();
          const previousCreditorIdentifier = values.previousCreditorIdentifier.toUndefined();
          const previousMandateReference = values.previousMandateReference.toUndefined();
          const previousDebtorIban = values.previousDebtorIban.toUndefined();

          const executionDate = dayjs(
            `${executionDateOnly} ${executionTime}`,
            `${locale.dateFormat} ${locale.timeFormat}`,
          ).toISOString();

          return simulate({
            input: {
              amount: { value: amount, currency: "EUR" },
              creditor: {
                address: isNotNullishOrEmpty(creditorCountry)
                  ? { country: creditorCountry }
                  : undefined,
                iban: creditorIban,
                identifier: creditorIdentifier,
                name: creditorName,
                ultimateName: creditorUltimateName,
              },
              debtorIban,
              endToEndId,
              executionDate,
              mandateAmendment: mandateIsAmended
                ? {
                    previousCreditorIdentifier: nullishOrEmptyToUndefined(
                      previousCreditorIdentifier,
                    ),
                    previousDebtorIban: nullishOrEmptyToUndefined(previousDebtorIban),
                    previousMandateReference: nullishOrEmptyToUndefined(previousMandateReference),
                  }
                : undefined,
              mandateReference,
              mandateSequence: mandateSequenceType,
              mandateType,
              label: remittanceInformation,
            },
          });
        }
      },
    });
  };

  const mandateType = useMemo(() => {
    return deriveUnion<SepaReceivedDirectDebitMandateType>({
      Core: true,
      B2B: true,
    }).array.map(value => ({ name: value, value }));
  }, []);

  const mandateSequenceType = useMemo(() => {
    return deriveUnion<SepaReceivedDirectDebitMandateSequence>({
      OneOff: true,
      Recurrent: true,
    }).array.map(value => ({ name: value, value }));
  }, []);

  const countries = useMemo(
    () => [
      {
        name: t("common.none"),
        icon: (
          <LakeText color={colors.gray[900]} style={styles.optionFlag}>
            {null}
          </LakeText>
        ),
        value: "",
      },
      ...countryList.map(country => ({
        name: country.name,
        icon: (
          <LakeText color={colors.gray[900]} style={styles.optionFlag}>
            {country.flag}
          </LakeText>
        ),
        value: country.cca3,
      })),
    ],
    [],
  );

  return (
    <Form style={commonStyles.fill}>
      <Tile
        description={
          <LakeText>
            {t("simulatorPage.receptionSddOutDescription")}{" "}
            <DocumentationLink to="simulatorSepaDebit">{t("common.learnMore")}</DocumentationLink>
          </LakeText>
        }
      >
        <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
          <Field name="executionDateOnly">
            {({ value, error, valid, onChange, onBlur }) => (
              <Rifm value={value} onChange={onChange} {...rifmDateProps}>
                {({ value, onChange }) => (
                  <LakeLabel
                    label={`${t("simulatorForm.executionDate")} *`}
                    render={id => (
                      <LakeTextInput
                        id={id}
                        value={value}
                        placeholder={locale.datePlaceholder}
                        valid={valid}
                        error={error}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                )}
              </Rifm>
            )}
          </Field>

          <Field name="executionTime">
            {({ value, valid, error, onChange, onBlur }) => (
              <Rifm value={value} onChange={onChange} {...rifmTimeProps}>
                {({ value, onChange }) => (
                  <LakeLabel
                    label={`${t("simulatorForm.executionTime")} *`}
                    render={id => (
                      <LakeTextInput
                        id={id}
                        value={value}
                        placeholder={locale.timePlaceholder}
                        valid={valid}
                        error={error}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )}
                  />
                )}
              </Rifm>
            )}
          </Field>

          <Field name="creditorIdentifier">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={`${t("simulatorForm.creditorIdentifier")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.creditorIdentifierPlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="creditorName">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={`${t("simulatorForm.creditorName")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.creditorNamePlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="creditorIban">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={`${t("simulatorForm.creditorIban")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.creditorIban")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="creditorCountry">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.creditorCountry")}
                render={id => (
                  <LakeSelect id={id} value={value} items={countries} onValueChange={onChange} />
                )}
              />
            )}
          </Field>

          <Field name="creditorUltimateName">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.creditorUltimateName")}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.creditorUltimateNamePlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="debtorIban">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={`${t("simulatorForm.debtorIban")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.debtorIbanPlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={text => onChange(iban.printFormat(text))}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="mandateReference">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={`${t("simulatorForm.mandateReference")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.mandateReferencePlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="mandateType">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.mandateType")}
                render={id => (
                  <LakeSelect id={id} value={value} items={mandateType} onValueChange={onChange} />
                )}
              />
            )}
          </Field>

          <Field name="mandateSequenceType">
            {({ value, onChange }) => (
              <LakeLabel
                label={t("simulatorForm.mandateSequenceType")}
                render={id => (
                  <LakeSelect
                    id={id}
                    value={value}
                    items={mandateSequenceType}
                    onValueChange={onChange}
                  />
                )}
              />
            )}
          </Field>

          <Field name="amount">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={`${t("simulatorForm.amount")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.amountPlaceholder")}
                    inputMode="decimal"
                    unit="€"
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="endToEndId">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.reference")}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.referencePlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>

          <Field name="remittanceInformation">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={t("simulatorForm.label")}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.labelPlaceholder")}
                    valid={valid}
                    error={error}
                    onChangeText={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            )}
          </Field>
        </Grid>

        <LakeLabelledCheckbox
          value={mandateIsAmended}
          onValueChange={setMandateIsAmended.toggle}
          label={t("simulatorForm.mandateAmendment")}
        />

        <Space height={32} />

        {mandateIsAmended && (
          <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
            <Field name="previousMandateReference">
              {({ value, valid, error, onChange, onBlur }) => (
                <LakeLabel
                  label={t("simulatorForm.previousMandateReference")}
                  render={id => (
                    <LakeTextInput
                      id={id}
                      value={value}
                      placeholder={t("simulatorForm.previousMandateReferencePlaceholder")}
                      valid={valid}
                      error={error}
                      onChangeText={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
              )}
            </Field>

            <Field name="previousCreditorIdentifier">
              {({ value, valid, error, onChange, onBlur }) => (
                <LakeLabel
                  label={t("simulatorForm.previousCreditorIdentifier")}
                  render={id => (
                    <LakeTextInput
                      id={id}
                      value={value}
                      placeholder={t("simulatorForm.previousCreditorIdentifierPlaceholder")}
                      valid={valid}
                      error={error}
                      onChangeText={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
              )}
            </Field>

            <Field name="previousDebtorIban">
              {({ value, valid, error, onChange, onBlur }) => (
                <LakeLabel
                  label={t("simulatorForm.previousDebtorIban")}
                  render={id => (
                    <LakeTextInput
                      id={id}
                      value={value}
                      placeholder={t("simulatorForm.previousDebtorIbanPlaceholder")}
                      valid={valid}
                      error={error}
                      onChangeText={text => onChange(iban.printFormat(text))}
                      onBlur={onBlur}
                    />
                  )}
                />
              )}
            </Field>
          </Grid>
        )}
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Submit SDD out reception form">
          <LakeButton
            size="small"
            color="current"
            loading={formStatus === "submitting"}
            onPress={onSubmit}
          >
            {t("simulatorForm.submitButton")}
          </LakeButton>
        </TrackPressable>

        <Space width={12} />

        <SimulatorResponses
          fields={[
            {
              key: "transactionId",
              label: t("simulatorForm.transactionId"),
              placeholder: "-",
            },
          ]}
          results={transactionResult}
        />
      </Box>
    </Form>
  );
};
