import { Option, Result } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Form } from "@swan-io/lake/src/components/Form";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { useForm } from "@swan-io/use-form";
import { StyleSheet } from "react-native";
import { match } from "ts-pattern";
import { DocumentationLink } from "../../components/DocumentationLink";
import { SimulatorResponses } from "../../components/SimulatorReponses";
import { TrackPressable } from "../../components/TrackPressable";
import { SimulateReleaseReservedBalanceDocument } from "../../graphql/sandbox-partner-admin";
import { t } from "../../utils/i18n";
import { validateRequired } from "../../utils/validations";

const styles = StyleSheet.create({
  grid: {
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: 1080,
  },
});

export const MerchantPaymentsReleaseReservedBalance = () => {
  const [mutate, simulation] = useMutation(SimulateReleaseReservedBalanceDocument);

  const result = simulation
    .mapOk(simulation => simulation.response)
    .mapOkToResult(response =>
      match(response)
        .with({ __typename: "ReleaseReservedBalanceSuccessPayload" }, ({ transactionId }) =>
          Result.Ok([{ key: "transactionId", value: transactionId }]),
        )
        .otherwise(({ __typename }) => Result.Error({ rejection: __typename })),
    );

  const { Field, formStatus, submitForm } = useForm({
    transactionId: {
      initialValue: "",
      validate: validateRequired,
    },
  });

  const onPressSubmit = () => {
    submitForm({
      onSuccess: values => {
        return Option.allFromDict(values)
          .map(({ transactionId }) => {
            mutate({
              input: {
                transactionId,
              },
            });
          })
          .toUndefined();
      },
    });
  };

  return (
    <Form style={commonStyles.fill}>
      <Tile
        description={
          <LakeText>
            {t("simulatorPage.merchantPayments.releaseReservedBalance.description")}{" "}
            <DocumentationLink to="merchant">{t("common.learnMore")}</DocumentationLink>
          </LakeText>
        }
      >
        <Grid numColumns={2} horizontalSpace={40} style={styles.grid}>
          <Field name="transactionId">
            {({ value, valid, error, onChange, onBlur }) => (
              <LakeLabel
                label={`${t("simulatorForm.transactionId")} *`}
                render={id => (
                  <LakeTextInput
                    id={id}
                    value={value}
                    placeholder={t("simulatorForm.transactionIdPlaceholder")}
                    onChangeText={onChange}
                    onBlur={onBlur}
                    valid={valid}
                    error={error}
                  />
                )}
              />
            )}
          </Field>
        </Grid>
      </Tile>

      <Space height={16} />

      <Box direction="row" alignItems="start">
        <TrackPressable action="Submit merchant payment release reserved balance">
          <LakeButton
            size="small"
            color="current"
            loading={formStatus === "submitting"}
            onPress={onPressSubmit}
          >
            {t("simulatorForm.submitButton")}
          </LakeButton>
        </TrackPressable>

        <Space width={12} />

        <SimulatorResponses
          fields={[
            {
              key: "transactionId",
              label: t("simulatorForm.transactionId"),
              placeholder: "-",
            },
          ]}
          results={result}
        />
      </Box>
    </Form>
  );
};
