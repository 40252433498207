import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { colors } from "@swan-io/lake/src/constants/design";
import { ReactText } from "react";

type Props = {
  children: ReactText;
};

export const WarningText = ({ children }: Props) => (
  <LakeText color={colors.negative[500]}>{children}</LakeText>
);
