import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { ProjectEnvTag } from "@swan-io/lake/src/components/ProjectEnvTag";
import { capitalize } from "@swan-io/lake/src/utils/string";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { t } from "../utils/i18n";

export const ProjectEnvBadge = () => {
  const { projectEnv } = useProjectInfo();
  const isLive = projectEnv === "live";

  return (
    <LakeTooltip
      width={280}
      placement="right"
      content={isLive ? t("projectEnv.description.live") : t("projectEnv.description.sandbox")}
    >
      <ProjectEnvTag projectEnv={capitalize(projectEnv)} />
    </LakeTooltip>
  );
};
