// https://github.com/arhs/iban.js/blob/v0.0.14/iban.js#L341
const EVERY_FOUR_CHARS = /(.{4})(?!$)/g;

export const printMaskedIbanFormat = (iban: string) =>
  iban.replace(EVERY_FOUR_CHARS, `$1 `).toUpperCase();

export const printIbanWithEllipsis = (iban: string) =>
  iban.length < 13
    ? iban
    : `${iban.substring(0, 4)}…${iban.substring(iban.length - 6, iban.length)}`;
