import { Option } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { LakeButton, LakeButtonGroup } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { filterRejectionsToResult } from "@swan-io/lake/src/utils/gql";
import { isNullish } from "@swan-io/lake/src/utils/nullish";
import { Request, badStatusToError } from "@swan-io/request";
import { FileInput } from "@swan-io/shared-business/src/components/FileInput";
import { Document } from "@swan-io/shared-business/src/components/SupportingDocumentCollection";
import { MAX_SUPPORTING_DOCUMENT_UPLOAD_SIZE } from "@swan-io/shared-business/src/constants/uploads";
import { showToast } from "@swan-io/shared-business/src/state/toasts";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { useForm } from "@swan-io/use-form";
import { useState } from "react";
import {
  GenerateSupportingDocumentUploadUrlDocument,
  SupportingDocumentPurposeEnum,
  SupportingDocumentType,
} from "../graphql/partner";
import { t } from "../utils/i18n";
import {
  validateFileRequired,
  validateNullableRequired,
  validateRequired,
} from "../utils/validations";

const documentPurposes: { name: string; value: SupportingDocumentPurposeEnum }[] = [
  {
    name: t("supportingDocument.purpose.associationRegistration"),
    value: "AssociationRegistration",
  },
  {
    name: t("supportingDocument.purpose.companyRegistration"),
    value: "CompanyRegistration",
  },
  {
    name: t("supportingDocument.purpose.other"),
    value: "Other",
  },
  {
    name: t("supportingDocument.purpose.proofOfCompanyAddress"),
    value: "ProofOfCompanyAddress",
  },
  {
    name: t("supportingDocument.purpose.proofOfCompanyIncome"),
    value: "ProofOfCompanyIncome",
  },
  {
    name: t("supportingDocument.purpose.proofOfIdentity"),
    value: "ProofOfIdentity",
  },
  {
    name: t("supportingDocument.purpose.proofOfIndividualAddress"),
    value: "ProofOfIndividualAddress",
  },
  {
    name: t("supportingDocument.purpose.proofOfIndividualIncome"),
    value: "ProofOfIndividualIncome",
  },
  {
    name: t("supportingDocument.purpose.proofOfOriginOfFunds"),
    value: "ProofOfOriginOfFunds",
  },
  {
    name: t("supportingDocument.purpose.signedStatus"),
    value: "SignedStatus",
  },
  { name: t("supportingDocument.purpose.uboDeclaration"), value: "UBODeclaration" },
];

const documentTypes: { name: string; value: SupportingDocumentType }[] = [
  {
    name: t("supportingDocument.type.accountStatement"),
    value: "AccountStatement",
  },
  {
    name: t("supportingDocument.type.articlesOfIncorporation"),
    value: "ArticlesOfIncorporation",
  },
  {
    name: t("supportingDocument.type.bankAccountDetails"),
    value: "BankStatement",
  },
  {
    name: t("supportingDocument.type.byLaws"),
    value: "ByLaws",
  },
  {
    name: t("supportingDocument.type.capitalShareDepositCertificate"),
    value: "CapitalShareDepositCertificate",
  },
  {
    name: t("supportingDocument.type.companyLeaseAgreement"),
    value: "CompanyLeaseAgreement",
  },
  {
    name: t("supportingDocument.type.corporateIncomeTaxReturn"),
    value: "CorporateIncomeTaxReturn",
  },
  {
    name: t("supportingDocument.type.deedOfSale"),
    value: "DeedOfSale",
  },
  {
    name: t("supportingDocument.type.deedOfDonation"),
    value: "DeedOfDonation",
  },
  {
    name: t("supportingDocument.type.deedOfSuccession"),
    value: "DeedOfSuccession",
  },
  {
    name: t("supportingDocument.type.drivingLicense"),
    value: "DrivingLicense",
  },
  {
    name: t("supportingDocument.type.homeInsurance"),
    value: "HomeInsurance",
  },
  {
    name: t("supportingDocument.type.incomeTaxReturn"),
    value: "IncomeTaxReturn",
  },
  {
    name: t("supportingDocument.type.JOAFFEExtract"),
    value: "JOAFFEExtract",
  },
  {
    name: t("supportingDocument.type.loanContract"),
    value: "LoanContract",
  },
  {
    name: t("supportingDocument.type.nationalIdCard"),
    value: "NationalIdCard",
  },
  {
    name: t("supportingDocument.type.notariaDeed"),
    value: "NotarialDeed",
  },
  {
    name: t("supportingDocument.type.other"),
    value: "Other",
  },
  {
    name: t("supportingDocument.type.passport"),
    value: "Passport",
  },
  {
    name: t("supportingDocument.type.paySlip"),
    value: "PaySlip",
  },
  {
    name: t("supportingDocument.type.phoneBill"),
    value: "PhoneBill",
  },
  {
    name: t("supportingDocument.type.powerOfAttorney"),
    value: "PowerOfAttorney",
  },
  {
    name: t("supportingDocument.type.registerExtract"),
    value: "RegisterExtract",
  },
  {
    name: t("supportingDocument.type.rentReceipt"),
    value: "RentReceipt",
  },
  {
    name: t("supportingDocument.type.residentPermit"),
    value: "ResidentPermit",
  },
  {
    name: t("supportingDocument.type.selfie"),
    value: "Selfie",
  },
  {
    name: t("supportingDocument.type.UBODeclaration"),
    value: "UBODeclaration",
  },
  {
    name: t("supportingDocument.type.utilityBill"),
    value: "UtilityBill",
  },
];

type Props = {
  supportingDocumentCollectionId: string;
  onSave: (document: Document<SupportingDocumentPurposeEnum>) => void;
  onPressCancel: () => void;
  canUploadSupportingDocument: boolean;
};

export const NewSupportingDocument = ({
  supportingDocumentCollectionId,
  canUploadSupportingDocument,
  onSave,
  onPressCancel,
}: Props) => {
  const [isUploading, setIsUploading] = useState(false);

  const { Field, setFieldValue, formStatus, submitForm } = useForm<{
    file: File | undefined;
    name: string;
    type: SupportingDocumentType | undefined;
    purpose: SupportingDocumentPurposeEnum | undefined;
  }>({
    file: {
      initialValue: undefined,
      validate: validateFileRequired,
    },
    name: {
      initialValue: "",
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
    type: {
      initialValue: undefined,
      validate: validateNullableRequired,
    },
    purpose: {
      initialValue: undefined,
      validate: validateNullableRequired,
    },
  });

  const [generateSupportingDocument] = useMutation(GenerateSupportingDocumentUploadUrlDocument);

  const handleSave = () => {
    submitForm({
      onSuccess: values => {
        const name = values.name;
        const file = values.file.flatMap(Option.fromNullable);
        const type = values.type.flatMap(Option.fromNullable);
        const purpose = values.purpose.flatMap(Option.fromNullable);

        if (name.isSome() && file.isSome() && type.isSome() && purpose.isSome()) {
          return generateSupportingDocument({
            input: {
              filename: name.get(),
              supportingDocumentPurpose: purpose.get(),
              supportingDocumentType: type.get(),
              supportingDocumentCollectionId,
            },
          })
            .mapOk(data => data.generateSupportingDocumentUploadUrl)
            .mapOkToResult(filterRejectionsToResult)
            .flatMapOk(({ supportingDocumentId, upload }) => {
              const body = new FormData();
              upload.fields.forEach(({ key, value }) => body.append(key, value));
              body.append("file", file.get());
              return Request.make({
                url: upload.url,
                method: "POST",
                body,
              })
                .mapOkToResult(badStatusToError)
                .mapOk(() => ({ supportingDocumentId }));
            })
            .tapOk(({ supportingDocumentId }) => {
              onSave({
                purpose: purpose.get(),
                file: {
                  id: supportingDocumentId,
                  name: name.get(),
                  statusInfo: { status: "Uploaded" },
                },
              });
            })
            .tapError(error => {
              setIsUploading(false);
              showToast({ variant: "error", error, title: translateError(error) });
            })
            .toPromise();
        }
      },
    });
  };

  return (
    <>
      <Field name="file">
        {({ error, value, onChange }) => (
          <FileInput
            layout="vertical"
            error={error}
            value={value}
            onPressRemove={() => onChange(undefined)}
            description={t("supportingDoc.documentTypes")}
            disabled={!canUploadSupportingDocument}
            onFiles={files => {
              const file = files[0];

              if (isNullish(file)) {
                return;
              }

              setFieldValue("name", file.name);
              onChange(file);
            }}
            accept={["application/pdf", "image/png", "image/jpeg"]}
            icon="document-regular"
            maxSize={MAX_SUPPORTING_DOCUMENT_UPLOAD_SIZE}
          />
        )}
      </Field>

      <LakeLabel
        label={t("supportingDocument.name")}
        render={id => (
          <Field name="name">
            {({ value, error, onChange }) => (
              <LakeTextInput id={id} error={error} onChangeText={onChange} value={value} />
            )}
          </Field>
        )}
      />

      <LakeLabel
        label={t("supportingDocument.type")}
        render={id => (
          <Field name="type">
            {({ value, error, onChange }) => (
              <LakeSelect
                id={id}
                size="small"
                error={error}
                placeholder={t("common.unknown")}
                value={value}
                items={documentTypes}
                onValueChange={onChange}
              />
            )}
          </Field>
        )}
      />

      <LakeLabel
        label={t("supportingDocument.purpose")}
        render={id => (
          <Field name="purpose">
            {({ value, error, onChange }) => (
              <LakeSelect
                id={id}
                error={error}
                size="small"
                placeholder={t("common.unknown")}
                value={value}
                items={documentPurposes}
                onValueChange={onChange}
              />
            )}
          </Field>
        )}
      />

      <LakeButtonGroup>
        <LakeButton color="gray" mode="secondary" onPress={onPressCancel} grow={true}>
          {t("common.cancel")}
        </LakeButton>

        <LakeTooltip
          placement="left"
          disabled={canUploadSupportingDocument}
          content={t("common.action.denied")}
          containerStyle={commonStyles.fill}
        >
          <LakeButton
            color="current"
            onPress={handleSave}
            disabled={!canUploadSupportingDocument}
            loading={formStatus === "submitting" || isUploading}
            grow={true}
          >
            {t("common.upload")}
          </LakeButton>
        </LakeTooltip>
      </LakeButtonGroup>
    </>
  );
};
