import { useFocusReset } from "@swan-io/chicane";
import { Box } from "@swan-io/lake/src/components/Box";
import { Breadcrumbs, BreadcrumbsRoot } from "@swan-io/lake/src/components/Breadcrumbs";
import { InformationTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Space } from "@swan-io/lake/src/components/Space";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { EditSandboxUserPage } from "../pages/EditSandboxUserPage";
import { NewSandboxUserPage } from "../pages/NewSandboxUserPage";
import { NotFoundPage } from "../pages/NotFoundPage";
import { SandboxUsersPage } from "../pages/SandboxUsersPage";
import { t } from "../utils/i18n";
import { Router, sandboxUsersRoutes } from "../utils/routes";
import { LakeDocumentationLink } from "./DocumentationLink";

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
});

export const SandboxUsersArea = () => {
  const { projectId } = useProjectInfo();
  const route = Router.useRoute(sandboxUsersRoutes);
  const containerRef = useRef(null);

  const rootLevelCrumbs = useMemo(
    () => [
      {
        label: t("sandboxUsers.all"),
        link: Router.DevelopersUsersRoot({ projectId, projectEnv: "sandbox" }),
      },
    ],
    [projectId],
  );

  useFocusReset({ route, containerRef });

  return (
    <BreadcrumbsRoot rootLevelCrumbs={rootLevelCrumbs}>
      <Box direction="row" justifyContent="spaceBetween" alignItems="center">
        <Breadcrumbs />

        <Box direction="row" justifyContent="end" alignItems="center">
          <LakeDocumentationLink to="sandboxUsers">{t("common.learnMore")}</LakeDocumentationLink>
          <Space width={16} />
          <InformationTooltip text={t("sandboxUsers.subtitle")} />
        </Box>
      </Box>

      <Space height={16} />

      <View ref={containerRef} style={styles.container}>
        {match(route)
          .with({ name: "DevelopersUsersRoot" }, ({ params }) => (
            <>
              <SandboxUsersPage />

              <LakeModal
                icon="add-circle-regular"
                title={t("sandboxUsers.newUser")}
                visible={params.new != null}
              >
                <NewSandboxUserPage
                  onSave={() =>
                    Router.push("DevelopersUsersRoot", {
                      projectId,
                      projectEnv: "sandbox",
                    })
                  }
                  onPressCancel={() =>
                    Router.push("DevelopersUsersRoot", {
                      projectId,
                      projectEnv: "sandbox",
                    })
                  }
                />
              </LakeModal>
            </>
          ))
          .with({ name: "SandboxDevelopersUsersEdit" }, ({ params: { userId } }) => (
            <EditSandboxUserPage userId={userId} />
          ))
          .otherwise(() => (
            <NotFoundPage />
          ))}
      </View>
    </BreadcrumbsRoot>
  );
};
