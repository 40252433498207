import { AsyncData, Dict, Future, Result } from "@swan-io/boxed";
import { pushUnsafe } from "@swan-io/chicane";
import { ClientError } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Breadcrumbs, useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tile, TileGrid } from "@swan-io/lake/src/components/Tile";
import { colors, invariantColors } from "@swan-io/lake/src/constants/design";
import { useEffect, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { PlayWithSandboxProjectSettingsBrandingDocument } from "../graphql/admin";
import { PlayWithSandboxCardDesignDocument } from "../graphql/exposed-internal";
import {
  PlayWithSandboxDataDocument,
  PlayWithSandboxTransactionCardDocument,
  PlayWithSandboxTransactionSctInDocument,
  PlayWithSandboxTransactionSctOutDocument,
  PlayWithSandboxWebhookSubscriptionsDocument,
} from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { env } from "../utils/env";
import {
  adminByProjectIdClient,
  adminByProjectIdClient__projectMember,
  sandboxExposedInternalByProjectIdClient,
  sandboxExposedInternalByProjectIdClient__projectMember,
  sandboxPartnerByProjectIdClient,
  sandboxPartnerByProjectIdClient__projectMember,
} from "../utils/gql";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { useTgglFlag } from "../utils/tggl";

export type SandboxData = {
  openYourFirstAccount: boolean;
  receiveFirstCreditTransfer: boolean;
  executeFirstCreditTransfer: boolean;
  designYourCard: boolean;
  issueYourFirstCard: boolean;
  simulateAFirstCardTransaction: boolean;
  uploadYourLogo: boolean;
  setYourAccentColor: boolean;
  invitePeopleToUseYourAccount: boolean;
  receiveYourFirstWebhook: boolean;
};

type ProjectActivationPlayWithSandboxDataFetcherProps = {
  onData: (data: AsyncData<Result<SandboxData, ClientError>>) => void;
};

export const ProjectActivationPlayWithSandboxDataFetcher = ({
  onData,
}: ProjectActivationPlayWithSandboxDataFetcherProps) => {
  const { projectId } = useProjectInfo();

  const shouldUseProjectMemberToken = useTgglFlag("dashboardProjectMemberToken").getOr(false);

  useEffect(() => {
    const partnerClient = shouldUseProjectMemberToken
      ? sandboxPartnerByProjectIdClient__projectMember(projectId)
      : sandboxPartnerByProjectIdClient(projectId);
    const exposedInternalClient = shouldUseProjectMemberToken
      ? sandboxExposedInternalByProjectIdClient__projectMember(projectId)
      : sandboxExposedInternalByProjectIdClient(projectId);
    const adminClient = shouldUseProjectMemberToken
      ? adminByProjectIdClient__projectMember(projectId)
      : adminByProjectIdClient(projectId);

    Future.all([
      adminClient.query(PlayWithSandboxProjectSettingsBrandingDocument, {
        projectId,
      }),
      partnerClient.query(PlayWithSandboxDataDocument, {}),
      partnerClient.query(PlayWithSandboxTransactionSctInDocument, {}),
      partnerClient.query(PlayWithSandboxTransactionSctOutDocument, {}),
      partnerClient.query(PlayWithSandboxTransactionCardDocument, {}),
      exposedInternalClient.query(PlayWithSandboxCardDesignDocument, {
        projectId,
      }),
      partnerClient.query(PlayWithSandboxWebhookSubscriptionsDocument, {}),
    ])
      .map(Result.all)
      .mapOk(
        ([
          admin,
          partner,
          transactionSctIn,
          transactionSctOut,
          transactionCard,
          exposedInternal,
          webhookSubscriptions,
        ]) => {
          return {
            openYourFirstAccount: partner.accounts.totalCount > 0,
            receiveFirstCreditTransfer: transactionSctIn.accounts.edges.some(
              item => (item.node.transactionsWithSepaCreditTransferIn?.totalCount ?? 0) > 0,
            ),
            executeFirstCreditTransfer: transactionSctOut.accounts.edges.some(
              item => (item.node.transactionsWithSepaCreditTransferOut?.totalCount ?? 0) > 0,
            ),
            designYourCard: Boolean(exposedInternal.cardSettingsByProjectId.cardProjectLogoSvgUrl),
            issueYourFirstCard: partner.cards.totalCount > 0,
            simulateAFirstCardTransaction: transactionCard.accounts.edges.some(
              item => item.node.transactionsByCard?.totalCount ?? 0 > 0,
            ),
            uploadYourLogo: Boolean(admin.project.sandboxProjectSettings?.logoUri),
            setYourAccentColor:
              Boolean(admin.project.sandboxProjectSettings?.accentColor) &&
              admin.project.sandboxProjectSettings?.accentColor !==
                invariantColors.defaultAccentColor,
            invitePeopleToUseYourAccount: partner.accountMemberships.edges.some(
              item => item.node.legalRepresentative === false,
            ),
            receiveYourFirstWebhook: webhookSubscriptions.webhookSubscriptions.totalCount > 0,
          };
        },
      )
      .tap(result => {
        onData(AsyncData.Done(result));
      });
  }, [projectId, onData, shouldUseProjectMemberToken]);

  return null;
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 24,
  },
  progressBar: {
    height: 8,
    borderRadius: 4,
    backgroundColor: colors.current[50],
  },
  progressBarProgress: {
    height: 8,
    borderRadius: 4,
    backgroundColor: colors.current[500],
    transition: "300ms ease-in-out width",
  },
  text: {
    position: "absolute",
    top: "100%",
    transform: "translateY(2px)",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  checked: {
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: colors.current.primary,
  },
  unchecked: {
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: colors.gray[100],
  },
});

const labels: Record<keyof SandboxData, string> = {
  openYourFirstAccount: t("projectActivationPlayWithSandbox.openYourFirstAccount"),
  receiveFirstCreditTransfer: t("projectActivationPlayWithSandbox.receiveFirstCreditTransfer"),
  executeFirstCreditTransfer: t("projectActivationPlayWithSandbox.executeFirstCreditTransfer"),
  designYourCard: t("projectActivationPlayWithSandbox.designYourCard"),
  issueYourFirstCard: t("projectActivationPlayWithSandbox.issueYourFirstCard"),
  simulateAFirstCardTransaction: t(
    "projectActivationPlayWithSandbox.simulateAFirstCardTransaction",
  ),
  uploadYourLogo: t("projectActivationPlayWithSandbox.uploadYourLogo"),
  setYourAccentColor: t("projectActivationPlayWithSandbox.setYourAccentColor"),
  invitePeopleToUseYourAccount: t("projectActivationPlayWithSandbox.invitePeopleToUseYourAccount"),
  receiveYourFirstWebhook: t("projectActivationPlayWithSandbox.receiveYourFirstWebhook"),
};

const ProjectActivationChecklist = ({ items }: { items: [keyof SandboxData, boolean][] }) => {
  return (
    <View>
      {items.map(([key, checked]) => (
        <View key={key} style={styles.row}>
          <View style={checked ? styles.checked : styles.unchecked} />
          <Space width={8} />

          <LakeText variant="regular" color={checked ? colors.current.primary : undefined}>
            {labels[key]}
          </LakeText>
        </View>
      ))}
    </View>
  );
};

const ProjectActivationPlayWithSandboxProgressBar = ({ percentage }: { percentage: number }) => {
  return (
    <View style={styles.container}>
      <View style={styles.progressBar}>
        <View style={[styles.progressBarProgress, { width: `${percentage * 100}%` }]}></View>

        <LakeText variant="smallMedium" style={styles.text}>{`${(percentage * 100).toFixed(
          0,
        )}%`}</LakeText>
      </View>
    </View>
  );
};

const titles = [
  t("projectActivationPlayWithSandbox.title.product"),
  t("projectActivationPlayWithSandbox.title.license"),
  t("projectActivationPlayWithSandbox.title.baas"),
  t("projectActivationPlayWithSandbox.title.heartAndSoul"),
  t("projectActivationPlayWithSandbox.title.eventSimulator"),
  t("projectActivationPlayWithSandbox.title.sweatAndBleed"),
  t("projectActivationPlayWithSandbox.title.floatSting"),
];

const goToLink = (url: string) => {
  if (url.startsWith("http")) {
    window.open(url);
  } else {
    pushUnsafe(url);
  }
};

export const ProjectActivationPlayWithSandbox = ({ sandboxData }: { sandboxData: SandboxData }) => {
  const { projectId } = useProjectInfo();
  const [title] = useState(() => titles[(titles.length * Math.random()) | 0]);

  useCrumb(
    useMemo(() => {
      return {
        label: t("projectActivation.playWithSandbox"),
        link: "?activate=true&step=PlayWithSandbox",
      };
    }, []),
  );

  // sorted by completion (completed first)
  const entries = useMemo(
    () =>
      Dict.entries(sandboxData).sort(([_a, a], [_b, b]) => (b === false && a === true ? -1 : 1)),
    [sandboxData],
  );

  const accountEntries = useMemo(
    () =>
      entries.filter(([key]) =>
        match(key)
          .with(
            "openYourFirstAccount",
            "receiveFirstCreditTransfer",
            "executeFirstCreditTransfer",
            () => true,
          )
          .otherwise(() => false),
      ),
    [entries],
  );

  const firstAccountEntryToDo = accountEntries.find(([, value]) => value === false);

  const cardEntries = useMemo(
    () =>
      entries.filter(([key]) =>
        match(key)
          .with("designYourCard", "issueYourFirstCard", "simulateAFirstCardTransaction", () => true)
          .otherwise(() => false),
      ),
    [entries],
  );

  const firstCardEntryToDo = cardEntries.find(([, value]) => value === false);

  const designEntries = useMemo(
    () =>
      entries.filter(([key]) =>
        match(key)
          .with("uploadYourLogo", "setYourAccentColor", () => true)
          .otherwise(() => false),
      ),
    [entries],
  );

  const firstDesignEntryToDo = designEntries.find(([, value]) => value === false);

  const membershipEntries = useMemo(
    () =>
      entries.filter(([key]) =>
        match(key)
          .with("invitePeopleToUseYourAccount", () => true)
          .otherwise(() => false),
      ),
    [entries],
  );

  const firstMembershipEntryToDo = membershipEntries.find(([, value]) => value === false);

  const webhookEntries = useMemo(
    () =>
      entries.filter(([key]) =>
        match(key)
          .with("receiveYourFirstWebhook", () => true)
          .otherwise(() => false),
      ),
    [entries],
  );

  const firstWebhookEntryToDo = webhookEntries.find(([, value]) => value === false);

  const links = useMemo(() => {
    const links = {
      openYourFirstAccount: Router.SettingsOnboarding({ projectId, projectEnv: "sandbox" }),
      receiveFirstCreditTransfer: Router.SandboxDevelopersSimulatorSepaCreditTransferReceptionIn({
        projectId,
      }),
      executeFirstCreditTransfer: `${env.BANKING_SANDBOX_URL}/projects/${projectId}?to=payments`,
      designYourCard: Router.SettingsCardProducts({ projectId, projectEnv: "sandbox" }),
      issueYourFirstCard: `${env.BANKING_SANDBOX_URL}/projects/${projectId}/cards`,
      simulateAFirstCardTransaction: Router.SandboxDevelopersSimulatorCardAuthorizationOut({
        projectId,
      }),
      uploadYourLogo: Router.SettingsBrandingRoot({ projectId, projectEnv: "sandbox" }),
      setYourAccentColor: Router.SettingsBrandingRoot({ projectId, projectEnv: "sandbox" }),
      invitePeopleToUseYourAccount: `${env.BANKING_SANDBOX_URL}/projects/${projectId}?to=members`,
      receiveYourFirstWebhook: Router.DevelopersWebhooksRoot({
        projectId,
        projectEnv: "sandbox",
      }),
    } as const;
    return links;
  }, [projectId]);

  const doneTasksCount = entries.filter(([_, value]) => value === true).length;

  return (
    <>
      <Breadcrumbs />
      <Space height={40} />

      <Box direction="row">
        <Tile
          flexBasis="60%"
          flexShrink={doneTasksCount < entries.length ? 1 : 0}
          flexGrow={doneTasksCount === entries.length ? 1 : 0}
        >
          <Icon name="person-regular" size={32} color={colors.current.primary} />
          <Space height={16} />

          <LakeHeading level={2} variant="h2">
            {doneTasksCount === entries.length
              ? t("projectActivationPlayWithSandbox.allDone")
              : doneTasksCount > 0
                ? t("projectActivationPlayWithSandbox.reassurance")
                : t("projectActivationPlayWithSandbox.welcome")}
          </LakeHeading>

          <Space height={12} />

          <LakeText>
            {doneTasksCount > 0 ? title : t("projectActivationPlayWithSandbox.title.skills")}
          </LakeText>

          <Space height={12} />

          <ProjectActivationPlayWithSandboxProgressBar
            percentage={doneTasksCount / entries.length}
          />
        </Tile>

        {doneTasksCount < entries.length ? (
          <>
            <Space width={24} />

            <Tile flexShrink={1} flexBasis="40%">
              <Icon name="target-arrow-regular" size={32} color={colors.current.primary} />
              <Space height={16} />

              <LakeHeading level={2} variant="h2">
                {doneTasksCount === 0
                  ? t("projectActivationPlayWithSandbox.taskOfTheDayFirst")
                  : doneTasksCount === entries.length - 1
                    ? t("projectActivationPlayWithSandbox.taskOfTheDayFinal")
                    : t("projectActivationPlayWithSandbox.taskOfTheDay")}
              </LakeHeading>

              <Space height={12} />

              <LakeText>
                {match({
                  firstDesignEntryToDo,
                  firstAccountEntryToDo,
                  firstCardEntryToDo,
                  firstMembershipEntryToDo,
                  firstWebhookEntryToDo,
                })
                  .with(
                    { firstDesignEntryToDo: P.nonNullable },
                    ({ firstDesignEntryToDo: [key] }) =>
                      t("projectActivationPlayWithSandbox.taskOfTheDay.introDesign", {
                        task: labels[key],
                      }),
                  )
                  .with(
                    { firstAccountEntryToDo: P.nonNullable },
                    ({ firstAccountEntryToDo: [key] }) =>
                      t("projectActivationPlayWithSandbox.taskOfTheDay.introAccounts", {
                        task: labels[key],
                      }),
                  )
                  .with({ firstCardEntryToDo: P.nonNullable }, ({ firstCardEntryToDo: [key] }) =>
                    t("projectActivationPlayWithSandbox.taskOfTheDay.introCards", {
                      task: labels[key],
                    }),
                  )
                  .with(
                    { firstMembershipEntryToDo: P.nonNullable },
                    ({ firstMembershipEntryToDo: [key] }) =>
                      t("projectActivationPlayWithSandbox.taskOfTheDay.introMemberships", {
                        task: labels[key],
                      }),
                  )
                  .with(
                    { firstWebhookEntryToDo: P.nonNullable },
                    ({ firstWebhookEntryToDo: [key] }) =>
                      t("projectActivationPlayWithSandbox.taskOfTheDay.introWebhooks", {
                        task: labels[key],
                      }),
                  )
                  .otherwise(() => null)}
              </LakeText>

              <Fill />
              <Space height={16} />

              <LakeButton
                color="current"
                onPress={() => {
                  const key = match({
                    firstDesignEntryToDo,
                    firstAccountEntryToDo,
                    firstCardEntryToDo,
                    firstMembershipEntryToDo,
                    firstWebhookEntryToDo,
                  })
                    .with(
                      { firstDesignEntryToDo: P.nonNullable },
                      ({ firstDesignEntryToDo: [key] }) => key,
                    )
                    .with(
                      { firstAccountEntryToDo: P.nonNullable },
                      ({ firstAccountEntryToDo: [key] }) => key,
                    )
                    .with(
                      { firstCardEntryToDo: P.nonNullable },
                      ({ firstCardEntryToDo: [key] }) => key,
                    )
                    .with(
                      { firstMembershipEntryToDo: P.nonNullable },
                      ({ firstMembershipEntryToDo: [key] }) => key,
                    )
                    .with(
                      { firstWebhookEntryToDo: P.nonNullable },
                      ({ firstWebhookEntryToDo: [key] }) => key,
                    )
                    .otherwise(() => null);
                  if (key !== null) {
                    goToLink(links[key]);
                  }
                }}
              >
                {t("projectActivationPlayWithSandbox.start")}
              </LakeButton>
            </Tile>
          </>
        ) : null}
      </Box>

      <Space height={24} />

      <LakeHeading level={2} variant="h2">
        {t("projectActivationPlayWithSandbox.tasks")}
      </LakeHeading>

      <Space height={24} />

      <TileGrid breakpoint={500}>
        <Tile>
          <Icon name="color-regular" size={32} color={colors.current.primary} />
          <Space height={16} />

          <LakeHeading level={3} variant="h5">
            {t("projectActivationPlayWithSandbox.design")}
          </LakeHeading>

          <Space height={12} />
          <ProjectActivationChecklist items={designEntries} />

          <ProjectActivationPlayWithSandboxProgressBar
            percentage={
              designEntries.filter(([_, value]) => value === true).length / designEntries.length
            }
          />

          <Space height={12} />

          <LakeButton
            color="current"
            mode={firstDesignEntryToDo == null ? "tertiary" : "primary"}
            disabled={firstDesignEntryToDo == null}
            onPress={() => {
              if (firstDesignEntryToDo != null) {
                const [key] = firstDesignEntryToDo;
                goToLink(links[key]);
              }
            }}
          >
            {firstDesignEntryToDo == null
              ? t("projectActivationPlayWithSandbox.done")
              : t("projectActivationPlayWithSandbox.start")}
          </LakeButton>
        </Tile>

        <Tile>
          <Icon name="person-regular" size={32} color={colors.current.primary} />
          <Space height={16} />

          <LakeHeading level={3} variant="h5">
            {t("projectActivationPlayWithSandbox.accounts")}
          </LakeHeading>

          <Space height={12} />
          <ProjectActivationChecklist items={accountEntries} />

          <ProjectActivationPlayWithSandboxProgressBar
            percentage={
              accountEntries.filter(([_, value]) => value === true).length / accountEntries.length
            }
          />

          <Space height={12} />

          <LakeButton
            color="current"
            mode={firstAccountEntryToDo == null ? "tertiary" : "primary"}
            disabled={firstAccountEntryToDo == null}
            onPress={() => {
              if (firstAccountEntryToDo != null) {
                const [key] = firstAccountEntryToDo;
                goToLink(links[key]);
              }
            }}
          >
            {firstAccountEntryToDo == null
              ? t("projectActivationPlayWithSandbox.done")
              : t("projectActivationPlayWithSandbox.start")}
          </LakeButton>
        </Tile>

        <Tile>
          <Icon name="payment-regular" size={32} color={colors.current.primary} />
          <Space height={16} />

          <LakeHeading level={3} variant="h5">
            {t("projectActivationPlayWithSandbox.cards")}
          </LakeHeading>

          <Space height={12} />
          <ProjectActivationChecklist items={cardEntries} />

          <ProjectActivationPlayWithSandboxProgressBar
            percentage={
              cardEntries.filter(([_, value]) => value === true).length / cardEntries.length
            }
          />

          <Space height={12} />

          <LakeButton
            color="current"
            mode={firstCardEntryToDo == null ? "tertiary" : "primary"}
            disabled={firstCardEntryToDo == null}
            onPress={() => {
              if (firstCardEntryToDo != null) {
                const [key] = firstCardEntryToDo;
                goToLink(links[key]);
              }
            }}
          >
            {firstCardEntryToDo == null
              ? t("projectActivationPlayWithSandbox.done")
              : t("projectActivationPlayWithSandbox.start")}
          </LakeButton>
        </Tile>

        <Tile>
          <Icon name="shield-checkmark-regular" size={32} color={colors.current.primary} />
          <Space height={16} />

          <LakeHeading level={3} variant="h5">
            {t("projectActivationPlayWithSandbox.memberships")}
          </LakeHeading>

          <Space height={12} />
          <ProjectActivationChecklist items={membershipEntries} />

          <ProjectActivationPlayWithSandboxProgressBar
            percentage={
              membershipEntries.filter(([_, value]) => value === true).length /
              membershipEntries.length
            }
          />

          <Space height={12} />

          <LakeButton
            color="current"
            mode={firstMembershipEntryToDo == null ? "tertiary" : "primary"}
            disabled={firstMembershipEntryToDo == null}
            onPress={() => {
              if (firstMembershipEntryToDo != null) {
                const [key] = firstMembershipEntryToDo;
                goToLink(links[key]);
              }
            }}
          >
            {firstMembershipEntryToDo == null
              ? t("projectActivationPlayWithSandbox.done")
              : t("projectActivationPlayWithSandbox.start")}
          </LakeButton>
        </Tile>

        <Tile>
          <Icon name="mail-regular" size={32} color={colors.current.primary} />
          <Space height={16} />

          <LakeHeading level={3} variant="h5">
            {t("projectActivationPlayWithSandbox.webhooks")}
          </LakeHeading>

          <Space height={12} />
          <ProjectActivationChecklist items={webhookEntries} />

          <ProjectActivationPlayWithSandboxProgressBar
            percentage={
              webhookEntries.filter(([_, value]) => value === true).length / webhookEntries.length
            }
          />

          <Space height={12} />

          <LakeButton
            color="current"
            mode={firstWebhookEntryToDo == null ? "tertiary" : "primary"}
            disabled={firstWebhookEntryToDo == null}
            onPress={() => {
              if (firstWebhookEntryToDo != null) {
                const [key] = firstWebhookEntryToDo;
                goToLink(links[key]);
              }
            }}
          >
            {firstWebhookEntryToDo == null
              ? t("projectActivationPlayWithSandbox.done")
              : t("projectActivationPlayWithSandbox.start")}
          </LakeButton>
        </Tile>
      </TileGrid>
    </>
  );
};
