import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { useMemo } from "react";
import { match } from "ts-pattern";
import { SimulatorTabs } from "../../components/SimulatorTabs";
import { useProjectInfo } from "../../hooks/useProjectInfo";
import { t } from "../../utils/i18n";
import { Router } from "../../utils/routes";
import { NotFoundPage } from "../NotFoundPage";
import { MerchantPaymentsCardsIncomingAuthorization } from "./MerchantPaymentsCardsIncomingAuthorization";
import { MerchantPaymentsCardsIncomingBooking } from "./MerchantPaymentsCardsIncomingBooking";
import { MerchantPaymentsChecksGenerateTest } from "./MerchantPaymentsChecksGenerateTest";
import { MerchantPaymentsChecksIncomingBooking } from "./MerchantPaymentsChecksIncomingBooking";
import { MerchantPaymentsChecksIncomingRejection } from "./MerchantPaymentsChecksIncomingRejection";
import { MerchantPaymentsChecksIncomingReturn } from "./MerchantPaymentsChecksIncomingReturn";
import { MerchantPaymentsInternalDirectDebitIncomingBooking } from "./MerchantPaymentsInternalDirectDebitIncomingBooking";
import { MerchantPaymentsInternalDirectDebitIncomingRejection } from "./MerchantPaymentsInternalDirectDebitIncomingRejection";
import { MerchantPaymentsInternalDirectDebitIncomingReturn } from "./MerchantPaymentsInternalDirectDebitIncomingReturn";
import { MerchantPaymentsReleaseReservedBalance } from "./MerchantPaymentsReleaseReservedBalance";
import { MerchantPaymentsRoot } from "./MerchantPaymentsRoot";
import { MerchantPaymentsSepaDirectDebitIncomingBooking } from "./MerchantPaymentsSepaDirectDebitIncomingBooking";
import { MerchantPaymentsSepaDirectDebitIncomingRejection } from "./MerchantPaymentsSepaDirectDebitIncomingRejection";
import { MerchantPaymentsSepaDirectDebitIncomingReturn } from "./MerchantPaymentsSepaDirectDebitIncomingReturn";

const MerchantPaymentsCardsArea = () => {
  const route = Router.useRoute([
    "SandboxDevelopersSimulatorMerchantPaymentsCardsIncomingAuthorization",
    "SandboxDevelopersSimulatorMerchantPaymentsCardsIncomingBooking",
    "SandboxDevelopersSimulatorMerchantPaymentsCardsReleaseReservedBalance",
  ]);

  return (
    <>
      <SimulatorTabs
        selected={route?.name}
        tabs={[
          {
            url: "SandboxDevelopersSimulatorMerchantPaymentsCardsIncomingAuthorization",
            label: t("simulatorPage.merchantPayments.card.incomingAuthorization"),
            render: () => <MerchantPaymentsCardsIncomingAuthorization />,
          },
          {
            url: "SandboxDevelopersSimulatorMerchantPaymentsCardsIncomingBooking",
            label: t("simulatorPage.merchantPayments.card.incomingBooking"),
            render: () => <MerchantPaymentsCardsIncomingBooking />,
          },
          {
            url: "SandboxDevelopersSimulatorMerchantPaymentsCardsReleaseReservedBalance",
            label: t("simulatorPage.merchantPayments.releaseReservedBalance"),
            render: () => <MerchantPaymentsReleaseReservedBalance />,
          },
        ]}
      />
    </>
  );
};

const MerchantPaymentsChecksArea = () => {
  const route = Router.useRoute([
    "SandboxDevelopersSimulatorMerchantPaymentsChecksGenerateTest",
    "SandboxDevelopersSimulatorMerchantPaymentsChecksIncomingBooking",
    "SandboxDevelopersSimulatorMerchantPaymentsChecksIncomingRejection",
    "SandboxDevelopersSimulatorMerchantPaymentsChecksIncomingReturn",
    "SandboxDevelopersSimulatorMerchantPaymentsChecksReleaseReservedBalance",
  ]);

  return (
    <>
      <SimulatorTabs
        selected={route?.name}
        tabs={[
          {
            url: "SandboxDevelopersSimulatorMerchantPaymentsChecksGenerateTest",
            label: t("simulatorPage.merchantPayments.check.generateTest"),
            render: () => <MerchantPaymentsChecksGenerateTest />,
          },
          {
            url: "SandboxDevelopersSimulatorMerchantPaymentsChecksIncomingBooking",
            label: t("simulatorPage.merchantPayments.check.incomingBooking"),
            render: () => <MerchantPaymentsChecksIncomingBooking />,
          },
          {
            url: "SandboxDevelopersSimulatorMerchantPaymentsChecksIncomingRejection",
            label: t("simulatorPage.merchantPayments.check.incomingRejection"),
            render: () => <MerchantPaymentsChecksIncomingRejection />,
          },
          {
            url: "SandboxDevelopersSimulatorMerchantPaymentsChecksIncomingReturn",
            label: t("simulatorPage.merchantPayments.check.incomingReturn"),
            render: () => <MerchantPaymentsChecksIncomingReturn />,
          },
          {
            url: "SandboxDevelopersSimulatorMerchantPaymentsChecksReleaseReservedBalance",
            label: t("simulatorPage.merchantPayments.releaseReservedBalance"),
            render: () => <MerchantPaymentsReleaseReservedBalance />,
          },
        ]}
      />
    </>
  );
};

const MerchantPaymentsSepaDirectDebitArea = () => {
  const route = Router.useRoute([
    "SandboxDevelopersSimulatorMerchantPaymentsSepaDirectDebitIncomingBooking",
    "SandboxDevelopersSimulatorMerchantPaymentsSepaDirectDebitIncomingRejection",
    "SandboxDevelopersSimulatorMerchantPaymentsSepaDirectDebitIncomingReturn",
    "SandboxDevelopersSimulatorMerchantPaymentsSepaDirectDebitReleaseReservedBalance",
  ]);

  return (
    <>
      <SimulatorTabs
        selected={route?.name}
        tabs={[
          {
            url: "SandboxDevelopersSimulatorMerchantPaymentsSepaDirectDebitIncomingBooking",
            label: t("simulatorPage.merchantPayments.sepaDirectDebit.incomingBooking"),
            render: () => <MerchantPaymentsSepaDirectDebitIncomingBooking />,
          },
          {
            url: "SandboxDevelopersSimulatorMerchantPaymentsSepaDirectDebitIncomingRejection",
            label: t("simulatorPage.merchantPayments.sepaDirectDebit.incomingRejection"),
            render: () => <MerchantPaymentsSepaDirectDebitIncomingRejection />,
          },
          {
            url: "SandboxDevelopersSimulatorMerchantPaymentsSepaDirectDebitIncomingReturn",
            label: t("simulatorPage.merchantPayments.sepaDirectDebit.incomingReturn"),
            render: () => <MerchantPaymentsSepaDirectDebitIncomingReturn />,
          },
          {
            url: "SandboxDevelopersSimulatorMerchantPaymentsSepaDirectDebitReleaseReservedBalance",
            label: t("simulatorPage.merchantPayments.releaseReservedBalance"),
            render: () => <MerchantPaymentsReleaseReservedBalance />,
          },
        ]}
      />
    </>
  );
};

const MerchantPaymentsInternalDirectDebitArea = () => {
  const route = Router.useRoute([
    "SandboxDevelopersSimulatorMerchantPaymentsInternalDirectDebitIncomingBooking",
    "SandboxDevelopersSimulatorMerchantPaymentsInternalDirectDebitIncomingRejection",
    "SandboxDevelopersSimulatorMerchantPaymentsInternalDirectDebitIncomingReturn",
    "SandboxDevelopersSimulatorMerchantPaymentsInternalDirectDebitReleaseReservedBalance",
  ]);

  return (
    <>
      <SimulatorTabs
        selected={route?.name}
        tabs={[
          {
            url: "SandboxDevelopersSimulatorMerchantPaymentsInternalDirectDebitIncomingBooking",
            label: t("simulatorPage.merchantPayments.internalDirectDebit.incomingBooking"),
            render: () => <MerchantPaymentsInternalDirectDebitIncomingBooking />,
          },
          {
            url: "SandboxDevelopersSimulatorMerchantPaymentsInternalDirectDebitIncomingRejection",
            label: t("simulatorPage.merchantPayments.internalDirectDebit.incomingRejection"),
            render: () => <MerchantPaymentsInternalDirectDebitIncomingRejection />,
          },
          {
            url: "SandboxDevelopersSimulatorMerchantPaymentsInternalDirectDebitIncomingReturn",
            label: t("simulatorPage.merchantPayments.internalDirectDebit.incomingReturn"),
            render: () => <MerchantPaymentsInternalDirectDebitIncomingReturn />,
          },
          {
            url: "SandboxDevelopersSimulatorMerchantPaymentsInternalDirectDebitReleaseReservedBalance",
            label: t("simulatorPage.merchantPayments.releaseReservedBalance"),
            render: () => <MerchantPaymentsReleaseReservedBalance />,
          },
        ]}
      />
    </>
  );
};

export const MerchantPaymentsArea = () => {
  const { projectId } = useProjectInfo();
  const route = Router.useRoute([
    "SandboxDevelopersSimulatorMerchantPaymentsRoot",
    "SandboxDevelopersSimulatorMerchantPaymentsCardsArea",
    "SandboxDevelopersSimulatorMerchantPaymentsChecksArea",
    "SandboxDevelopersSimulatorMerchantPaymentsSepaDirectDebitArea",
    "SandboxDevelopersSimulatorMerchantPaymentsInternalDirectDebitArea",
  ]);

  const siblings = useMemo(
    () => [
      {
        url: Router.SandboxDevelopersSimulatorMerchantPaymentsCardsIncomingAuthorization({
          projectId,
        }),
        isMatching: route?.name === "SandboxDevelopersSimulatorMerchantPaymentsCardsArea",
        label: t("simulatorPage.merchantPayments.card"),
      },
      {
        url: Router.SandboxDevelopersSimulatorMerchantPaymentsChecksGenerateTest({
          projectId,
        }),
        isMatching: route?.name === "SandboxDevelopersSimulatorMerchantPaymentsChecksArea",
        label: t("simulatorPage.merchantPayments.check"),
      },
      {
        url: Router.SandboxDevelopersSimulatorMerchantPaymentsSepaDirectDebitIncomingBooking({
          projectId,
        }),
        isMatching: route?.name === "SandboxDevelopersSimulatorMerchantPaymentsSepaDirectDebitArea",
        label: t("simulatorPage.merchantPayments.sepaDirectDebit"),
      },
      {
        url: Router.SandboxDevelopersSimulatorMerchantPaymentsInternalDirectDebitIncomingBooking({
          projectId,
        }),
        isMatching:
          route?.name === "SandboxDevelopersSimulatorMerchantPaymentsInternalDirectDebitArea",
        label: t("simulatorPage.merchantPayments.internalDirectDebit"),
      },
    ],
    [projectId, route?.name],
  );

  useCrumb(
    useMemo(() => {
      const item = siblings.find(item => item.isMatching);
      if (item == null) {
        return undefined;
      }
      return {
        label: item.label,
        link: item.url,
        siblings,
      };
    }, [siblings]),
  );

  return match(route)
    .with({ name: "SandboxDevelopersSimulatorMerchantPaymentsRoot" }, () => (
      <MerchantPaymentsRoot />
    ))
    .with({ name: "SandboxDevelopersSimulatorMerchantPaymentsCardsArea" }, () => (
      <MerchantPaymentsCardsArea />
    ))
    .with({ name: "SandboxDevelopersSimulatorMerchantPaymentsChecksArea" }, () => (
      <MerchantPaymentsChecksArea />
    ))
    .with({ name: "SandboxDevelopersSimulatorMerchantPaymentsSepaDirectDebitArea" }, () => (
      <MerchantPaymentsSepaDirectDebitArea />
    ))
    .with({ name: "SandboxDevelopersSimulatorMerchantPaymentsInternalDirectDebitArea" }, () => (
      <MerchantPaymentsInternalDirectDebitArea />
    ))
    .otherwise(() => <NotFoundPage />);
};
