import { Option } from "@swan-io/boxed";
import { useMutation } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Grid } from "@swan-io/lake/src/components/Grid";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeLabelledCheckbox } from "@swan-io/lake/src/components/LakeCheckbox";
import { LakeLabel } from "@swan-io/lake/src/components/LakeLabel";
import { LakeRadio } from "@swan-io/lake/src/components/LakeRadio";
import { LakeSelect } from "@swan-io/lake/src/components/LakeSelect";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { LakeTextInput } from "@swan-io/lake/src/components/LakeTextInput";
import { Pressable } from "@swan-io/lake/src/components/Pressable";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { colors, radii, spacings } from "@swan-io/lake/src/constants/design";
import { countries as countryList } from "@swan-io/shared-business/src/constants/countries";
import { useForm } from "@swan-io/use-form";
import dayjs from "dayjs";
import { useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { Rifm } from "rifm";
import { P, match } from "ts-pattern";
import {
  CreateSandboxUserInput,
  EndorseSandboxUserDocument,
  SandboxUserFragment,
} from "../graphql/sandbox-partner-admin";
import { locale, rifmDateProps, t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { validateDate, validateRequired } from "../utils/validations";
import { TrackPressable } from "./TrackPressable";

const API_BIRTHDATE_FORMAT = "YYYY-MM-DD";

const styles = StyleSheet.create({
  optionFlag: {
    display: "inline-block",
    width: 21,
  },
  autoConsent: {
    flexGrow: 1,
    justifyContent: "center",
  },
  identificationLevels: {
    backgroundColor: colors.gray[50],
    flexGrow: 1,
    paddingHorizontal: spacings[8],
    height: 40,
    minWidth: 0,
    borderRadius: radii[6],
    overflow: "hidden",
  },
});

type Props = {
  projectId: string;
  user: SandboxUserFragment;
  onSave: (value: CreateSandboxUserInput) => Promise<unknown>;
};

export const SandboxUserEditor = ({ projectId, user, onSave }: Props) => {
  const { Field, FieldsListener, submitForm, formStatus } = useForm({
    firstName: {
      initialValue: user.firstName ?? "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
    lastName: {
      initialValue: user.lastName ?? "",
      strategy: "onBlur",
      validate: validateRequired,
      sanitize: value => value.trim(),
    },
    birthCountryCCA3: {
      initialValue: user.birthCountryCCA3 ?? "FRA",
      validate: validateRequired,
    },
    birthDate: {
      initialValue: match(user.birthDate)
        .with(P.nullish, () => "")
        .otherwise(value => dayjs(value, API_BIRTHDATE_FORMAT, true).format(locale.dateFormat)),
      validate: validateDate,
    },
    autoConsent: {
      initialValue: Boolean(user.autoConsent),
    },
    nationalityCCA3: {
      initialValue: user.nationalityCCA3 ?? "FRA",
    },
  });

  const [endorseSandboxUser, sandboxUserEndorsement] = useMutation(EndorseSandboxUserDocument);

  const logAs = () => {
    void endorseSandboxUser({ input: { id: user.id } });
  };

  const handleSave = () => {
    submitForm({
      onSuccess: values =>
        Option.allFromDict(values)
          .map(({ birthDate, ...values }) =>
            onSave({
              birthDate: dayjs(birthDate, locale.dateFormat, true).format(API_BIRTHDATE_FORMAT),
              ...values,
            }),
          )
          .toUndefined(),
    });
  };

  const countries = useMemo(
    () =>
      countryList.map(country => ({
        name: country.name,
        icon: (
          <LakeText color={colors.gray[900]} style={styles.optionFlag}>
            {country.flag}
          </LakeText>
        ),
        value: country.cca3,
      })),
    [],
  );

  const isExpert = user.identificationLevels?.expert ?? false;
  const isQES = user.identificationLevels?.QES ?? false;
  const isPVID = user.identificationLevels?.PVID ?? false;

  return (
    <ResponsiveContainer>
      {({ large }) => (
        <>
          <View style={styles.autoConsent}>
            <Box direction="row" alignItems="center">
              <TrackPressable action="Log as sandbox user">
                <Pressable onPress={logAs}>
                  <Box direction="row" alignItems="center">
                    <LakeRadio
                      value={user.isActive === true}
                      color="current"
                      disabled={sandboxUserEndorsement.isLoading()}
                    />

                    <Space width={8} />

                    <FieldsListener names={["firstName", "lastName"]}>
                      {({ firstName, lastName }) => (
                        <LakeText color={colors.gray[900]}>
                          {t("sandboxUsers.form.logAs", {
                            fullname: [firstName.value, lastName.value].filter(Boolean).join(" "),
                          })}
                        </LakeText>
                      )}
                    </FieldsListener>
                  </Box>
                </Pressable>
              </TrackPressable>

              <Space width={24} />

              <Field name="autoConsent">
                {({ value, onChange }) => (
                  <LakeLabelledCheckbox
                    value={value}
                    onValueChange={onChange}
                    label={t("sandboxUsers.form.autoConsent")}
                  />
                )}
              </Field>
            </Box>

            <Space height={24} />
          </View>

          <Grid numColumns={large ? 2 : 1} horizontalSpace={40}>
            <Field name="firstName">
              {({ value, valid, error, onChange, onBlur }) => (
                <LakeLabel
                  label={t("sandboxUsers.form.firstName")}
                  render={id => (
                    <LakeTextInput
                      id={id}
                      value={value}
                      placeholder="John"
                      onChangeText={onChange}
                      onBlur={onBlur}
                      valid={valid}
                      error={error}
                    />
                  )}
                />
              )}
            </Field>

            <Field name="lastName">
              {({ value, valid, error, onChange, onBlur }) => (
                <LakeLabel
                  label={t("sandboxUsers.form.lastName")}
                  render={id => (
                    <LakeTextInput
                      id={id}
                      value={value}
                      placeholder="Doe"
                      onChangeText={onChange}
                      onBlur={onBlur}
                      valid={valid}
                      error={error}
                    />
                  )}
                />
              )}
            </Field>

            <Field name="nationalityCCA3">
              {({ value, onChange }) => (
                <LakeLabel
                  label={t("sandboxUsers.form.nationality")}
                  render={id => (
                    <LakeSelect id={id} value={value} items={countries} onValueChange={onChange} />
                  )}
                />
              )}
            </Field>

            <Field name="birthDate">
              {({ value, valid, error, onChange, onBlur }) => (
                <LakeLabel
                  label={t("sandboxUsers.form.birthdate")}
                  render={id => (
                    <Rifm value={value} onChange={onChange} {...rifmDateProps}>
                      {({ value, onChange }) => (
                        <LakeTextInput
                          id={id}
                          value={value}
                          placeholder={locale.datePlaceholder}
                          onChange={onChange}
                          onBlur={onBlur}
                          valid={valid}
                          error={error}
                        />
                      )}
                    </Rifm>
                  )}
                />
              )}
            </Field>

            <Field name="birthCountryCCA3">
              {({ value, onChange }) => (
                <LakeLabel
                  label={t("sandboxUsers.form.birthCountry")}
                  render={id => (
                    <LakeSelect id={id} value={value} items={countries} onValueChange={onChange} />
                  )}
                />
              )}
            </Field>

            <LakeLabel
              label={t("sandboxUsers.form.identificationLevels")}
              render={() => (
                <Box direction="row" alignItems="center">
                  <Box direction="row" alignItems="center" style={styles.identificationLevels}>
                    <Tag
                      label={t("user.details.user.identificationLevel.expert")}
                      color={isExpert ? "positive" : "gray"}
                    >
                      {isExpert ? t("common.true") : t("common.false")}
                    </Tag>

                    <Space width={12} />

                    <Tag
                      label={t("user.details.user.identificationLevel.qes")}
                      color={isQES ? "positive" : "gray"}
                    >
                      {isQES ? t("common.true") : t("common.false")}
                    </Tag>

                    <Space width={12} />

                    <Tag
                      label={t("user.details.user.identificationLevel.pvid")}
                      color={isPVID ? "positive" : "gray"}
                    >
                      {isPVID ? t("common.true") : t("common.false")}
                    </Tag>
                  </Box>

                  <Space width={8} />

                  <LakeButton
                    onPress={() =>
                      Router.push("SandboxDevelopersSimulatorSandboxUsersCreateIdentification", {
                        projectId,
                        userId: user.id,
                      })
                    }
                    icon="arrow-right-filled"
                    mode="secondary"
                    size="small"
                  >
                    {t("sandboxUsers.form.updateIdentifications")}
                  </LakeButton>
                </Box>
              )}
            />
          </Grid>

          <Space height={40} />

          <Box direction="row">
            <TrackPressable action="Save sandbox user">
              <LakeButton
                size="small"
                onPress={handleSave}
                color="current"
                loading={formStatus === "submitting"}
              >
                {t("common.save")}
              </LakeButton>
            </TrackPressable>
          </Box>
        </>
      )}
    </ResponsiveContainer>
  );
};
