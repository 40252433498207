import { AsyncData, Result } from "@swan-io/boxed";
import { ClientError, useDeferredQuery, useQuery } from "@swan-io/graphql-client";
import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { TileGridPlaceholder } from "@swan-io/lake/src/components/TilePlaceholder";
import { useEffect, useMemo } from "react";
import { match, P } from "ts-pattern";
import { UserAreaDocument, UserCardsCountDocument } from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { NotFoundPage } from "../pages/NotFoundPage";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { ErrorView } from "./ErrorView";
import { UserDetailAccounts } from "./UserDetailAccounts";
import { ACTIVE_STATUSES, UserDetailCards } from "./UserDetailCards";
import { UserDetailConsents } from "./UserDetailConsents";
import { UserDetailGeneral } from "./UserDetailGeneral";
import { UserDetailIdentifications } from "./UserDetailIdentifications";

type Props = {
  userId: string;
};

export const UserDetailArea = ({ userId }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const [user] = useQuery(UserAreaDocument, {
    userId,
  });
  const [cards, { query }] = useDeferredQuery(UserCardsCountDocument);

  const route = Router.useRoute([
    "UserDetailRoot",
    "UserDetailIdentifications",
    "UserDetailAccountMemberships",
    "UserDetailCards",
    "UserDetailConsents",
  ]);

  useEffect(() => {
    match({ user, cards }).with(
      {
        user: AsyncData.P.Done(Result.P.Ok({ user: P.select(P.nonNullable) })),
        cards: AsyncData.P.NotAsked,
      },
      user => {
        query({ fullName: user.fullName ?? "", statuses: ACTIVE_STATUSES });
      },
    );
  }, [user, cards, query]);

  useCrumb(
    useMemo(
      () =>
        match(user)
          .with(AsyncData.P.Done(Result.P.Ok({ user: P.select(P.nonNullable) })), user => ({
            label: user.fullName ?? "",
            link: Router.UserDetailRoot({ projectId, projectEnv, userId }),
          }))
          .otherwise(() => undefined),
      [user, projectEnv, projectId, userId],
    ),
  );

  useEffect(() => {
    match(user)
      .with(AsyncData.P.Done(Result.P.Error(P.select())), errors => {
        ClientError.forEach(errors, error => {
          match(error)
            .with({ name: "GraphQLError", message: P.string.includes("not found") }, () => {
              Router.replace("UserList", { projectEnv, projectId });
            })
            .otherwise(() => {});
        });
      })
      .otherwise(() => {});
  }, [user, projectEnv, projectId]);

  return match(AsyncData.allFromDict({ user, cards }).map(Result.allFromDict))
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <TileGridPlaceholder withTabs={true} />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(
      AsyncData.P.Done(
        Result.P.Ok({
          user: { user: P.select("user", P.nonNullable), consents: P.select("consents") },
          cards: P.select("cards"),
        }),
      ),
      ({ user, cards, consents }) => {
        return (
          <>
            <TabView
              tabs={[
                {
                  label: t("user.detail.general"),
                  url: Router.UserDetailRoot({ projectId, projectEnv, userId }),
                },
                {
                  label: t("user.detail.identifications"),
                  count: user.identifications?.totalCount ?? 0,
                  url: Router.UserDetailIdentifications({ projectId, projectEnv, userId }),
                },
                {
                  label: t("user.detail.accounts"),
                  count: user.accountMemberships.totalCount ?? 0,
                  url: Router.UserDetailAccountMemberships({ projectId, projectEnv, userId }),
                },
                {
                  label: t("user.detail.cards"),
                  count: cards.cards.totalCount,
                  url: Router.UserDetailCards({ projectId, projectEnv, userId }),
                },
                {
                  label: t("user.detail.consents"),
                  count: consents.totalCount,
                  url: Router.UserDetailConsents({ projectId, projectEnv, userId }),
                },
              ]}
              otherLabel={t("common.tabs.other")}
            />

            <Space height={24} />

            {match(route)
              .with({ name: "UserDetailRoot" }, () => (
                <>
                  <UserDetailGeneral user={user} />
                  <Space height={24} />
                </>
              ))
              .with({ name: "UserDetailIdentifications" }, ({ params }) => (
                <UserDetailIdentifications userId={userId} params={params} />
              ))
              .with({ name: "UserDetailAccountMemberships" }, () => (
                <UserDetailAccounts userId={userId} />
              ))
              .with({ name: "UserDetailCards" }, ({ params }) => (
                <UserDetailCards fullName={user.fullName ?? ""} params={params} />
              ))
              .with({ name: "UserDetailConsents" }, () => <UserDetailConsents userId={userId} />)
              .otherwise(() => (
                <NotFoundPage />
              ))}
          </>
        );
      },
    )
    .otherwise(() => <NotFoundPage />);
};
