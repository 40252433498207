import { useFocusReset } from "@swan-io/chicane";
import { Box } from "@swan-io/lake/src/components/Box";
import { Breadcrumbs, BreadcrumbsRoot } from "@swan-io/lake/src/components/Breadcrumbs";
import { InformationTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Space } from "@swan-io/lake/src/components/Space";
import { useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { AccountHolderListPage } from "../pages/AccountHolderListPage";
import { AccountHolderVerificationListPage } from "../pages/AccountHolderVerificationListPage";
import { NotFoundPage } from "../pages/NotFoundPage";
import { t } from "../utils/i18n";
import { Router, accountHoldersRoutes } from "../utils/routes";
import { AccountHolderDetail } from "./AccountHolderDetail";
import { LakeDocumentationLink } from "./DocumentationLink";

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
});

export const AccountHoldersArea = () => {
  const { projectId, projectEnv } = useProjectInfo();

  const route = Router.useRoute(accountHoldersRoutes);
  const containerRef = useRef(null);

  const rootLevelCrumbs = useMemo(
    () => [
      {
        label: t("accountHolders.all"),
        link: Router.HoldersRoot({ projectId, projectEnv }),
      },
    ],
    [projectEnv, projectId],
  );

  useFocusReset({ route, containerRef });

  return (
    <BreadcrumbsRoot rootLevelCrumbs={rootLevelCrumbs}>
      <Box direction="row" justifyContent="spaceBetween" alignItems="center">
        <Breadcrumbs />

        <Box direction="row" justifyContent="end" alignItems="center">
          <LakeDocumentationLink to="accountHolder">{t("common.learnMore")}</LakeDocumentationLink>
          <Space width={16} />
          <InformationTooltip text={t("accounts.subtitle")} />
        </Box>
      </Box>

      <Space height={16} />

      <View ref={containerRef} style={styles.container}>
        {match(route)
          .with({ name: "HoldersRoot" }, ({ params }) => <AccountHolderListPage params={params} />)
          .with({ name: "HoldersVerificationArea" }, ({ params }) => (
            <AccountHolderVerificationListPage params={params} />
          ))
          .with({ name: "HoldersDetailArea" }, ({ params: { accountHolderId } }) => (
            <AccountHolderDetail accountHolderId={accountHolderId} />
          ))
          .otherwise(() => (
            <NotFoundPage />
          ))}
      </View>
    </BreadcrumbsRoot>
  );
};
