import { Option } from "@swan-io/boxed";
import { GetEdge, GetNode } from "@swan-io/lake/src/utils/types";
import { P, match } from "ts-pattern";
import { CardListFragment } from "../graphql/partner";

// https://github.com/arhs/iban.js/blob/0aa5dbe8a75b6ca2a515408c45e07499e2747618/iban.js#L341
const EVERY_FOUR_CHARS = /(.{4})(?!$)/g;
const X_LETTER = /X/gi;

export type Edge = GetEdge<CardListFragment>;
export type Card = GetNode<CardListFragment>;

export const printMaskedPanFormat = (pan: string) =>
  pan.replace(EVERY_FOUR_CHARS, `$1 `).replace(X_LETTER, "•");

export const getCardHolderName = <T extends { __typename: "Card" }>(card: T | null | undefined) =>
  match(card as unknown)
    .with(
      {
        accountMembership: {
          statusInfo: P.union(
            {
              __typename: "AccountMembershipConsentPendingStatusInfo",
              restrictedTo: { firstName: P.string, lastName: P.string },
            },
            {
              __typename: "AccountMembershipInvitationSentStatusInfo",
              restrictedTo: { firstName: P.string, lastName: P.string },
            },
          ),
        },
      },
      ({ accountMembership: { statusInfo } }) =>
        Option.Some(`${statusInfo.restrictedTo.firstName} ${statusInfo.restrictedTo.lastName}`),
    )
    .with({ accountMembership: { user: { fullName: P.select(P.string) } } }, fullName =>
      Option.Some(fullName),
    )
    .otherwise(() => Option.None());
