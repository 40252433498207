import {
  ActionCell,
  BalanceCell,
  Cell,
  CopyableTextCell,
  HeaderCell,
  TextCell,
} from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnCellConfig,
  ColumnConfig,
  ColumnTitleConfig,
  LinkConfig,
  VirtualizedList,
} from "@swan-io/lake/src/components/VirtualizedList";
import { colors, negativeSpacings } from "@swan-io/lake/src/constants/design";
import { isNotNullish, isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { GetEdge } from "@swan-io/lake/src/utils/types";
import dayjs from "dayjs";
import { isValid } from "iban";
import { ReactElement } from "react";
import { match } from "ts-pattern";
import { TransactionListFragment, TransactionsOrderByInput } from "../graphql/partner";
import { ProjectEnv } from "../hooks/useProjectInfo";
import { printMaskedPanFormat } from "../utils/card";
import { formatCurrency, locale, t } from "../utils/i18n";
import { printMaskedIbanFormat } from "../utils/iban";
import { PaymentProductTag } from "./PaymentProductTag";
import { TrackPressable } from "./TrackPressable";

export type ExtraInfo = {
  projectEnv: ProjectEnv;
  projectId: string;
  onChangeSort?: (sortBy: TransactionsOrderByInput) => void;
  sortBy?: TransactionsOrderByInput;
  reexecuteQuery: () => void;
};

type Edge = GetEdge<TransactionListFragment>;

type Props = {
  transactions: TransactionListFragment["edges"];
  onEndReached: () => void;
  perPage: number;
  isLoading: boolean;
  extraInfo: ExtraInfo;
  getRowLink?: (config: LinkConfig<Edge, ExtraInfo>) => ReactElement | undefined;
  emptyListTitle: string;
  hasSearchOrFilters: boolean;
};

const keyExtractor = ({ node: { id } }: Edge) => id;

const stickedToStartColumns = [
  {
    width: 180,
    id: "executionDate",
    title: t("transaction.executionDate"),
    renderTitle: ({ title, extraInfo }: ColumnTitleConfig<ExtraInfo>) => (
      <TrackPressable action="Sort transactions by execution date">
        <HeaderCell
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "executionDate", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "executionDate"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
          text={title}
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { executionDate },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <TextCell text={dayjs(executionDate).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
    ),
  },
];
const columns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 120,
    id: "status",
    title: t("transaction.status"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: {
          statusInfo: { status },
        },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <Cell>
        {match(status)
          .with("Booked", value => <Tag color="positive">{value}</Tag>)
          .with("Rejected", value => <Tag color="negative">{value}</Tag>)
          .with("Pending", value => <Tag color="shakespear">{value}</Tag>)
          .with("Canceled", value => <Tag color="gray">{value}</Tag>)
          .with("Upcoming", value => <Tag color="shakespear">{value}</Tag>)
          .with("Released", value => <Tag color="gray">{value}</Tag>)
          .exhaustive()}
      </Cell>
    ),
  },
  {
    width: 200,
    id: "label",
    title: t("transaction.label"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { label },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => <TextCell text={label} />,
  },

  {
    width: 200,
    id: "id",
    title: t("transaction.id"),
    renderTitle: ({ title, extraInfo }: ColumnTitleConfig<ExtraInfo>) => (
      <TrackPressable action="Sort transactions by ID">
        <HeaderCell
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "id", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "id"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
          text={title}
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { id },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <CopyableTextCell
        text={id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    width: 200,
    id: "paymentProduct",
    title: t("transaction.paymentProduct"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { paymentProduct },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <Cell>
        <PaymentProductTag paymentProduct={paymentProduct} />
      </Cell>
    ),
  },
  {
    width: 250,
    id: "type",
    title: t("transaction.type"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { type },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <Cell>
        <Tag color="gray">{type}</Tag>
      </Cell>
    ),
  },
  {
    width: 300,
    id: "paymentMethod",
    title: t("transaction.paymentMethod"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { paymentMethodIdentifier },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <TextCell
        text={
          isValid(paymentMethodIdentifier)
            ? printMaskedIbanFormat(paymentMethodIdentifier)
            : printMaskedPanFormat(paymentMethodIdentifier)
        }
      />
    ),
  },
  {
    width: 180,
    id: "counterparty",
    title: t("transaction.counterparty"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { counterparty },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => <TextCell text={counterparty} />,
  },
  {
    width: 200,
    id: "createdAt",
    title: t("transaction.createdAt"),
    renderTitle: ({ title, extraInfo }: ColumnTitleConfig<ExtraInfo>) => (
      <TrackPressable action="Sort transactions by creation date">
        <HeaderCell
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "createdAt", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "createdAt"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
          text={title}
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { createdAt },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <TextCell text={dayjs(createdAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
    ),
  },
  {
    width: 200,
    id: "updatedAt",
    title: t("transaction.updatedAt"),
    renderTitle: ({ title, extraInfo }: ColumnTitleConfig<ExtraInfo>) => (
      <TrackPressable action="Sort transactions by update date">
        <HeaderCell
          onPress={direction => {
            extraInfo.onChangeSort?.({ field: "updatedAt", direction });
          }}
          sort={
            extraInfo.sortBy?.field === "updatedAt"
              ? (extraInfo.sortBy?.direction ?? undefined)
              : undefined
          }
          text={title}
        />
      </TrackPressable>
    ),
    renderCell: ({
      item: {
        node: { updatedAt },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <TextCell text={dayjs(updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
    ),
  },

  {
    width: 180,
    id: "reference",
    title: t("transaction.reference"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { reference },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) =>
      isNotNullishOrEmpty(reference) ? (
        <CopyableTextCell
          text={reference}
          copyWording={t("copyButton.copyTooltip")}
          copiedWording={t("copyButton.copiedTooltip")}
        />
      ) : null,
  },
  {
    width: 180,
    id: "externalReference",
    title: t("transaction.externalReference"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { externalReference },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) =>
      isNotNullish(externalReference) ? <TextCell text={externalReference} /> : null,
  },
  {
    width: 200,
    id: "bookedBalanceAfter",
    title: t("transaction.bookedBalanceAfter"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => <HeaderCell text={title} />,
    renderCell: ({
      item: {
        node: { bookedBalanceAfter },
      },
    }: ColumnCellConfig<Edge, ExtraInfo>) =>
      isNotNullish(bookedBalanceAfter) ? (
        <BalanceCell
          currency={bookedBalanceAfter.currency}
          value={Number(bookedBalanceAfter.value)}
          formatCurrency={formatCurrency}
        />
      ) : null,
  },
];

const stickedToEndColumns: ColumnConfig<Edge, ExtraInfo>[] = [
  {
    width: 150,
    id: "amount",
    title: t("transaction.amount"),
    renderTitle: ({ title }: ColumnTitleConfig<ExtraInfo>) => (
      <HeaderCell align="right" text={title} />
    ),
    renderCell: ({ item: { node } }: ColumnCellConfig<Edge, ExtraInfo>) => {
      const {
        amount: { currency, value },
        side,
      } = node;

      const originalAmount = match(node)
        .with({ __typename: "CardTransaction" }, ({ originalAmount: { value, currency } }) => {
          const unsigned = Number(value);
          return {
            value:
              unsigned *
              (unsigned === 0
                ? 1
                : match(side)
                    .with("Debit", () => -1)
                    .with("Credit", () => 1)
                    .exhaustive()),
            currency,
          };
        })
        .otherwise(() => undefined);

      const unsigned = Number(value);
      return (
        <BalanceCell
          currency={currency}
          value={
            unsigned *
            (unsigned === 0
              ? 1
              : match(side)
                  .with("Debit", () => -1)
                  .with("Credit", () => 1)
                  .exhaustive())
          }
          originalValue={originalAmount}
          formatCurrency={formatCurrency}
        />
      );
    },
  },
  {
    width: 48,
    id: "actions",
    title: t("common.table.actions"),
    renderTitle: () => null,
    renderCell: ({ isHovered }: ColumnCellConfig<Edge, ExtraInfo>) => (
      <Cell align="right">
        <ActionCell>
          <Icon
            name="chevron-right-filled"
            color={isHovered ? colors.gray[700] : colors.gray[200]}
            size={16}
          />
        </ActionCell>
      </Cell>
    ),
  },
];

export const AccountDetailTransactionList = ({
  transactions,
  onEndReached,
  isLoading,
  perPage,
  extraInfo,
  getRowLink,
  emptyListTitle,
  hasSearchOrFilters,
}: Props) => {
  return (
    <VirtualizedList
      variant="default"
      marginHorizontal={negativeSpacings[24]}
      extraInfo={extraInfo}
      getRowLink={getRowLink}
      keyExtractor={keyExtractor}
      data={transactions}
      stickedToStartColumns={stickedToStartColumns}
      columns={columns}
      stickedToEndColumns={stickedToEndColumns}
      onEndReached={onEndReached}
      headerHeight={48}
      rowHeight={48}
      loading={{
        isLoading,
        count: perPage,
      }}
      renderEmptyList={() =>
        hasSearchOrFilters ? (
          <EmptyView
            icon="clipboard-search-regular"
            title={t("common.list.noResults")}
            subtitle={t("common.list.noResultsSuggestion")}
          />
        ) : (
          <EmptyView icon="lake-inbox-empty" title={emptyListTitle} />
        )
      }
    />
  );
};
