import { Array, AsyncData, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Avatar } from "@swan-io/lake/src/components/Avatar";
import { Box } from "@swan-io/lake/src/components/Box";
import { Cell, CopyableTextCell, HeaderCell } from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeSearchField } from "@swan-io/lake/src/components/LakeSearchField";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { InformationTooltip, LakeTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Link } from "@swan-io/lake/src/components/Link";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { Pressable } from "@swan-io/lake/src/components/Pressable";
import { ScrollView } from "@swan-io/lake/src/components/ScrollView";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnConfig,
  LinkConfig,
  VirtualizedList,
} from "@swan-io/lake/src/components/VirtualizedList";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import {
  backgroundColor,
  colors,
  negativeSpacings,
  spacings,
} from "@swan-io/lake/src/constants/design";
import { isNotNullish, isNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { CollapsibleLakeAlert } from "@swan-io/shared-business/src/components/CollapsibleLakeAlert";
import { useCallback, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { ErrorView } from "../components/ErrorView";
import { AddMemberPanel } from "../components/MemberPanels/AddMemberPanel";
import { DeleteMemberConfirmationModal } from "../components/MemberPanels/DeleteMemberConfirmationModal";
import { EditMembersPanel } from "../components/MemberPanels/EditMemberPanel";
import { RoleTooltip } from "../components/MemberPanels/ProjectMembershipEditor";
import { TrackPressable } from "../components/TrackPressable";
import { GetMembersPageDocument, ProjectMembershipFragment } from "../graphql/admin";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { formatCount, isTranslationKey, t } from "../utils/i18n";
import { useFiltersTracking } from "../utils/matomo";
import { Role } from "../utils/permissions";
import { Router, adminRoutes } from "../utils/routes";

const styles = StyleSheet.create({
  base: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: "stretch",
  },
  contentContainer: {
    marginHorizontal: "auto",
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: colors.gray[50],
    backgroundColor: backgroundColor.default,
  },
  container: {
    flexGrow: 1,
    flexShrink: 1,
    maxWidth: 2560,
    marginHorizontal: "auto",
    flexDirection: "row",
  },
  content: {
    flexGrow: 1,
    paddingHorizontal: 24,
    paddingTop: spacings[12],
  },
});

type ExtraInfo = {
  projectId: string;
  onPressRemove: (membershipId: string) => void;
  canManageTeam: boolean;
  currentUserMembershipId: string;
};

const keyExtractor = ({ id }: ProjectMembershipFragment) => id;

const stickedToStartColumns: ColumnConfig<ProjectMembershipFragment, ExtraInfo>[] = [
  {
    width: 250,
    id: "name",
    title: t("membersTable.name"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { user } }) => {
      return (
        <Cell>
          <Avatar size={32} user={user} />
          <Space width={16} />

          <LakeText color={colors.gray[900]} numberOfLines={1} variant="medium">
            {Option.fromNullable(user?.fullName).getOr("-")}
          </LakeText>
        </Cell>
      );
    },
  },
];

const columns: ColumnConfig<ProjectMembershipFragment, ExtraInfo>[] = [
  {
    width: 350,
    id: "email",
    title: t("membersTable.email"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item: { email } }) =>
      email == null ? null : (
        <CopyableTextCell
          text={email}
          copyWording={t("copyButton.copyTooltip")}
          copiedWording={t("copyButton.copiedTooltip")}
        />
      ),
  },
  {
    width: 250,
    id: "role",
    title: t("membersTable.role"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item }) => (
      <Cell>
        <LakeTooltip placement="center" content={<RoleTooltip role={item.roleV2?.name as Role} />}>
          <Icon name="info-regular" size={20} color={colors.gray[900]} />
        </LakeTooltip>

        <Space width={16} />

        <LakeText color={colors.gray[600]} variant="medium" numberOfLines={1}>
          {isNotNullish(item.roleV2)
            ? match(`membership.role.${item.roleV2.name}`)
                .with(P.when(isTranslationKey), key => t(key))
                .otherwise(() => item.roleV2?.name ?? "")
            : ""}
        </LakeText>
      </Cell>
    ),
  },
];
const endColumns: ColumnConfig<ProjectMembershipFragment, ExtraInfo>[] = [
  {
    width: 250,
    id: "status",
    title: t("membersTable.status"),
    renderTitle: ({ title }) => <HeaderCell align="right" text={title} />,
    renderCell: ({ item: { type } }) => (
      <Cell align="right">
        {type === "INVITED" ? (
          <Tag color="shakespear">{t("membersTable.invitationSent")}</Tag>
        ) : (
          <Tag color="positive">{t("membersTable.active")}</Tag>
        )}
      </Cell>
    ),
  },
];

const actionColumn: ColumnConfig<ProjectMembershipFragment, ExtraInfo> = {
  width: 100,
  id: "action",
  title: t("membersTable.action"),
  renderTitle: ({ title }) => <HeaderCell align="right" text={title} />,
  renderCell: ({ item: { id }, extraInfo }) => <ActionsRow id={id} extraInfo={extraInfo} />,
};

const ActionsRow = ({
  id,
  extraInfo: { projectId, currentUserMembershipId, canManageTeam, onPressRemove },
}: {
  id: string;
  extraInfo: ExtraInfo;
}) => {
  return (
    <Cell align="right">
      {currentUserMembershipId !== id && (
        <TrackPressable action="Remove member">
          <LakeTooltip content={t("common.action.denied")} disabled={canManageTeam}>
            <Pressable
              disabled={!canManageTeam}
              onPress={event => {
                event.stopPropagation();
                event.preventDefault();
                onPressRemove(id);
              }}
            >
              {({ hovered }) => (
                <Icon
                  name="delete-regular"
                  color={hovered ? colors.negative.primary : colors.gray[200]}
                  size={16}
                />
              )}
            </Pressable>
          </LakeTooltip>
        </TrackPressable>
      )}

      <Space width={16} />

      <TrackPressable action="Edit member">
        <Pressable
          onPress={event => {
            event.stopPropagation();
            event.preventDefault();
            Router.push("ProjectAdminTeam", { projectId, editingMembershipId: id });
          }}
        >
          {({ hovered }) => (
            <Icon
              name="chevron-right-filled"
              color={hovered ? colors.gray[700] : colors.gray[200]}
              size={16}
            />
          )}
        </Pressable>
      </TrackPressable>
    </Cell>
  );
};

type Props = {
  currentUserMembershipId: string;
};

const getRowLink = ({
  item,
  extraInfo: { projectId },
}: LinkConfig<ProjectMembershipFragment, ExtraInfo>) => (
  <Link
    to={Router.ProjectAdminTeam({
      projectId,
      editingMembershipId: item.id,
    })}
  />
);

export const AdminPage = ({ currentUserMembershipId }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const canManageTeam = usePermissions(projectEnv).teamManagement.write;
  const route = Router.useRoute(adminRoutes);

  const [data, { refresh }] = useQuery(GetMembersPageDocument, { projectId });

  const [membershipIdToDelete, setMembershipIdToDelete] = useState<Option<string>>(Option.None());

  const [search, setSearch] = useState("");

  const onPressRemove = useCallback((membershipId: string) => {
    setMembershipIdToDelete(Option.Some(membershipId));
  }, []);

  const extraInfo: ExtraInfo = useMemo(
    () => ({
      onPressRemove,
      canManageTeam,
      projectId,
      currentUserMembershipId,
    }),
    [canManageTeam, onPressRemove, projectId, currentUserMembershipId],
  );

  useFiltersTracking({
    filters: { search },
    totalCount: data
      .toOption()
      .flatMap(result => result.toOption())
      .map(({ projectMemberships }) => projectMemberships.length)
      .getOr(0),
    loaded: true, // always true with useQueryWithErrorBoundary
  });

  const editingMembershipId = match(route)
    .with(
      { name: "ProjectAdminTeam" },
      ({ params: { editingMembershipId } }) => editingMembershipId ?? null,
    )
    .otherwise(() => null);

  return (
    <>
      {match(data)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <LoadingView />)
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => {
          const projectMemberships = data.projectMemberships.filter(membership => {
            if (isNullishOrEmpty(search.trim())) {
              return true;
            }
            return Array.filterMap(
              [membership.user?.fullName, membership.email, membership.user?.mobilePhoneNumber],
              Option.fromNullable,
            )
              .join(" ")
              .includes(search);
          });

          const roles = data?.project?.roles ?? [];

          const roleIdsByName: Record<Role, string> = roles.reduce(
            (acc, current) => {
              acc[current.name as Role] = current.id;
              return acc;
            },
            {} as Record<Role, string>,
          );

          return (
            <ScrollView
              horizontal={true}
              style={commonStyles.fill}
              contentContainerStyle={styles.base}
            >
              <View style={styles.container}>
                <ScrollView
                  role="main"
                  style={styles.contentContainer}
                  contentContainerStyle={styles.content}
                >
                  <Box direction="row" justifyContent="spaceBetween" alignItems="center">
                    <LakeHeading level={1} variant="h1">
                      {t("members.title")}
                    </LakeHeading>

                    <InformationTooltip text={t("members.subtitle")} />
                  </Box>

                  <Space height={24} />

                  <Box direction="row" alignItems="center">
                    <TrackPressable action="New member">
                      <LakeTooltip content={t("common.action.denied")} disabled={canManageTeam}>
                        <LakeButton
                          color="swan"
                          size="small"
                          icon="add-circle-filled"
                          onPress={() =>
                            Router.push("ProjectAdminTeam", { projectId, new: "true" })
                          }
                          disabled={!canManageTeam}
                        >
                          {t("common.new")}
                        </LakeButton>
                      </LakeTooltip>
                    </TrackPressable>

                    <Fill />

                    <LakeSearchField
                      placeholder={t("common.search")}
                      initialValue={search ?? ""}
                      onChangeText={setSearch}
                      debounceDuration={100}
                      maxWidth={250}
                      renderEnd={() => (
                        <Tag color="partner">{formatCount(projectMemberships.length)}</Tag>
                      )}
                    />
                  </Box>

                  <Space height={24} />

                  <CollapsibleLakeAlert
                    variant="warning"
                    title={t("membership.rolev2.alert.title")}
                  >
                    {t("membership.rolev2.alert.description")}
                  </CollapsibleLakeAlert>

                  <Space height={24} />

                  <VirtualizedList
                    variant="default"
                    marginHorizontal={negativeSpacings[24]}
                    extraInfo={extraInfo}
                    data={projectMemberships}
                    keyExtractor={keyExtractor}
                    columns={columns}
                    stickedToStartColumns={stickedToStartColumns}
                    stickedToEndColumns={[...endColumns, actionColumn]}
                    headerHeight={48}
                    rowHeight={48}
                    getRowLink={getRowLink}
                    renderEmptyList={() =>
                      search?.length > 0 ? (
                        <EmptyView
                          icon="clipboard-search-regular"
                          title={t("common.list.noResults")}
                          subtitle={t("common.list.noResultsSuggestion")}
                        />
                      ) : (
                        <EmptyView
                          icon="lake-inbox-empty"
                          title={t("onboarding.list.empty.title")}
                          subtitle={t("onboarding.list.empty.subtitle")}
                        />
                      )
                    }
                  />

                  <AddMemberPanel
                    visible={match(route)
                      .with({ name: "ProjectAdminTeam" }, ({ params }) => Boolean(params.new))
                      .otherwise(() => false)}
                    onPressClose={() => {
                      Router.push("ProjectAdminTeam", { projectId });
                    }}
                    onAdd={() => {
                      Router.push("ProjectAdminTeam", { projectId });
                      refresh();
                    }}
                    roleIdsByName={roleIdsByName}
                    canManageTeam={canManageTeam}
                  />

                  <EditMembersPanel
                    roleIdsByName={roleIdsByName}
                    editingMembershipId={editingMembershipId}
                    projectMemberships={projectMemberships}
                    canManageTeam={canManageTeam}
                    currentUserMembershipId={currentUserMembershipId}
                    onDelete={() => {
                      refresh();
                    }}
                  />
                </ScrollView>
              </View>
            </ScrollView>
          );
        })
        .exhaustive()}

      <DeleteMemberConfirmationModal
        membershipId={membershipIdToDelete}
        onCancel={() => setMembershipIdToDelete(Option.None())}
        onDelete={() => {
          setMembershipIdToDelete(Option.None());
          refresh();
        }}
      />
    </>
  );
};
