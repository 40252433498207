import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { colors } from "@swan-io/lake/src/constants/design";
import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  link: {
    alignItems: "center",
    display: "inline-flex",
    flexDirection: "row",
  },
  legacyLink: {
    color: colors.current.primary,
  },
});

export const documentationLinks = {
  account: `https://docs.swan.io/topics/accounts/`,
  accountHolder: `https://docs.swan.io/topics/onboarding/account-holders/`,
  accountMembership: `https://docs.swan.io/topics/accounts/memberships/`,
  cards: `https://docs.swan.io/topics/cards/design/`,
  capitalDeposit: `https://docs.swan.io/topics/capital-deposits/`,
  companyAvailableCountries:
    "https://support.swan.io/en/articles/4551415-is-my-company-or-organization-eligible-to-open-an-account-legal-person",
  consentNotification: `https://docs.swan.io/topics/users/consent/#notifications`,
  fundingSource: `https://docs.swan.io/topics/accounts/funding/#sources`,
  merchant: `https://docs.swan.io/topics/merchants`,
  onboarding: `https://docs.swan.io/topics/onboarding/`,
  projectOwner: `https://docs.swan.io/topics/projects/#owner`,
  paymentControl: `https://docs.swan.io/developers/using-api/payment-control`,
  paymentLink: `https://docs.swan.io/topics/merchants/online/cards/guide-create-link`,
  receivedDirectDebitMandate: `https://docs.swan.io/topics/payments/direct-debit/#mandates`,
  sandboxUsers: `https://docs.swan.io/developers/tools/sandbox-users`,
  serverConsent: `https://docs.swan.io/topics/users/consent/#s2s`,
  simulatorApi: `https://docs.swan.io/developers/tools/testing-api`,
  simulatorCapitalDeposit: `https://docs.swan.io/topics/capital-deposits/`,
  simulatorCard: `https://docs.swan.io/topics/payments/cards/`,
  simulatorCardPayment: `https://docs.swan.io/topics/payments/cards/`,
  simulatorCardXPay: `https://docs.swan.io/topics/cards/digital/`,
  simulatorCompanyHolders: `https://docs.swan.io/topics/onboarding/account-holders/#verification-process`,
  simulatorSepaCredit: `https://docs.swan.io/topics/payments/credit-transfers/sepa/`,
  simulatorIct: `https://docs.swan.io/topics/payments/credit-transfers/international/`,
  simulatorSepaDebit: `https://docs.swan.io/topics/payments/direct-debit/`,
  simulatorSupportingCollectionStatus: `https://docs.swan.io/topics/onboarding/documents/`,
  simulatorBilling: `https://docs.swan.io/topics/accounts/billing/`,
  supportingDocumentCollection: `https://docs.swan.io/topics/onboarding/documents`,
  transactions: `https://docs.swan.io/topics/accounts/#transactions`,
  userIdentifications: `https://docs.swan.io/topics/users/identifications/`,
  userIdentificationsExpert: `https://docs.swan.io/topics/users/identifications/#levels-processes`,
  userIdentificationsQes: `https://docs.swan.io/topics/users/identifications/#levels-processes`,
  userIdentificationsPvid: `https://docs.swan.io/topics/users/identifications/#levels-processes`,
  users: `https://docs.swan.io/topics/users/`,
  webhook: `https://docs.swan.io/developers/using-api/webhooks`,
} as const;

export type DocumentationName = keyof typeof documentationLinks;

type LegacyProps = {
  children: string;
  to: DocumentationName;
};

export const DocumentationLink = ({ children, to }: LegacyProps) => (
  <Link to={documentationLinks[to]} style={[styles.link, styles.legacyLink]} target="blank">
    {children}
  </Link>
);

type Props = {
  children: string;
  color?: string;
  to: DocumentationName;
  underline?: boolean;
};

export const LakeDocumentationLink = ({ children, color = colors.current.primary, to }: Props) => (
  <Link target="blank" to={documentationLinks[to]} style={styles.link}>
    <LakeText color={color}>{children}</LakeText>
    <Space width={4} />
    <Icon color={color} name="open-filled" size={16} />
  </Link>
);
