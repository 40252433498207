import { AsyncData, Result } from "@swan-io/boxed";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { ScrollView } from "@swan-io/lake/src/components/ScrollView";
import { Space } from "@swan-io/lake/src/components/Space";
import { TabView } from "@swan-io/lake/src/components/TabView";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { backgroundColor, colors } from "@swan-io/lake/src/constants/design";
import {
  BadStatusError,
  EmptyResponseError,
  NetworkError,
  Request,
  TimeoutError,
  badStatusToError,
  emptyToError,
} from "@swan-io/request";
import { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { AccessDeniedPage } from "../pages/AccessDeniedPage";
import { t } from "../utils/i18n";
import { Router, insightsRoutes } from "../utils/routes";
import { ErrorView } from "./ErrorView";
import { Iframe } from "./Iframe";
import { Redirect } from "./Redirect";
import { Menu, Sidebar } from "./Sidebar";

const styles = StyleSheet.create({
  base: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: "stretch",
  },
  contentContainer: {
    borderRightWidth: 1,
    borderColor: colors.gray[50],
    backgroundColor: backgroundColor.default,
    flexGrow: 1,
    paddingHorizontal: 24,
  },
  container: {
    flexGrow: 1,
    flexShrink: 1,
    maxWidth: 2560,
    marginHorizontal: "auto",
    flexDirection: "row",
  },
  iframe: {
    flexGrow: 1,
  },
  empty: {
    flexGrow: 1,
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
  },
});

const endpointsByRoute: Record<(typeof insightsRoutes)[number], string> = {
  InsightsOverview: "overview",
  InsightsAllTransactions: "allTransactions",
  InsightsCreditTransfer: "transactionCreditTransfers",
  InsightsCards: "transactionCards",
  InsightsOnboardingAndAccounts: "onboardingAccountVerification",
  InsightsIdentityVerification: "identityVerification",
  InsightsConsents: "consents",
};

export const LiveInsightsArea = () => {
  const { projectId, projectEnv } = useProjectInfo();
  const route = Router.useRoute(insightsRoutes);
  const canViewInsights = usePermissions(projectEnv).insights.read;

  const menu: Menu = [
    {
      matchRoutes: ["InsightsOverview"],
      to: Router.InsightsOverview({ projectId, projectEnv }),
      icon: "board-regular",
      iconActive: "board-filled",
      name: t("menu.overview"),
    },
    {
      matchRoutes: ["InsightsAllTransactions", "InsightsCards", "InsightsCreditTransfer"],
      to: Router.InsightsAllTransactions({ projectId, projectEnv }),
      icon: "payment-regular",
      iconActive: "payment-filled",
      name: t("menu.transactionsAndCards"),
    },
    {
      matchRoutes: ["InsightsOnboardingAndAccounts"],
      to: Router.InsightsOnboardingAndAccounts({ projectId, projectEnv }),
      icon: "task-list-square-ltr-regular",
      iconActive: "task-list-square-ltr-filled",
      name: t("menu.onboardingAndAccounts"),
    },
    {
      matchRoutes: ["InsightsIdentityVerification"],
      to: Router.InsightsIdentityVerification({ projectId, projectEnv }),
      icon: "person-regular",
      iconActive: "person-filled",
      name: t("menu.identityVerification"),
    },
    {
      matchRoutes: ["InsightsConsents"],
      to: Router.InsightsConsents({ projectId, projectEnv }),
      icon: "lock-closed-regular",
      iconActive: "lock-closed-filled",
      name: t("menu.consents"),
    },
  ];

  const [frameUrl, setFrameUrl] = useState<
    AsyncData<Result<string, NetworkError | TimeoutError | BadStatusError | EmptyResponseError>>
  >(() => AsyncData.NotAsked());

  useEffect(() => {
    if (route == null) {
      setFrameUrl(AsyncData.NotAsked());
    } else {
      setFrameUrl(AsyncData.Loading());
      const name = route.name;
      const endpoint = endpointsByRoute[name];
      const url = `/api/metabase/${projectId}/${endpoint}`;
      const request = Request.make({
        url,
        method: "POST",
        responseType: "json",
      })
        .mapOkToResult(badStatusToError)
        .mapOkToResult(emptyToError)
        .mapOkToResult(res =>
          match(res as unknown)
            .with({ url: P.string }, ({ url }) => Result.Ok(url))
            .otherwise(() => Result.Error(new EmptyResponseError(url))),
        )
        .tap(result => setFrameUrl(AsyncData.Done(result)));
      return () => request.cancel();
    }
  }, [route, projectId]);

  const onIframeLoad = (event: UIEvent) => {
    const target = event.target as unknown as HTMLIFrameElement;
    const document = target.contentWindow?.document;
    if (document != null) {
      const style = document.createElement("style");
      style.innerText = `.EmbedFrame { background-color: transparent !important; }
      .EmbedFrame-header { background-color: transparent !important; }
      .EmbedFrame-footer { background-color: transparent !important; }
      body, .Button { font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important; }
      .text-uppercase { letter-spacing: 0; }
      h2 { font-size: 22px !important; color: #16141a !important; margin-bottom: 16px !important; font-weight: 600 !important; }
      h3 { font-size: 16px !important; color: #16141a !important; margin-bottom: 16px !important; font-weight: 600 !important; }
      h4 { font-size: 14px !important; color: #16141a !important; margin-bottom: 16px !important; font-weight: 600 !important; }
      hr { border-top: 1px solid #e8e7e8 !important; margin: 1em 0 !important;}
      [data-testid="legend-caption"] > div > div { font-size: 14px !important; line-height: 1.5 !important; color: #454348 !important; letter-spacing: -0.011em }
      p, li { font-size: 14px !important; line-height: 1.4 !important; color: #454348 !important; letter-spacing: -0.011em }
      ul { padding: 0 !important; padding-left: 1em !important; }
      ul li { line-height: 1.2 !important; margin-bottom: 0.2em !important; }
      .text-card-markdown { padding: 24px !important; }
      .text-card-markdown a { display: inline !important; }`;
      document.head.append(style);
    }
  };

  if (route == null) {
    return <Redirect to={Router.InsightsOverview({ projectId, projectEnv })} />;
  }

  if (canViewInsights === false) {
    return <AccessDeniedPage />;
  }

  return (
    <ScrollView horizontal={true} style={commonStyles.fill} contentContainerStyle={styles.base}>
      <View style={styles.container}>
        <Sidebar menu={menu} />

        <View role="main" style={styles.contentContainer}>
          <Space height={16} />

          {match(route)
            .with(
              { name: "InsightsAllTransactions" },
              { name: "InsightsCards" },
              { name: "InsightsCreditTransfer" },
              () => (
                <TabView
                  tabs={[
                    {
                      label: t("insights.allTransactions"),
                      url: Router.InsightsAllTransactions({ projectId, projectEnv }),
                    },
                    {
                      label: t("insights.cards"),
                      url: Router.InsightsCards({ projectId, projectEnv }),
                    },
                    {
                      label: t("insights.creditTransfers"),
                      url: Router.InsightsCreditTransfer({ projectId, projectEnv }),
                    },
                  ]}
                  otherLabel={t("common.tabs.other")}
                />
              ),
            )
            .otherwise(() => null)}

          {match(frameUrl)
            .with(AsyncData.P.NotAsked, () => null)
            .with(AsyncData.P.Loading, () => <LoadingView color={colors.current[500]} />)
            .with(AsyncData.P.Done(Result.P.Error(P.select())), error => (
              <ErrorView error={error} />
            ))
            .with(AsyncData.P.Done(Result.P.Ok(P.select())), url =>
              url === "about:blank" ? (
                <View style={styles.empty}>
                  <EmptyView icon="board-regular" title={t("insights.empty")} />
                </View>
              ) : (
                <Iframe
                  title={route?.name ?? ""}
                  src={url}
                  style={styles.iframe}
                  onLoad={onIframeLoad}
                />
              ),
            )
            .exhaustive()}
        </View>
      </View>
    </ScrollView>
  );
};
